import { getApolloContext } from "../common";
import { ALL_MY_COURSES, ALL_MY_COURSES_PAGINATION } from "../graphql/queries";
import { MYCOURSE_STATUS } from "../constants";
import store from "../store";

export default {
  state: {
    myCourses: [],
    totalCourseCount: 0,
    myCoursesPagination: null
  },
  reducers: {
    setMyCourses(state, payload) {
      return {
        ...state,
        myCourses: payload
      };
    },
    setMyCoursesPagination(state, payload) {
      return {
        ...state,
        myCoursesPagination: payload
      };
    },
    setTotalCourseCount(state, payload) {
      return {
        ...state,
        totalCourseCount: payload
      };
    }
  },
  effects: dispatch => ({
    async getMyCourses(
      { last, status, sort, pageSize, first, currentPage } = {},
      rootState
    ) {
      const state = store.getState();
      let startPage = 1;

      const quotient = Math.floor((Number(currentPage) - 1) / pageSize);
      if (quotient > 0) {
        startPage = quotient * pageSize + 1;
      }

      if (
        status === MYCOURSE_STATUS.completed &&
        state.dashboard.myCoursesPagination === null
      ) {
        const myCoursesPagination = await this.getMyCoursesPaginations({
          pageSize,
          startPage: startPage,
          status
        });
        if (myCoursesPagination)
          this.setMyCoursesPagination(myCoursesPagination);
      }

      const { general: client } = rootState.common.apollo.client;

      let after = null;
      if (currentPage !== 1 && status === MYCOURSE_STATUS.completed) {
        if (state.dashboard.myCoursesPagination === null) {
          const { pages } = await this.getMyCoursesPaginations({
            pageSize,
            startPage: startPage,
            status
          });
          after = pages[Number(currentPage) - 1].after;
        } else {
          after =
            state.dashboard.myCoursesPagination.pages[Number(currentPage) - 1]
              .after;
        }
      }

      try {
        const {
          data: { myCourses }
        } = await client.query({
          query: ALL_MY_COURSES,
          context: await getApolloContext(),
          fetchPolicy: "network-only",
          variables: {
            first,
            last,
            status,
            sort,
            after
          }
        });

        const { edges, edgeCount } = myCourses;
        const newMyCourses = edges.map((val, idx) => val.node);
        this.setMyCourses(newMyCourses);
        this.setTotalCourseCount(edgeCount);
      } catch ({ graphQLErrors, message }) {
        console.error("getMyCourses", message);
      }
    },
    async getMyCoursesPaginations(
      { pageSize, startPage, status } = {},
      rootState
    ) {
      const { general: client } = rootState.common.apollo.client;
      try {
        const {
          data: { myCoursesPagination }
        } = await client.query({
          query: ALL_MY_COURSES_PAGINATION,
          context: await getApolloContext(),
          fetchPolicy: "network-only",
          variables: {
            pageSize,
            startPage,
            status
          }
        });

        return myCoursesPagination;
      } catch ({ graphQLErrors, message }) {
        console.error("getMyCoursesPaginations", message);
      }
    }
  })
};
