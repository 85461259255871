import naverpayImg from "../src/images/img-naverpay@3x.jpg";

export const MY_QUESTIONS_PAGE_COUNT = 5;
export const MY_ANSWERS_PAGE_COUNT = 5;
export const MY_COMMENTS_PAGE_COUNT = 5;
// export const MY_COMPLETED_COURSES_PAGE_COUNT = 5;
// export const ADMIN_COURSE_STATES_PAGE_COUNT = 5;

export const STEP_QUESTIONS_PAGE_COUNT = 5;
export const STEP_ANSWERS_PAGE_COUNT = 5;
export const STEP_COMMENTS_PAGE_COUNT = 5;

export const CONTENTS_COUNT_PER_PAGE_FOR_MY_QUESTIONS = 15;
export const CONTENTS_COUNT_PER_PAGE_FOR_MY_ANSWERS = 10;
export const CONTENTS_COUNT_PER_PAGE_FOR_MY_COMMENTS = 10;
// export const CONTENTS_COUNT_PER_PAGE_FOR_MY_COMPLETED_COURSES = 10;
export const CONTENTS_COUNT_PER_PAGE_FOR_ADMIN_COURSE_STATES = 10;

export const CONTENTS_COUNT_PER_PAGE_FOR_STEP_QUESTIONS = 5;
export const CONTENTS_COUNT_PER_PAGE_FOR_STEP_ANSWERS = 5;
export const CONTENTS_COUNT_PER_PAGE_FOR_STEP_COMMENTS = 5;
export const TOAST_AUTOCLOSE_TIME = 2000;
export const TOAST_OPTION = closeTime => {
  return {
    autoClose: closeTime,
    pauseOnHover: false,
    hideProgressBar: true,
    closeOnClick: true
  };
};

export const QuestionSortTypeEnum = Object.freeze({
  CREATED_AT: { name: "최신", value: "created_at_reverse" },
  ANSWERS_COUNT: { name: "답변수", value: "answers_count_reverse" },
  MOST_POPULAR_QUESTIONS: { name: "좋아요", value: "most_popular_questions" },
  VIEW_COUNT: { name: "조회수", value: "view_count_reverse" }
});

export const AnswerSortTypeEnum = Object.freeze({
  CREATED_AT: {
    name: "최신",
    value: "created_at_reverse",
    questionSort: "latest_i_answered"
  },
  MOST_POPULAR_ANSWERS: {
    name: "좋아요",
    value: "most_popular_answers",
    questionSort: "most_popular_i_answered"
  }
});

export const CommentSortTypeEnum = Object.freeze({
  CREATED_AT: {
    name: "최신",
    value: "created_at_reverse",
    answerSort: "",
    questionSort: ""
  }
});

export const LANGUAGE_TAGS = ["HTML", "CSS", "Python", "Etc"];

//mycourses
export const MY_COURSE_PAGINATION_PAGESIZE = 10;

export const MYCOURSE_STATUS = Object.freeze({
  learning: "NOTEXPIRED",
  completed: "EXPIRED"
});

export const MYCOURSE_SORT = Object.freeze({
  learning: "RECENTLY_COMPLETED_STEP",
  completed: "REGISTER_DATE_DESC"
});

//paments
export const PAYMENTS_PAGINATION_PAGESIZE = 5;

export const PAYMENTS_INFO = Object.freeze({
  pay_method: "card",
  pg: "kcp.IP0AA",
  methods: [
    {
      col: 5,
      id: "creditcard",
      name: "신용카드"
    },
    // {
    //   col: 5,
    //   img: naverpayImg,
    //   id: "naver",
    //   pg: "naverpay",
    //   name: "네이버페이"
    // },
    {
      col: 4,
      id: "globalpay",
      pg: "paypal",
      name: "해외결제 (PayPal)"
    },
    {
      col: 5,
      id: "virtual",
      pay_method: "vbank",
      name: "가상계좌"
    },
    {
      col: 5,
      id: "transfer",
      pay_method: "trans",
      name: "실시간 계좌이체"
    }
  ]
});

export const ALL_PAYMENTS_STATUS = Object.freeze({
  company: "COMPANY",
  personal: "PERSONAL",
  free: "FREE"
});

export const CONTACT_US_TICKET_TYPE = Object.freeze({
  collaboration: "Collaboration",
  oneToOne: "OneToOne",
  payment: "Payment",
  etc: "ETC"
});

//coupon
export const COUPON_PAGINATION_PERPAGE = 10;
export const COUPON_PAGINATION_PAGESIZE = 5;

//b2b organization
export const ALPACO_CNAME = "alpaco";
export const KBBANK_CNAME = "kbbank";
