import React, { Component } from "react";
import imgLoadingscene02 from "../../images/img-quizgradingscene-02.svg";
import imgLoadingscene03 from "../../images/img-quizgradingscene-03.svg";
import imgLoadingscene04 from "../../images/img-quizgradingscene-04.svg";
import imgLoadingsceneB2B from "../../images/img-b2b-loadingscene-01.svg";

export default class LoadingQuiz extends Component {
  constructor(props) {
    super(props);

    props.organization
      ? (this.state = {
          imgs: [imgLoadingsceneB2B],
          countIndex: 0
        })
      : (this.state = {
          imgs: [imgLoadingscene02, imgLoadingscene03, imgLoadingscene04],
          countIndex: 0
        });
  }

  componentDidMount() {
    const { organization } = this.props;
    let cnt = parseInt(Math.random() * 10) % 3;

    if (!organization) {
      this.intervalImage = setInterval(() => {
        const that = this;
        if (cnt < that.state.imgs.length - 1) {
          cnt += 1;
        } else {
          cnt = 0;
        }

        this.setState({
          countIndex: cnt
        });
      }, 3000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalImage);
  }

  render() {
    const { organization } = this.props;
    let dimension = "position-fixed vw-100 vh-100 bg-transparent";
    let dimension_bg = "position-fixed vw-100 vh-100 bg-dark";

    return (
      <>
        <div
          style={{
            top: 0,
            left: 0,
            zIndex: 98,
            opacity: 0.8,
            backgroundColor: "#1e1e1e"
          }}
          className={`d-flex justify-content-center align-items-center ${dimension_bg}`}
        ></div>
        <div
          style={{
            top: 0,
            left: 0,
            zIndex: 99,
            backgroundColor: "#1e1e1e"
          }}
          className={`loading-wrapper d-flex justify-content-center align-items-center ${dimension}`}
        >
          <div
            className="ideloadingscene-bg align-items-center"
            style={{ height: "530px" }}
          >
            <>
              <div className="img_loadingscene01">
                <img
                  src={this.state.imgs[this.state.countIndex]}
                  alt="loading-scene"
                />
              </div>
              <div
                className="loading-text justify-content-center align-items-center"
                style={{ height: "44px", maringTop: "28px" }}
              >
                {organization ? "답안 저장중···" : "퀴즈 채점중···"}
              </div>
            </>
          </div>
        </div>
      </>
    );
  }
}
