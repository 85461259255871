import jwtDecode from "jwt-decode";
import moment from "moment/min/moment-with-locales";

import {
  getAccessToken,
  getApolloContext,
  getRefreshToken,
  removeTokens,
  setAccessToken,
  setTokens
} from "../common";
import { BASIC_AUTH } from "../graphql/queries";
import {
  B2B_LOGIN,
  B2B_SIGNIN,
  B2B_SIGNIN_COURSE,
  GET_ACCESS_TOKEN
} from "../graphql/mutations";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import { TOAST_OPTION } from "../constants";

export default {
  state: {
    isAuthenticated: false,
    username: null,
    usernameIsChange: { value: false, canChange: true },
    authState: { requiredAddCreditCard: false },
    authBasic: {},
    modalNewbieWelcome: false
  },
  reducers: {
    authenticate(state, payload) {
      return {
        ...state,
        isAuthenticated: true
      };
    },
    setAuthBasic(state, payload) {
      return {
        ...state,
        authBasic: payload
      };
    },
    setUsernameIsChange(state, payload) {
      return {
        ...state,
        usernameIsChange: {
          ...state.usernameIsChange,
          ...payload
        }
      };
    },
    signout(state, payload) {
      removeTokens();

      return {
        ...state,
        isAuthenticated: false
      };
    },

    setAuthentication(state, payload) {
      return { ...state, isAuthenticated: payload.isAuthenticated };
    }
  },
  effects: dispatch => ({
    async checkAuthentication(params, rootState) {
      const context = getApolloContext();
      const { history } = params;
      if (Object.keys(context).length) {
        try {
          const {
            data: { my }
          } = await rootState.common.apollo.client.general.query({
            query: BASIC_AUTH,
            context: getApolloContext(),
            fetchPolicy: "network-only"
          });

          dispatch.gtm.gtmPushDataLayer({
            event: "UserIdentify",
            userHashid: my.hashid,
            userNickName: my.nickname
          });

          this.setAuthBasic(my);
          this.authenticate();
          this.refreshAccessToken();
        } catch ({ graphQLErrors, networkError }) {
          const { pathname, search, hash } = document.location;

          if (networkError) {
            this.signout();
          }

          graphQLErrors.forEach(async error => {
            switch (error.code) {
              case 1400000:
              case 1400004:
                if (error.message === "JSONWebTokenExpiredError") {
                  history.push("/auth/signin", {
                    from: `${pathname}${search}${hash}`
                  });
                }

                try {
                  await this.refreshAccessToken();
                  this.checkAuthentication({ history });
                } catch (err) {
                  this.signout();
                }
                break;
              case 1400001:
                this.signout();
                break;
              default:
              //Nothing
            }
          });
        }
      }
    },
    async refreshAccessToken(params, rootState) {
      const { exp } = jwtDecode(getAccessToken());
      const refreshToken = getRefreshToken();

      let now = parseInt(moment().format("x") / 1000);
      //let then = parseInt(moment(exp).format("x"));
      //let expireTerm = now.diff(then);

      // console.log(
      //   now,
      //   exp,
      //   "codelion.accessToken::",
      //   jwtDecode(localStorage.getItem(KEY_ACCESS_TOKEN)),
      //   "authBasic",
      //   rootState.auth.authBasic,
      //   exp - now
      // );

      if (exp - now < 1800) {
        if (refreshToken) {
          const { general: client } = rootState.common.apollo.client;
          const {
            data: {
              getAccessToken: { accessToken }
            }
          } = await client.mutate({
            mutation: GET_ACCESS_TOKEN,
            variables: { refreshToken: refreshToken }
          });

          setAccessToken(accessToken, true);
        }
      }
    },
    async updateAuthBasic(params, rootState) {
      const { authBasic } = rootState.auth;
      this.setAuthBasic({ ...authBasic, ...params });
    },
    async b2bSignIn(params, rootState) {
      const { general: client } = rootState.common.apollo.client;
      const { userkey, company, history } = params;
      try {
        const {
          data: {
            b2bLogin: { accessToken, refreshToken }
          }
        } = await client.mutate({
          mutation: B2B_LOGIN,
          variables: { company, user: userkey }
        });

        setTokens(true, accessToken, refreshToken);
        this.checkAuthentication({ history });
        history.push("/");
      } catch (graphQLErrors) {
        console.log(graphQLErrors);
      }
    },
    async b2bSignInAuth(params, rootState) {
      const { general: client } = rootState.common.apollo.client;
      const { userkey, company, history } = params;
      try {
        const {
          data: {
            b2bLogin: { accessToken, refreshToken }
          }
        } = await client.mutate({
          mutation: B2B_SIGNIN,
          variables: { company, user: userkey }
        });

        setTokens(true, accessToken, refreshToken);
        this.checkAuthentication({ history });
        history.push("/");
      } catch (graphQLErrors) {
        console.log(graphQLErrors);
      }
    },

    async b2bSignInCourse(params, rootState) {
      const { general: client } = rootState.common.apollo.client;
      const { company, p_num, id, global_id, history } = params;
      try {
        const {
          data: {
            b2bSignIn: { accessToken, refreshToken, globalId }
          }
        } = await client.mutate({
          mutation: B2B_SIGNIN_COURSE,
          variables: { company, user: id, pNum: p_num, globalId: global_id }
        });

        console.log(
          "accessToken, refreshToken, globalId:",
          accessToken,
          refreshToken,
          globalId
        );
        setTokens(true, accessToken, refreshToken);
        this.checkAuthentication({ history });
        history.push(`/course/${globalId}`);
      } catch (graphQLErrors) {
        toast.error(JSON.stringify(graphQLErrors), TOAST_OPTION(2000));
        console.log(graphQLErrors);
      }
    },

    restoreUsernameChange: debounce(
      (
        params,
        {
          auth: {
            usernameIsChange: { canChange }
          }
        }
      ) => {
        dispatch.auth.setUsernameIsChange(
          canChange ? { value: false } : { canChange: true }
        );
      },
      100
    )
  })
};
