import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { terminalTabs, typesForWeb, webOutputModalModes } from "../../common";
import WebConsole from "./WebConsole";
import { ReactComponent as WebOutputIcon } from "../../images/btn-ide-output-blower-off.svg";
import { ReactComponent as WebOutputOnIcon } from "../../images/btn-ide-output-blower-hover.svg";

import "@dash4/react-xterm/lib/ReactXterm.css";

class Terminals extends Component {
  organization = null;

  render() {
    const {
      leftTab,
      classType,
      terminals,
      webOutputModal,
      setTerminals,
      setWebOutputModal,
      serviceMode,
      terminalType,
      audioPlayer,
      changeServiceMode,
      sectors,
      ws,
      gtmPushDataLayer,
      authBasic: { organization }
    } = this.props;

    this.organization = organization;

    const labelTeacher = terminalTabs["TEACHER"];
    const labelStudent = terminalTabs["STUDENT"];

    return (
      <div
        className={
          classType === "learning"
            ? "terminals-container d-flex flex-column"
            : "terminals-container d-none"
        }
      >
        <div
          className="terminal-container d-flex h-100"
          ref={sectors.TERMINAL_TAB.ref}
          style={{
            height: 40,
            zIndex: 1,
            minHeight: 40,
            maxHeight: 40
          }}
        >
          <ul className="list-group list-group-horizontal list-unstyled d-inline-flex flex-grow-1">
            <li
              className={`d-flex align-items-center${
                labelTeacher === this.props.terminals.currentTab
                  ? " active"
                  : ""
              }`}
              onClick={() => {
                if (serviceMode !== "STUDENT") {
                  setTerminals({ currentTab: labelTeacher });
                }

                gtmPushDataLayer({
                  event: "ideTerminalCourseTerminalClick"
                });
              }}
              style={{ minWidth: "100px" }}
            >
              {labelTeacher}
            </li>

            <li
              className={`d-flex align-items-center${
                labelStudent === this.props.terminals.currentTab
                  ? " active"
                  : ""
              }`}
              onClick={async () => {
                if (serviceMode === "TEACHER") {
                  await changeServiceMode({ mode: "STUDENT" });
                }
                setTerminals({ currentTab: labelStudent });

                gtmPushDataLayer({
                  event: "ideTerminalMyTerminalClick"
                });
              }}
              style={{ minWidth: "100px" }}
            >
              {labelStudent}
            </li>
            <li
              className="d-flex align-items-center"
              style={{ marginLeft: "-3px" }}
            >
              <span
                className="d-flex border-0 text-decoration-none"
                style={{
                  cursor: "default",
                  color: serviceMode === "TEACHER" ? "#ff9300" : "#00ffdd",
                  backgroundColor: "#1e1e1e"
                }}
              >
                {serviceMode !== "TEACHER" && !this.organization && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="24"
                    viewBox="0 0 16 16"
                    className="d-flex"
                  >
                    <path
                      fill="currentColor"
                      d="M.727 10.182c.402 0 .727.327.727.728v1.454c0 .402-.325.728-.727.728-.402 0-.727-.326-.727-.728V10.91c0-.401.325-.728.727-.728zM7.675.077c.204-.103.446-.103.65 0l7.273 3.636c.256.13.414.395.401.681-.011.286-.19.54-.456.646l-2.452.98v3.566c0 .276-.156.527-.402.651-1.25.624-2.535 1.396-4.689 1.396-2.153 0-3.439-.772-4.689-1.396-.246-.124-.402-.375-.402-.65V6.02L1.455 5.44v3.118c0 .401-.326.728-.728.728-.402 0-.727-.327-.727-.728V4.364c0-.074.034-.226.078-.314.066-.138.178-.264.324-.337zm3.962 6.525L8.27 7.949C8.183 7.984 8.092 8 8 8c-.092 0-.183-.016-.27-.051L4.364 6.602v2.535l.709.354c1.832.917 4.02.917 5.855 0l.709-.354V6.602zM8 1.542L2.501 4.29l5.5 2.2 5.498-2.2L8 1.541z"
                    />
                  </svg>
                )}
                {serviceMode === "TEACHER" ? (
                  audioPlayer.playReady ? (
                    <div className="d-flex">
                      <span
                        className="d-flex"
                        style={{
                          cursor: "default",
                          color: "#00ffdd"
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          className="align-self-center justify-content-center align-items-center"
                        >
                          <path
                            fill="#0FD"
                            fillRule="evenodd"
                            d="M12 6L4 10.667 4 1.333z"
                          />
                        </svg>
                        &nbsp;[재생버튼]을 눌러 수업을 시작해 주세요.
                      </span>
                    </div>
                  ) : (
                    `강의모드`
                  )
                ) : (
                  <div className="d-flex">
                    {!this.organization ? (
                      <>학생모드</>
                    ) : (
                      <span
                        className="d-flex"
                        style={{
                          cursor: "default",
                          color: "#00ffdd"
                        }}
                      >
                        <div
                          className="align-self-center justify-content-center align-items-center"
                          style={{
                            width: "6px",
                            height: "6px",
                            backgroundColor: "#00ffdd",
                            borderRadius: 5
                          }}
                        ></div>
                        &nbsp; 강의를 이어서 수강하시려면 &nbsp;{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          className="align-self-center justify-content-center align-items-center"
                        >
                          <path
                            fill="#0FD"
                            fillRule="evenodd"
                            d="M12 6L4 10.667 4 1.333z"
                          />
                        </svg>
                        &nbsp;[재생버튼]을 눌러주세요.
                      </span>
                    )}
                  </div>
                )}
              </span>
            </li>
          </ul>
          <div className="d-flex" style={{ height: 40 }}>
            <div className="flex-grow-1" />
            {(webOutputModal.mode === webOutputModalModes.MINIMIZED ||
              webOutputModal.mode === webOutputModalModes.GENERAL) && (
              <Button
                variant="link"
                className="border-0 text-decoration-none"
                onClick={() => {
                  gtmPushDataLayer({
                    event: "ideFunctionMinibrowserClick"
                  });
                  if (serviceMode === "STUDENT") {
                    setWebOutputModal({
                      mode:
                        webOutputModal.mode === webOutputModalModes.MINIMIZED
                          ? webOutputModalModes.GENERAL
                          : webOutputModalModes.MINIMIZED
                    });
                  }
                  //refreshSlideDimension();
                }}
              >
                {webOutputModal.mode === webOutputModalModes.MINIMIZED ? (
                  <WebOutputIcon />
                ) : (
                  <WebOutputOnIcon />
                )}
              </Button>
            )}
            {typesForWeb.find(t => t.value === terminalType) && <WebConsole />}
          </div>
        </div>
        <div
          className={`flex-grow-1 term-container${
            terminals.currentTab === terminalTabs.TEACHER ? "" : " d-none"
          }`}
          style={{
            height: terminals.containerHeight
              ? terminals.containerHeight
              : "auto",
            maxHeight: terminals.containerHeight
              ? terminals.containerHeight
              : "auto",
            paddingLeft: "3px",
            backgroundColor: "#000"
          }}
          ref={sectors.TERMINAL.ref}
        />
        <div
          className={`flex-grow-1 term-container${
            terminals.currentTab === terminalTabs.STUDENT ? "" : " d-none"
          }`}
          style={{
            height: terminals.containerHeight
              ? terminals.containerHeight
              : "auto",
            maxHeight: terminals.containerHeight
              ? terminals.containerHeight
              : "auto",
            paddingLeft: "3px"
          }}
        >
          <iframe
            src={leftTab !== "quiz" ? ws.terminal : ""}
            width="100%"
            height={
              terminals.containerHeight ? terminals.containerHeight : "auto"
            }
            allowFullScreen
            frameBorder={0}
            marginWidth={0}
            marginHeight={0}
            data-hj-allow-iframe=""
          />
        </div>
      </div>
    );
  }
}

const mapState = ({
  common: { ws },
  ide: { leftTab, terminals, webOutputModal, classType },
  viditing: {
    serviceMode,
    elements: { terminalType },
    audioPlayer
  },
  auth: { authBasic }
}) => ({
  ws,

  leftTab,
  terminals,
  webOutputModal,
  classType,

  serviceMode,
  terminalType,
  audioPlayer,

  authBasic
});
const mapDispatch = ({
  ide: { setTerminals, setWebOutputModal, refreshSlideDimension },
  viditing: { changeServiceMode },
  gtm: { gtmPushDataLayer }
}) => ({
  setTerminals,
  setWebOutputModal,
  refreshSlideDimension,
  changeServiceMode,
  gtmPushDataLayer
});

export default connect(mapState, mapDispatch)(Terminals);
