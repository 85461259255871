import React, { Component } from "react";
import { connect } from "react-redux";
import Split from "react-split-grid";
import Terminals from "./Terminals";

class GridForConsoles extends Component {
  render() {
    const {
      gridForConsoles,
      setGridForConsoles,
      sectors,
      classType
    } = this.props;

    return (
      <Split
        columnMinSizes={gridForConsoles.columnMinSizes}
        gridTemplateColumns={gridForConsoles.gridTemplateColumns}
        onDrag={(direction, track, style) => {
          //this.props.onResizeGridRow();
          console.log("onDrag:GridFroConsoles::", style);
          setGridForConsoles({ gridTemplateColumns: style });
        }}
        render={({ getGridProps, getGutterProps }) => {
          return (
            <div
              ref={this.gridForFiletree}
              {...getGridProps()}
              className="d-grid h-100"
            >
              {classType !== "evaluation" && <Terminals sectors={sectors} />}
              <div
                {...getGutterProps("column", 1)}
                className={`gutter gutter-column`}
              />
              <div style={{ backgroundColor: "white" }} />
            </div>
          );
        }}
      />
    );
  }
}

const mapState = ({ ide: { gridForConsoles, classType } }) => ({
  gridForConsoles,
  classType
});

const mapDispatch = ({ ide: { setGridForConsoles } }) => ({
  setGridForConsoles
});

export default connect(mapState, mapDispatch)(GridForConsoles);
