export default {
  state: {},
  reducers: {},
  effects: dispatch => ({
    async requestCoupon({ url, data, method = "GET" } = {}, rootState) {
      const res = await dispatch.common.requestTarangire({ url, data, method });
      return res;
    }
  })
};
