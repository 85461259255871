import React, { Component, createRef, lazy } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Navbar, Row, Alert } from "react-bootstrap";
import Split from "react-split-grid";
import ReactPlayer from "react-player";
import { Motion, spring } from "react-motion";
import debounce from "lodash/debounce";
import ScrollMemory from "react-router-scroll-memory";
import root from "window-or-global";
import moment from "moment/min/moment-with-locales";
import { toast } from "react-toastify";

import Loading from "../common/Loading";
import PersonalDropdown from "../common/PersonalDropdown";
import ModalNoOrYes from "../common/modals/NoOrYes";
import WebWorker from "../../workers/WebWorker";
import TimerWorker from "../../workers/TimerForStep";

import LeftTabs from "./LeftTabs";
import LeftTabContainer from "./left-tabs/Container";
import GridForNS from "./GridForNS";

import { isChrome, isSafari, isTablet } from "react-device-detect";

import { TOAST_OPTION } from "../../constants";

import { ReactComponent as Logo } from "../../images/img-logo-horizontal-wh-01.svg";
import { ReactComponent as IconTimeout } from "../../images/img-ide-video-timeout.svg";

import {
  EVENT_MESSAGE,
  DURATION_FORMAT,
  getClassTypeByBack,
  VIDITING_STATUS,
  MODAL_COMPLETED_TEXT,
  webOutputModalModes
} from "../../common";

const EVENT_RESIZE = `resize`;

export const DIMENSION_MIN = {
  width: 1024,
  height: 600,
  lectureWidth: 848,
  lectureHeight: 592
};
const IS_MOUNTED = `IS_MOUNTED`;

const ContactUs = lazy(() => import("../common/modals/ContactUs"));

class Container extends Component {
  state = {
    isLoading: true,
    dimensionWindow: {
      width: Infinity,
      height: Infinity,
      available: true,
      onceTime: false
    },
    time: 0,
    isPlayingMedia: false,
    isPausedMediaBySystem: false,
    isFinishedMedia: false,
    isShow: false,
    isShownModalDisconnected: false
  };

  organization = null;

  controlContactUs = () => {
    const { classType, setStatus } = this.props;

    if (classType === "media-only") {
      this.setState({
        ...this.state,
        isPlayingMedia: false,
        isShow: !this.state.isShow
      });
    } else if (classType === "learning") {
      setStatus(VIDITING_STATUS.PAUSED);

      this.setState({
        ...this.state,
        isShow: !this.state.isShow
      });
    } else {
      this.setState({
        ...this.state,
        isShow: !this.state.isShow
      });
    }
  };

  gridForLeftTab = createRef();
  initialDimension = {};
  mediaPlayer = createRef();

  onResizeWindow = debounce(ev => {
    const { clientHeight: height = 0, clientWidth: width = 0 } =
      root.document.body || {}; //document.querySelector(`#root`);
    const { classType, setWindowDimension, correctGrids } = this.props;
    const available =
      width >= DIMENSION_MIN.width && height >= DIMENSION_MIN.height;

    if (
      this.state.dimensionWindow.width !== width ||
      this.state.dimensionWindow.height !== height ||
      this.state.dimensionWindow.available !== available
    ) {
      if (!available && !this.state.onceTime) {
        this.setState({
          ...this.state,
          dimensionWindow: { width, height, available, onceTime: true }
        });
      } else {
        this.setState({
          ...this.state,
          dimensionWindow: { width, height, available }
        });
      }

      setWindowDimension({ width, height });

      if (available) {
        correctGrids();
      }

      if (classType !== "media-only") {
        if (IS_MOUNTED === ev) {
          const { gridForNS, editorContainer, setTerminals } = this.props;
          const editorContainerPartnerH = Number.parseInt(
            gridForNS.gridTemplateRows.split(" ")[2],
            10
          );
          this.initialDimension = {
            ...this.initialDimension,
            winH: height,
            editorContainerH: editorContainer.ref.current.scrollHeight - 40,
            editorContainerPartnerH,
            terminalContainerH: editorContainerPartnerH - 40 - 2 //terminals.container.current.clientHeight
          };
          setTerminals({
            containerHeight: this.initialDimension.terminalContainerH
          });
        } else if (ev !== "notAction") {
          const { refreshWebOutputModal, refreshSlideDimension } = this.props;
          this.onResizeGridRow(false);
          if (typeof refreshWebOutputModal === "function") {
            refreshWebOutputModal();
            refreshSlideDimension();
          }
        }

        try {
          const editorElement = this.props.editor.ref.getDomNode();

          if (editorElement) {
            let editorWidth =
              this.props.windowDimension.width -
              (this.props.gridForLeftTab.colSize +
                this.props.gridForFiletree.colSize +
                8);

            editorElement.style.width = `${editorWidth}px`;
            editorElement.style.height = `${this.props.editor.height}px`;
            this.props.editor.ref.layout();
          }
        } catch (e) {}
      }
    } else {
      if (classType !== "media-only") {
        if (IS_MOUNTED === ev) {
          const { gridForNS, editorContainer, setTerminals } = this.props;
          const editorContainerPartnerH = Number.parseInt(
            gridForNS.gridTemplateRows.split(" ")[2],
            10
          );
          this.initialDimension = {
            ...this.initialDimension,
            winH: height,
            editorContainerH: editorContainer.ref.current.scrollHeight - 40,
            editorContainerPartnerH,
            terminalContainerH: editorContainerPartnerH - 40 - 2 //terminals.container.current.clientHeight
          };
          setTerminals({
            containerHeight: this.initialDimension.terminalContainerH
          });
        } else if (ev !== "notAction") {
          const { refreshWebOutputModal, refreshSlideDimension } = this.props;
          this.onResizeGridRow(false);
          if (typeof refreshWebOutputModal === "function") {
            refreshWebOutputModal();
            refreshSlideDimension();
          }
        }

        try {
          const editorElement = this.props.editor.ref.getDomNode();

          if (editorElement) {
            let editorWidth =
              this.props.windowDimension.width -
              (this.props.gridForLeftTab.colSize +
                this.props.gridForFiletree.colSize +
                8);

            editorElement.style.width = `${editorWidth}px`;
            editorElement.style.height = `${this.props.editor.height}px`;
            this.props.editor.ref.layout();
          }
        } catch (e) {}
      }
    }
  }, 50);

  onResizeGridRow = (inline = true) => {
    const container = root.document.body; //document.querySelector(`#root`);
    const winH = container.clientHeight;

    const {
      gridForNS,
      quiz: { simpleHint },
      setEditorContainer,
      setTerminals
    } = this.props;

    const {
      editorContainerH,
      editorContainerPartnerH,
      terminalContainerH
    } = this.initialDimension;

    const diff = {
      winH: winH - this.initialDimension.winH,
      editorContainerPartnerH:
        Number.parseInt(gridForNS.gridTemplateRows.split(" ")[2], 10) -
        editorContainerPartnerH
    };

    setEditorContainer({
      height:
        editorContainerH -
        simpleHint.height +
        diff.winH -
        diff.editorContainerPartnerH
    });

    setTerminals({
      containerHeight: terminalContainerH + diff.editorContainerPartnerH
    });
    if (inline) {
      this.onResizeWindow();
    }
  };

  finishReset = async () => {
    const {
      classType,
      step,
      toggleModalStepIsCompleted,
      toggleModalStepIsCompletedText,
      updateVideoStepComplete,
      stepDuration
    } = this.props;

    if (classType === "media-only") {
      if (!this.state.isFinishedMedia) {
        this.setState({ ...this.state, isFinishedMedia: true });

        const {
          isCompletedStep,
          isCompletedCourse,
          typename,
          completed
        } = await updateVideoStepComplete({
          step: step.id,
          time: stepDuration.milliseconds,
          finish: false
        });

        if (typename) {
          if (!isCompletedStep) {
            toast.error(MODAL_COMPLETED_TEXT.NOT_COMPLETED, TOAST_OPTION(4000));
          } else {
            if (!completed) {
              toggleModalStepIsCompletedText({
                textGrade: "STEP"
              });
              toggleModalStepIsCompleted(true);
            } else {
              toggleModalStepIsCompletedText({
                textGrade: "STEP"
              });
              toggleModalStepIsCompleted(true);
            }
          }
        } else {
          if (isCompletedCourse) {
            toggleModalStepIsCompletedText({
              textGrade: "COURSE"
            });
            toggleModalStepIsCompleted(true);
          }
        }
      }
    } else {
      this.setState({ ...this.state, isFinishedMedia: false });
    }
  };

  onMessageTimer = async ({ data: { time, initialized } }) => {
    const {
      classType,
      step,
      toggleModalStepIsCompleted,
      toggleModalStepIsCompletedText,
      updateViditingStepComplete,
      updateVideoStepComplete,
      setStepDuration,
      status
    } = this.props;

    setStepDuration({ milliseconds: time });

    if (initialized) {
      setTimeout(() => {
        setStepDuration({ isShown: false });
      }, 0);
    }
    if (
      status !== VIDITING_STATUS.LOADING &&
      status !== VIDITING_STATUS.SEEK &&
      status !== VIDITING_STATUS.INIT
    ) {
      if (classType === "media-only" && this.mediaPlayer.current) {
        this.setState({ ...this.state, time });

        // console.log(
        //   "duration:",
        //   this.mediaPlayer.current.getDuration(),
        //   ":currentTime:",
        //   this.mediaPlayer.current.getCurrentTime(),
        //   ":worker-time:",
        //   time,
        //   ":isFinishedMedia:",
        //   this.state.isFinishedMedia
        // );

        if (
          this.mediaPlayer.current.getDuration() !==
            this.mediaPlayer.current.getCurrentTime() &&
          parseInt(Math.round(this.mediaPlayer.current.getDuration()) - 1) <=
            parseInt(Math.round(this.mediaPlayer.current.getCurrentTime())) &&
          parseInt(Math.round(this.mediaPlayer.current.getCurrentTime())) > 5 &&
          !this.state.isFinishedMedia
        ) {
          console.log("worker-time::", time);
          this.worker.postMessage({
            action: "stop"
          });

          this.setState({ ...this.state, isFinishedMedia: true });

          const {
            isCompletedStep,
            isCompletedCourse,
            typename,
            completed
          } = await updateVideoStepComplete({
            step: step.id,
            time,
            finish: false
          });

          if (typename) {
            if (!isCompletedStep) {
              toast.error(
                MODAL_COMPLETED_TEXT.NOT_COMPLETED,
                TOAST_OPTION(4000)
              );
            } else {
              if (!completed) {
                toggleModalStepIsCompletedText({
                  textGrade: "STEP"
                });
                toggleModalStepIsCompleted(true);
              } else {
                toggleModalStepIsCompletedText({
                  textGrade: "STEP"
                });
                toggleModalStepIsCompleted(true);
              }
            }
          } else {
            if (isCompletedCourse) {
              toggleModalStepIsCompletedText({
                textGrade: "COURSE"
              });
              toggleModalStepIsCompleted(true);
            }
          }
        }
      } else if (classType === "learning" && !this.state.isFinishedMedia) {
        if (
          parseInt(Math.round(this.props.scriptTime.duration / 1000)) !==
            parseInt(Math.round(this.props.scriptTime.lastPlayTime / 1000)) &&
          parseInt(Math.round(this.props.scriptTime.duration / 1000) - 1) <=
            parseInt(Math.round(this.props.scriptTime.lastPlayTime / 1000)) &&
          parseInt(Math.round(this.props.scriptTime.lastPlayTime / 1000)) > 5
        ) {
          this.setState({ ...this.state, isFinishedMedia: true });

          console.log("worker-time::", time);

          this.worker.postMessage({
            action: "stop"
          });

          const {
            isCompletedStep,
            isCompletedCourse,
            typename,
            completed
          } = await updateViditingStepComplete({
            step: step.id,
            time,
            finish: true
          });

          if (typename) {
            if (!isCompletedStep) {
              toast.error(
                MODAL_COMPLETED_TEXT.NOT_COMPLETED,
                TOAST_OPTION(4000)
              );
            } else {
              if (!completed) {
                toggleModalStepIsCompletedText({
                  textGrade: "STEP"
                });
                toggleModalStepIsCompleted(true);
              } else {
                toggleModalStepIsCompletedText({
                  textGrade: "STEP"
                });
                toggleModalStepIsCompleted(true);
              }
            }
          } else {
            if (isCompletedCourse) {
              toggleModalStepIsCompletedText({
                textGrade: "COURSE"
              });
              toggleModalStepIsCompleted(true);
            }
          }
        } else if (
          this.props.audioPlayer.onEnd &&
          parseInt(Math.round(this.props.audioPlayer.lastPlayTime / 1000)) > 5
        ) {
          this.setState({ ...this.state, isFinishedMedia: true });

          console.log("worker-time::", time);
          this.worker.postMessage({
            action: "stop"
          });

          const {
            isCompletedStep,
            isCompletedCourse,
            typename,
            completed
          } = await updateViditingStepComplete({
            step: step.id,
            time,
            finish: true
          });

          if (typename) {
            if (!isCompletedStep) {
              toast.error(
                MODAL_COMPLETED_TEXT.NOT_COMPLETED,
                TOAST_OPTION(4000)
              );
            } else {
              if (!completed) {
                toggleModalStepIsCompletedText({
                  textGrade: "STEP"
                });
                toggleModalStepIsCompleted(true);
              } else {
                toggleModalStepIsCompletedText({
                  textGrade: "STEP"
                });
                toggleModalStepIsCompleted(true);
              }
            }
          } else {
            if (isCompletedCourse) {
              toggleModalStepIsCompletedText({
                textGrade: "COURSE"
              });
              toggleModalStepIsCompleted(true);
            }
          }
        }
      }
    }
  };

  handleProgressAudioEnded = async time => {
    console.log("handleProgressAudioEnded", time);

    const {
      step,
      toggleModalStepIsCompleted,
      toggleModalStepIsCompletedText,
      updateViditingStepComplete,
      updateVideoStepComplete,
      classType
    } = this.props;

    this.worker.postMessage({
      action: "stop"
    });

    if (classType === "media-only" && !this.state.isFinishedMedia) {
      if (
        parseInt(Math.round(this.mediaPlayer.current.getCurrentTime())) > 10
      ) {
        const {
          isCompletedStep,
          isCompletedCourse,
          typename,
          completed
        } = await updateVideoStepComplete({
          step: step.id,
          time,
          finish: false
        });

        if (typename) {
          if (!isCompletedStep) {
            toast.error(MODAL_COMPLETED_TEXT.NOT_COMPLETED, TOAST_OPTION(4000));
          } else {
            if (!completed) {
              toggleModalStepIsCompletedText({
                textGrade: "STEP"
              });
              toggleModalStepIsCompleted(true);
            } else {
              toggleModalStepIsCompletedText({
                textGrade: "STEP"
              });
              toggleModalStepIsCompleted(true);
            }
          }
        } else {
          if (isCompletedCourse) {
            toggleModalStepIsCompletedText({
              textGrade: "COURSE"
            });
            toggleModalStepIsCompleted(true);
          }
        }
      }
    } else if (classType === "learning" && !this.state.isFinishedMedia) {
      const {
        isCompletedStep,
        isCompletedCourse,
        typename,
        completed
      } = await updateViditingStepComplete({
        step: step.id,
        time,
        finish: true
      });

      if (typename) {
        if (!isCompletedStep) {
          toast.error(MODAL_COMPLETED_TEXT.NOT_COMPLETED, TOAST_OPTION(4000));
        } else {
          if (!completed) {
            toggleModalStepIsCompletedText({
              textGrade: "STEP"
            });
            toggleModalStepIsCompleted(true);
          } else {
            toggleModalStepIsCompletedText({
              textGrade: "STEP"
            });
            toggleModalStepIsCompleted(true);
          }
        }
      } else {
        if (isCompletedCourse) {
          toggleModalStepIsCompletedText({
            textGrade: "COURSE"
          });
          toggleModalStepIsCompleted(true);
        }
      }
    }

    this.setState({ ...this.state, isFinishedMedia: true });

    setTimeout(await this.finishReset(), 500);
  };

  handleVisibilityChange = evt => {
    //const { isPlayingMedia } = this.state;
    const { classType } = this.props;

    let hidden = false;

    switch (evt.type) {
      case "visibilitychange":
        hidden = document.hidden;
        break;
      case "webkitvisibilitychange":
        hidden = document.webkitHidden;
        break;
      case "mozvisibilitychange":
        hidden = document.mozHidden;
        break;
      case "msvisibilitychange":
        hidden = document.msHidden;
        break;
      case "blur":
        hidden = true;
        break;
      case "pagehide":
        hidden = true;
        break;
      default:
        hidden = false;
      // case "focusout":
      //   hidden = true;
      //   break;
    }

    if (classType === "media-only") {
      // if (hidden) {
      //   if (this.mediaPlayer.current && isPlayingMedia) {
      //     this.setState({
      //       ...this.state,
      //       isPlayingMedia: false,
      //       isPausedMediaBySystem: true
      //     });
      //     this.worker.postMessage({ action: "hold" });
      //   }
      // } else {
      //   this.worker.postMessage({ action: "release" });
      // }
    } else if (classType === "learning") {
      // if (status === VIDITING_STATUS.PLAYING) {
      //   if (hidden) {
      //     setStatus(VIDITING_STATUS.PAUSED);
      //     this.worker.postMessage({ action: "hold" });
      //   } else {
      //     this.worker.postMessage({ action: "release" });
      //   }
      // } else {
      //   if (hidden) {
      //     this.worker.postMessage({ action: "hold" });
      //   } else {
      //     this.worker.postMessage({ action: "release" });
      //   }
      // }
    }
  };

  componentDidMount() {
    if (!isChrome && !isSafari) {
      this.props.history.push({
        pathname: "/browserInfo"
      });
    }

    const { setRandomScene, classType } = this.props;

    setRandomScene(Math.floor(Math.random() * 3 + 1));

    this.worker = new WebWorker(TimerWorker);
    this.worker.addEventListener(EVENT_MESSAGE, this.onMessageTimer, false);

    // if (classType === "learning" || classType === "media-only") {
    //   this.organization = organization;
    // }

    if ("hidden" in document)
      window.addEventListener("visibilitychange", this.handleVisibilityChange);
    else if ("mozHidden" in document)
      window.addEventListener(
        "mozvisibilitychange",
        this.handleVisibilityChange
      );
    else if ("webkitHidden" in document)
      window.addEventListener(
        "webkitvisibilitychange",
        this.handleVisibilityChange
      );
    else if ("msHidden" in document)
      window.addEventListener(
        "msvisibilitychange",
        this.handleVisibilityChange
      );

    // if ("blur" in window) {
    //   window.addEventListener("blur", this.handleVisibilityChange);
    // }
    if ("pagehide" in window) {
      window.addEventListener("pagehide", this.handleVisibilityChange);
    }

    this.loadClass(this.props.match.params.step, IS_MOUNTED);

    // if ("focusout" in window) {
    //   window.addEventListener("focusout", this.handleVisibilityChange);
    // }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: {
        params: { step: prevStep }
      },
      quiz: {
        simpleHint: { height: prevSimpleHintHeight }
      }
    } = prevProps;
    const {
      match: {
        params: { step }
      },
      quiz: {
        simpleHint: { height: simpleHintHeight }
      },
      ws,
      setWs,
      setStatus,
      contactUS,
      setContactUS
      // slide,
      // classType
    } = this.props;
    if (contactUS) {
      this.controlContactUs();
      setContactUS(false);
    }

    if (ws.isDisconnected) {
      setWs({ isDisconnected: false });
      setStatus(VIDITING_STATUS.PAUSED);

      console.log("ws.isDisconnected");
      this.setState({
        ...this.state,
        isShownModalDisconnected: true
      });
    }

    if (prevStep !== step) {
      this.props.setRandomScene(Math.floor(Math.random() * 3 + 1));
      this.loadClass(step, IS_MOUNTED);
    }
    if (prevSimpleHintHeight !== simpleHintHeight) {
      this.onResizeGridRow();
    }
    // if (classType === "learning" && slide.isMinimized) {
    //   this.onResizeWindow();
    // }
  }
  componentWillUnmount() {
    const { setRandomScene } = this.props;
    setRandomScene(Math.floor(Math.random() * 3 + 1));
    this.classUnmountData();

    window.removeEventListener(EVENT_RESIZE, this.onResizeWindow);

    if ("hidden" in document)
      window.removeEventListener(
        "visibilitychange",
        this.handleVisibilityChange
      );
    else if ("mozHidden" in document)
      window.removeEventListener(
        "mozvisibilitychange",
        this.handleVisibilityChange
      );
    else if ("webkitHidden" in document)
      window.removeEventListener(
        "webkitvisibilitychange",
        this.handleVisibilityChange
      );
    else if ("msHidden" in document)
      window.removeEventListener(
        "msvisibilitychange",
        this.handleVisibilityChange
      );

    // if ("blur" in window) {
    //   window.removeEventListener("blur", this.handleVisibilityChange);
    // }
    if ("pagehide" in window) {
      window.removeEventListener("pagehide", this.handleVisibilityChange);
    }

    // if ("focusout" in window) {
    //   window.removeEventListener("focusout", this.handleVisibilityChange);
    // }

    this.worker.removeEventListener(EVENT_MESSAGE, this.onMessageTimer);
    this.worker.terminate();
  }

  classUnmountData = () => {
    const {
      closeSocket,
      classType,
      audioPlayer,
      setStatus,
      setResetBaseData,
      setSlide,
      setWebOutputModal,
      setAudioPlayer,
      toggleModalStepIsCompleted,
      setGridForNS
    } = this.props;

    setAudioPlayer({
      playbackRateMem: audioPlayer.playbackRate,
      playbackRate: 1
    });

    if (this.state.isFinishedMedia) {
      this.setState({ ...this.state, isFinishedMedia: false });
    }

    if (classType === "learning") {
      setStatus(VIDITING_STATUS.PAUSED);

      setWebOutputModal({
        mode: webOutputModalModes.NONE,
        initializeUrl: true
      });

      setGridForNS({ gridTemplateRows: "1fr 4px 180px 48px" });

      setAudioPlayer({ lastPlayTime: 0, onEnd: false });
      // setScriptTime({
      //   duration: 0,
      //   lastPlayTime: 0,
      //   durationGap: 1
      // });

      setResetBaseData();
      closeSocket();

      toggleModalStepIsCompleted(false);

      setSlide({
        width: 300,
        height: 300,
        widthForNormal: 0,
        heightForNormal: 0,
        isVisible: false,
        isMinimized: true,
        widthForMini: 0,
        heightForMini: 0,
        currentSlideUrl: null
      });
    }
  };

  dimmedOff = e => {
    e.stopPropagation();
    this.setState({
      ...this.state,
      dimensionWindow: { available: true }
    });
  };

  loadClass = async (step, isMounted) => {
    const {
      history,
      client,
      getClassByStepType,
      getTypenameByBackNode,
      setClassType,
      setupStep,
      initialize,
      classType: classTypeMain,
      setStepDuration
    } = this.props;

    this.setState({
      ...this.state,
      isLoading: true,
      isPlayingMedia: false
    });

    let classType = null;

    if (isMounted !== "notAction") {
      setStepDuration({ isShown: false });

      initialize();

      classType = getClassTypeByBack(
        await getTypenameByBackNode({ gid: step, history })
      );
      setClassType(classType);
    } else {
      classType = classTypeMain;
    }

    const data = await getClassByStepType({ classType, step, history });

    if (data) {
      console.log(
        "data.step:",
        data.step,
        data.step.terminalType,
        classType,
        this.props.scriptTime,
        this.props.audioPlayer
      );
      //terminalType

      if (classType === "learning") {
        if (!data.step.terminalType) {
          toast.error("터미널이 세팅되지 않았습니다.", TOAST_OPTION(2000));
          window.history.back();
          return;
        }
        this.props.setElements({ terminalType: data.step.terminalType });
      }
      setupStep(data.step);

      if (isMounted !== "notAction") {
        window.removeEventListener(EVENT_RESIZE, this.onResizeWindow);
      }

      setTimeout(() => {
        this.setState(
          {
            ...this.state,
            isLoading: false,

            // video step에 자동재생 속성이 추가될 때 활용
            isPlayingMedia: true
          },
          () => {
            if (isMounted !== "notAction") {
              window.addEventListener(EVENT_RESIZE, this.onResizeWindow);
              this.onResizeWindow(isMounted);
            }

            this.worker.postMessage({ action: "set", time: 0 });
          }
        );
      }, 0);
    }
  };

  render() {
    const {
      history,

      // from common model
      addNotification,

      // from ide model
      leftTab,
      step,
      gridForLeftTab,
      classType,
      webOutputModal,
      isShownModalStepIsCompleted,
      setGridForLeftTab,
      refreshWebOutputModal,
      toggleModalStepIsCompleted,
      stepDuration,
      nextStep,
      gtmPushDataLayer,
      setQuiz,
      authBasic: { organization }
    } = this.props;
    const {
      isLoading,
      dimensionWindow: {
        width: winW,
        height: winH,
        available: availableResolution
      },
      isPlayingMedia,
      isShownModalDisconnected
    } = this.state;

    const quizStyle = `gridTemplateColumns: "296px 4px 1fr"`;

    return (
      <div className="d-flex flex-column flex-grow-1 class-container overflow-hidden">
        <ScrollMemory />
        <Navbar className={"p-0"}>
          <Row
            className="flex-grow-1 m-0"
            onClick={() => {
              addNotification({ a: new Date().getTime() });
            }}
          >
            <Navbar.Brand as={Link} to="/" className={"py-2 pl-3"}>
              <Logo className="d-block" />
              {/*<img src={imgLogo} alt="" className="d-block" />*/}
            </Navbar.Brand>
          </Row>
          <Row className="m-0">
            <Col className="d-flex align-items-center justify-content-end">
              {/*<NotificationDropdown />*/}
              <PersonalDropdown />
              {/*<div className="vertical-rule" />*/}
              {/*<LocaleDropdown />*/}
            </Col>
          </Row>
        </Navbar>
        <div className="d-flex flex-grow-1">
          <>
            {isLoading ? (
              <Loading isNotFixed />
            ) : (
              <>
                <LeftTabs controlContactUs={this.controlContactUs} />
                <div className="flex-grow-1">
                  <Motion
                    style={{
                      x: spring(gridForLeftTab.colSize, {
                        stiffness: 1000,
                        damping: 100
                      })
                    }}
                    onRest={
                      typeof refreshWebOutputModal === "function" &&
                      refreshWebOutputModal
                    }
                  >
                    {({ x }) => (
                      <Split
                        columnMinSizes={gridForLeftTab.minSize}
                        gridTemplateColumns={`${x}px ${
                          gridForLeftTab.colSize ? 4 : 0
                        }px 1fr`}
                        onDrag={(direction, track, style) => {
                          const size = Number.parseInt(style.split(" ")[0], 10);
                          setGridForLeftTab({
                            gridTemplateColumns: style,
                            colSize: size,
                            memorizedColSize: size
                          });
                          this.onResizeWindow();
                        }}
                        render={({ getGridProps, getGutterProps }) => {
                          return (
                            <div
                              ref={this.gridForLeftTab}
                              {...(classType !== "evaluation" && {
                                ...getGridProps()
                              })}
                              className={`d-grid h-100 ${classType ===
                                "evaluation" && "quiz-grid-fix"}`}
                            >
                              <LeftTabContainer />
                              <div
                                {...(classType !== "evaluation" && {
                                  ...getGutterProps("column", 1)
                                })}
                                className={`gutter gutter-column`}
                              />
                              <div
                                className={`viditing-boundary position-relative`}
                                ref={webOutputModal.boundaryRef}
                              >
                                {classType === "media-only" ? (
                                  <ReactPlayer
                                    ref={this.mediaPlayer}
                                    playing={isPlayingMedia}
                                    controls={true}
                                    url={step.url}
                                    width={"100%"}
                                    height={"100%"}
                                    onEnded={async () => {
                                      setTimeout(await this.finishReset(), 500);
                                      // this.handleProgressAudioEnded(
                                      //   this.state.time
                                      // );
                                    }}
                                    onPlay={() => {
                                      this.setState({
                                        ...this.state,
                                        isPlayingMedia: true,
                                        isFinishedMedia: false
                                      });

                                      setTimeout(() => {
                                        this.worker.postMessage({
                                          action: "release"
                                        });
                                      }, 500);
                                    }}
                                    onPause={() => {
                                      this.setState({
                                        ...this.state,
                                        isPlayingMedia: false
                                      });
                                      this.worker.postMessage({
                                        action: "hold"
                                      });
                                    }}
                                  />
                                ) : (
                                  <GridForNS
                                    onResizeGridRow={this.onResizeGridRow}
                                    timeWorker={this.worker}
                                    handleProgressAudioEnded={
                                      this.handleProgressAudioEnded
                                    }
                                    finishReset={this.finishReset}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        }}
                      />
                    )}
                  </Motion>
                  {stepDuration.isShown && (
                    <div
                      className={
                        leftTab !== "quiz"
                          ? "duration-container position-fixed d-flex align-items-center"
                          : "duration-container d-none"
                      }
                    >
                      <IconTimeout className="d-block" />{" "}
                      {moment
                        .duration(stepDuration.milliseconds, "milliseconds")
                        .format(DURATION_FORMAT, { trim: false })}
                    </div>
                  )}
                </div>
              </>
            )}
            {!availableResolution && (
              <div
                className={`dimmer available-resolution position-fixed d-flex justify-content-center align-items-center`}
                onClick={e => this.dimmedOff(e)}
              >
                <Alert
                  style={{
                    backgroundColor: "#000",
                    border: "1px solid #00ffdd",
                    color: "#00ffdd",
                    padding: "20px 20px 5px 20px"
                  }}
                >
                  <Alert.Heading>
                    브라우저 글꼴 크기를 최소 해상도에 맞게 조정해주세요.
                    <br />
                    해상도가 맞지 않는 경우, 수업 화면이 깨져 보일 수 있습니다.
                  </Alert.Heading>
                  <p>
                    최소 해상도: {DIMENSION_MIN.width} x {DIMENSION_MIN.height}
                    <br />
                    현재 해상도: {winW} x {winH}
                  </p>
                </Alert>
              </div>
            )}

            <ModalNoOrYes
              show={isShownModalDisconnected}
              onYes={e => {
                console.log("yes!!!!");
                this.setState({
                  ...this.state,
                  isShownModalDisconnected: false
                });
                window.location.reload();
              }}
              onNo={e => {
                console.log("no!!!!");
                this.setState({
                  ...this.state,
                  isShownModalDisconnected: false
                });
                history.push(`/personal/dashboard/inProgressCourses`);
              }}
            >
              <p>다른 브라우저에서 수업 진행이 감지되었습니다.</p>
              <p>현재 브라우저에서 수업을 진행하시겠습니까?</p>
              <p>(현재 브라우저에서 진행 시, 페이지가 새로고침 됩니다)</p>
            </ModalNoOrYes>

            <ModalNoOrYes
              show={isShownModalStepIsCompleted}
              onYes={e => {
                if (isTablet && nextStep.typename === "ExecutionQuizStepNode") {
                  e.stopPropagation();
                  this.loadClass(this.props.match.params.step, IS_MOUNTED);
                  this.props.setAudioPlayer({ lastPlayTime: 0, onEnd: false });
                  // this.props.setScriptTime({
                  //   duration: 0,
                  //   lastPlayTime: 0,
                  //   durationGap: 1
                  // });
                  this.setState(
                    { ...this.state, isFinishedMedia: false },
                    () => {
                      toggleModalStepIsCompleted(false);
                    }
                  );

                  alert(
                    "퀴즈 스탭은 PC환경에 화적화 되어있습니다.\n\nPC-Chrome 브라우저에서 이용해주세요."
                  );
                } else {
                  e.stopPropagation();
                  this.classUnmountData();
                  this.setState(
                    { ...this.state, isFinishedMedia: false },
                    () => {
                      toggleModalStepIsCompleted(false);
                      nextStep.id
                        ? history.push(`/class/${nextStep.id}`)
                        : history.push(
                            `/personal/dashboard/completedCourses?page=1&sort=REGISTER_DATE_DESC`
                          );
                    }
                    //(window.location.href = `/class/${nextStep.id}`)
                  );
                }

                gtmPushDataLayer({
                  event: "idePopupNextstepYClick"
                });
              }}
              onNo={e => {
                e.stopPropagation();

                if (classType === "evaluation") {
                  setQuiz({
                    isQuizPassShow: false
                  });
                } else {
                  this.loadClass(this.props.match.params.step, "notAction");
                }
                this.props.setAudioPlayer({ lastPlayTime: 0, onEnd: false });
                // this.props.setScriptTime({
                //   duration: 0,
                //   lastPlayTime: 0,
                //   durationGap: 1
                // });
                this.setState({ ...this.state, isFinishedMedia: false }, () => {
                  toggleModalStepIsCompleted(false);
                });

                gtmPushDataLayer({
                  event: "idePopupNextstepNClick"
                });
              }}
            >
              <p>{this.props.isShownModalStepIsCompletedText}</p>
            </ModalNoOrYes>
            <ContactUs
              show={this.state.isShow}
              onHide={this.controlContactUs}
            />
          </>
        </div>
      </div>
    );
  }
}

const mapState = ({
  common: {
    apollo: {
      client: { general: client }
    },
    nextStep,
    windowDimension,
    ws
  },
  ide: {
    leftTab,
    gridForLeftTab,
    gridForFiletree,
    gridForNS,
    classType,
    editor,
    editorContainer,
    terminals,
    webOutputModal,
    quiz,
    step,
    slide,
    isShownModalStepIsCompleted,
    isShownModalStepIsCompletedText,
    stepDuration,
    mobileChk,
    contactUS
  },
  viditing: { audioPlayer, scriptTime, status },
  auth: { authBasic }
}) => ({
  nextStep,
  ws,
  leftTab,
  client,
  gridForLeftTab,
  gridForFiletree,
  gridForNS,
  classType,
  editor,
  editorContainer,
  terminals,
  webOutputModal,
  quiz,
  step,
  slide,
  isShownModalStepIsCompleted,
  isShownModalStepIsCompletedText,
  stepDuration,
  mobileChk,
  contactUS,

  audioPlayer,
  scriptTime,
  status,

  windowDimension,

  authBasic
});

const mapDispatch = ({
  common: {
    getClassByStepType,
    addNotification,
    setWindowDimension,
    getTypenameByBackNode,
    newSocket,
    requestSocket,
    closeSocket,
    setWs
  },
  ide: {
    setGridForLeftTab,
    setGridForNS,
    setClassType,
    setEditorContainer,
    setTerminals,
    setupStep,
    initialize,
    refreshWebOutputModal,
    refreshSlideDimension,
    toggleModalStepIsCompleted,
    toggleModalStepIsCompletedText,
    setStepDuration,
    correctGrids,
    updateVideoStepComplete,
    setSlide,
    setWebOutputModal,
    setContactUS,
    setQuiz
  },
  viditing: {
    terminalActWorker,
    setTerminalWorker,
    closeTerminalWorker,
    onMessageForTerminalTimer,
    setStatus,
    updateViditingStepComplete,
    setElements,
    setAudioPlayer,
    setRandomScene,
    setResetBaseData,
    setScriptTime
  },
  gtm: { gtmPushDataLayer }
}) => ({
  // from common
  getClassByStepType,
  addNotification,
  setWindowDimension,
  getTypenameByBackNode,
  newSocket,
  requestSocket,
  closeSocket,
  setWs,

  // from ide
  setGridForLeftTab,
  setGridForNS,
  setClassType,
  setEditorContainer,
  setTerminals,
  setupStep,
  initialize,
  refreshWebOutputModal,
  refreshSlideDimension,
  toggleModalStepIsCompleted,
  toggleModalStepIsCompletedText,
  setStepDuration,
  correctGrids,
  updateVideoStepComplete,
  setSlide,
  setWebOutputModal,
  setContactUS,
  setQuiz,

  // from viditing
  terminalActWorker,
  setTerminalWorker,
  closeTerminalWorker,
  onMessageForTerminalTimer,
  setStatus,
  updateViditingStepComplete,
  setElements,
  setAudioPlayer,
  setRandomScene,
  setResetBaseData,
  setScriptTime,
  gtmPushDataLayer
});

export default connect(mapState, mapDispatch)(Container);
