import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Row, OverlayTrigger, Popover } from "react-bootstrap";
import ReactPlayer from "react-player";
import { Range, getTrackBackground } from "react-range";
import { debounce, throttle } from "lodash";
import moment from "moment/min/moment-with-locales";
import { toast } from "react-toastify";

import { DURATION_FORMAT, VIDITING_STATUS } from "../../common";
import { ALPACO_CNAME, KBBANK_CNAME } from "../../constants";

import { ReactComponent as IconPlay } from "../../images/btn-courseplay-play.svg";
import { ReactComponent as IconPause } from "../../images/btn-courseplay-pause-t-01.svg";
import { ReactComponent as IconMute } from "../../images/btn-coursevoice-on.svg";
import { ReactComponent as IconMuteOff } from "../../images/btn-coursevoice-t-01-off.svg";

import { ReactComponent as IconSolution } from "../../images/ide-quizsolution-hover.svg";

import { ReactComponent as StepSetting } from "../../images/btn-stepspeed-off.svg";

//
// class ToolTipTimeline extends Component {
//   walkDurationForSlide = toolTipTime => {
//     const { duration } = this.props;
//     let durationTimestamp = parseInt(Math.round(duration / 1000));
//
//     return parseFloat((toolTipTime / durationTimestamp) * 100);
//   };
//
//   render() {
//     const { duration } = this.props;
//
//     let parseDuration = parseInt(Math.round(duration / 1000));
//
//     for (let i = 1; i <= parseDuration; i++) {
//       console.log(i);
//       return (
//         <li
//           key={i + 200}
//           className="position-absolute"
//           style={{
//             left: `${this.walkDurationForSlide(i)}%`,
//             right: `${100 - this.walkDurationForSlide(i + 1)}%`,
//             opacity: 0.2
//           }}
//         >
//           {i}
//         </li>
//       );
//     }
//   }
// }

class Progress extends Component {
  state = {
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    ended: false,
    slides: [],
    settingShow: false
  };

  organization = null;

  createParams() {
    const { quiz, ws, editor, classType } = this.props;

    let fetchParams = {};

    switch (classType) {
      case "evaluation":
        let formData = new FormData();

        // const model = editor.ref.getModel();
        const value = editor.ref.getValue();
        const answerCode = [value]; //quiz 문제에대한 답변 value
        const codeBlob = new Blob(answerCode);

        formData.append("file", codeBlob);
        formData.append("storage", ws.storage);
        formData.append(
          "path",
          quiz.subQuizzes[quiz.inProgressSubQuiz].uploadPath
        );

        fetchParams = {
          url: "file",
          method: "PUT",
          data: formData
        };
        break;
      default:
    }
    return fetchParams;
  }

  async runGrade() {
    const { quiz, requestFetch, requestSocket, ws, setWs } = this.props;

    if (ws.storage) {
      setWs({ isLoading: true, isGrade: true });
      const res = await requestFetch(this.createParams());
      if (res && res.file_path) {
        await requestSocket({
          type: "quiz",
          context: {
            action: "grade",
            subquiz: quiz.inProgressSubQuiz,
            user: localStorage.getItem("codelion.accessToken")
          }
        });
      }
    }
  }

  seeking = debounce(val => {
    //this.setState({ ...this.state, playing: false, seeking: true });
    //this.handleSeekChange(val);
    this.props.setStatus(VIDITING_STATUS.SEEK);
  }, 200);

  handlePlay = () => {
    //this.props.onProgress(VIDITING_STATUS.PLAYING);
    this.props.viditingPlayer.postMessage({
      action: "release"
    });
    this.setState({ playing: true });
  };

  handlePlayPause = e => {
    e.stopPropagation();
    const { setStatus } = this.props;
    if (this.state.playing && this.props.status === VIDITING_STATUS.PLAYING) {
      setStatus(VIDITING_STATUS.PAUSED);
    } else {
      if (this.state.playing) {
        setStatus(VIDITING_STATUS.PAUSED);
      } else {
        setStatus(VIDITING_STATUS.PLAYING);
      }
    }
  };

  handleStop = () => {
    this.props.viditingPlayer.postMessage({
      action: "paused"
    });
    this.setState({ url: null, playing: false });
  };

  handlePause = () => {
    console.log("onPause");
    this.props.onProgress(VIDITING_STATUS.PAUSED);
    this.setState({ playing: false });
  };

  handleOnStart = () => {
    console.log("onStart:");
    // this.setState({
    //   ...this.state,
    //   muted: false
    // });
  };

  handleToggleMuted = e => {
    e.stopPropagation();
    this.setState({ muted: !this.state.muted });
  };

  handleMouseDown = e => {
    console.log("handleMouseDown:", e);
    this.props.setStatus(VIDITING_STATUS.PAUSED);
  };

  handleSeekMouseDown = e => {
    if (!this.props.isBatchProcessing) {
      this.setState({
        ...this.state,
        played: parseFloat(e)
      });

      let tmpTime =
        parseInt((parseFloat(this.state.duration * e) * 1000) / 100) * 100;
      if (tmpTime === undefined) {
        tmpTime = 0;
      }

      let tmpLastPlayTime =
        parseInt(
          (this.props.sectors.PROGRESS.ref.current.getCurrentTime() * 1000) /
            100
        ) * 100;
      if (tmpLastPlayTime === undefined) {
        tmpLastPlayTime = 0;
      }

      this.props.setAudioPlayer({
        lastSeekTime: parseFloat(tmpTime)
      });
      this.props.setStatus(VIDITING_STATUS.PAUSED);
    }
  };

  handleError = e => {
    console.log("onError:", this.props.status, "::", e);

    try {
      if (e === "hlsError") {
        setTimeout(() => {
          this.onPlayThrottle(VIDITING_STATUS.PLAYING);
          this.props.setStatus(VIDITING_STATUS.PLAYING);
        });
      } else {
        if (
          this.props.status === VIDITING_STATUS.READY ||
          this.props.status === VIDITING_STATUS.PLAYING
        ) {
          this.props.setStatus(VIDITING_STATUS.PAUSED);
          toast.error("재생 버튼을 눌러 수업을 시작해 주세요.", {
            onOpen: () => {
              setTimeout(() => {
                this.props.viditingPlayer.postMessage({ action: "reset" });
                this.props.setAudioPlayer({ playReady: true });
              }, 100);
            },
            autoClose: 3000,
            pauseOnHover: false,
            hideProgressBar: true
          });

          // let playInterval = setInterval(() => {
          //   console.log("interval-err:ing", e);
          //   if(this.props.status!==VIDITING_STATUS.PLAYING){
          //     console.log("interval-err:close");
          //     this.onPlayThrottle(VIDITING_STATUS.PLAYING);
          //     this.props.setStatus(VIDITING_STATUS.PLAYING);
          //   }else{
          //     console.log("interval-err:clear");
          //     clearInterval(playInterval)
          //     // this.setState({
          //     //   ...this.state,
          //     //   muted: false
          //     // });
          //   }
          //
          // },200)
        }
      }
    } catch (et) {
      console.log("catch:onError:", this.props.status, et);
    }
  };

  handleSeekEnd = e => {
    console.log("handleSeekEnd", e);
    this.onPlayThrottle(VIDITING_STATUS.PLAYING);
    this.props.setStatus(VIDITING_STATUS.PLAYING);
  };

  handleSeekChange = e => {
    if (!this.props.isBatchProcessing) {
      this.props.setStatus(VIDITING_STATUS.BEFORE_LOADING);
      setTimeout(() => this.props.setStatus(VIDITING_STATUS.SEEK));
    }
  };

  handleSeekMouseUp = e => {
    console.log("handleSeekMouseUp:", e);
    this.setState({ ...this.state, playing: true, seeking: false });
    //sectors.PROGRESS.ref.current.seekTo(parseFloat(e.target.value));
  };

  handleProgress = state => {
    const { status } = this.props;
    let tmpTime =
      parseInt(
        (this.props.sectors.PROGRESS.ref.current.getCurrentTime() * 1000) / 10
      ) * 10;
    if (tmpTime === undefined) {
      tmpTime = 0;
    }

    if (status === VIDITING_STATUS.PLAYING) {
      if (status !== "LOADING") {
        if (state.playedSeconds < state.loadedSeconds) {
          this.props.setAudioPlayer({
            lastPlayTime: parseFloat(tmpTime)
          });
          this.setState(state);
          //onProgress("setTime", tmpTime);
        } else {
          this.setState({ ended: true });
          //onProgress("reset");
        }
      }
    }
  };

  handleDuration = duration => {
    this.props.setAudioPlayer({ duration: parseFloat(duration * 1000) });
    this.setState({ duration });
  };

  handleEnded = () => {
    console.log(
      "onEnded",
      this.props.audioPlayer.duration,
      this.props.scriptTime.duration
    );
    if (
      parseInt(this.props.scriptTime.duration) >
      parseInt(this.props.audioPlayer.duration)
    ) {
      this.props.handleProgressAudioEnded(this.props.audioPlayer.duration);
    } else if (
      this.props.audioPlayer.lastPlayTime > 10 &&
      parseInt(this.props.scriptTime.duration) <=
        parseInt(this.props.audioPlayer.duration)
    ) {
      this.props.handleProgressAudioEnded(this.props.scriptTime.duration);
    }
    this.onPlayThrottle(VIDITING_STATUS.FINISHED);
    this.props.setStatus(VIDITING_STATUS.FINISHED);
  };

  onLoad = () => {
    this.setState({ url: this.props.streamingUrl });
  };

  handleReady = () => {
    const { setAudioPlayer, status } = this.props;
    setAudioPlayer({ onReady: true });
    if (status === VIDITING_STATUS.READY) {
      //this.onPlayThrottle(VIDITING_STATUS.PLAYING);
    }
  };

  handleBuffer = () => {
    const { setAudioPlayer } = this.props;
    setAudioPlayer({ onReady: false });
    console.log("onBuffer");
  };

  handleRun = async () => {
    if (this.props.serviceMode === "TEACHER") {
      await this.props.changeServiceMode({ mode: "STUDENT" });
      await this.props.historyCreate({
        historyMode: "RUN",
        history: this.props.history
      });
      switch (this.props.terminalType) {
        case "web":
        case "django":
        case "ror":
          this.props.setWebOutputModal({ reload: true });
          break;
        default:
        //Nothing
      }
    } else {
      await this.props.historyCreate({
        historyMode: "RUN",
        history: this.props.history
      });
      switch (this.props.terminalType) {
        case "web":
        case "django":
        case "ror":
          this.props.setWebOutputModal({ reload: true });
          break;
        default:
        //Nothing
      }
    }
  };

  handlePlaybackRate = (e, rate) => {
    console.log("rate:", rate);
    this.props.setAudioPlayer({ playbackRate: rate, playbackRateMem: rate });
  };

  onPlayThrottle = throttle(async status => {
    //const { history } = this.props;
    switch (status) {
      case VIDITING_STATUS.PLAYING:
        //login logic add
        if (!this.props.isAuthenticated) {
          // this.props.checkAuthentication({ history });
          window.location.reload();
          return;
        } else {
          if (this.props.serviceMode === "STUDENT") {
            if (this.props.filetree !== this.props.changeModeData.filetree) {
              if (
                this.props.changeModeData.beforeFiletree !== this.props.filetree
              ) {
                setTimeout(() => {
                  this.setState({
                    ...this.state,
                    playing: true,
                    seeking: false,
                    ended: false
                  });
                }, 0);
              } else {
                setTimeout(() => {
                  this.setState({
                    ...this.state,
                    playing: true,
                    seeking: false,
                    ended: false
                  });
                });
              }
            } else {
              setTimeout(() => {
                this.setState({
                  ...this.state,
                  playing: true,
                  seeking: false,
                  ended: false
                });
              });
            }
          } else {
            setTimeout(() => {
              this.setState({
                ...this.state,
                playing: true,
                seeking: false,
                ended: false
              });
              this.props.setAudioPlayer({ playReady: false });
            });
          }
        }
        break;
      case VIDITING_STATUS.PAUSED:
        this.setState({
          ...this.state,
          playing: false,
          seeking: false
        });
        break;
      case VIDITING_STATUS.FINISHED:
        this.props.viditingPlayer.postMessage({
          action: "reset"
        });
        this.setState({
          ...this.state,
          played: 1,
          playing: false,
          seeking: false,
          ended: true
        });
        this.props.setAudioPlayer({ lastPlayTime: 0, onEnd: true });

        break;
      case VIDITING_STATUS.SEEK:
        this.setState({
          ...this.state,
          playing: false,
          seeking: true
        });

        break;
      default:
        break;
    }
  }, 100);

  resetQuizSource() {
    const {
      setEditor,
      quiz: { subQuizzes, inProgressSubQuiz }
    } = this.props;

    setEditor({
      value: subQuizzes[inProgressSubQuiz].originQuestionSource
    });
  }

  componentDidMount() {
    //this.onLoad();
    if (this.props.classType === "learning") {
      const {
        step: {
          slides: { edges: slideData }
        },
        authBasic: { organization }
      } = this.props;
      this.organization = organization;

      this.setState({
        ...this.state,
        slides: slideData
      });
    }
  }

  async componentDidUpdate(prevProps) {
    const { status: prevStatus } = prevProps;
    const { status } = this.props;
    //console.log("progress status;;", status);
    if (prevStatus !== status) {
      this.onPlayThrottle(status);
    }
    // else{
    //   //console.log('prevStatus === status::',status,this.props.audioPlayer.onEnd)
    //   if (prevStatus === status && status===VIDITING_STATUS.PLAYING && this.props.audioPlayer.onEnd) {
    //     this.props.setAudioPlayer({onEnd :false});
    //      this.onPlayThrottle(status);
    //   }
    // }
  }

  componentWillUnmount() {
    this.setState({
      ...this.state,
      playing: false,
      controls: false,
      light: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      ended: false,
      slides: []
    });
  }

  walkDurationForSlide = slideTime => {
    const { duration } = this.state;
    let durationTimestamp = parseInt(duration * 1000);

    return parseFloat((slideTime / durationTimestamp) * 100);
  };

  render() {
    const {
      step,
      leftTab,
      classType,
      quiz: {
        tryCount,
        solution,
        isSubQuizComplete,
        subQuizzes,
        inProgressSubQuiz,
        isSubmitted
      },
      setQuizSolution,
      audioPlayer,
      status,
      sectors,
      teacherPause,
      viditingPoint,
      gtmPushDataLayer,
      authBasic
    } = this.props;

    const { playing, muted, played, slides } = this.state;

    let organizationCname = null;
    let allowSeeking = true;
    let allowSpeed = true;
    let completeLimit = 0;

    if (authBasic.organization) {
      organizationCname = authBasic.organization.cname;
      allowSeeking = authBasic.organization.allowSeeking;
      allowSpeed = authBasic.organization.allowSpeed;
      completeLimit = authBasic.organization.completeLimit;
    }

    return (
      <div className="progress-container d-flex flex-column h-100">
        {classType === "learning" &&
          status !== VIDITING_STATUS.LOADING &&
          status !== VIDITING_STATUS.INIT && (
            <div className="track position-relative">
              <ReactPlayer
                id={"audio-player"}
                ref={sectors.PROGRESS.ref}
                url={audioPlayer.streamingUrl && audioPlayer.streamingUrl}
                width={audioPlayer.width}
                height={audioPlayer.height}
                progressInterval={audioPlayer.progressInterval}
                playing={playing}
                volume={null}
                muted={muted}
                playbackRate={audioPlayer.playbackRate}
                onReady={this.handleReady}
                onStart={this.handleOnStart}
                onPlay={this.handlePlay}
                onSeek={this.handleSeekEnd}
                onError={this.handleError}
                onBuffer={this.handleBuffer}
                onPause={this.handlePause}
                onEnded={this.handleEnded}
                onProgress={this.handleProgress}
                onDuration={this.handleDuration}
                controls={false}
                allow={"autoplay"}
              />
              <div
                className="runner position-absolute"
                style={{
                  transition: "right 100ms ease 0s",
                  backgroundColor: "transparent",
                  zIndex: 1
                }}
                //onMouseDown={this.handleMouseSeekDown}
                // onMouseUp={this.handleSeekMouseUp}
              >
                <Range
                  values={[parseFloat(played * 100)]}
                  step={0.1}
                  min={0}
                  max={100}
                  onChange={values => {
                    if (authBasic.organization) {
                      if (step.completed || allowSeeking) {
                        this.handleSeekMouseDown(parseFloat(values[0] / 100));
                      } else {
                        return false;
                      }
                    } else {
                      this.handleSeekMouseDown(parseFloat(values[0] / 100));
                    }
                  }}
                  onFinalChange={values => {
                    if (authBasic.organization) {
                      //if (organizationCname !== ALPACO_CNAME) {
                      if (step.completed || allowSeeking) {
                        this.handleSeekChange(parseFloat(values[0] / 100));
                      } else {
                        return false;
                      }
                    } else {
                      this.handleSeekChange(parseFloat(values[0] / 100));
                    }
                  }}
                  renderTrack={({ props, children }) => (
                    <div
                      onMouseDown={props.onMouseDown}
                      onTouchStart={props.onTouchStart}
                      style={{
                        ...props.style,
                        height: "8px",
                        display: "flex",
                        width: "100%",
                        transition: "right 100ms ease 0s"
                      }}
                    >
                      <div
                        ref={props.ref}
                        style={{
                          height: "8px",
                          width: "100%",
                          borderRadius: "0px",
                          background: getTrackBackground({
                            values: [parseFloat(played * 100)],
                            colors: [
                              this.organization ? "#00ffdd" : "#00ffdd",
                              "#383a43"
                            ],
                            min: 0,
                            max: 100,
                            step: 0.1
                          }),
                          alignSelf: "center"
                        }}
                      >
                        {children}
                      </div>
                    </div>
                  )}
                  renderThumb={({ props, isDragged }) =>
                    organizationCname !== ALPACO_CNAME ? (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "8px",
                          width: "8px",
                          borderRadius: "1px",
                          backgroundColor: "#ebebeb",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        {isDragged && (
                          <div
                            style={{
                              position: "absolute",
                              top: "-28px",
                              color: "#fff",
                              borderRadius: "4px",
                              padding: "0 4px 0 4px",
                              border: "0px",
                              backgroundColor: "#292a2f"
                            }}
                          >
                            {moment
                              .duration(
                                parseInt(
                                  Math.round(
                                    (this.props.audioPlayer.duration * played) /
                                      1000
                                  )
                                ),
                                "seconds"
                              )
                              .format(DURATION_FORMAT, { trim: false })}
                          </div>
                        )}

                        <div
                          style={{
                            height: "8px",
                            width: "8px",
                            border: "0px",
                            backgroundColor: isDragged ? "#ebebeb" : "#383a43"
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "8px",
                          width: "8px",
                          borderRadius: "1px",
                          backgroundColor: "#ebebeb",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        {isDragged && (
                          <div
                            style={{
                              position: "absolute",
                              top: "-28px",
                              color: "#fff",
                              borderRadius: "4px",
                              padding: "0 4px 0 4px",
                              border: "0px",
                              backgroundColor: "#292a2f"
                            }}
                          >
                            {moment
                              .duration(
                                parseInt(
                                  Math.round(
                                    (this.props.audioPlayer.duration * played) /
                                      1000
                                  )
                                ),
                                "seconds"
                              )
                              .format(DURATION_FORMAT, { trim: false })}
                          </div>
                        )}

                        <div
                          style={{
                            height: "8px",
                            width: "8px",
                            border: "0px",
                            backgroundColor: isDragged ? "#ebebeb" : "#383a43"
                          }}
                        />
                      </div>
                    )
                  }
                />
              </div>
              <ol
                className="list-unstyled mb-0"
                style={{ backgroundColor: "transparent" }}
              >
                {/*<ToolTipTimeline duration={this.props.audioPlayer.duration} />*/}
                {slides.length > 0 &&
                  slides.map(({ node: slide }, index) => {
                    return (
                      <li
                        key={index}
                        className="position-absolute"
                        style={{
                          left: `${this.walkDurationForSlide(
                            slide.startTimestamp < 1000
                              ? slide.startTimestamp
                              : slide.startTimestamp - 500
                          )}%`,
                          right: `${100 -
                            this.walkDurationForSlide(
                              slide.endTimestamp - 500
                            )}%`,
                          opacity: 0.7,
                          backgroundColor: `${
                            this.organization ? "#ebebeb" : "#ebebeb"
                          }`,
                          zIndex: 1
                        }}
                      ></li>
                    );
                  })}
                {viditingPoint.length > 0 &&
                  viditingPoint.map((vp, index) => {
                    return (
                      <li
                        key={index + 100}
                        className="position-absolute"
                        style={{
                          left: `${this.walkDurationForSlide(
                            vp.timestamp - 1500
                          )}%`,
                          right: `${100 -
                            this.walkDurationForSlide(vp.timestamp - 1000)}%`,
                          opacity: 0.9,
                          backgroundColor: `${
                            this.organization ? "#ff9300" : "#ff9300"
                          }`,
                          zIndex: 1
                        }}
                      ></li>
                    );
                  })}
              </ol>
            </div>
          )}

        <div className="flex-grow-1 d-flex align-items-center">
          <Row className="w-100 mx-0">
            <Col className="p-0 align-items-center" sm={4}>
              {classType === "learning" && (
                <>
                  <Button
                    variant="link"
                    className="play border-0"
                    onClick={e => {
                      this.handlePlayPause(e);
                      gtmPushDataLayer({
                        event: "ideControllerPlayClick"
                      });
                    }}
                  >
                    {!playing ? (
                      <IconPlay className="d-block" />
                    ) : (
                      <IconPause className="d-block" />
                    )}
                  </Button>
                  <Button
                    variant="link"
                    className="mute border-0"
                    onClick={e => {
                      this.handleToggleMuted(e);
                      gtmPushDataLayer({
                        event: "ideControllerVoiceClick"
                      });
                    }}
                  >
                    {!muted ? (
                      <IconMute className="d-block" />
                    ) : (
                      <IconMuteOff className="d-block" />
                    )}
                  </Button>
                  &nbsp;
                  {moment
                    .duration(
                      parseInt(this.props.audioPlayer.lastPlayTime / 1000),
                      "seconds"
                    )
                    .format(DURATION_FORMAT, { trim: false })}
                  &nbsp;/&nbsp;
                  <span style={{ color: "#5A5C65" }}>
                    {moment
                      .duration(
                        parseInt(this.props.audioPlayer.duration / 1000),
                        "seconds"
                      )
                      .format(DURATION_FORMAT, { trim: false })}
                  </span>
                </>
              )}

              {/* b2b 솔루션 */}
              {classType === "evaluation" &&
                authBasic.organization &&
                authBasic.organization?.cname !== KBBANK_CNAME &&
                (step.completed || isSubmitted) && (
                  <Button
                    variant="link"
                    className="solution border-0 d-flex align-items-center"
                    disabled={solution.isShown}
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      setQuizSolution({ isShown: true });
                      gtmPushDataLayer({
                        event: "ideQuizSolutionClick"
                      });
                    }}
                  >
                    <IconSolution className="d-block mr-2" />
                    솔루션
                  </Button>
                )}

              {/* b2c 솔루션 */}
              {(!authBasic.organization ||
                authBasic.organization?.cname === KBBANK_CNAME) &&
                (tryCount >= 4 ||
                  isSubQuizComplete ||
                  (subQuizzes[inProgressSubQuiz] &&
                    subQuizzes[inProgressSubQuiz].completed)) && (
                  <Button
                    variant="link"
                    className="solution border-0 d-flex align-items-center"
                    disabled={solution.isShown}
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      setQuizSolution({ isShown: true });
                      gtmPushDataLayer({
                        event: "ideQuizSolutionClick"
                      });
                    }}
                  >
                    <IconSolution className="d-block mr-2" />
                    솔루션
                  </Button>
                )}
            </Col>
            <Col className="p-0 w-100 align-items-center text-center" sm={4}>
              {classType === "learning" &&
              false &&
              this.props.serviceMode === "STUDENT" ? (
                <Button
                  variant="link"
                  className="run w-100 border-0"
                  onClick={() => {
                    this.props.setStatus(VIDITING_STATUS.PLAYING);
                  }}
                >
                  <label
                    className="d-block w-100 m-0 border-top-0 border-bottom-0"
                    style={{ fontSize: "14px" }}
                  >
                    {teacherPause ? (
                      <>
                        직접 코딩해 보셨나요? 재생 버튼을 눌러 이어서 수강해
                        주세요.
                      </>
                    ) : (
                      <>
                        직접 코딩해 보셨나요? 재생 버튼을 눌러 이어서 수강해
                        주세요.
                      </>
                    )}
                  </label>
                </Button>
              ) : (
                classType === "evaluation" && (
                  <Button
                    disabled={
                      !authBasic.organization
                        ? subQuizzes[inProgressSubQuiz]
                          ? subQuizzes[inProgressSubQuiz].completed
                          : false
                        : isSubmitted || step.completed
                        ? true
                        : false
                    }
                    variant="link"
                    className="run border-0"
                    onClick={() => {
                      if (classType === "evaluation") {
                        this.runGrade();
                      } else if (classType === "learning") {
                        this.handleRun();
                      }
                      gtmPushDataLayer({
                        event: "ideFunctionRunClick"
                      });
                    }}
                  >
                    <label className="d-block m-0 border-top-0 border-bottom-0">
                      {authBasic.organization ? "저장" : "실행"}
                    </label>
                  </Button>
                )
              )}
            </Col>
            {leftTab === "quiz" ? (
              <Col className="p-0 align-items-center text-right" sm={4}>
                <Button
                  variant="link"
                  className="reset border-0"
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    this.resetQuizSource();
                    gtmPushDataLayer({
                      event: "ideFunctionResetClick"
                    });
                  }}
                >
                  코드초기화
                </Button>
              </Col>
            ) : (
              (!authBasic.organization || step.completed || allowSpeed) && (
                <Col className="p-0 pr-2 align-items-center text-right">
                  <OverlayTrigger
                    placement="left"
                    rootClose
                    trigger="click"
                    onToggle={e => {
                      this.setState({ ...this.state, settingShow: e });
                    }}
                    show={this.state.settingShow}
                    overlay={
                      <Popover id="popover-basic" className={`setting-main`}>
                        <div className={`setting-body`}>
                          <div className={`setting-title`}>재생속도</div>
                          <div className={`setting-line`}>
                            <span></span>
                          </div>
                          <div
                            className={`setting-text ${audioPlayer.playbackRate ===
                              0.5 && "on"}`}
                            onClick={e => {
                              this.handlePlaybackRate(e, 0.5);
                              setTimeout(() => {
                                this.setState({
                                  ...this.state,
                                  settingShow: false
                                });
                              }, 200);
                            }}
                          >
                            0.5
                          </div>
                          <div
                            className={`setting-text ${audioPlayer.playbackRate ===
                              1 && "on"}`}
                            onClick={e => {
                              this.handlePlaybackRate(e, 1);
                              setTimeout(() => {
                                this.setState({
                                  ...this.state,
                                  settingShow: false
                                });
                              }, 200);
                            }}
                          >
                            보통
                          </div>
                          <div
                            className={`setting-text ${audioPlayer.playbackRate ===
                              1.5 && "on"}`}
                            onClick={e => {
                              this.handlePlaybackRate(e, 1.5);
                              setTimeout(() => {
                                this.setState({
                                  ...this.state,
                                  settingShow: false
                                });
                              }, 200);
                            }}
                          >
                            1.5
                          </div>
                          <div
                            className={`setting-text ${audioPlayer.playbackRate ===
                              2.0 && "on"}`}
                            onClick={e => {
                              this.handlePlaybackRate(e, 2.0);
                              setTimeout(() => {
                                this.setState({
                                  ...this.state,
                                  settingShow: false
                                });
                              }, 200);
                            }}
                          >
                            2.0
                          </div>
                        </div>
                      </Popover>
                    }
                  >
                    <Button
                      variant="link"
                      className="border-0 align-items-center btn_stepSetting"
                    >
                      <StepSetting className="d-block" />
                    </Button>
                  </OverlayTrigger>
                </Col>
              )
            )}
          </Row>
        </div>
      </div>
    );
  }
}

const mapState = ({
  ide: { classType, quiz, step, leftTab, editor, mobileChk },
  common: { ws },
  viditing: {
    audioPlayer,
    scriptTime,
    isBatchProcessing,
    status,
    filetree,
    initialDirectory,
    serviceMode,
    changeModeData,
    elements: { terminalType }
  },
  auth: { isAuthenticated, authBasic }
}) => ({
  classType,
  quiz,
  step,
  leftTab,
  editor,
  mobileChk,

  ws,
  audioPlayer,
  scriptTime,
  status,
  isBatchProcessing,
  filetree,
  initialDirectory,
  serviceMode,
  changeModeData,
  terminalType,
  isAuthenticated,
  authBasic
});
const mapDispatch = ({
  ide: {
    toggleQuizSimpleHint,
    setQuiz,
    setQuizSolution,
    setWebOutputModal,
    setEditor
  },
  common: { requestFetch, requestSocket, setWs },
  viditing: {
    setStatus,
    setAudioPlayer,
    setSeekFiletree,
    setSeekTabs,
    requestTerminal,
    changeServiceMode,
    historyCreate
  },
  auth: { checkAuthentication },
  gtm: { gtmPushDataLayer }
}) => ({
  toggleQuizSimpleHint,
  setQuiz,
  setQuizSolution,
  setWebOutputModal,
  setEditor,

  requestFetch,
  requestSocket,
  setWs,
  setStatus,
  setAudioPlayer,
  setSeekFiletree,
  setSeekTabs,
  requestTerminal,
  changeServiceMode,
  historyCreate,
  checkAuthentication,
  gtmPushDataLayer
});

export default connect(mapState, mapDispatch)(Progress);
