import React, { Component } from "react";

import { ReactComponent as Logo } from "../../images/img-logo-horizontal-wh-01.svg";
import { ReactComponent as SystemNotice } from "../../images/img-systemnotice.svg";
import { withRouter } from "react-router-dom";

class BroswerGuide extends Component {
  fallbackCopyTextToClipboard = text => {
    let textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      let successful = document.execCommand("copy");
      let msg = successful ? "주소 복사 완료!!" : "주소 복사 실패!!";

      alert(msg);
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  };
  clipBoardData = text => {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function() {
        alert("주소 복사 완료!!");
        console.log("Async: Copying to clipboard was successful!");
      },
      function(err) {
        alert("주소 복사 실패!!");
        console.error("Async: Could not copy text: ", err);
      }
    );
  };
  render() {
    const { company, userkey } = this.props.location;

    return (
      <div className="d-flex flex-column w-100 h-100 maintenance-container">
        <div className="p-5"></div>
        <div className="p-5"></div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="p-5"></div>

          <div className="p-5 justify-content-center">
            <div className="p-4"></div>
            <div>
              <Logo />
            </div>
            <div className="p-4"></div>
            <div className="p-1"></div>
            <div className="d-flex align-items-start justify-content-center">
              <SystemNotice />
              <span className="p-2"></span>
              <div className="d-flex flex-column w-100 h-100 maintenance-container">
                <div className="maintenance-text1" style={{ fontSize: "30px" }}>
                  {userkey ? (
                    <span>
                      아래{" "}
                      <b
                        style={{
                          color: "#00ffdd"
                        }}
                      >
                        주소복사하기
                      </b>{" "}
                      버튼을 클릭하여 <br />
                      크롬 브라우저 주소창에 붙여넣어 수업을 진행해 주세요.
                    </span>
                  ) : (
                    "크롬(Chrome) 브라우저로 접속해주세요!"
                  )}
                </div>
                <div className="p-3"></div>
                <div className="maintenance-text2">
                  {userkey &&
                    "복사가 되지 않는 경우 아래 주소를 수동으로 복사하여 붙여주세요."}
                </div>{" "}
                <div
                  className="maintenance는-text2"
                  style={{ color: "#00ffdd" }}
                >
                  {userkey &&
                    `https://www.codelion.net/b2b/sso/${userkey}/${company}`}
                </div>{" "}
                <div className="maintenance-text2">
                  코드라이언은 크롬(Chrome) 브라우저 PC환경에 최적화
                  되어있습니다!
                </div>
                <div className="p-4"></div>
                <div className="d-flex align-items-center">
                  {userkey ? (
                    <button
                      variant="link"
                      className="align-items-center lion"
                      style={{ fontSize: "30px", color: "#00ffdd" }}
                      onClick={() =>
                        this.clipBoardData(
                          `https://www.codelion.net/b2b/sso/${userkey}/${company}`
                        )
                      }
                    >
                      주소복사하기
                    </button>
                  ) : (
                    <button
                      variant="link"
                      className="align-items-center lion"
                      style={{ fontSize: "30px", color: "#00ffdd" }}
                      onClick={() => {
                        window.location.href = "https://www.google.com/chrome/";
                      }}
                    >
                      크롬 다운로드
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="p-5"></div>
          <div className="p-5"></div>
        </div>
      </div>
    );
  }
}

export default withRouter(BroswerGuide);
