import gql from "graphql-tag";

export const TYPENAME = gql`
  query($gid: ID!) {
    node(id: $gid) {
      id
      __typename
    }
  }
`;

export const BASIC_AUTH = gql`
  query {
    my {
      hashid
      email
      nickname
      imgCrop(width: 534, height: 534) {
        url
      }
      organization {
        cname
        name
        allowSeeking
        completeLimit
        allowSpeed
      }
      stats {
        learningCourseCount
        chapterCompletedCount
        myAnswerCount
        myQuestionCount
        courseCompletedCount
        courseExpiredCount
      }
    }
  }
`;

const chapterNode = `
      id
      name
      introduce
      order
      course {
      id
      }
      steps(first: 50) {
        edges {
          node {
            ... on VideoStepNode {
              __typename
              id
              hashid
              name
              summary
              order
              duration
              completed
            }
            ... on LiveCodingStepNode {
              __typename
              id
              hashid
              name
              summary
              order
              duration
              completed
              terminalType
            }
            ... on ExecutionQuizStepNode {
              __typename
              hashid
              id
              name
              summary
              order
              completed
            }
          }
        }
      }`;

export const STEP_BY_CLASSTYPE = {
  "media-only": gql`
    query($step: ID!) {
        step: videoStep(stepGid: $step) {
        id
        name
        summary
        order
        url
        duration
        completed
            chapter {
                id
                course {
                    id
                    chapters (first: 20) {
                        edges {
                            node {
                                ${chapterNode}
                            }
                        }
                    }
                }
            }
            referencesCount
            references(first: 20, after: "") {
                edges {
                    node {
                        content
                        id
                        title
                        hashid
                    }
                    cursor
                }
                pageInfo {
                    hasNextPage
                    endCursor
                }
            }
            attachments(after: "", first: 20) {
                edgeCount
                totalCount
                edges {
                    cursor
                    node {
                        filePath
                        fileSize
                        hashid
                        id
                        originName
                    }
                }
                pageInfo {
                    hasNextPage
                    endCursor
                }
            }
      }
    }
  `,
  learning: gql`
    query($step: ID!) {
        step: liveCodingStep(stepGid: $step) {
        id
        hashid
        name
        summary
        order
        duration
        streamStatus
        completed
        slides(first: 100) {
          edges {
            node {
              id
              startTimestamp
              endTimestamp
              image
              img {
                width
                height
                url
              }
            }
          }
        }
        streamingUrl
        eventUrl
        slidesPdfUrl
            terminalType
        progressTimestamp
        slidesCount
            chapter {
                id
                course {
                    id
                    chapters (first: 20) {
                        edges {
                            node {
                                ${chapterNode}
                            }
                        }
                    }
                }
            }
            referencesCount
            references(first: 50, after: "") {
                edges {
                    node {
                        content
                        id
                        title
                        hashid
                    }
                    cursor
                }
                pageInfo {
                    hasNextPage
                    endCursor
                }
            }
            attachments(after: "", first: 20) {
                edgeCount
                totalCount
                edges {
                    cursor
                    node {
                        filePath
                        fileSize
                        hashid
                        id
                        originName
                    }
                }
                pageInfo {
                    hasNextPage
                    endCursor
                }
            }
      }
    }
  `,
  evaluation: gql`
    query($step: ID!) {
        step: executionQuizStep(stepGid: $step) {
            id
            name
            summary
            order
            instruction
            completed
            hashid
            stepStatus{
              totalScore
            }
            subQuiz(first: 50) {
                edges {
                    node {
                        hashid
                        id
                        createdAt
                        updatedAt
                        order
                        question
                        checkType
                        answer
                        uploadPath
                        softHint
                        hardHint
                        completed
                        difficulty
                        failCount
                        score
                        questionSource
                        originQuestionSource
                    }
                }
            }
            subQuizCount
            chapter {
                id
                course {
                    id
                    chapters (first: 20) {
                        edges {
                            node {
                                ${chapterNode}
                            }
                        }
                    }
                }
            }
        }
    }
  `
};

export const CATALOG_GROUP = isAuthenticated => {
  return gql`
      query($madeBy: madeBy!) {
          allCourses(first: 20, sort:priority, madeBy:$madeBy) {
              edges {
                  node {
                      id
                      name
                      introduce
                      difficulty
                      period
                      learningTime
                      languageTags(first:5){
                        edges{
                          node{
                            name
                            color
                            description
                            id
                          }
                        }
                      }
                      imgCrop(width: 320, height: 180) {
                        url
                      }
                      teacher {
                        id
                        introduce
                        nickname
                        user {
                          imgCrop(width: 60, height: 60) {
                            url
                          }
                        }
                        profileItems{
                          itemText,
                          order
                        }
                      }
                      ${isAuthenticated ? "registered" : ""}
                  }
              }
          }
      }
  `;
};

export const CATALOG_DETAIL = isAuthenticated => {
  return gql`
      query ($courseGid: ID!) {
          course (courseGid: $courseGid) {
              id
              name
              estimatedTotalDuration
              summary
              introduce
              overview
              difficulty
              myLike
              level
              likeCount
              madeBy
              learningAt
              package{
                id
                name
                originPrice
                price
                usdPrice
                priority
              }
              languageTags(first:10) {
                edges {
                  node {
                    id
                    color
                  }
                }
              }
              teacher {
                id
                nickname
                introduce
                profileItems {
                  itemText
                  order
                }
                user {
                  imgCrop(width: 240, height: 240) {
                    url
                  }
                }
              }
              goals(first: 99) {
                edges {
                  node {
                    id
                    order
                    objective
                  }
                }
              }
              faqs(first: 99) {
                edges {
                  node {
                    id
                    order
                    question
                    answer
                  }
                }
              }
              ${
                isAuthenticated
                  ? `lastChapterInProgress{ id introduce order name hashid 
                    stepsCount {
                      videoStepCount
                      liveCodingStepCount
                      executionQuizStepCount
                      ${isAuthenticated ? "completedStepCount" : ""}
                      ${isAuthenticated ? "totalStepCount" : ""}
                    }
                    steps (first:50) {
                       edges {
                           node {
                               ... on VideoStepNode {
                                   __typename
                                   id name summary order duration
                                   ${isAuthenticated ? "completed" : ""}
                               }
                               ... on LiveCodingStepNode {
                                   __typename
                                   terminalType
                                   id name summary order duration
                                   ${isAuthenticated ? "completed" : ""}
                               }
                               ... on ExecutionQuizStepNode {
                                   __typename
                                   id name summary order
                                   ${isAuthenticated ? "completed" : ""}
                               }
                           }
                       }
                    }
                  } 
                  courseStatus { isCompleted completedAt isExpired expiredAt createdAt}`
                  : ""
              }
              chapters(first: 20) {
                  edges {
                      node {
                          id
                          name
                          order
                          introduce
                          stepsCount {
                            videoStepCount
                            liveCodingStepCount
                            executionQuizStepCount
                            ${isAuthenticated ? "completedStepCount" : ""}
                            ${isAuthenticated ? "totalStepCount" : ""}
                          }
                          steps (first:50) {
                             edges {
                                 node {
                                     ... on VideoStepNode {
                                         __typename
                                         id name summary order duration
                                         ${isAuthenticated ? "completed" : ""}
                                     }
                                     ... on LiveCodingStepNode {
                                         __typename
                                         terminalType
                                         id name summary order duration
                                         ${isAuthenticated ? "completed" : ""}
                                     }
                                     ... on ExecutionQuizStepNode {
                                         __typename
                                         id name summary order
                                         ${isAuthenticated ? "completed" : ""}
                                     }
                                 }
                             }
                         }
                      }
                  }
              }
              stepsCount {
                  videoStepCount
                  liveCodingStepCount
                  executionQuizStepCount
              }
              learningTime
              studyTime
              period
              ${isAuthenticated ? "registered" : ""}
          }
      }
  `;
};

export const TEACHER_COURSES = gql`
  query($teacherGid: ID!) {
    allCourses(teacherGid: $teacherGid, first: 20) {
      edges {
        node {
          id
          name
          summary
          introduce
          overview
          myLike
          likeCount
          imgCrop(width: 228, height: 128) {
            url
          }
          teacher {
            id
            profileItems {
              itemText
              order
            }
          }
        }
      }
    }
  }
`;

export const STEP_ALL_SLIDES = isAuthenticated => {
  return gql`
    query($liveCodingStepGid: ID!) {
      allSlides(liveCodingStepGid: $liveCodingStepGid, first: 100) {
        edges {
          node {
            id # 슬라이드 글로벌 id
            startTimestamp # 슬라이드 시작 타임스탬프
            endTimestamp # 슬라이드 끝 타임스탬프
          }
        }
      }
    }
  `;
};

const commentNode = `
  cursor
  node {
    id
    content
    createdAt
    author {
      hashid
      nickname
    }
  }
`;

const answerNode = `
  cursor
  node {
    id
    content
    myLike
    myDislike
    likeCount
    dislikeCount
    commentsCount
    createdAt
    updatedAt
    isUpdated
    author {
      hashid
      nickname
      imgCrop(width: 24, height: 24) {
        url
      }
    }
    img(first: 99) {
      edges {
          node {
              id
              url
              originName
          }
      }
    }
    imgCrop(width: 268, height: 201, first: 99) {
      edges {
        node {
          id
          url
          originName
        }
      }
    }
    comments(first: 15,sort: created_at) {
      totalCount
      edges {
        ${commentNode}
      }
    }
  }
`;

export const QUESTION = gql`
  query($questionGid: ID) {
    allAnswers(
      questionGid: $questionGid
      first: 1
      sort: most_popular_answers_reverse
    ) {
      edges {
        ${answerNode}
      }
    }
    question(questionGid: $questionGid) {
      id
      title
      content
      viewCount
      myLike
      myDislike
      likeCount
      dislikeCount
      author {
        hashid
        nickname
        imgCrop(width: 24, height: 24) {
          url
        }
      }
        img(first: 99) {
            edges {
                node {
                    id
                    url
                    originName
                }
            }
        }
      imgCrop(width: 268, height: 201, first: 99) {
        edges {
          node {
            id
            url
              originName
          }
        }
      }
      answers(first: 10, sort: created_at_reverse) {
        totalCount
        edges {
          cursor
          node {
            id
            content
            myLike
            myDislike
            likeCount
            dislikeCount
            commentsCount
            createdAt
            updatedAt
            isUpdated
            author {
              hashid
              nickname
              imgCrop(width: 24, height: 24) {
                url
              }
            }
              img(first: 99) {
                  edges {
                      node {
                          id
                          url
                          originName
                      }
                  }
              }
            imgCrop(width: 226, height: 201, first: 99) {
              edges {
                node {
                  id
                  url
                    originName
                }
              }
            }
            comments(first: 15,sort: created_at) {
              totalCount
              edges {
                cursor
                node {
                  id
                  content
                  createdAt
                  author {
                      hashid
                    nickname
                  }
                }
              }
            }
          }
        }
      }
      languageTags(sort: admin_order) {
        id
        name
        color
      }
      createdAt
      isUpdated
      updatedAt
    }
  }
`;

export const ANSWERS_FOR_QUESTION = gql`
  query($questionGid: ID, $after: String) {
    question(questionGid: $questionGid) {
      id
      answers(first: 1, sort: created_at_reverse, after: $after) {
        totalCount
        edges {
          ${answerNode}
        }
      }
    }
  }
`;

export const COMMENTS_FOR_ANSWER = gql`
  query($answerGid: ID!, $after: String) {
    allComments(
      answerGid: $answerGid
      first: 1
      after: $after
      sort: created_at_reverse
    ) {
      totalCount
      edges {
        ${commentNode}
      }
    }
  }
`;

export const ALL_MY_QUESTIONS = gql`
  query(
    $contentsCountPerPage: Int!
    $startPageId: String
    $questionSort: QuestionSort
  ) {
    allQuestions(
      my: true
      first: $contentsCountPerPage
      after: $startPageId
      sort: $questionSort
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          title
          content
          myLike
          myDislike
          likeCount
          dislikeCount
          totalLikeCount
          viewCount
          myAnswersCount
          answersCount
          createdAt
          updatedAt
          languageTags(sort: admin_order) {
            id
            name
            color
          }
        }
      }
    }
  }
`;

export const ALL_MY_QUESTIONS_PAGINATION = gql`
  query(
    $contentsCountPerPage: Int!
    $startPageNum: Int!
    $questionSort: QuestionSort
  ) {
    allQuestionsPagination(
      my: true
      pageSize: $contentsCountPerPage
      startPage: $startPageNum
      sort: $questionSort
    ) {
      lastPage {
        pageNumber
        after
      }
      pages {
        pageNumber
        after
      }
    }
  }
`;

/**
 *  나의 답변들을 조회하는 경우 내 질문의 내 답변은 제외. (my: false)
 */
export const ALL_MY_ANSWERS_FOR_QUESTION = gql`
  query(
    $contentsCountPerPage: Int!
    $startPageId: String
    $questionSort: QuestionSort
    $answerSort: AnswerSort
  ) {
    allQuestions(
      first: $contentsCountPerPage
      after: $startPageId
      sort: $questionSort
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          title
          answersCount
          myAnswersCount
          languageTags(sort: admin_order) {
            id
            name
            color
          }
          author {
            hashid
            nickname
            imgCrop(width: 534, height: 534) {
              url
            }
          }
          createdAt
          answers(my: true, first: 1, sort: $answerSort) {
            edges {
              node {
                id
                myLike
                content
                totalLikeCount
                createdAt
                updatedAt
                isUpdated
                author {
                  nickname
                }
                question {
                  id
                  title
                  answersCount
                  languageTags {
                    id
                    name
                    color
                  }
                  author {
                    nickname
                  }
                  updatedAt
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 *  나의 답변들을 조회하는 경우 내 질문의 내 답변은 제외. (my: false)
 */
export const ALL_MY_ANSWERS_FOR_QUESTION_PAGINATION = gql`
  query(
    $contentsCountPerPage: Int!
    $startPageNum: Int!
    $questionSort: QuestionSort
  ) {
    allQuestionsPagination(
      pageSize: $contentsCountPerPage
      startPage: $startPageNum
      sort: $questionSort
    ) {
      lastPage {
        pageNumber
        after
      }
      pages {
        pageNumber
        after
      }
    }
  }
`;

/**
 *  나의 댓글들을 조회하는 경우 내 질문의 내 답변은 제외. (my: false)
 */
export const ALL_MY_COMMENTS_FOR_QUESTION = gql`
  query(
    $contentsCountPerPage: Int!
    $startPageId: String
    $questionSort: QuestionSort
    $answerSort: AnswerSort
    $commentSort: CommentSort
  ) {
    allQuestions(
      first: $contentsCountPerPage
      after: $startPageId
      sort: $questionSort
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          title
          answersCount
          myAnswersCount
          languageTags(sort: admin_order) {
            id
            name
            color
          }
          author {
            nickname
            imgCrop(width: 24, height: 24) {
              url
            }
          }
          createdAt
          updatedAt
          answers(my: false, first: 1, sort: $answerSort) {
            edges {
              node {
                id
                content
                totalLikeCount
                commentsCount
                createdAt
                updatedAt
                isUpdated
                author {
                  nickname
                }
                comments(my: true, first: 1, sort: $commentSort) {
                  totalCount
                  edges {
                    cursor
                    node {
                      id
                      content
                      createdAt
                      updatedAt
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 *  나의 댓글들을 조회하는 경우 내 질문의 내 답변은 제외. (my: false)
 */
export const ALL_MY_COMMENTS_FOR_QUESTION_PAGINATION = gql`
  query(
    $contentsCountPerPage: Int!
    $startPageNum: Int!
    $questionSort: QuestionSort
  ) {
    allQuestionsPagination(
      my: false
      pageSize: $contentsCountPerPage
      startPage: $startPageNum
      sort: $questionSort
    ) {
      lastPage {
        pageNumber
        after
      }
      pages {
        pageNumber
        after
      }
    }
  }
`;

export const STEP_QUESTION = gql`
  query($questionGid: ID) {
    question(questionGid: $questionGid) {
      id
      title
      content
      viewCount
      myLike
      myDislike
      likeCount
      dislikeCount
      answersCount
      myAnswersCount
      commentsCount
      myCommentsCount
      author {
        hashid
        nickname
        imgCrop(width: 24, height: 24) {
          url
        }
      }
      img(first: 99) {
        edges {
          node {
            id
            url
            originName
          }
        }
      }
      imgCrop(width: 268, height: 201, first: 99) {
        edges {
          node {
            url
            originName
          }
        }
      }
      answers(first: 10, sort: created_at_reverse) {
        totalCount
        edges {
          cursor
          node {
            id
            content
            myLike
            myDislike
            likeCount
            dislikeCount
            commentsCount
            createdAt
            updatedAt
            isUpdated
            author {
              hashid
              nickname
              imgCrop(width: 24, height: 24) {
                url
              }
            }
            imgCrop(width: 268, height: 201, first: 99) {
              edges {
                node {
                  url
                  originName
                }
              }
            }
            comments(first: 15, sort: created_at) {
              totalCount
              edges {
                cursor
                node {
                  id
                  content
                  createdAt
                  author {
                    hashid
                    nickname
                  }
                }
              }
            }
          }
        }
      }
      languageTags(sort: admin_order) {
        id
        name
        color
      }
      createdAt
      isUpdated
      updatedAt
    }
  }
`;

export const ALL_STEP_QUESTIONS = gql`
  query(
    $contentsCountPerPage: Int!
    $startPageId: String
    $searchKeyword: String
    $courseGid: ID
    $questionSort: QuestionSort
  ) {
    allQuestions(
      courseGid: $courseGid
      titleContent: $searchKeyword
      first: $contentsCountPerPage
      after: $startPageId
      sort: $questionSort
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edgeCount
      edges {
        node {
          id
          title
          content
          myLike
          myDislike
          likeCount
          dislikeCount
          totalLikeCount
          viewCount
          myAnswersCount
          answersCount
          createdAt
          updatedAt
          languageTags(sort: admin_order) {
            id
            name
            color
          }
          author {
            hashid
            nickname
            imgCrop(width: 24, height: 24) {
              url
              width
              height
            }
          }
        }
      }
    }
  }
`;

export const ALL_STEP_QUESTIONS_PAGINATION = gql`
  query(
    $contentsCountPerPage: Int!
    $startPageNum: Int!
    $searchKeyword: String
    $courseGid: ID
    $questionSort: QuestionSort
  ) {
    allQuestionsPagination(
      courseGid: $courseGid
      pageSize: $contentsCountPerPage
      startPage: $startPageNum
      titleContent: $searchKeyword
      sort: $questionSort
    ) {
      lastPage {
        pageNumber
        after
      }
      pages {
        pageNumber
        after
      }
    }
  }
`;

export const ALL_MY_COURSES = gql`
  query(
    $last: Int
    $first: Int!
    $status: MyCourseStatusEnum!
    $sort: MyCourseSortEnum
    $after: String
  ) {
    myCourses(
      after: $after
      status: $status
      sort: $sort
      first: $first
      last: $last
    ) {
      totalCount
      edgeCount
      edges {
        cursor
        node {
          id
          name
          period
          introduce
          imgCrop(width: 60, height: 60) {
            url
          }
          languageTags(first: 5) {
            edges {
              node {
                name
                color
                description
                id
              }
            }
          }
          courseStatus {
            isCompleted
            completedAt
            isExpired
            expiredAt
            createdAt
          }
          lastChapterInProgress {
            id
            name
            order
            introduce
            stepsCount {
              videoStepCount
              liveCodingStepCount
              executionQuizStepCount
              totalStepCount
              completedStepCount
            }
            steps(first: 50) {
              edges {
                node {
                  ... on VideoStepNode {
                    __typename
                    id
                    order
                    name
                    summary
                    completed
                  }
                  ... on LiveCodingStepNode {
                    __typename
                    id
                    name
                    summary
                    order
                    completed
                    terminalType
                  }
                  ... on ExecutionQuizStepNode {
                    __typename
                    id
                    name
                    summary
                    order
                    completed
                  }
                }
              }
            }
          }
          stepsCount {
            videoStepCount
            liveCodingStepCount
            executionQuizStepCount
            totalStepCount
            completedStepCount
          }
          chapters(first: 20) {
            totalCount
            edges {
              node {
                id
                order
                name
                introduce
                chapterStatus {
                  isCompleted
                  completedAt
                }
                stepsCount {
                  videoStepCount
                  liveCodingStepCount
                  executionQuizStepCount
                  totalStepCount
                  completedStepCount
                }
                #imgCrop(width: 320, height: 180) {
                #  url
                #}
                steps(first: 50) {
                  edges {
                    node {
                      ... on VideoStepNode {
                        __typename
                        id
                        order
                        name
                        summary
                        completed
                      }
                      ... on LiveCodingStepNode {
                        __typename
                        id
                        name
                        summary
                        order
                        completed
                        terminalType
                      }
                      ... on ExecutionQuizStepNode {
                        __typename
                        id
                        name
                        summary
                        order
                        completed
                      }
                    }
                  }
                }
              }
            }
          }
          teacher {
            hashid
            id
            nickname
            introduce
            profileItems {
              itemText
              order
            }
            user {
              hashid
              # teacher 의 사인 이미지
              imgCrop(width: 240, height: 240) {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const ALL_MY_COURSES_PAGINATION = gql`
  query($pageSize: Int!, $startPage: Int!, $status: MyCourseStatusEnum) {
    myCoursesPagination(
      pageSize: $pageSize
      startPage: $startPage
      status: $status
    ) {
      lastPage {
        pageNumber
        after
      }
      pages {
        pageNumber
        after
      }
    }
  }
`;

export const ALL_PAYMENTS = gql`
  query($first: Int!, $status: String!, $after: String) {
    allPayments(first: $first, status: $status, after: $after) {
      edgeCount
      edges {
        cursor
        node {
          name
          id
          hashid
          payMethod
          paidAt
          amount
          pgProvider
          impUid
          cardName
          cardNumber
          currency
          vbankNum
          vbankName
          vbankHolder
          cancelAmount
          status
          buyerEmail
          course {
            id
            courseStatus {
              createdAt
              expiredAt
            }
          }
        }
      }
    }
  }
`;

export const ALL_PAYMENTS_PAGINATION = gql`
  query($pageSize: Int!, $startPage: Int!) {
    allPaymentsPagination(pageSize: $pageSize, startPage: $startPage) {
      lastPage {
        pageNumber
        after
      }
      pages {
        pageNumber
        after
      }
    }
  }
`;

export const STEP_HISTORY_INFO = gql`
  query($first: Int = 100, $step: ID, $date: String) {
    stepHistoryInfo(first: $first, step: $step, date: $date) {
      histories {
        createdAt
        hashid
        name
        playTime
        user
        id
        tabTree
        fileTree
        currentFile
      }
    }
  }
`;

export const STEP_HISTORY_CALENDAR = gql`
  query($first: Int = 100, $step: ID) {
    stepHistoryCalendar(first: $first, step: $step)
  }
`;

export const NEXT_STEP = gql`
  query($step: ID) {
    nextStep(stepGid: $step) {
      __typename
      ... on VideoStepNode {
        id
        completed
      }
      ... on LiveCodingStepNode {
        id
        completed
        terminalType
      }
      ... on ExecutionQuizStepNode {
        id
        completed
      }
    }
  }
`;

export const ALL_LANGUAGE_TAGS = gql`
  query {
    allLanguageTags(sort: admin_order, first: 20) {
      edges {
        node {
          id
          name
          color
        }
      }
    }
  }
`;

export const ORDER_COURSE = isAuthenticated => {
  return gql`
    query($courseGid: ID!) {
      course(courseGid: $courseGid) {
        id
        name
        introduce
        overview
        madeBy
        learningAt
        level
        period
        courseStatus { isExpired }
        ${isAuthenticated ? "registered" : ""}
        imgCrop(width: 320, height: 320) {
          url
        }
        package {
          id
          name
          originPrice
          price
          usdPrice
          priority
        }
        teacher {
          id
          nickname
        }
        languageTags(first: 10) {
          edges {
            node {
              id
              color
              name
            }
          }
        }
      }
    }
  `;
};

export const PAYMENT_RESULT = isAuthenticated => {
  return gql`
    query($paymentGid: ID!) {
      payment(paymentGid: $paymentGid) {
        id
        hashid
        name
        merchantUid
        paidAt
        pgProvider
        payMethod
        amount
        currency
        buyerName
        vbankNum
        vbankName
        vbankHolder
        vbankDate
        receiptUrl
        course {
          id
          name
          madeBy
          learningAt
          introduce
          overview
          level
          period
          imgCrop(width: 320, height: 320) {
            url
          }
          package {
            id
            name
            originPrice
            price
            priority
          }
          teacher {
            id
            nickname
          }
          languageTags(first: 10) {
            edges {
              node {
                id
                color
                name
              }
            }
          }
        }
      }
    }
  `;
};
