import React, { Component, lazy } from "react";
import { connect } from "react-redux";
import { KBBANK_CNAME } from "../../../constants";

const Chapter = lazy(() => import("./Chapter"));
const References = lazy(() => import("./References"));
const ChangeHistory = lazy(() => import("./ChangeHistory"));
const Quiz = lazy(() => import("./Quiz"));
const QuizB2B = lazy(() => import("./QuizB2B"));
const Peeking = lazy(() => import("./Peeking"));
const QAndA = lazy(() => import("../q-and-a/Container"));

class Container extends Component {
  render() {
    const { leftTab, gridForLeftTab, authBasic } = this.props;
    return (
      <div
        className={`left-tab-contents h-100${
          gridForLeftTab.colSize === 0 ? "" : " show"
        }`}
      >
        {leftTab === "chapter" && <Chapter />}
        {leftTab === "q-and-a" && <QAndA />}
        {leftTab === "references" && <References />}
        {leftTab === "change-history" && <ChangeHistory />}
        {leftTab === "quiz" &&
          (!authBasic.organization ||
            authBasic.organization?.cname === KBBANK_CNAME) && <Quiz />}
        {leftTab === "quiz" &&
          authBasic.organization &&
          authBasic.organization?.cname !== KBBANK_CNAME && <QuizB2B />}
        {leftTab === "peeking" && <Peeking />}
      </div>
    );
  }
}

const mapState = ({
  ide: { leftTab, gridForLeftTab },
  auth: { authBasic }
}) => ({
  leftTab,
  gridForLeftTab,
  authBasic
});
export default connect(mapState)(Container);
