import { getApolloContext } from "../common";
import { ALL_PAYMENTS, ALL_PAYMENTS_PAGINATION } from "../graphql/queries";
import store from "../store";

export default {
  state: {
    allPayments: [],
    totalPaymentsCount: 0,
    allPaymentsPagination: null
  },
  reducers: {
    setAllPayments(state, payload) {
      return {
        ...state,
        allPayments: payload
      };
    },
    setAllPaymentsPagination(state, payload) {
      return {
        ...state,
        allPaymentsPagination: payload
      };
    },
    setTotalPaymentsCount(state, payload) {
      return {
        ...state,
        totalPaymentsCount: payload
      };
    }
  },
  effects: dispatch => ({
    async getAllPayments(
      { last, status, pageSize, first, currentPage } = {},
      rootState
    ) {
      const state = store.getState();
      let startPage = 1;

      const quotient = Math.floor((Number(currentPage) - 1) / pageSize);
      if (quotient > 0) {
        startPage = quotient * pageSize + 1;
      }

      if (state.payments.allPaymentsPagination === null) {
        const allPaymentsPagination = await this.getAllPaymentsPaginations({
          pageSize,
          startPage: startPage,
          status
        });
        if (allPaymentsPagination)
          this.setAllPaymentsPagination(allPaymentsPagination);
      }

      const { general: client } = rootState.common.apollo.client;

      let after = "";
      if (currentPage !== 1) {
        if (state.payments.allPaymentsPagination === null) {
          const { pages } = await this.getAllPaymentsPaginations({
            pageSize,
            startPage: startPage,
            status
          });
          after = pages[Number(currentPage) - 1].after;
        } else {
          after =
            state.payments.allPaymentsPagination.pages[Number(currentPage) - 1]
              .after;
        }
      }

      try {
        const {
          data: { allPayments }
        } = await client.query({
          query: ALL_PAYMENTS,
          context: await getApolloContext(),
          fetchPolicy: "network-only",
          variables: {
            first,
            last,
            status,
            after
          }
        });

        const { edges, edgeCount } = allPayments;
        const newAllPayments = edges.map((val, idx) => val.node);
        this.setAllPayments(newAllPayments);
        this.setTotalPaymentsCount(edgeCount);
      } catch ({ graphQLErrors, message }) {
        console.error("getAllPayments", message);
      }
    },
    async getAllPaymentsPaginations(
      { pageSize, startPage, status } = {},
      rootState
    ) {
      const { general: client } = rootState.common.apollo.client;
      try {
        const {
          data: { allPaymentsPagination }
        } = await client.query({
          query: ALL_PAYMENTS_PAGINATION,
          context: await getApolloContext(),
          fetchPolicy: "network-only",
          variables: {
            pageSize,
            startPage,
            status
          }
        });

        return allPaymentsPagination;
      } catch ({ graphQLErrors, message }) {
        console.error("getAllPaymentsPagination", message);
      }
    }
  })
};
