import React, { Component } from "react";
import { Modal, Container, Button } from "react-bootstrap";

class NoOrYes extends Component {
  render() {
    const {
      children,
      show,
      labelForYes,
      labelForNo,
      onYes,
      onNo,
      alertYes = false
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={() => {}}
        centered
        className="text-center"
        dialogClassName="popup"
        style={{ zIndex: 999 }}
        backdrop={false}
      >
        <Modal.Body className="py-5">{children}</Modal.Body>

        <Modal.Footer className="p-0 border-top-0">
          <Container>
            <div className="row">
              {!alertYes && (
                <Button variant="light" className="col border-0" onClick={onNo}>
                  {labelForNo || "No"}
                </Button>
              )}

              <Button variant="light" className="col border-0" onClick={onYes}>
                <strong>{labelForYes || "Yes"}</strong>
              </Button>
            </div>
          </Container>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default NoOrYes;
