import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";

class ScrollBar extends Component {
  render() {
    const { children, getRef } = this.props;
    return (
      <Scrollbars
        ref={scrollbar => {
          if (getRef) {
            getRef(scrollbar);
          }
        }}
        className={"scrollbar-container"}
        autoHide
        renderTrackVertical={({ style, ...props }) => (
          <div
            style={{ ...style, ...{ width: 4 } }}
            {...props}
            className="scrollbar-track-vertical"
          />
        )}
        renderThumbVertical={props => (
          <div {...props} className="scrollbar-thumb-vertical" />
        )}
      >
        {children}
      </Scrollbars>
    );
  }
}

export default ScrollBar;
