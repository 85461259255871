import React, { Component } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

class PrivateRoute extends Component {
  render() {
    const {
      isAuthenticated,
      authBasic,
      component: Compo,
      render,
      ...rest
    } = this.props;

    return (
      <Route
        {...rest}
        render={props => {
          return isAuthenticated ? (
            render ? (
              render(props)
            ) : (
              <Compo {...props} />
            )
          ) : (
            <Redirect
              to={{
                pathname: "/auth/signin",
                state: { from: props.location }
              }}
            />
          );
        }}
      />
    );
  }
}

const mapState = ({ auth: { isAuthenticated, authBasic } }) => ({
  isAuthenticated,
  authBasic
});

export default withRouter(connect(mapState)(PrivateRoute));
