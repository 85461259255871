import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { animations, decorators, Treebeard } from "react-treebeard";
import { ContextMenu, MenuItem } from "react-contextmenu";
import moment from "moment/min/moment-with-locales";

import { getFileType, getFiletreeTgz, VIDITING_STATUS } from "../../common";
import ScrollBar from "../common/ScrollBar";

import iconDir from "../../images/btn-ide-filetree-directory-off.svg";
import { ReactComponent as IconExport } from "../../images/btn-idefiletree-exportfile-off.svg";
import { Motion, spring } from "react-motion";

import { saveAs } from "file-saver";
import { TOAST_OPTION } from "../../constants";

const toggleDuration = 200;
const refs = {};
const walkRefs = arr => {
  arr.forEach(node => {
    refs[node.relative_path] = createRef();

    if (node.file_type === "directory") {
      walkRefs(node.children);
    }
  });
};

decorators.Toggle = ({ style, node }) => {
  return (
    <div
      style={{
        ...style.base,
        position: "absolute",
        width: 18,
        height: 18,
        marginLeft: 0,
        top: 5,
        left: node.depth * 15 + 6
      }}
    >
      <div
        style={{
          ...style.wrapper,
          height: "auto",
          position: "static",
          margin: 0,
          transition: `transform ${toggleDuration}ms ease-out`,
          transform: `rotateZ(${node.toggled ? 90 : 0}deg)`
        }}
      >
        <img src={iconDir} alt="" className="d-block" />
      </div>
    </div>
  );
};

decorators.Header = ({ style, node }) => {
  return (
    <>
      <div
        style={{
          ...style.base,
          display: "flex",
          paddingLeft: (node.depth + 1) * 15 + 3 + 6 + 4,
          color: node.children ? "#ebebeb" : undefined
        }}
        className={`item align-items-center pr-2${
          node.opened ? " opened" : ""
        }${!node.children && node.isActive ? " active" : ""}`}
        ref={refs[node.relative_path]}
      >
        <span
          className={`icon d-inline-block ${
            node.children ? "dir" : `file ${getFileType(node.name).type}`
          }`}
        ></span>
        <span className="text-truncate">{node.name}</span>
      </div>
    </>
  );
};

decorators.Container = props => {
  return (
    <div onClick={props.onClick}>
      {props.node.children && !!props.node.children.length && (
        <props.decorators.Toggle {...props} style={props.style.toggle} />
      )}
      <props.decorators.Header {...props} style={props.style.header} />
    </div>
  );
};

animations.drawer = () => ({
  enter: {
    animation: "slideDown",
    duration: toggleDuration
  },
  leave: {
    animation: "slideUp",
    duration: toggleDuration
  }
});

const FileContextMenu = ({ node, addFile, addDirectory, rename, remove }) => {
  return (
    <ContextMenu id={`context-menu-${node.id}`}>
      <MenuItem data={{ id: node.id }} onClick={addFile}>
        Add a file
      </MenuItem>
      <MenuItem data={{ id: node.id }} onClick={addDirectory}>
        Add a directory
      </MenuItem>
      <MenuItem data={{ id: node.id }} onClick={rename}>
        Rename
      </MenuItem>
      <MenuItem data={{ id: node.id }} onClick={remove}>
        Delete
      </MenuItem>
    </ContextMenu>
  );
};

class Filetree extends Component {
  state = { contextMenu: [], scrollTop: 0 };
  containerHeight = 0;
  container;
  scrollbar;

  onToggle = (node, toggled) => {
    const { setBy, selectFile, serviceMode } = this.props;

    if (serviceMode !== "TEACHER") {
      node.active = !node.active;
      if (node.children) {
        node.toggled = toggled;

        // this.props.pushToCargo({
        //   target: SECTORS.FILETREE.name,
        //   type: "toggledir",
        //   path: node.relative_path,
        //   expanded: toggled
        // });
      } else {
        if (node.encoding === "binary" && !node.isImage) {
          toast.error(
            "이미지 외 Binary 형식의 파일은 열 수 없습니다.",
            TOAST_OPTION(2000)
          );
        } else {
          setBy({ filetree: true });
          selectFile({ targetFile: node });
        }
      }
      this.setState({ ...this.state, cursor: node });
    }
  };

  onScroll = ev => {
    if (ev) {
      //const { scrollTop } = ev.target;
      // console.log("filetree scroll", { scrollTop });
      // this.props.pushToCargo({
      //   target: SECTORS.FILETREE.name,
      //   type: "scroll",
      //   scrollTop
      // });
    }
  };

  addFile = (e, data) => {
    console.log("addFile: ", data);
  };

  addDirectory = (e, data) => {
    console.log("addDirectory: ", data);
  };

  rename = (e, data) => {
    console.log("rename: ", data);
  };

  remove = (e, data) => {
    console.log("remove: ", data);
  };

  generateContextMenu = (result, arr) => {
    arr.forEach(node => {
      if (node.children) {
        this.generateContextMenu(result, node.children);
      }
      result.push(
        <FileContextMenu
          key={node.id}
          node={node}
          addFile={this.addFile}
          addDirectory={this.addDirectory}
          rename={this.rename}
          remove={this.remove}
        />
      );
    });
  };

  componentDidMount() {
    this.props.incrFiletreeRev();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      filetree: prevFiletree,
      filetreeRev: prevFiletreeRev,
      currentFile: prevFile
    } = prevProps;
    const {
      filetree,
      filetreeRev,
      currentFile,
      by,
      setBy,
      status
    } = this.props;

    if (status !== VIDITING_STATUS.LOADING) {
      //const items = [];
      if (filetree && prevFiletree !== filetree) {
        //this.generateContextMenu(items, filetree);
        //this.setState({ ...this.state, contextMenu: items });
      }

      if (prevFiletreeRev !== filetreeRev) {
        walkRefs(filetree);
      }

      if (
        prevFile &&
        currentFile &&
        prevFile.relative_path !== currentFile.relative_path &&
        by.tabs
      ) {
        setTimeout(() => {
          const clientHeight = this.scrollbar.getClientHeight();
          const scrollTop = this.scrollbar.getScrollTop();
          const diff = this.scrollbar.getScrollHeight() - clientHeight;

          if (refs[currentFile.relative_path]) {
            let el = refs[currentFile.relative_path].current.offsetParent;
            const { clientHeight: height } = el;
            let { offsetTop } = el;

            while (!el.offsetParent.classList.contains("scrollbar-container")) {
              el = el.offsetParent;
              offsetTop += el.offsetTop;
            }

            let top = scrollTop;

            if (scrollTop > offsetTop) {
              top = offsetTop;
            } else if (scrollTop + clientHeight < offsetTop + height) {
              top = offsetTop + height - clientHeight;
            }

            if (top !== scrollTop) {
              this.setState({
                ...this.state,
                scrollTop: diff < top ? diff : top < 0 ? 0 : top
              });
            }
          }
        }, 100);
        setBy({ tabs: false });
      }
    }
  }

  render() {
    const {
      forSolution,
      sectors,
      setScrollElements,
      filetree,
      gtmPushDataLayer
    } = this.props;

    const {
      [!forSolution && "gridForFiletree"]: gridForFiletree
      // [forSolution &&
      // "gridForQuizSolutionFiletree"]: gridForQuizSolutionFiletree
    } = this.props;
    const { contextMenu } = this.state;

    return (
      <div
        className="filetree-container d-flex flex-column"
        ref={sectors.FILETREE.ref}
      >
        <div
          className="d-flex"
          style={{
            ...(gridForFiletree.colSize === 0 ? { overflow: "hidden" } : {})
          }}
        >
          <div className="flex-grow-1"></div>
          <Button
            variant="link"
            className="border-0 export"
            onClick={async () => {
              // zlib.gzip(JSON.stringify({ filetree }), async (error, result) => {
              //   if (error) throw error;
              //
              //   const blob = new Blob([result], {
              //     type: "application/x-gzip"
              //   });

              const blob = await getFiletreeTgz(filetree, false);
              saveAs(
                blob,
                `${this.props.step.name}${moment().format(
                  "YYYYMMDDHHmmss"
                )}.tgz`
              );
              return;
              // });

              gtmPushDataLayer({
                event: "ideFiletreeExportfileClick"
              });
            }}
          >
            <IconExport className="d-block" />
          </Button>
        </div>
        <ScrollBar
          getRef={scrollbar => {
            this.scrollbar = scrollbar;
            setScrollElements({ filetree: scrollbar });
          }}
          onScroll={this.onScroll}
        >
          <Treebeard
            data={filetree}
            animations={animations}
            decorators={decorators}
            onToggle={this.onToggle}
          />
        </ScrollBar>

        <Motion
          style={{
            scrollTop: spring(this.state.scrollTop, {
              stiffness: 500,
              damping: 50
            })
          }}
        >
          {currentStyles => {
            return (
              <Scroller
                scrollingElement={this.scrollbar}
                scrollTop={currentStyles.scrollTop}
              />
            );
          }}
        </Motion>
        {contextMenu}
      </div>
    );
  }
}

class Scroller extends Component {
  componentDidUpdate(prevProps) {
    const { scrollingElement, scrollTop } = this.props;
    // console.log(scrollingElement, prevProps.scrollLeft, scrollLeft);
    if (scrollingElement && prevProps.scrollTop !== scrollTop) {
      scrollingElement.scrollTop(scrollTop);
    }
  }

  render() {
    return null;
  }
}
const mapState = ({
  ide: { gridForFiletree, gridForQuizSolutionFiletree, step },
  viditing: { filetree, filetreeRev, currentFile, by, status, serviceMode }
}) => ({
  gridForFiletree,
  gridForQuizSolutionFiletree,
  step,
  filetree,
  filetreeRev,
  currentFile,
  by,
  status,
  serviceMode
});
const mapDispatch = ({
  viditing: { incrFiletreeRev, setBy, setScrollElements, selectFile },
  gtm: { gtmPushDataLayer }
}) => ({
  incrFiletreeRev,
  setBy,
  setScrollElements,
  selectFile,
  gtmPushDataLayer
});
export default connect(mapState, mapDispatch)(Filetree);
