import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Dropdown, Button } from "react-bootstrap";
import { removeTokens } from "../../common";

import imgDefaultProfile from "../../images/img-accountsetting-defaultprofilepic.svg";
import { ReactComponent as IconArrowOn } from "../../images/btn-header-language-on.svg";
import { ReactComponent as IconArrowOff } from "../../images/btn-header-language-off.svg";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    variant="link"
    ref={ref}
    onClick={onClick}
    className="border-0 toggle-personal"
  >
    {children}
  </Button>
));

class PersonalDropdown extends Component {
  state = { isOpen: false };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      isAuthenticated: prevIsAuthenticated,
      location: { pathname: prevLocationPathName }
    } = prevProps;
    const { isAuthenticated, location } = this.props;

    if (prevIsAuthenticated !== isAuthenticated && !isAuthenticated) {
      this.setState({ ...this.state, isOpen: false });
    } else if (
      prevLocationPathName !== location.pathname &&
      this.state.isOpen === true
    ) {
      this.setState({ ...this.state, isOpen: false });
    }
  }

  render() {
    const {
      history,
      isAuthenticated,
      authBasic,
      gtmPushDataLayer
    } = this.props;
    const { isOpen } = this.state;
    const { pathname, search, hash } = document.location;
    const organization = authBasic.organization;

    return (
      <Dropdown
        alignRight
        bsPrefix="dropdown common"
        show={isOpen}
        onToggle={open => {
          if (isAuthenticated) {
            this.setState({ ...this.state, isOpen: open });
          } else {
            history.push("/auth/signin", {
              from: `${pathname}${search}${hash}`
            });
          }
        }}
        onSelect={eventKey => {
          // history.push(`/class/${eventKey}`);
        }}
      >
        <Dropdown.Toggle as={CustomToggle}>
          <img
            src={
              authBasic && authBasic.imgCrop && authBasic.imgCrop.url
                ? authBasic.imgCrop.url
                : imgDefaultProfile
            }
            alt=""
            className="rounded-circle mr-1"
          />
          {isOpen ? (
            <IconArrowOn className="arrow" />
          ) : (
            <IconArrowOff className="arrow" />
          )}
        </Dropdown.Toggle>
        {!organization ? (
          <Dropdown.Menu as={CustomMenu}>
            <Dropdown.Item
              as={Link}
              to="/personal/dashboard"
              onClick={() => {
                gtmPushDataLayer({
                  event: "headerMydashboardClick"
                });
              }}
            >
              {!organization ? "나의 대시보드" : "나의 강의실"}
            </Dropdown.Item>

            <Dropdown.Item
              as={Link}
              to="/personal/profile"
              onClick={() => {
                gtmPushDataLayer({
                  event: "headerMyprofileClick"
                });
              }}
            >
              {!organization ? "나의 프로필" : "나의 계정"}
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to="/personal/qAndA/questions?page=1&sort=created_at_reverse"
              onClick={() => {
                gtmPushDataLayer({
                  event: "headerMyqnaClick"
                });
              }}
            >
              나의 질문답변
            </Dropdown.Item>
          </Dropdown.Menu>
        ) : (
          <Dropdown.Menu as={CustomMenu}>
            <Dropdown.Item
              as={Link}
              to="/personal/dashboard"
              onClick={() => {
                gtmPushDataLayer({
                  event: "headerMydashboardClick"
                });
              }}
            >
              {!organization ? "나의 대시보드" : "나의 강의실"}
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to="/personal/qAndA/questions?page=1&sort=created_at_reverse"
              onClick={() => {
                gtmPushDataLayer({
                  event: "headerMyqnaClick"
                });
              }}
            >
              나의 질문답변
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to="/personal/profile"
              onClick={() => {
                gtmPushDataLayer({
                  event: "headerMyprofileClick"
                });
              }}
            >
              {!organization ? "나의 프로필" : "나의 계정"}
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
    );
  }
}

const CustomMenu = React.forwardRef(
  ({ gtmPushDataLayer, children, alignRight, close, show, ...props }, ref) => {
    const { pathname } = document.location;
    const { dataLayer } = window;
    return (
      <div {...props}>
        <div className="list-unstyled text-right">{children}</div>
        <div className="btn-container">
          <Button
            variant="dark"
            className="signout"
            block
            onClick={() => {
              // store.dispatch.auth.signout();
              dataLayer.push({ event: "headerLogoutClick" });
              removeTokens();
              window.location.href = "/";
            }}
          >
            로그아웃
          </Button>
        </div>
      </div>
    );
  }
);

const mapState = ({ auth: { isAuthenticated, authBasic } }) => ({
  isAuthenticated,
  authBasic
});

const mapDispatch = ({ gtm: { gtmPushDataLayer } }) => ({
  gtmPushDataLayer
});

export default withRouter(connect(mapState, mapDispatch)(PersonalDropdown));
