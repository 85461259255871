export const REACT_APP_BACKEND_ENDPOINT = window.REACT_APP_BACKEND_ENDPOINT
  ? window.REACT_APP_BACKEND_ENDPOINT
  : process.env.REACT_APP_BACKEND_ENDPOINT;
export const REACT_APP_BACKEND_WITH_AUTH_ENDPOINT = window.REACT_APP_BACKEND_WITH_AUTH_ENDPOINT
  ? window.REACT_APP_BACKEND_WITH_AUTH_ENDPOINT
  : process.env.REACT_APP_BACKEND_WITH_AUTH_ENDPOINT;
export const REACT_APP_BACKEND_OASIS = window.REACT_APP_BACKEND_OASIS
  ? window.REACT_APP_BACKEND_OASIS
  : process.env.REACT_APP_BACKEND_OASIS;
export const REACT_APP_BACKEND_BRANCH = window.REACT_APP_BACKEND_BRANCH
  ? window.REACT_APP_BACKEND_BRANCH
  : process.env.REACT_APP_BACKEND_BRANCH;
export const REACT_APP_BACKEND_TARANGIRE = window.REACT_APP_BACKEND_TARANGIRE
  ? window.REACT_APP_BACKEND_TARANGIRE
  : process.env.REACT_APP_BACKEND_TARANGIRE;
export const REACT_APP_MODE = window.REACT_APP_MODE
  ? window.REACT_APP_MODE
  : process.env.REACT_APP_MODE;
export const REACT_APP_IAMPORT_IDENTIFY_CODE = window.REACT_APP_IAMPORT_IDENTIFY_CODE
  ? window.REACT_APP_IAMPORT_IDENTIFY_CODE
  : process.env.REACT_APP_IAMPORT_IDENTIFY_CODE;
export const REACT_APP_AWS_COGNITO_PASSWORD_HTML5_REGEXP = window.REACT_APP_AWS_COGNITO_PASSWORD_HTML5_REGEXP
  ? window.REACT_APP_AWS_COGNITO_PASSWORD_HTML5_REGEXP
  : process.env.REACT_APP_AWS_COGNITO_PASSWORD_HTML5_REGEXP;
export const REACT_APP_AUTH_EXPIRY_INTERVAL = window.REACT_APP_AUTH_EXPIRY_INTERVAL
  ? window.REACT_APP_AUTH_EXPIRY_INTERVAL
  : process.env.REACT_APP_AUTH_EXPIRY_INTERVAL;
export const REACT_APP_RECAPTCHA_SITE_KEY = window.REACT_APP_RECAPTCHA_SITE_KEY
  ? window.REACT_APP_RECAPTCHA_SITE_KEY
  : process.env.REACT_APP_RECAPTCHA_SITE_KEY;
