export default function CheckingAuth(args) {
  // let onmessage = e => {
  //   // eslint-disable-line no-unused-vars
  //   // Write your code here...
  //   console.log("Web Worker called by PARENT THREAD...", e.data);
  //   postMessage("Response");
  // };
  this.addEventListener("message", ({ data: { action, time } }) => {
    const params = { action };

    switch (action) {
      case "start":
        this.interval = time;
        setInterval(() => {
          postMessage(params);
        }, time);
        break;
      default:
      // Nothing
    }
  });
}
