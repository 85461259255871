import React, { Component } from "react";
import MonacoEditor from "react-monaco-editor";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { VIDITING_STATUS } from "../../common";
import { toast } from "react-toastify";
import { TOAST_OPTION } from "../../constants";

class Editor extends Component {
  state = { value: "" };
  monaco = null;

  save = debounce(() => {
    this.props.saveFile();
  }, 500);

  onChangeSelection = debounce(({ selection, source }) => {
    const { currentFile } = this.props;
    if (currentFile) {
    }
  }, 50);
  onScroll = ({ type, ...detail }) => {
    const { currentFile } = this.props;
    if (currentFile) {
    }
  };

  changeMode = async v => {
    const { serviceMode, changeServiceMode, slide } = this.props;

    if (
      serviceMode === "TEACHER" &&
      (!slide.isVisible || (slide.isVisible && slide.isMinimized))
    ) {
      await changeServiceMode({ mode: "STUDENT" });
    }
  };

  updateLayout = debounce(() => {
    const { editor, editorContainer, quiz } = this.props;
    if (editor.ref) {
      editor.ref.layout({
        width: editorContainer.ref?.current?.offsetWidth,
        height: editorContainer.ref?.current?.offsetHeight
      });
    }

    if (quiz.solutionEditor.ref) {
      quiz.solutionEditor.ref.layout({
        width: editorContainer.ref?.current?.offsetWidth,
        height: editorContainer.ref?.current?.offsetHeight
      });
    }
  }, 500);

  fileTypeVal = currentFile => {
    let fileType = null;
    if (currentFile) {
      fileType = currentFile.type;
      switch (fileType) {
        case "js":
          fileType = "javascript";
          break;
        case "md":
          fileType = "markdown";
          break;

        case "py":
          fileType = "python";
          break;

        case "rb":
          fileType = "ruby";
          break;

        case "yml":
          fileType = "yaml";
          break;

        case "txt":
          fileType = "restructuredtext";
          break;
        default:
          fileType = currentFile.type;
      }
    } else {
      return null;
    }
    return fileType;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      currentFile: prevCurrentFile,
      serviceMode: prevServiceMode
    } = prevProps;
    const { currentFile, editor, classType, serviceMode } = this.props;

    if (classType === "evaluation") {
      window.addEventListener("resize", this.updateLayout());
    }

    if (classType === "learning") {
      if (prevServiceMode !== serviceMode && serviceMode === "TEACHER") {
        if (currentFile) {
          editor.ref.setValue(currentFile.value);
        }
      }

      if (!currentFile) {
        editor.ref.setValue(null);
      } else if (
        (!prevCurrentFile ? null : prevCurrentFile.value) !== currentFile.value
      ) {
        const model = editor.ref.getModel();
        if (currentFile.value !== editor.ref.getValue()) {
          editor.ref.focus();

          const selection = editor.ref.getSelection();
          //editor.ref.pushUndoStop();
          model.pushEditOperations(
            [selection],
            [
              {
                range: new this.monaco.Range(
                  1,
                  1,
                  Number.MAX_SAFE_INTEGER,
                  Number.MAX_SAFE_INTEGER
                ),

                text: currentFile.value
              }
            ]
          );
        }
      }
    }
    // else if (answer) {
    //   if ((!prevAnswer ? null : prevAnswer) !== answer) {
    //     editor.ref.setValue(answer);
    //   }
    // } else if (editor) {
    //   if ((!prevEditor ? null : prevEditor.value) !== editor.value) {
    //     editor.ref.setValue(editor.value);
    //   }
    // }
  }

  render() {
    const {
      forSolution,
      editorContainer,
      setEditor,
      setViditingEditor,
      classType,
      answer,
      editor,
      show,
      currentFile,
      readOnly,
      mobileChk,
      setQuiz,

      onPointerMove,
      setCurrentFile
    } = this.props;

    const { isMobile, isSafari } = mobileChk;
    let safariHeight = 382;
    if (isMobile) {
      safariHeight = 422;
    }

    let editorHeight =
      forSolution && isSafari && editorContainer.height
        ? editorContainer.height
        : classType !== "media-only"
        ? editorContainer.height
        : isSafari &&
          !forSolution &&
          editorContainer.height > 400 &&
          editorContainer.height - safariHeight;

    if (!editorHeight) {
      editorHeight = "auto";
    }

    let editorWidth = editorContainer.width;

    if (!editorWidth) {
      if (classType === "evaluation") {
        editorWidth = "100%";
      } else {
        editorWidth = "auto";
      }
    }

    return (
      <div
        className={`editor-container flex-grow-1${show ? "" : " d-none"}`}
        // ref={r => {
        //   setRef(r);
        // }}

        onPointerMove={onPointerMove}
        style={{
          height: editorHeight,
          maxHeight: editorHeight,
          width: editorWidth,
          maxWidth: editorWidth
        }}
        onMouseDown={v => {
          if (classType === "learning") {
            this.changeMode(v);
          }
        }}
      >
        {classType === "learning" ? (
          <MonacoEditor
            language={this.fileTypeVal(currentFile)}
            //value={classType !== "learning" && answer ? answer : editor.value}
            options={{
              scrollBeyondLastLine: false,
              automaticLayout: true,
              selectOnLineNumbers: true,
              overviewRulerBorder: false,
              overviewRulerLanes: 0,
              //autoSurround: "never",
              fontFamily:
                '"D2 coding", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
              fontLigatures: true,
              fontSize: 16,
              lineHeight: 28,
              colorDecorators: false,
              smoothScrolling: true,
              minimap: {
                enabled: false
              },
              //wordBasedSuggestions: false,

              readOnly: readOnly
            }}
            id={"viditing-editor"}
            onChange={v => {
              setCurrentFile({ value: v });
              this.save();
            }}
            editorDidMount={(editor, monaco) => {
              editor.focus();
              //editor.onDidChangeCursorSelection(this.onChangeSelection);
              //editor.onDidScrollChange(this.onScroll);
              this.editor = editor;
              this.monaco = monaco;

              setEditor({ ref: editor });
              setViditingEditor({ ref: editor });

              editor.addAction({
                // An unique identifier of the contributed action.
                id: "run-answer",

                // A label of the action that will be presented to the user.
                label: "Answer",

                // An optional array of keybindings for the action.
                keybindings: [
                  monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter
                  // chord
                  // monaco.KeyMod.chord(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_K, monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_M)
                ],

                // A precondition for this action.
                precondition: null,

                // A rule to evaluate on top of the precondition in order to dispatch the keybindings.
                keybindingContext: null,

                // contextMenuGroupId: 'navigation',

                // contextMenuOrder: 1.5,

                // Method that will be executed when the action is triggered.
                // @param editor The editor instance is passed in as a convinience

                run: function(ed) {
                  // runAnswer();
                  return null;
                }
              });

              monaco.editor.defineTheme("codelion-dark", {
                base: "vs-dark",
                inherit: true,
                rules: [{ background: "292a2f" }],
                colors: {
                  "editor.background": "#292a2f",
                  "editor.lineHighlightBackground": "#383a43"
                }
              });
              monaco.editor.setTheme("codelion-dark");
            }}
          />
        ) : (
          <MonacoEditor
            language={undefined}
            value={answer ? answer : editor.value}
            options={{
              scrollBeyondLastLine: false,
              // automaticLayout: true,
              selectOnLineNumbers: true,
              overviewRulerBorder: false,
              overviewRulerLanes: 0,
              //autoSurround: "never",
              fontFamily:
                '"D2 coding", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
              fontLigatures: true,
              fontSize: 16,
              lineHeight: 28,
              colorDecorators: false,
              smoothScrolling: true,
              minimap: {
                enabled: false
              },
              wordWrap: "on",
              wordBasedSuggestions: false,

              readOnly: forSolution
            }}
            onChange={v => {
              if (!forSolution) {
                setEditor({
                  value: v
                });
              }
            }}
            editorDidMount={(editor, monaco) => {
              if (!forSolution) {
                editor.focus();
                this.editor = editor;
                this.monaco = monaco;

                setTimeout(() => {
                  editor.layout();
                }, 1000);

                setEditor({ ref: editor });
                setViditingEditor({ ref: editor });
              } else {
                setQuiz({ solutionEditor: { ref: editor } });
              }

              monaco.editor.defineTheme("codelion-dark", {
                base: "vs-dark",
                inherit: true,
                rules: [{ background: "292a2f" }],
                colors: {
                  "editor.background": "#292a2f",
                  "editor.lineHighlightBackground": "#383a43"
                }
              });
              monaco.editor.setTheme("codelion-dark");
            }}
          />
        )}
      </div>
    );
  }
}

const mapState = ({
  ide: { editorContainer, leftTab, quiz, editor, classType, mobileChk, slide },
  viditing: { serviceMode, currentFile, editor: viditingEditor }
}) => ({
  editorContainer,
  leftTab,
  quiz,
  editor,
  classType,
  mobileChk,
  slide,

  currentFile,
  serviceMode,
  viditingEditor
});
const mapDispatch = ({
  ide: { setEditor, setQuiz },
  viditing: {
    setViditingEditor,
    setCurrentFile,
    saveFile,
    onPointerMove,
    changeServiceMode
  }
}) => ({
  setEditor,
  setViditingEditor,
  setQuiz,
  setCurrentFile,
  saveFile,

  onPointerMove,
  changeServiceMode
});

export default connect(mapState, mapDispatch)(Editor);
