import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Frame } from "framer";
import { debounce } from "lodash";
import useRootClose from "react-overlays/useRootClose";
import { CellMeasurer, CellMeasurerCache, Grid } from "react-virtualized";
import { Button, FormControl } from "react-bootstrap";

import { ReactComponent as IconOff } from "../../images/btn-ide-console-off.svg";
import { ReactComponent as IconOn } from "../../images/btn-ide-console-on.svg";
import { ReactComponent as IconRefresh } from "../../images/btn-browserrefresh-off.svg";

const variants = {
  open: {
    opacity: 1,
    preserve3d: true,
    transition: { duration: 0 }
  },
  closed: {
    opacity: 0
  }
};
const webConsoleHeight = 55;

const WebConsole = ({
  webOutputModal: {
    latestAddress,
    isShownBigAddressInput,
    addressInput,
    ...webOutputModal
  },
  webConsole,
  setWebOutputModal,
  setWebConsole,
  gtmPushDataLayer,
  serviceMode
}) => {
  const [list, setList] = useState([]);
  const containerRef = useRef();
  const [rowIndexForScroll, setRowIndexForScroll] = useState();
  const [isBybassRootClose, toggleBypassRootClose] = useState(false);
  const vgrid = useRef();

  const updateList = useRef(
    debounce((l, cb) => {
      setList(l);
      cb();
    }, 50)
  );
  const debouncedScroll = useRef(
    debounce(scrollInfo => {
      setWebConsole({ scroll: { ...scrollInfo } });
    }, 50)
  );
  const invalidateTimer = useCallback(
    e => {
      if (webConsole.timer !== null) {
        clearTimeout(webConsole.timer);
        setWebConsole({ timer: null });
      }
    },
    [webConsole.timer, setWebConsole]
  );
  const _cache = useRef(
    new CellMeasurerCache({
      defaultWidth: 298,
      fixedWidth: true
      // minHeight: 23,
    })
  );
  const cellRenderer = useCallback(
    ({ columnIndex, key, parent, rowIndex, style }) => {
      return (
        <CellMeasurer
          cache={_cache.current}
          columnIndex={columnIndex}
          key={key}
          parent={parent}
          rowIndex={rowIndex}
        >
          <Frame
            key={key}
            width="100%"
            height="auto"
            className={`console-item ${list[rowIndex].level}`}
            style={{ ...style }}
          >
            {list[rowIndex].args.join(" ")}
          </Frame>
        </CellMeasurer>
      );
    },
    [list]
  );
  useEffect(() => {
    const currentUpdateList = updateList.current;
    const currentDebouncedScroll = debouncedScroll.current;

    setWebConsole({ scrollForPlayback: null });

    return () => {
      currentUpdateList.cancel();
      currentDebouncedScroll.cancel();
    };
  }, [setWebConsole]);

  useEffect(() => {
    if (webConsole.list.length === 0) {
      _cache.current.clearAll();

      try {
        vgrid.current.measureAllCells();
      } catch (err) {}
    }

    updateList.current(webConsole.list, () => {
      setRowIndexForScroll(webConsole.list.length - 1);
    });
  }, [webConsole.list]);

  useEffect(() => {
    if (webConsole.scrollForPlayback && webConsole.scrollForPlayback.y) {
      console.info(
        "webConsole.scrollForPlayback.y.value",
        webConsole.scrollForPlayback.y.value
      );
      // setScrollTop(webConsole.scrollForPlayback.y.value);
      // scrollRef.current.scrollTo({ top: webConsole.scrollForPlayback.y.value });
    }
  }, [webConsole.scrollForPlayback]);

  useRootClose(containerRef, () => setWebConsole({ shown: false }), {
    disabled: isBybassRootClose || !webConsole.shown
  });

  return (
    <Frame
      width={40}
      height="auto"
      position="relative"
      background="transparent"
    >
      <Frame
        className="btn"
        width="100%"
        height={40}
        background="transparent"
        style={{
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
          justifyContent: "center"
        }}
        onTap={() => {
          gtmPushDataLayer({
            event: "ideFunctionConsoleClick"
          });
          if (serviceMode === "STUDENT") {
            setWebConsole({ shown: !webConsole.shown });
          }
        }}
      >
        {webConsole.shown ? <IconOn /> : <IconOff />}
      </Frame>
      <Frame
        ref={containerRef}
        width={300}
        height={400}
        bottom={-140}
        right={39}
        initial={"closed"}
        variants={variants}
        animate={webConsole.shown ? "open" : "closed"}
        background="transparent"
        style={{
          zIndex: 6,
          cursor: "default",
          pointerEvents: webConsole.shown ? "auto" : "none"
        }}
        onTapStart={invalidateTimer}
      >
        <Frame
          width={`calc(100% - 1px)`}
          height={webConsoleHeight}
          background="#ebebeb"
          radius="8px 8px 0 0"
          style={{
            minHeight: webConsoleHeight,
            pointerEvents: webConsole.shown ? "auto" : "none"
          }}
        >
          <Frame
            width="auto"
            height="auto"
            background="transparent"
            style={{
              padding: 10,
              cursor: "pointer",
              pointerEvents: webConsole.shown ? "auto" : "none"
            }}
            onTap={() => {
              setWebConsole({ shown: false });
              gtmPushDataLayer({
                event: "ideFunctionConsoleMinimizationClick"
              });
            }}
          >
            <hr
              className="my-0"
              style={{
                width: 12,
                borderTop: "2px solid #1e1e1e",
                pointerEvents: webConsole.shown ? "auto" : "none"
              }}
            />
          </Frame>
          <Frame
            width="100%"
            height="auto"
            background="transparent"
            style={{
              color: "#000",
              fontSize: "12px",
              textAlign: "center",
              paddingTop: "1px"
            }}
          >
            Console
          </Frame>
          <form
            className="address-container d-flex mt-4 pr-2"
            style={{ pointerEvents: webConsole.shown ? "auto" : "none" }}
          >
            <Button
              variant="link"
              id={"btn-refresh"}
              className="mr-2"
              style={{ pointerEvents: webConsole.shown ? "auto" : "none" }}
              onClick={() => {
                setWebOutputModal({ reload: true });

                gtmPushDataLayer({
                  event: "ideFunctionConsoleRefreshClick"
                });
              }}
            >
              <IconRefresh className="d-block" />
            </Button>
            <FormControl
              className="px-2 py-0 text-truncate flex-grow-1"
              value={latestAddress}
              onChange={() => {}}
              onFocus={() => {
                const target = {
                  isShownBigAddressInput: true
                };

                toggleBypassRootClose(true);
                setTimeout(() => {
                  toggleBypassRootClose(false);
                }, 300);

                if (latestAddress !== addressInput) {
                  target.addressInput = latestAddress;
                }
                setWebOutputModal(target);

                setTimeout(() => {
                  webOutputModal.addressInputRef.current.focus();
                }, 200);
              }}
            />
            <Button
              variant="link"
              id={"btn-clear"}
              className="ml-2 text-decoration-none"
              style={{ pointerEvents: webConsole.shown ? "auto" : "none" }}
              onClick={() => {
                setWebConsole({ list: [] });
                gtmPushDataLayer({
                  event: "ideFunctionConsoleResetClick"
                });
              }}
            >
              Reset
            </Button>
          </form>
        </Frame>

        <Grid
          ref={ref => (vgrid.current = ref)}
          cellRenderer={cellRenderer}
          columnCount={1}
          columnWidth={298}
          containerProps={{
            onWheel: invalidateTimer
          }}
          containerStyle={{
            pointerEvents: webConsole.shown ? "auto" : "none"
          }}
          style={{
            width: "100%",
            height: `calc(100% - ${webConsoleHeight}px`,
            position: "absolute",
            top: webConsoleHeight,
            pointerEvents: webConsole.shown ? "auto" : "none"
          }}
          deferredMeasurementCache={_cache.current}
          rowCount={list.length}
          rowHeight={_cache.current.rowHeight}
          width={300}
          height={400 - webConsoleHeight - 1}
          // onScroll={({ scrollLeft, scrollTop }) => {
          //   console.log({ scrollLeft, scrollTop });
          //   debouncedScroll.current({
          //     x: { value: scrollLeft },
          //     y: { value: scrollTop },
          //   });
          // }}
          scrollToAlignment="start"
          scrollToRow={rowIndexForScroll}
        />
      </Frame>
    </Frame>
  );
};

const mapState = ({
  ide: { webConsole, webOutputModal },
  viditing: { serviceMode }
}) => ({
  webConsole,
  webOutputModal,
  serviceMode
});
const mapDispatch = ({
  ide: { setWebConsole, setWebOutputModal },
  gtm: { gtmPushDataLayer }
}) => ({
  setWebConsole,
  setWebOutputModal,
  gtmPushDataLayer
});

export default connect(mapState, mapDispatch)(WebConsole);
