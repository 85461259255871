import { createRef } from "react";
import {
  getApolloContext,
  isSameSteps,
  leftTabs,
  slideConsts,
  terminalTabs,
  webOutputModalModes,
  MODAL_COMPLETED_TEXT
} from "../common";
import { DIMENSION_MIN } from "../components/class/Container";
import {
  ALL_STEP_QUESTIONS,
  ALL_STEP_QUESTIONS_PAGINATION,
  QUESTION
} from "../graphql/queries";
import {
  ADD_DISLIKE,
  ADD_LIKE,
  CANCEL_DISLIKE,
  CANCEL_LIKE,
  CREATE_IMAGE,
  DELETE_IMAGE,
  CREATE_QUESTION,
  UPDATE_QUESTION,
  CREATE_ANSWER,
  UPDATE_ANSWER,
  CREATE_COMMENT,
  UPDATE_QUESTION_VIEW_COUNT,
  UPDATE_VIDEO_STEP_COMPLETE
} from "../graphql/mutations";

import {
  STEP_QUESTIONS_PAGE_COUNT,
  CONTENTS_COUNT_PER_PAGE_FOR_STEP_QUESTIONS,
  TOAST_OPTION
} from "../constants";
import { toast } from "react-toastify";
import store from "../store";

export default {
  state: {
    mobileChk: {
      isMobile: false,
      isTablet: false,
      isMobileOnly: false,
      isChrome: false,
      isSafari: false
    },
    leftTab: null,
    gridForLeftTab: {
      minSize: [200, 0, 848],
      colSize: 0,
      memorizedColSize: 0
    },
    gridForNS: {
      minSize: 150,
      gridTemplateRows: "1fr 4px 180px 48px",
      gridTemplateQuizRows: "1fr 4px 48px"
    },
    gridForFiletree: {
      minSize: 160,
      colSize: 160,
      memorizedColSize: 0
    },
    gridForQuizSolutionFiletree: {
      minSize: 160,
      colSize: 0,
      memorizedColSize: 0
    },
    gridForConsoles: {
      columnMinSizes: [300, 4, 400],
      gridTemplateColumns: "1fr 0px 0fr"
    },

    classType: null,
    editorContainer: { ref: createRef(), height: "auto" },
    editor: { ref: null, value: "" },

    terminals: {
      isShown: true,
      currentTab: terminalTabs.TEACHER,
      // container: createRef(),
      containerHeight: "140"
    },

    contactUS: false,

    webOutputModal: {
      webOutputID: "WEBOUT" + Math.floor(Math.random() * 10000000),
      ref: createRef(),
      boundaryRef: createRef(),
      addressInputRef: createRef(),
      mode: webOutputModalModes.NONE,
      width: 300,
      height: 300,
      top: 100,
      left: 100,
      latestAddress: ""
    },
    quiz: {
      isQuizPassShow: false,
      isSubmitted: false,
      totalScore: null,
      isSubQuizComplete: false,
      subQuizzes: {},
      inProgressSubQuiz: "",
      hardHintHashid: "",
      lastOnGoingSubQuiz: "",
      tryCount: 0,
      hardHintShown: false,
      simpleHint: {
        isShown: false,
        containerRef: createRef(),
        height: 0,
        content: ""
      },
      solution: {
        isShown: false
      },
      solutionEditor: { ref: null }
    },

    webConsole: {
      shown: false,
      list: [],
      timer: null
    },

    languageTags: null,

    loadingModal: {
      isShow: false
    },

    questionModal: {
      stepGid: null,
      question: null,
      isShow: false,
      isUpdate: false,
      loadQuestions: null,
      loadAnswers: null
    },
    answerModal: {
      questionGid: null,
      question: null,
      answer: null,
      isShow: false,
      isUpdate: false,
      loadQuestions: null,
      loadAnswers: null
    },
    commentModal: {
      answerGid: null,
      question: null,
      answer: null,
      isShow: false,
      isUpdate: false,
      loadQuestions: null,
      loadAnswers: null
    },

    slide: {
      width: 300,
      height: 300,
      widthForNormal: 0,
      heightForNormal: 0,
      isVisible: false,
      isMinimized: true,
      widthForMini: 0,
      heightForMini: 0,
      currentSlideUrl: null
    },

    chapters: [],
    chapter: null,
    step: null,
    questions: [],
    questionsPagination: {},
    answerTopNode: null,
    answers: [],
    isShownModalStepIsCompleted: false,
    isShownModalStepIsCompletedText: MODAL_COMPLETED_TEXT.STEP,
    stepDuration: { isShown: true, milliseconds: 0 }
  },
  reducers: {
    setLeftTab(state, payload = null) {
      return { ...state, leftTab: payload };
    },
    setContactUS(state, payload = true) {
      return { ...state, contactUS: payload };
    },
    setMobileChk(state, payload = null) {
      return { ...state, mobileChk: payload };
    },
    setGridForLeftTab(state, payload) {
      return {
        ...state,
        gridForLeftTab: { ...state.gridForLeftTab, ...payload }
      };
    },
    setGridForNS(state, payload) {
      return {
        ...state,
        gridForNS: { ...state.gridForNS, ...payload }
      };
    },
    setGridForFiletree(state, payload) {
      return {
        ...state,
        gridForFiletree: { ...state.gridForFiletree, ...payload }
      };
    },
    setGridForQuizSolutionFiletree(state, payload) {
      return {
        ...state,
        gridForQuizSolutionFiletree: {
          ...state.gridForQuizSolutionFiletree,
          ...payload
        }
      };
    },
    setGridForConsoles(state, payload) {
      return {
        ...state,
        gridForConsoles: { ...state.gridForConsoles, ...payload }
      };
    },

    setClassType(state, payload) {
      return { ...state, classType: payload };
    },

    setEditorContainer(state, payload) {
      return {
        ...state,
        editorContainer: { ...state.editorContainer, ...payload }
      };
    },
    setEditor(state, payload) {
      return { ...state, editor: { ...state.editor, ...payload } };
    },

    setTerminals(state, payload) {
      return { ...state, terminals: { ...state.terminals, ...payload } };
    },

    setWebOutputModal(state, payload) {
      if (payload.mode) {
        if (payload.mode === webOutputModalModes.GENERAL) {
          return {
            ...state,
            webOutputModal: {
              ...state.webOutputModal,
              ...payload,
              reload: true
            }
          };
        } else {
          return {
            ...state,
            webOutputModal: { ...state.webOutputModal, ...payload }
          };
        }
      } else {
        return {
          ...state,
          webOutputModal: { ...state.webOutputModal, ...payload }
        };
      }
    },

    setQuiz(state, payload) {
      return { ...state, quiz: { ...state.quiz, ...payload } };
    },
    setQuizSimpleHint(state, payload) {
      return {
        ...state,
        quiz: {
          ...state.quiz,
          simpleHint: { ...state.quiz.simpleHint, ...payload }
        }
      };
    },
    setQuizSolution(state, payload) {
      return {
        ...state,
        quiz: {
          ...state.quiz,
          solution: { ...state.quiz.solution, ...payload }
        }
      };
    },
    setSlide(state, payload) {
      return { ...state, slide: { ...state.slide, ...payload } };
    },
    setChapters(state, chapterEdges) {
      return {
        ...state,
        chapters: chapterEdges.edges.map(({ node: chapter }) => ({
          ...chapter,
          steps: chapter.steps.edges.map(({ node: step }) => step)
        }))
      };
    },

    setChapter(state, chapter) {
      return {
        ...state,
        chapter: {
          ...chapter,
          steps: chapter.steps.edges.map(({ node: step }) => step)
        }
      };
    },

    setStep(state, step) {
      return {
        ...state,
        step
      };
    },

    toggleModalStepIsCompleted(state, isShown) {
      return {
        ...state,
        isShownModalStepIsCompleted:
          typeof isShown === "boolean"
            ? isShown
            : !state.isShownModalStepIsCompleted
      };
    },
    toggleModalStepIsCompletedText(state, { textGrade }) {
      return {
        ...state,
        isShownModalStepIsCompletedText: MODAL_COMPLETED_TEXT[textGrade]
      };
    },

    setComments(state, payload) {
      return {
        ...state,
        answers: {
          ...state.answers,
          answers: {
            ...state.answers.answers,
            edges: payload
          }
        }
      };
    },

    setAnswers(state, payload) {
      return {
        ...state,
        answers: {
          ...state.answers,
          answers: payload
        }
      };
    },

    setQuestionAnswers(state, payload) {
      return {
        ...state,
        answers: payload
      };
    },

    setAnswersTopNode(state, payload) {
      return {
        ...state,
        answerTopNode: payload
      };
    },

    setQuestionsAddLike(state, payload) {
      return {
        ...state,
        questions: {
          ...state.questions,
          edges: payload
        }
      };
    },

    setQuestionsAddLikeAnswers(state, payload) {
      return {
        ...state,
        answers: payload
      };
    },

    setAddLikeAnswers(state, payload) {
      return {
        ...state,
        answers: {
          ...state.answers,
          answers: {
            ...state.answers.answers,
            edges: payload
          }
        }
      };
    },
    setAddLikeAnswerTopNode(state, payload) {
      return {
        ...state,
        answerTopNode: {
          ...state.answerTopNode,
          edges: payload
        }
      };
    },

    setQuestions(state, payload) {
      return {
        ...state,
        questions: payload
        // questions: payload.edges.map(({ node: questions }) => {
        //   return { node: questions };
        // })
      };
    },

    setLoadingModal(state, payload) {
      return {
        ...state,
        loadingModal: payload
      };
    },

    setQuestionsPagination(state, payload) {
      return {
        ...state,
        questionsPagination: payload
      };
    },

    setQuestionModalTarget(state, payload) {
      return {
        ...state,
        questionModal: payload
      };
    },

    setAnswerModalTarget(state, payload) {
      return {
        ...state,
        answerModal: payload
      };
    },
    setCommentModalTarget(state, payload) {
      return {
        ...state,
        commentModal: payload
      };
    },
    setStepDuration(state, payload) {
      return {
        ...state,
        stepDuration: { ...state.stepDuration, ...payload }
      };
    },
    setWebConsole(state, payload) {
      return { ...state, webConsole: { ...state.webConsole, ...payload } };
    }
  },
  effects: dispatch => ({
    graphQlErrors({ graphQLErrors, history }, rootState) {
      const { pathname, search, hash } = document.location;
      if (graphQLErrors) {
        graphQLErrors.forEach(async error => {
          try {
            switch (error.code) {
              case 1100001:
                toast.error("수업을 등록해주세요.", TOAST_OPTION(2000));
                window.history.back();
                break;
              case 1400000:
                toast.error(
                  "장시간 사용하지 않아 자동 로그아웃 되었습니다.",
                  TOAST_OPTION(2000)
                );
                try {
                  if (history) {
                    history.push("/auth/signin", {
                      from: `${pathname}${search}${hash}`
                    });
                  } else {
                    window.location.href = "/auth/signin";
                  }
                } catch (e) {}
                break;
              default:
              //Nothing
            }
          } catch (e) {
            console.log("history-error:ide:", e);
          }
        });
      } else {
        console.error("graphQLErrors:ide:", graphQLErrors);
        if (history) {
          history.push("/auth/signin", {
            from: `${pathname}${search}${hash}`
          });
        } else {
          window.location.href = "/auth/signin";
        }
      }
    },
    async createImage({ fileName, file }, rootState) {
      const { upload: client } = rootState.common.apollo.client;

      try {
        return client.mutate({
          mutation: CREATE_IMAGE,
          context: await getApolloContext(),
          variables: { fileName, file }
        });
      } catch ({ graphQlErrors }) {
        console.log("graphQlError:", graphQlErrors);
      }
    },

    async deleteImage({ imageGid }, rootState) {
      const {
        common: {
          apollo: {
            client: { general: client }
          }
        }
      } = rootState;

      return client.mutate({
        mutation: DELETE_IMAGE,
        context: await getApolloContext(),
        variables: { imageGid }
      });
    },

    async createQuestion({ stepGid, questionInfo }, rootState) {
      const {
        common: {
          apollo: {
            client: { general: client }
          }
        }
      } = rootState;

      return client.mutate({
        mutation: CREATE_QUESTION,
        context: await getApolloContext(),
        variables: { stepGid, questionInfo }
      });
    },
    async updateQuestion({ questionGid, questionInfo }, rootState) {
      const {
        common: {
          apollo: {
            client: { general: client }
          }
        }
      } = rootState;

      return client.mutate({
        mutation: UPDATE_QUESTION,
        context: await getApolloContext(),
        variables: { questionGid, questionInfo }
      });
    },
    async createAnswer({ questionGid, answerInfo }, rootState) {
      const {
        common: {
          apollo: {
            client: { general: client }
          }
        }
      } = rootState;

      return client.mutate({
        mutation: CREATE_ANSWER,
        context: await getApolloContext(),
        variables: { questionGid, answerInfo }
      });
    },
    async createComment({ answerGid, content }, rootState) {
      const {
        common: {
          apollo: {
            client: { general: client }
          }
        }
      } = rootState;

      return client.mutate({
        mutation: CREATE_COMMENT,
        context: await getApolloContext(),
        variables: { answerGid, content }
      });
    },
    async updateAnswer({ answerGid, answerInfo }, rootState) {
      const {
        common: {
          apollo: {
            client: { general: client }
          }
        }
      } = rootState;

      return client.mutate({
        mutation: UPDATE_ANSWER,
        context: await getApolloContext(),
        variables: { answerGid, answerInfo }
      });
    },

    openCommentModal(
      { answerGid, question, answer, isUpdate, loadQuestions, loadAnswers },
      rootState
    ) {
      this.setCommentModalTarget({
        answerGid,
        question,
        answer,
        isShow: true,
        isUpdate,
        loadQuestions,
        loadAnswers
      });
    },
    closeCommentModal() {
      this.setCommentModalTarget({
        answerGid: null,
        question: null,
        answer: null,
        isShow: false,
        isUpdate: false,
        loadQuestions: null,
        loadAnswers: null
      });
    },
    openAnswerModal(
      { questionGid, question, answer, isUpdate, loadQuestions, loadAnswers },
      rootState
    ) {
      this.setAnswerModalTarget({
        questionGid,
        question,
        answer,
        isShow: true,
        isUpdate,
        loadQuestions,
        loadAnswers
      });
    },
    closeAnswerModal() {
      this.setAnswerModalTarget({
        questionGid: null,
        question: null,
        answer: null,
        isShow: false,
        isUpdate: false,
        loadQuestions: null,
        loadAnswers: null
      });
    },

    openQuestionModal(
      { stepGid, question, isUpdate, loadQuestions, loadAnswers },
      rootState
    ) {
      this.setQuestionModalTarget({
        stepGid,
        question,
        isShow: true,
        isUpdate,
        loadQuestions,
        loadAnswers
      });
    },
    closeQuestionModal() {
      this.setQuestionModalTarget({
        stepGid: null,
        question: null,
        isShow: false,
        isUpdate: false,
        loadQuestions: null
      });
    },

    async asyncLoadAnswers({ history, questionGid, isDetail }, rootState) {
      const {
        common: {
          apollo: {
            client: { general: client }
          }
        }
      } = rootState;

      try {
        const {
          data: { allAnswers: answerTopNode, question }
        } = await client.query({
          query: QUESTION,
          context: getApolloContext(),
          fetchPolicy: "network-only", //fetchPolicy of ‘network-only’또는‘cache-first’
          variables: {
            questionGid
          }
        });

        if (isDetail) {
          this.setAnswersTopNode(answerTopNode);
          this.setQuestionAnswers(question);
        } else {
          if (question) {
            this.setQuestionAnswers(question);
          }
        }
      } catch ({ graphQLErrors }) {
        //console.log("graphQLErrors:", graphQLErrors);
        this.graphQlErrors({ history, graphQLErrors });
      }
    },
    async updateQuestionViewCount({ history, questionGid }, rootState) {
      const {
        common: {
          apollo: {
            client: { general: client }
          }
        }
      } = rootState;

      try {
        client.mutate({
          mutation: UPDATE_QUESTION_VIEW_COUNT,
          context: await getApolloContext(),
          variables: { questionGid }
        });
      } catch ({ graphQLErrors }) {
        this.graphQlErrors({ history, graphQLErrors });
      }
    },
    async asyncLoadQuestions(
      { history, courseId, currentPageNum, currentStartPageId, search, sort },
      rootState
    ) {
      const {
        common: {
          apollo: {
            client: { general: client }
          }
        }
      } = rootState;

      try {
        const {
          data: { allQuestions }
        } = await client.query({
          query: ALL_STEP_QUESTIONS,
          context: getApolloContext(),
          fetchPolicy: "network-only",
          variables: {
            contentsCountPerPage: CONTENTS_COUNT_PER_PAGE_FOR_STEP_QUESTIONS,
            startPageId: currentStartPageId,
            searchKeyword: search,
            courseGid: courseId,
            questionSort: sort
          }
        });
        this.setQuestions(allQuestions);

        const { totalCount } = allQuestions;

        let pageCount = Math.floor(totalCount / STEP_QUESTIONS_PAGE_COUNT) + 1;

        let startPageNum = 1;
        const quotient = Math.floor(
          (currentPageNum - 1) / STEP_QUESTIONS_PAGE_COUNT
        );
        if (quotient > 0) {
          startPageNum = parseInt(quotient * STEP_QUESTIONS_PAGE_COUNT + 1);
        }

        const {
          data: { allQuestionsPagination }
        } = await client.query({
          query: ALL_STEP_QUESTIONS_PAGINATION,
          context: getApolloContext(),
          fetchPolicy: "network-only",
          variables: {
            contentsCountPerPage: CONTENTS_COUNT_PER_PAGE_FOR_STEP_QUESTIONS,
            startPageNum: startPageNum,
            searchKeyword: search,
            courseGid: courseId,
            questionSort: sort
          }
        });
        this.setQuestionsPagination(allQuestionsPagination);

        return { startPageNum, pageCount };
      } catch ({ graphQLErrors }) {
        this.graphQlErrors({ history, graphQLErrors });
      }
    },
    async setToggleLike(
      { history, objectGid, isDetail, isCancel, componentsName },
      rootState
    ) {
      const {
        common: {
          apollo: {
            client: { general: client }
          }
        }
      } = rootState;

      const { data } = await client.mutate({
        mutation: isCancel ? CANCEL_LIKE : ADD_LIKE,
        context: await getApolloContext(),
        variables: { objectGid }
      });

      let ok = null;
      if (isCancel) {
        ok = data.cancelLike.ok;
      } else {
        ok = data.addLike.ok;
      }

      if (ok) {
        if (componentsName === "question" || componentsName === "questions") {
          await this.setLikeAnswers({
            objectGid,
            isDetail,
            isCancel,
            componentsName,
            keyName: "like"
          });
        }
      }
    },
    async setToggleDisLike(
      { history, objectGid, isDetail, isCancel, componentsName },
      rootState
    ) {
      const {
        common: {
          apollo: {
            client: { general: client }
          }
        }
      } = rootState;

      const { data } = await client.mutate({
        mutation: isCancel ? CANCEL_DISLIKE : ADD_DISLIKE,
        context: await getApolloContext(),
        variables: { objectGid }
      });

      let ok = null;
      if (isCancel) {
        ok = data.cancelLike.ok;
      } else {
        ok = data.addLike.ok;
      }

      if (ok) {
        // await this.setLikeAnswers({
        //   objectGid,
        //   isDetail,
        //   isCancel,
        //   componentsName,
        //   keyName: "disLike"
        // });
      }
    },

    async setLikeAnswers(
      { objectGid, isDetail, isCancel, componentsName, keyName },
      rootState
    ) {
      const {
        ide: { questions, answers, answerTopNode }
      } = rootState;

      let likeConstNum = 0;
      //if (keyName === "like") {
      likeConstNum = isCancel ? -1 : 1;
      // } else {
      //   likeConstNum = isCancel ? 1 : -1;
      // }

      if (isDetail) {
        if (componentsName === "answers") {
          let answersData = [];
          for (let { node: answer } of answers.answers.edges) {
            if (answer.id === objectGid) {
              answersData.push(
                await this.setLikeAnswersParse({
                  obj: answer,
                  isCancel,
                  componentsName,
                  keyName,
                  likeConstNum
                }).then(response => {
                  return response;
                })
              );
            } else {
              answersData.push({ node: answer });
            }
          }

          this.setAddLikeAnswers(answersData);
          //topNode 데이터가 있다면 동시에 업뎃
          if (answerTopNode) {
            let answersDataTop = [];
            for (let { node: answer } of answerTopNode.edges) {
              if (answer.id === objectGid) {
                answersDataTop.push(
                  await this.setLikeAnswersParse({
                    obj: answer,
                    isCancel,
                    componentsName,
                    keyName,
                    likeConstNum
                  }).then(response => {
                    return response;
                  })
                );
              } else {
                answersDataTop.push({ node: answer });
              }
            }

            this.setAddLikeAnswerTopNode(answersDataTop);
          }
        } else if (componentsName === "answerTopNode") {
          if (answerTopNode) {
            let answersDataTop = [];
            for (let { node: answer } of answerTopNode.edges) {
              if (answer.id === objectGid) {
                answersDataTop.push(
                  await this.setLikeAnswersParse({
                    obj: answer,
                    isCancel,
                    componentsName,
                    keyName,
                    likeConstNum
                  }).then(response => {
                    return response;
                  })
                );
              } else {
                answersDataTop.push({ node: answer });
              }
            }
            this.setAddLikeAnswerTopNode(answersDataTop);
          }
          //answer list 데이터에 있다면 동시에 업뎃
          let answersData = [];
          for (let { node: answer } of answers.answers.edges) {
            if (answer.id === objectGid) {
              answersData.push(
                await this.setLikeAnswersParse({
                  obj: answer,
                  isCancel,
                  componentsName,
                  keyName,
                  likeConstNum
                }).then(response => {
                  return response;
                })
              );
            } else {
              answersData.push({ node: answer });
            }
          }

          await this.setAddLikeAnswers(answersData);
        } else {
          let answersData = {
            ...answers,
            likeCount: !isCancel
              ? answers.likeCount + likeConstNum
              : answers.likeCount > 0
              ? answers.likeCount + likeConstNum
              : answers.likeCount,
            dislikeCount: !isCancel
              ? answers.dislikeCount > 0
                ? answers.dislikeCount - 1
                : answers.dislikeCount
              : answers.dislikeCount,
            myDislike: isCancel ? !isCancel : answers.myDislike && false,
            myLike: !isCancel
          };

          let questionsData = [];
          for (let { node: question } of questions.edges) {
            if (question.id === objectGid) {
              //addLikeIndex = index;
              questionsData.push(
                await this.setLikeAnswersParse({
                  obj: question,
                  isCancel,
                  componentsName: "questions",
                  keyName,
                  likeConstNum
                }).then(response => {
                  return response;
                })
              );
            } else {
              questionsData.push({ node: question });
            }
          }

          this.setQuestionsAddLikeAnswers(answersData);
          this.setQuestionsAddLike(questionsData);
        }
      } else {
        //let tmpQuestions = Object.assign([], questions);

        let questionsData = [];
        for (let { node: question } of questions.edges) {
          if (question.id === objectGid) {
            //addLikeIndex = index;
            questionsData.push(
              await this.setLikeAnswersParse({
                obj: question,
                isCancel,
                componentsName,
                keyName,
                likeConstNum
              }).then(response => {
                return response;
              })
            );
          } else {
            questionsData.push({ node: question });
          }
        }

        this.setQuestionsAddLike(questionsData);
      }
    },
    async setLikeAnswersParse(
      { obj, isCancel, componentsName, keyName, likeConstNum },
      rootState
    ) {
      if (componentsName === "questions") {
        if (keyName === "disLike") {
          return {
            node: {
              ...obj,
              likeCount: isCancel
                ? obj.likeCount + likeConstNum
                : obj.likeCount > 0
                ? obj.likeCount + likeConstNum
                : obj.likeCount,
              dislikeCount: obj.dislikeCount - likeConstNum,
              myDislike: !isCancel,
              myLike: isCancel,
              totalLikeCount: obj.totalLikeCount + likeConstNum
            }
          };
        } else {
          return {
            node: {
              ...obj,

              likeCount: obj.likeCount + likeConstNum,
              dislikeCount: obj.dislikeCount - likeConstNum,
              myDislike: isCancel,
              myLike: !isCancel,
              totalLikeCount: obj.totalLikeCount + likeConstNum
            }
          };
        }
      } else {
        if (keyName === "disLike") {
          return {
            node: {
              ...obj,
              likeCount: !isCancel
                ? obj.likeCount > 0
                  ? obj.likeCount - 1
                  : obj.likeCount
                : obj.likeCount,
              dislikeCount: !isCancel
                ? obj.dislikeCount + likeConstNum
                : obj.dislikeCount > 0
                ? obj.dislikeCount + likeConstNum
                : obj.dislikeCount,
              myDislike: !isCancel,
              myLike: isCancel ? !isCancel : obj.myLike && false
            }
          };
        } else {
          return {
            node: {
              ...obj,
              likeCount: !isCancel
                ? obj.likeCount + likeConstNum
                : obj.likeCount > 0
                ? obj.likeCount + likeConstNum
                : obj.likeCount,
              dislikeCount: !isCancel
                ? obj.dislikeCount > 0
                  ? obj.dislikeCount - 1
                  : obj.dislikeCount
                : obj.dislikeCount,
              myDislike: isCancel ? !isCancel : obj.myDislike && false,
              myLike: !isCancel
            }
          };
        }
      }
    },
    async setupLeftTab(params, rootState) {
      const {
        ide: {
          gridForLeftTab: { minSize, memorizedColSize }
        }
      } = rootState;

      if (rootState.ide.leftTab === params) {
        this.setLeftTab(null);
        this.setGridForLeftTab({
          colSize: 0
        });
      } else {
        this.setLeftTab(params);

        const minLeftSize = leftTabs[params].columnInitSize;
        let size = memorizedColSize;
        if (memorizedColSize < minLeftSize) {
          size = minLeftSize;
        }

        minSize[0] = minLeftSize;

        this.setGridForLeftTab({
          minSize: [...minSize],
          colSize: size,
          memorizedColSize: size
        });
      }
    },

    initialize(params, rootState) {
      const { leftTab, quiz } = rootState.ide;

      this.setupLeftTab(leftTab);
      this.setEditorContainer({ height: "auto" });
      this.setTerminals({ containerHeight: "auto" });
      this.setQuiz({
        // tryCount: 0,
        // simpleHint: { ...quiz.simpleHint, isShown: false, height: 0 },
        solution: { ...quiz.solution, isShown: false }
      });
    },

    refreshWebOutputModal(params, rootState) {
      const {
        ide: { classType, webOutputModal: webModal },
        common: {
          windowDimension: { width: winW, height: winH }
        }
      } = rootState;

      if (
        classType !== "media-only" &&
        classType !== "evaluation" &&
        typeof webModal.ref.getBoundingClientRect === "function"
      ) {
        try {
          const modalRect = webModal.ref.getBoundingClientRect();
          const {
            top: containerTop,
            left: containerLeft
          } = webModal.boundaryRef.current.getBoundingClientRect();
          const containerWidth = winW - containerLeft;
          const containerHeight = winH - containerTop;

          let left = modalRect.left - containerLeft;
          let right = left + modalRect.width;

          if (right > containerWidth) {
            left -= right - containerWidth;
          }

          const mod = { left: left < 0 ? 0 : left };
          if (mod.left + modalRect.width > containerWidth) {
            mod.width = containerWidth;
          }

          let top = modalRect.top - containerTop;
          let bottom = top + modalRect.height;

          if (bottom > containerHeight) {
            top -= bottom - containerHeight;
          }
          mod.top = top < 0 ? 0 : top;
          if (mod.top + modalRect.height > containerHeight) {
            mod.height = containerHeight;
          }

          this.setWebOutputModal(mod);
        } catch (e) {}
      }
    },

    toggleQuizSimpleHint(params = {}, rootState) {
      const state = store.getState();
      const { isShown, containerRef } = state.ide.quiz.simpleHint;

      if (params.content && (params.isShown === true || !isShown)) {
        const values = {
          isShown: true,
          ...(params.content ? { content: params.content } : {})
        };

        this.setQuizSimpleHint(values);
        this.setQuizSimpleHint({
          ...values,
          height: containerRef.current && containerRef.current.offsetHeight
        });
      } else {
        this.setQuizSimpleHint({ isShown: false, height: 0, content: "" });
      }
    },

    refreshSlideDimension(params, rootState) {
      const {
        ide: {
          slide: { width, height }
        },
        common: {
          windowDimension: { width: winW, height: winH }
        }
      } = rootState;

      const ratioX = width / (winW - slideConsts.maxPad);
      const ratioY = height / (winH - slideConsts.maxPad);
      let widthForNormal = width;
      let heightForNormal = height;

      if (ratioX > 1 || ratioY > 1) {
        if (ratioX > ratioY) {
          widthForNormal = width / ratioX;
          heightForNormal = height / ratioX;
        } else {
          heightForNormal = height / ratioY;
          widthForNormal = width / ratioY;
        }
      }

      this.setSlide({ widthForNormal, heightForNormal });
    },

    correctGrids(params, rootState) {
      const {
        common: {
          windowDimension: { width: winW }
        },
        ide: {
          gridForLeftTab: { colSize }
        }
      } = rootState;

      if (winW > DIMENSION_MIN.width) {
        const width = winW - DIMENSION_MIN.width - 54 - 8;
        if (width <= colSize) {
          this.setGridForLeftTab({
            colSize: width,
            memorizedColSize: width
          });
        }
      }
    },

    setupStep(
      {
        chapter: {
          id: thisChapterId,
          course: { chapters }
        },
        ...step
      },
      rootState
    ) {
      let checkedLastChapter = false;
      let checkedLastStep = false;
      let checkedNextStep = false;

      this.setChapters({
        edges: chapters.edges.map(({ node: chapter }, idx) => {
          const c = {
            ...chapter,
            steps: {
              edges: chapter.steps.edges.map(({ node: s }, s_idx) => {
                if (s.completed) {
                  chapter.stepCompleted = true;
                }
                if (!checkedLastStep) {
                  if (!s.completed) {
                    checkedLastStep = true;
                    s.latest = true;
                  }
                } else if (!checkedNextStep) {
                  checkedNextStep = true;
                  step.nextStep = s;
                }
                if (isSameSteps(s.id, step.id)) {
                  step.seq = s_idx;
                  step.id = s.id;
                }

                return {
                  node: s
                };
              })
            }
          };

          if (thisChapterId === chapter.id) {
            c.seq = idx;
            this.setChapter(c);
          }

          if (!checkedLastChapter && !chapter.completed) {
            checkedLastChapter = true;
            c.latest = true;
          }
          return {
            node: c
          };
        })
      });

      this.setStep(step);
    },
    async updateVideoStepComplete({ step, time, finish = false }, rootState) {
      const {
        common: {
          apollo: {
            client: { general: client }
          }
        }
      } = rootState;

      try {
        const {
          data: {
            updateVideoStepProgress: { ok, isCompletedStep, isCompletedCourse }
          }
        } = await client.mutate({
          mutation: UPDATE_VIDEO_STEP_COMPLETE,
          context: getApolloContext(),
          variables: { step, timestamp: time }
        });

        if (ok) {
          const { typename, id, completed } = await dispatch.common.nextStep({
            step
          });

          return {
            isCompletedStep,
            isCompletedCourse,
            typename,
            id,
            completed
          };
        } else {
          return {
            isCompletedStep: false,
            isCompletedCourse: false,
            typename: "",
            id: "",
            completed: false
          };
        }

        // if (ok) {
        //   if (isCompletedStep) {
        //     dispatch.ide.toggleModalStepIsCompletedText({
        //       textGrade: "STEP"
        //     });
        //     if (isCompletedChapter) {
        //       dispatch.ide.toggleModalStepIsCompletedText({
        //         textGrade: "CHAPTER"
        //       });
        //       if (isCompletedCourse) {
        //         dispatch.ide.toggleModalStepIsCompletedText({
        //           textGrade: "COURSE"
        //         });
        //         dispatch.ide.toggleModalStepIsCompleted(true);
        //       }
        //     }
        //   } else {
        //     dispatch.ide.toggleModalStepIsCompletedText({ textGrade: "STEP" });
        //     dispatch.ide.toggleModalStepIsCompleted(true);
        //   }
        // }
      } catch (e) {
        console.log(e);
        return {
          isCompletedStep: false,
          isCompletedCourse: false,
          typename: "",
          id: "",
          completed: false
        };
      }
    }
  })
};
