import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Container, Form } from "react-bootstrap";
import qs from "query-string";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { RESEND_VERIFY_EMAIL } from "../../../graphql/mutations";

import { TOAST_OPTION } from "../../../constants";

class SignupFailure extends Component {
  state = { username: null };

  componentDidMount() {
    const { search } = document.location;
    this.setState({ ...this.state, username: qs.parse(search).email });
  }

  render() {
    const { history, i18n, gtmPushDataLayer } = this.props;
    const { username } = this.state;
    return (
      <Container className="auth-container signup-failure d-flex flex-column align-items-center">
        <h2>회원가입 인증 링크 기간 만료</h2>
        <p className="d-inline-block mb-5">
          안녕하세요 코드라이언입니다.
          <br />
          클릭하신 인증 링크는 기간이 만료하였습니다.
          <br />
          가입 완료를 위해 아래의 버튼을 클릭하여 새로운 인증링크를 받아보시기
          바랍니다.
        </p>
        <Formik
          initialValues={{
            values: ""
          }}
          onSubmit={async (values, { setSubmitting }) => {
            gtmPushDataLayer({
              event: "signUpLinkExpiredGetAgainClick"
            });
            try {
              const {
                data: {
                  resendVerifyEmail: { ok }
                }
              } = await this.props.client.mutate({
                mutation: RESEND_VERIFY_EMAIL,
                variables: {
                  email: username,
                  redirectUrl: document.location.origin,
                  locale: i18n.language.split("-")[0].toUpperCase()
                }
              });
              if (ok) {
                toast.success(
                  `새로운 인증 메일이 발송되었습니다. 메일함을 확인해주세요.`,
                  TOAST_OPTION(2000)
                );
                history.replace("/auth/signin");
              }
            } catch ({ graphQLErrors, message }) {
              toast.error(
                `새로운 인증 메일이 발송되지 못했습니다.(${message})`,
                TOAST_OPTION(2000)
              );
            }
            setSubmitting(false);
          }}
        >
          {({
            handleSubmit,
            isSubmitting
            /* and other goodies */
          }) => {
            return (
              <Form
                noValidate
                onSubmit={handleSubmit}
                className="w-100 d-flex flex-column"
              >
                <Button type="submit" className="lion" disabled={isSubmitting}>
                  인증 링크 재발급
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Container>
    );
  }
}

const mapState = ({
  common: {
    apollo: {
      client: { general: client }
    }
  }
}) => ({ client });

const mapDispatch = ({ gtm: { gtmPushDataLayer } }) => ({
  gtmPushDataLayer
});

export default withTranslation()(
  withRouter(connect(mapState, mapDispatch)(SignupFailure))
);
