import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import store from "./store";
import {REACT_APP_MODE} from './config';
import "./i18n";

import * as serviceWorker from "./serviceWorker";

import Router from "./components/RawRouter";

ReactDOM.render(
  <Provider store={store}>
    <Router />
  </Provider>,
  document.getElementById("root")
);

function noop() {}

if (REACT_APP_MODE === "production") {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
} else {
  console.log("REACT_APP_MODE:", REACT_APP_MODE);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
