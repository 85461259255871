import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class SignupSuccess extends Component {
  render() {
    return (
      <Container className="auth-container signup-failure d-flex flex-column align-items-center">
        <h2>Hello World!</h2>
        <p className="d-inline-block mb-5">
          이제 코딩 공부를 시작해볼까요?
          <br />
          공부에만 집중할 수 있도록 코드라이언이 도울게요!
          <br />
        </p>
        <div className="w-100 d-flex flex-column">
          <Button
            type="submit"
            className="lion gtm__completeRegistration"
            as={Link}
            to="/catalog"
          >
            카탈로그로 이동하기
          </Button>
        </div>
      </Container>
    );
  }
}

export default withRouter(SignupSuccess);
