import React from "react";

import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

class CodeBlock extends React.PureComponent {
  render() {
    const { language, value } = this.props;

    return (
      <SyntaxHighlighter language={language} style={atomOneDark}>
        {value || ""}
      </SyntaxHighlighter>
    );
  }
}

export default CodeBlock;
