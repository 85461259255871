export default {
  state: {},
  reducers: {},
  effects: dispatch => ({
    gtmPushDataLayer({ ...data } = {}) {
      const { dataLayer } = window;
      dataLayer.push({ ...data });
    }
  })
};
