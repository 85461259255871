import gql from "graphql-tag";

export const GET_ACCESS_TOKEN = gql`
  mutation($refreshToken: String!) {
    getAccessToken(refreshToken: $refreshToken) {
      accessToken
    }
  }
`;

export const LOGIN = gql`
  mutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`;

export const B2B_SIGNIN = gql`
  mutation($company: String, $user: String) {
    b2bSignIn(company: $company, user: $user) {
      accessToken
      refreshToken
    }
  }
`;

export const B2B_SIGNIN_COURSE = gql`
  mutation(
    $company: String!
    $user: String!
    $globalId: String
    $pNum: String
  ) {
    b2bSignIn(
      company: $company
      user: $user
      globalId: $globalId
      pNum: $pNum
    ) {
      accessToken
      refreshToken
      globalId
    }
  }
`;

export const B2B_LOGIN = gql`
  mutation($company: String, $user: String) {
    b2bLogin(company: $company, user: $user) {
      accessToken
      refreshToken
    }
  }
`;

export const SIGN_UP = gql`
  mutation(
    $email: String!
    $locale: LanguageCodeTypes!
    $marketingAgreement: Boolean!
    $password: String!
    $redirectUrl: String!
  ) {
    signUp(
      email: $email
      locale: $locale
      marketingAgreement: $marketingAgreement
      password: $password
      redirectUrl: $redirectUrl
    ) {
      ok
    }
  }
`;

export const RESEND_VERIFY_EMAIL = gql`
  mutation(
    $email: String!
    $redirectUrl: String!
    $locale: LanguageCodeTypes!
  ) {
    resendVerifyEmail(
      email: $email
      redirectUrl: $redirectUrl
      locale: $locale
    ) {
      ok
    }
  }
`;

export const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation($email: String!, $locale: LanguageCodeTypes!) {
    sendPasswordResetEmail(email: $email, locale: $locale) {
      ok
    }
  }
`;

export const COURSE_REGISTER = gql`
  mutation($course: ID!) {
    courseRegister(courseGid: $course) {
      ok
    }
  }
`;

export const UPDATE_VIDEO_STEP_COMPLETE = gql`
  mutation($step: ID!, $timestamp: Int!) {
    updateVideoStepProgress(stepGid: $step, timestamp: $timestamp) {
      ok
      isCompletedStep
      isCompletedChapter
      isCompletedCourse
    }
  }
`;

export const UPDATE_VIDITING_STEP_COMPLETE = gql`
  mutation($step: ID, $timestamp: Int) {
    updateLiveCodingStepProgress(stepGid: $step, timestamp: $timestamp) {
      ok
      isCompletedStep
      isCompletedChapter
      isCompletedCourse
    }
  }
`;

export const CREATE_HISTORY = gql`
  mutation(
    $step: ID!
    $storage: String!
    $playTime: String!
    $name: String!
    $currentFile: String!
    $fileTree: String!
    $tabTree: String!
  ) {
    createHistory(
      step: $step
      storage: $storage
      playTime: $playTime
      name: $name
      currentFile: $currentFile
      fileTree: $fileTree
      tabTree: $tabTree
    ) {
      ok
      history {
        id
        hashid
        createdAt
        user
        playTime
        name
        currentFile
        fileTree
        tabTree
      }
    }
  }
`;

export const LOAD_HISTORY = gql`
  mutation($historyId: ID!, $storage: String!) {
    loadHistory(historyId: $historyId, storage: $storage) {
      ok
      history {
        id
        hashid
        createdAt
        user
        playTime
        name
        currentFile
        fileTree
        tabTree
      }
    }
  }
`;

export const UPDATE_MY_INFO = gql`
  mutation($myInfo: MyUpdateInfo!) {
    updateMyInfo(myInfo: $myInfo) {
      ok
      my {
        nickname
        imgCrop(width: 534, height: 534) {
          url
        }
      }
    }
  }
`;

export const UPDATE_MY_PASSWORD = gql`
  mutation($newPassword: String!, $oldPassword: String!) {
    updateMyPassword(newPassword: $newPassword, oldPassword: $oldPassword) {
      ok
    }
  }
`;

export const ADD_LIKE = gql`
  mutation($objectGid: ID!) {
    addLike(objectGid: $objectGid, vote: 1) {
      ok
    }
  }
`;

export const ADD_DISLIKE = gql`
  mutation($objectGid: ID!) {
    addLike(objectGid: $objectGid, vote: -1) {
      ok
    }
  }
`;

export const CANCEL_LIKE = gql`
  mutation($objectGid: ID!) {
    cancelLike(objectGid: $objectGid, vote: 1) {
      ok
    }
  }
`;

export const CANCEL_DISLIKE = gql`
  mutation($objectGid: ID!) {
    cancelLike(objectGid: $objectGid, vote: -1) {
      ok
    }
  }
`;

export const CREATE_QUESTION = gql`
  mutation($stepGid: ID!, $questionInfo: QuestionInfo!) {
    createQuestion(stepGid: $stepGid, questionInfo: $questionInfo) {
      ok
    }
  }
`;

export const UPDATE_QUESTION = gql`
  mutation($questionGid: ID!, $questionInfo: QuestionUpdateInfo!) {
    updateQuestion(questionGid: $questionGid, questionInfo: $questionInfo) {
      ok
    }
  }
`;

export const CREATE_ANSWER = gql`
  mutation($questionGid: ID!, $answerInfo: AnswerInfo!) {
    createAnswer(questionGid: $questionGid, answerInfo: $answerInfo) {
      ok
    }
  }
`;

export const UPDATE_ANSWER = gql`
  mutation($answerGid: ID!, $answerInfo: AnswerUpdateInfo!) {
    updateAnswer(answerGid: $answerGid, answerInfo: $answerInfo) {
      ok
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation($answerGid: ID!, $content: String!) {
    createComment(answerGid: $answerGid, content: $content) {
      ok
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation($commentGid: ID, $content: String!) {
    updateComment(commentGid: $commentGid, content: $content) {
      ok
    }
  }
`;

export const UPDATE_QUESTION_VIEW_COUNT = gql`
  mutation($questionGid: ID!) {
    updateQuestionViewCount(questionGid: $questionGid) {
      ok
    }
  }
`;

export const CREATE_IMAGE = gql`
  mutation($fileName: String!) {
    createImage(fileName: $fileName) {
      uploadToken
    }
  }
`;

export const DELETE_IMAGE = gql`
  mutation($imageGid: ID!) {
    deleteImage(imageGid: $imageGid) {
      ok
    }
  }
`;

export const PAY_RESULT = gql`
  mutation($gid: ID!, $impUid: String) {
    payResult(gid: $gid, impUid: $impUid) {
      success
      msg
      payment {
        id
      }
      courses(first: 20) {
        edges {
          node {
            id
            name
            registered
          }
        }
      }
    }
  }
`;

export const PAY_CANCEL = gql`
  mutation($impUid: String!) {
    payCancel(impUid: $impUid) {
      success
      result
    }
  }
`;

export const WANT_REFUND = gql`
  mutation($impUid: String!) {
    wantRefund(impUid: $impUid) {
      success
    }
  }
`;

export const CONTACTUS = gql`
  mutation(
    $email: String!
    $title: String!
    $content: String!
    $ticketType: TicketType!
    $images: [String]!
  ) {
    sendContactUs(
      email: $email
      title: $title
      content: $content
      ticketType: $ticketType
      images: $images
    ) {
      ok
    }
  }
`;

export const QUIZ_STEP_COMPLETE = gql`
  mutation($stepGid: ID!) {
    updateQuizStepComplete(stepGid: $stepGid) {
      ok
      isCompletedStep
      isCompletedChapter
      isCompletedCourse
      totalScore
    }
  }
`;
