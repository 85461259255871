import { getApolloContext } from "../common";
import {
  QUESTION,
  ANSWERS_FOR_QUESTION,
  COMMENTS_FOR_ANSWER,
  ALL_MY_QUESTIONS,
  ALL_MY_QUESTIONS_PAGINATION,
  ALL_MY_ANSWERS_FOR_QUESTION,
  ALL_MY_ANSWERS_FOR_QUESTION_PAGINATION,
  ALL_MY_COMMENTS_FOR_QUESTION,
  ALL_MY_COMMENTS_FOR_QUESTION_PAGINATION
} from "../graphql/queries";
import {
  ADD_LIKE,
  ADD_DISLIKE,
  CANCEL_LIKE,
  CANCEL_DISLIKE,
  CREATE_QUESTION,
  UPDATE_QUESTION,
  CREATE_ANSWER,
  UPDATE_ANSWER,
  CREATE_COMMENT,
  UPDATE_QUESTION_VIEW_COUNT,
  CREATE_IMAGE,
  DELETE_IMAGE
} from "../graphql/mutations";
import {
  CONTENTS_COUNT_PER_PAGE_FOR_MY_QUESTIONS,
  CONTENTS_COUNT_PER_PAGE_FOR_MY_ANSWERS,
  CONTENTS_COUNT_PER_PAGE_FOR_MY_COMMENTS,
  MY_QUESTIONS_PAGE_COUNT,
  MY_ANSWERS_PAGE_COUNT,
  MY_COMMENTS_PAGE_COUNT
} from "../constants";

export default {
  state: {
    questions: [],
    questionsPagination: {},
    answers: [],
    answersPagination: {},
    comments: [],
    commentsPagination: {},
    questionDetailModalTarget: {
      question: null,
      mostPopularAnswer: null,
      totalAnswersCount: 0,
      answers: [],
      isModalShow: false
    },
    questionModalTarget: {
      question: null,
      isShow: false,
      isUpdate: false
    },
    answerModalTarget: {
      question: null,
      answer: null,
      isShow: false,
      isUpdate: false
    },
    commentModalTarget: {
      question: null,
      answer: null,
      isShow: false
    }
  },
  reducers: {
    setQuestions(state, payload) {
      return {
        ...state,
        questions: payload
      };
    },
    setQuestionsPagination(state, payload) {
      return {
        ...state,
        questionsPagination: payload
      };
    },
    setAnswers(state, payload) {
      return {
        ...state,
        answers: payload
      };
    },
    setAnswersPagination(state, payload) {
      return {
        ...state,
        answersPagination: payload
      };
    },
    setComments(state, payload) {
      return {
        ...state,
        comments: payload
      };
    },
    setCommentsPagination(state, payload) {
      return {
        ...state,
        commentsPagination: payload
      };
    },
    setAnswerModalTarget(state, payload) {
      return {
        ...state,
        answerModalTarget: {
          ...state.answerModalTarget,
          ...payload
        }
      };
    },
    setCommentModalTarget(state, payload) {
      return {
        ...state,
        commentModalTarget: {
          ...state.commentModalTarget,
          ...payload
        }
      };
    },
    setQuestionModalTarget(state, payload) {
      return {
        ...state,
        questionModalTarget: {
          ...state.questionModalTarget,
          ...payload
        }
      };
    },
    setQuestionDetailModalTarget(state, payload) {
      return {
        ...state,
        questionDetailModalTarget: {
          ...state.questionDetailModalTarget,
          ...payload
        }
      };
    }
  },
  effects: dispatch => ({
    async createImage({ fileName, file }, rootState) {
      const { upload: client } = rootState.common.apollo.client;
      return client.mutate({
        mutation: CREATE_IMAGE,
        context: await getApolloContext(),
        variables: { fileName, file }
      });
    },
    async deleteImage({ imageGid }, rootState) {
      const { general: client } = rootState.common.apollo.client;
      return client.mutate({
        mutation: DELETE_IMAGE,
        context: await getApolloContext(),
        variables: { imageGid }
      });
    },
    async createQuestion({ stepGid, questionInfo }, rootState) {
      const { general: client } = rootState.common.apollo.client;
      return client.mutate({
        mutation: CREATE_QUESTION,
        context: await getApolloContext(),
        variables: { stepGid, questionInfo }
      });
    },
    async updateQuestion({ questionGid, questionInfo }, rootState) {
      const { general: client } = rootState.common.apollo.client;
      return client.mutate({
        mutation: UPDATE_QUESTION,
        context: await getApolloContext(),
        variables: { questionGid, questionInfo }
      });
    },
    async createAnswer({ questionGid, answerInfo }, rootState) {
      const { general: client } = rootState.common.apollo.client;
      return client.mutate({
        mutation: CREATE_ANSWER,
        context: await getApolloContext(),
        variables: { questionGid, answerInfo }
      });
    },
    async updateAnswer({ answerGid, answerInfo }, rootState) {
      const { general: client } = rootState.common.apollo.client;
      return client.mutate({
        mutation: UPDATE_ANSWER,
        context: await getApolloContext(),
        variables: { answerGid, answerInfo }
      });
    },
    async createComment({ answerGid, content }, rootState) {
      const { general: client } = rootState.common.apollo.client;
      return client.mutate({
        mutation: CREATE_COMMENT,
        context: await getApolloContext(),
        variables: { answerGid, content }
      });
    },
    async updateQuestionViewCount({ questionGid }, rootState) {
      const { general: client } = rootState.common.apollo.client;
      return client.mutate({
        mutation: UPDATE_QUESTION_VIEW_COUNT,
        context: await getApolloContext(),
        variables: { questionGid }
      });
    },
    async toggleLike({ objectGid, isCancel }, rootState) {
      const { general: client } = rootState.common.apollo.client;
      return client.mutate({
        mutation: isCancel ? CANCEL_LIKE : ADD_LIKE,
        context: await getApolloContext(),
        variables: { objectGid }
      });
    },
    async toggleDislike({ objectGid, isCancel }, rootState) {
      const { general: client } = rootState.common.apollo.client;
      return client.mutate({
        mutation: isCancel ? CANCEL_DISLIKE : ADD_DISLIKE,
        context: await getApolloContext(),
        variables: { objectGid }
      });
    },
    async getQuestion({ questionGid }, rootState) {
      const { general: client } = rootState.common.apollo.client;
      try {
        return client.query({
          query: QUESTION,
          context: await getApolloContext(),
          fetchPolicy: "network-only",
          variables: { questionGid }
        });
      } catch ({ graphQLErrors, message }) {
        console.error("getQuestion", message);
      }
    },
    async getCommentsForAnswer({ answerGid, after }, rootState) {
      const { general: client } = rootState.common.apollo.client;
      return client.query({
        query: COMMENTS_FOR_ANSWER,
        context: await getApolloContext(),
        fetchPolicy: "network-only",
        variables: { answerGid, after }
      });
    },
    async getMyQuestionsPagination(
      { contentsCountPerPage, startPageNum, questionSort } = {},
      rootState
    ) {
      const { general: client } = rootState.common.apollo.client;
      const {
        data: { allQuestionsPagination }
      } = await client.query({
        query: ALL_MY_QUESTIONS_PAGINATION,
        context: await getApolloContext(),
        fetchPolicy: "network-only",
        variables: {
          contentsCountPerPage,
          startPageNum,
          questionSort
        }
      });
      return allQuestionsPagination;
    },
    async getMyQuestions(
      { contentsCountPerPage, startPageId, questionSort } = {},
      rootState
    ) {
      const { general: client } = rootState.common.apollo.client;
      const {
        data: { allQuestions }
      } = await client.query({
        query: ALL_MY_QUESTIONS,
        context: await getApolloContext(),
        fetchPolicy: "network-only",
        variables: {
          contentsCountPerPage,
          startPageId,
          questionSort
        }
      });
      return allQuestions;
    },
    async openQuestionDetailModal({ questionGid }, rootState) {
      const {
        data: { question, allAnswers }
      } = await this.getQuestion({ questionGid });

      const {
        answers: { totalCount: totalAnswersCount, edges: answers }
      } = question;

      const mostPopularAnswer =
        question &&
        question.answers &&
        question.answers.totalCount > 2 &&
        allAnswers &&
        allAnswers.edges.length &&
        allAnswers.edges[0].node;

      this.setQuestionDetailModalTarget({
        question,
        mostPopularAnswer,
        totalAnswersCount,
        answers,
        isModalShow: true
      });

      this.updateQuestionViewCount({ questionGid });
    },
    async closeQuestionDetailModal() {
      this.setQuestionDetailModalTarget({
        question: null,
        mostPopularAnswer: null,
        totalAnswersCount: 0,
        answers: [],
        isModalShow: false
      });
    },
    async updateQuestionDetail({ questionGid }, rootState) {
      const {
        data: { question, allAnswers }
      } = await this.getQuestion({ questionGid });

      const {
        answers: { totalCount: totalAnswersCount, edges: answers }
      } = question;

      const mostPopularAnswer =
        question &&
        question.answers &&
        question.answers.totalCount > 2 &&
        allAnswers &&
        allAnswers.edges.length &&
        allAnswers.edges[0].node;

      this.setQuestionDetailModalTarget({
        question,
        mostPopularAnswer,
        totalAnswersCount,
        answers
      });
    },
    async getAnswersForQuestion({ questionGid, sort, after }, rootState) {
      const { general: client } = rootState.common.apollo.client;
      return client.query({
        query: ANSWERS_FOR_QUESTION,
        context: await getApolloContext(),
        fetchPolicy: "network-only",
        variables: { questionGid, sort, after }
      });
    },
    async updateAnswersForQuestion({ questionGid, after }, rootState) {
      const {
        data: {
          question: {
            answers: { totalCount, edges }
          }
        }
      } = await this.getAnswersForQuestion({ questionGid, after });

      const totalAnswersCount = totalCount;
      const newAnswer = rootState.qAndA.questionDetailModalTarget.answers
        .slice()
        .concat(edges);

      this.setQuestionDetailModalTarget({
        totalAnswersCount,
        answers: newAnswer
      });
    },
    async getAllMyQuestions({ currentPageNum, questionSort } = {}, rootState) {
      try {
        const contentsCountPerPage = CONTENTS_COUNT_PER_PAGE_FOR_MY_QUESTIONS;

        let startPageNum = 1;
        const quotient = Math.floor(
          (currentPageNum - 1) / MY_QUESTIONS_PAGE_COUNT
        );
        if (quotient > 0) {
          startPageNum = quotient * MY_QUESTIONS_PAGE_COUNT + 1;
        }

        const allQuestionsPagination = await this.getMyQuestionsPagination({
          contentsCountPerPage,
          startPageNum,
          questionSort
        });
        this.setQuestionsPagination(allQuestionsPagination);

        let startPageId = null;
        if (currentPageNum !== 1) {
          const { pages } = await this.getMyQuestionsPagination({
            contentsCountPerPage,
            startPageNum: currentPageNum,
            questionSort
          });
          startPageId = pages[0].after;
        }

        const { edges } = await this.getMyQuestions({
          contentsCountPerPage,
          startPageId,
          questionSort
        });
        const myQuestions = edges.map((val, idx) => val.node);
        this.setQuestions(myQuestions);
      } catch ({ graphQLErrors, message }) {
        console.error(message);
      }
    },
    async getMyAnswersPagination(
      { contentsCountPerPage, startPageNum, questionSort },
      rootState
    ) {
      const { general: client } = rootState.common.apollo.client;
      const {
        data: { allQuestionsPagination }
      } = await client.query({
        query: ALL_MY_ANSWERS_FOR_QUESTION_PAGINATION,
        context: await getApolloContext(),
        fetchPolicy: "network-only",
        variables: {
          contentsCountPerPage,
          startPageNum,
          questionSort
        }
      });
      return allQuestionsPagination;
    },
    async getMyAnswers(
      { contentsCountPerPage, startPageId, questionSort, answerSort } = {},
      rootState
    ) {
      const { general: client } = rootState.common.apollo.client;
      const {
        data: { allQuestions }
      } = await client.query({
        query: ALL_MY_ANSWERS_FOR_QUESTION,
        context: await getApolloContext(),
        fetchPolicy: "network-only",
        variables: {
          contentsCountPerPage,
          startPageId,
          questionSort,
          answerSort
        }
      });
      return allQuestions;
    },
    async getAllMyAnswers(
      { currentPageNum, questionSort, answerSort } = {},
      rootState
    ) {
      try {
        const contentsCountPerPage = CONTENTS_COUNT_PER_PAGE_FOR_MY_ANSWERS;

        let startPageNum = 1;
        const quotient = Math.floor(
          (currentPageNum - 1) / MY_ANSWERS_PAGE_COUNT
        );
        if (quotient > 0) {
          startPageNum = quotient * MY_ANSWERS_PAGE_COUNT + 1;
        }

        const allQuestionsPagination = await this.getMyAnswersPagination({
          contentsCountPerPage,
          startPageNum,
          questionSort
        });
        this.setAnswersPagination(allQuestionsPagination);

        let startPageId = null;
        if (currentPageNum !== 1) {
          const { pages } = await this.getMyAnswersPagination({
            contentsCountPerPage,
            startPageNum: currentPageNum,
            questionSort
          });
          startPageId = pages[0].after;
        }

        const { edges } = await this.getMyAnswers({
          contentsCountPerPage,
          startPageId,
          questionSort,
          answerSort
        });
        const myAnswers = edges.map((val, idx) => val.node);
        this.setAnswers(myAnswers);
      } catch ({ graphQLErrors, message }) {
        console.error(message);
      }
    },
    async getMyCommentsPagination(
      { contentsCountPerPage, startPageNum, questionSort },
      rootState
    ) {
      const { general: client } = rootState.common.apollo.client;
      const {
        data: { allQuestionsPagination }
      } = await client.query({
        query: ALL_MY_COMMENTS_FOR_QUESTION_PAGINATION,
        context: await getApolloContext(),
        fetchPolicy: "network-only",
        variables: {
          contentsCountPerPage,
          startPageNum,
          questionSort
        }
      });
      return allQuestionsPagination;
    },
    async getMyComments(
      {
        contentsCountPerPage,
        startPageId,
        questionSort,
        answerSort,
        commentSort
      } = {},
      rootState
    ) {
      const { general: client } = rootState.common.apollo.client;
      const {
        data: { allQuestions }
      } = await client.query({
        query: ALL_MY_COMMENTS_FOR_QUESTION,
        context: await getApolloContext(),
        fetchPolicy: "network-only",
        variables: {
          contentsCountPerPage,
          startPageId,
          questionSort,
          answerSort,
          commentSort
        }
      });
      return allQuestions;
    },
    async getAllMyComments({ currentPageNum, commentSort }, rootState) {
      try {
        const contentsCountPerPage = CONTENTS_COUNT_PER_PAGE_FOR_MY_COMMENTS;

        let startPageNum = 1;
        const quotient = Math.floor(
          (currentPageNum - 1) / MY_COMMENTS_PAGE_COUNT
        );
        if (quotient > 0) {
          startPageNum = quotient * MY_COMMENTS_PAGE_COUNT + 1;
        }

        const questionSort = "latest_i_commented";
        const answerSort = "latest_i_commented";
        const allQuestionsPagination = await this.getMyCommentsPagination({
          contentsCountPerPage,
          startPageNum,
          questionSort
        });
        this.setCommentsPagination(allQuestionsPagination);

        let startPageId = null;
        if (currentPageNum !== 1) {
          const { pages } = await this.getMyCommentsPagination({
            contentsCountPerPage,
            startPageNum: currentPageNum,
            questionSort
          });
          startPageId = pages[0].after;
        }

        const { edges } = await this.getMyComments({
          contentsCountPerPage,
          startPageId,
          questionSort,
          answerSort,
          commentSort
        });
        const myComments = edges.map((val, idx) => val.node);
        this.setComments(myComments);
      } catch ({ graphQLErrors, message }) {
        console.error(message);
      }
    },
    openQuestionModal({ question, isUpdate }, rootState) {
      this.setQuestionModalTarget({
        question,
        isShow: true,
        isUpdate
      });
    },
    closeQuestionModal() {
      this.setQuestionModalTarget({
        question: null,
        isShow: false,
        isUpdate: false
      });
    },
    openAnswerModal({ question, answer, isUpdate }, rootState) {
      this.setAnswerModalTarget({
        question,
        answer,
        isShow: true,
        isUpdate
      });
    },
    closeAnswerModal() {
      this.setAnswerModalTarget({
        answer: null,
        question: null,
        isShow: false,
        isUpdate: false
      });
    },
    openCommentModal({ question, answer, isUpdate }, rootState) {
      this.setCommentModalTarget({
        question,
        answer,
        isShow: true
      });
    },
    closeCommentModal() {
      this.setCommentModalTarget({
        question: null,
        answer: null,
        isShow: false
      });
    }
  })
};
