import { toast } from "react-toastify";
import { debounce } from "lodash";
import moment from "moment/min/moment-with-locales";

import {
  getFileType,
  VIDITING_STATUS,
  getFiletreeTgz,
  getFiletreeUnTgz,
  typesForWeb,
  webOutputModalModes,
  getApolloContext,
  terminalTabs,
  getEncoding
} from "../common";
import store from "../store";

import {
  CREATE_HISTORY,
  LOAD_HISTORY,
  UPDATE_VIDITING_STEP_COMPLETE
} from "../graphql/mutations";

import { STEP_HISTORY_CALENDAR, STEP_HISTORY_INFO } from "../graphql/queries";

import { TOAST_OPTION } from "../constants";

export default {
  state: {
    randomScene: 0,
    name: "",
    elements: { terminalType: "python" },
    status: "LOADING",
    initialDirectory: { path: null, blob: null, tree: [] },

    serviceMode: "TEACHER",
    changeModeData: {
      beforeFiletree: [],
      filetree: [],
      tabs: [],
      currentFile: null
    },

    historyCalendars: [],
    historyInfos: [],
    historyCurrentDate: null,
    changeBatchTerminal: false,

    isBatchProcessing: false,

    terminalActWorker: null,

    filetree: [],
    filetreeRev: 0,
    currentFile: null,
    tabs: [],
    by: { filetree: false },

    seekFiletree: [],
    seekTabs: [],

    editor: {},
    loading: { file: false },
    times: { serverSync: 0 },
    sendingFile: { path: null, selection: null },

    timeline: [],

    scrollElements: { filetree: null, tabs: null },
    term: null,

    scriptTime: {
      duration: 0,
      lastPlayTime: 0,
      durationGap: 1
    },

    audioPlayer: {
      onReady: false,
      onEnd: false,
      width: 0,
      height: 0,
      streamingUrl: "",
      streamStatus: "",
      duration: 0,
      progressTimestamp: 0,
      progressInterval: 100,
      lastPlayTime: 0,
      lastSeekTime: 0,
      playbackRate: 1,
      playbackRateMem: 1
    },

    fileName: ""
  },

  // onMessageForServerSync: ,

  reducers: {
    setName(state, payload) {
      return { ...state, name: payload };
    },
    setChangeBatchTerminal(state, payload) {
      return { ...state, changeBatchTerminal: payload };
    },
    setRandomScene(state, payload) {
      return { ...state, randomScene: payload };
    },
    setServiceMode(state, payload) {
      return { ...state, serviceMode: payload };
    },
    setHistoryCalendars(state, payload) {
      return {
        ...state,
        historyCalendars: payload
      };
    },
    setHistoryCurrentDate(state, payload) {
      return {
        ...state,
        historyCurrentDate: payload
      };
    },
    setHistoryInfos(state, payload) {
      return {
        ...state,
        historyInfos: payload
      };
    },
    // setTerminalWorker(state, payload) {
    //   const terminalWorker = new WebWorker(TerminalAck);
    //   terminalWorker.addEventListener(
    //     EVENT_MESSAGE,
    //     this.onMessageForTerminalTimer,
    //     false
    //   );
    //
    //   return { ...state, terminalActWorker: terminalWorker };
    // },
    setAudioPlayer(state, payload) {
      return {
        ...state,
        audioPlayer: { ...state.audioPlayer, ...payload }
      };
    },
    setScriptTime(state, payload) {
      return {
        ...state,
        scriptTime: { ...state.scriptTime, ...payload }
      };
    },
    // closeTerminalWorker(state, payload) {
    //   state.terminalActWorker.removeEventListener(
    //     EVENT_MESSAGE,
    //     this.onMessageForTerminalTimer
    //   );
    //   state.terminalActWorker.terminate();
    // },

    setElements(state, payload) {
      return { ...state, elements: { ...state.elements, ...payload } };
    },
    setStatus(state, payload) {
      return { ...state, status: payload };
    },
    setInitialDirectory(state, payload) {
      return {
        ...state,
        initialDirectory: { ...state.initialDirectory, ...payload },
        ...(payload.tree
          ? {
              filetree: JSON.parse(JSON.stringify(payload.tree)),
              seekFiletree: JSON.parse(JSON.stringify(payload.tree))
            }
          : {})
      };
    },

    setResetBaseData(state, payload) {
      return {
        ...state,
        status: "LOADING",
        initialDirectory: {
          ...state.initialDirectory,
          path: null,
          blob: null,
          tree: []
        },

        changeModeData: {
          ...state.changeModeData,
          beforeFiletree: [],
          filetree: [],
          tabs: [],
          currentFile: null
        },
        isBatchProcessing: false,
        terminalActWorker: null,

        filetree: [],
        filetreeRev: 0,
        currentFile: null,
        tabs: [],
        by: { filetree: false },

        changeBatchTerminal: false,

        seekFiletree: [],
        seekTabs: [],

        editor: {},

        timeline: [],

        scriptTime: {
          ...state.scriptTime,
          duration: 0,
          lastPlayTime: 0,
          durationGap: 1
        },

        serviceMode: "TEACHER",

        audioPlayer: {
          ...state.audioPlayer,
          onReady: false,
          onEnd: false,
          width: 0,
          height: 0,
          streamingUrl: "",
          streamStatus: "",
          duration: 0,
          progressTimestamp: 0,
          progressInterval: 100,
          lastPlayTime: 0,
          lastSeekTime: 0,
          playReady: false
        }
      };
    },

    setChangeModeDataBeforeFiletree(state, payload) {
      //if (payload === "STUDENT") {
      return {
        ...state,
        changeModeData: {
          ...state.changeModeData,
          beforeFiletree: state.filetree
        }
      };
      //}
    },

    setChangeModeData(state, payload) {
      if (payload === "STUDENT") {
        return {
          ...state,
          changeModeData: {
            ...state.changeModeData,
            filetree: state.filetree,
            tabs: state.tabs,
            currentFile: state.currentFile
          }
        };
      } else {
        return {
          ...state,
          filetree: state.changeModeData.filetree,
          tabs: state.changeModeData.tabs,
          currentFile: state.changeModeData.currentFile,
          changeModeData: {
            ...state.changeModeData,
            filetree: [],
            tabs: [],
            currentFile: null
          }
        };
      }
    },
    setChangeLoadData: function(state, { fileTree, tabTree, currentFile }) {
      //console.log("fileTree::", state.filetree, JSON.parse(fileTree));
      return {
        ...state,
        filetree: JSON.parse(fileTree),
        tabs: JSON.parse(tabTree),
        currentFile: JSON.parse(currentFile)
      };
    },
    setTimeline(state, payload) {
      return {
        ...state,
        timeline: payload
      };
    },

    toggleBatchProcessing(state, payload) {
      return {
        ...state,
        isBatchProcessing:
          payload === undefined ? !state.isBatchProcessing : payload
      };
    },

    setFiletree(state, payload) {
      return { ...state, filetree: payload };
    },

    setSeekFiletree(state, payload) {
      return { ...state, seekFiletree: payload };
    },
    incrFiletreeRev(state, payload) {
      return { ...state, filetreeRev: state.filetreeRev + 1 };
    },
    setCurrentFile(state, payload) {
      let target = !payload ? null : { ...state.currentFile, ...payload };

      if (payload && payload.name) {
        const fileType = getFileType(payload.name);
        target = { ...state.currentFile, ...fileType, ...payload };
      }

      return { ...state, currentFile: target };
    },
    setTabs(state, payload) {
      return { ...state, tabs: payload };
    },

    setSeekTabs(state, payload) {
      return { ...state, seekTabs: payload };
    },
    setBy(state, payload) {
      return { ...state, by: { ...state.by, ...payload } };
    },

    setViditingEditor(state, payload) {
      return { ...state, editor: payload };
    },
    setLoading(state, payload) {
      return { ...state, loading: { ...state.loading, ...payload } };
    },
    setTimes(state, payload) {
      return { ...state, times: { ...state.times, ...payload } };
    },
    setSendingFile(state, payload) {
      return { ...state, sendingFile: { ...state.sendingFile, ...payload } };
    },

    setScrollElements(state, payload) {
      return {
        ...state,
        scrollElements: { ...state.scrollElements, ...payload }
      };
    },
    setTerm(state, payload) {
      return { ...state, term: payload };
    }
  },
  effects: dispatch => ({
    graphQlErrors(
      { graphQLErrors, history },
      {
        auth: {
          authBasic: { organization }
        }
      }
    ) {
      const { pathname, search, hash } = document.location;
      if (graphQLErrors.graphQLErrors) {
        graphQLErrors.graphQLErrors.forEach(async error => {
          try {
            switch (error.code) {
              case 1100001:
                if (organization) {
                  alert(
                    "네트워크 오류가 발생하였습니다. \n\n다시한번 접근 부탁드립니다."
                  );
                } else {
                  toast.error("수업을 등록해주세요.", TOAST_OPTION(2000));
                  window.history.back();
                }

                break;
              case 1400000:
                if (organization) {
                  alert(
                    "네트워크 오류가 발생하였습니다. \n\n다시한번 접근 부탁드립니다."
                  );
                  window.close();
                } else {
                  toast.error(
                    "장시간 사용하지 않아 자동 로그아웃 되었습니다.",
                    TOAST_OPTION(2000)
                  );
                  if (history) {
                    history.push("/auth/signin", {
                      from: `${pathname}${search}${hash}`
                    });
                  } else {
                    window.location.href = "/auth/signin";
                  }
                }
                break;
              default:
              //Nothing
            }
          } catch (e) {
            console.log("history-error:viditing:", e);
          }
        });
      } else {
        console.error("graphQLErrors:viditing:", graphQLErrors);
        if (history) {
          history.push("/auth/signin", {
            from: `${pathname}${search}${hash}`
          });
        } else {
          window.location.href = "/auth/signin";
        }
      }
    },
    async changeServiceMode(
      { mode, historyMode = "PLAY", history },
      {
        ide: {
          step: { id: stepGid },
          leftTab
        }
      }
    ) {
      console.log("terminalTabs[mode]", mode, terminalTabs[mode], historyMode);
      if (mode === "STUDENT") {
        if (store.getState().viditing.audioPlayer.lastPlayTime > 0) {
          this.setStatus(VIDITING_STATUS.PAUSED);
          this.setServiceMode(mode);
          dispatch.ide.setTerminals({ currentTab: terminalTabs[mode] });
          this.setChangeModeData(mode);
          dispatch.ide.setSlide({ isMinimized: true });
          toast.warn("학생모드 활성화!", TOAST_OPTION(1000));
          dispatch.gtm.gtmPushDataLayer({
            event: "ideCodeWindowStudentmodeClick"
          });
        }
      } else if (mode === "TEACHER") {
        if (store.getState().viditing.serviceMode === "STUDENT") {
          if (
            store.getState().viditing.filetree !==
            store.getState().viditing.changeModeData.filetree
          ) {
            this.setChangeBatchTerminal(true);
            await this.historyCreate({ historyMode, history });
            this.setChangeModeData(mode);
            this.setServiceMode(mode);
            dispatch.ide.setTerminals({ currentTab: terminalTabs[mode] });
            await this.requestChangeTerminal();
            if (leftTab === "change-history") {
              await this.loadHistoryList({ history, stepGid });
            }
          } else {
            this.setChangeModeData(mode);
            this.setServiceMode(mode);
            dispatch.ide.setTerminals({ currentTab: terminalTabs[mode] });
            await dispatch.viditing.applyFiletreeAlign(
              store.getState().viditing.currentFile &&
                store.getState().viditing.currentFile.relative_path
            );
          }
        }
      }
    },
    switchOffBatchProcessing: debounce((params, rootState) => {
      dispatch.viditing.toggleBatchProcessing(false);
      //this.setStatus(VIDITING_STATUS.PLAYING);
      //dispatch.viditing.setStatus(VIDITING_STATUS.PLAYING);
    }, 500),
    onMessageForTerminalTimer() {
      const state = store.getState();

      if (state.common.ws.isConnected) {
        state.common.ws.socket.json({ type: "ping" });
      }
    },
    async getJSON(gzURL) {
      return await fetch(gzURL, {
        method: "GET"
      })
        .then(response => response.json())
        .catch(error => {
          console.error("fetch Error:", error);
        })
        .then(response => {
          return response;
        });
    },
    async initTerminal(params, { common, ide }) {
      const gzUrl = ide.step.eventUrl;
      const hashId = ide.step.hashid;
      const {
        streamingUrl,
        streamStatus,
        duration,
        progressTimestamp
      } = ide.step;

      if (streamingUrl) {
        this.setAudioPlayer({
          streamingUrl,
          streamStatus,
          duration,
          progressTimestamp,
          lastPlayTime: 0
        });
      }

      common.ws.socket.json({
        type: "resource",
        context: {
          resource_type: "terminal",
          action: "create",
          lecture: hashId,
          user: localStorage.getItem("codelion.accessToken")
        }
      });

      if (gzUrl) {
        let urlJsonDownload = null;
        if (gzUrl.substring(gzUrl.lastIndexOf("."), gzUrl.length) === ".gzip") {
          urlJsonDownload = await getFiletreeUnTgz(gzUrl);
        } else {
          urlJsonDownload = await this.getJSON(gzUrl);
        }

        try {
          const { initialFiletree, timeline, webAutoReload } = urlJsonDownload;

          this.setInitialDirectory({
            tree: initialFiletree,
            webAutoReload: webAutoReload
              ? true
              : webAutoReload === undefined
              ? true
              : false
          });
          this.setTimeline(timeline);
        } catch (e) {
          toast.error("잘못된 데이터 파일입니다.", TOAST_OPTION(2000));
          console.log("ERROR:::::잘못된 데이터 파일입니다.", e);
          window.history.back();
        }
      }
    },
    async requestTerminal(payload, { common, viditing }) {
      console.log("requestTerminal::::::", viditing.initialDirectory.tree);
      if (viditing.initialDirectory.tree.length) {
        const form = new FormData();
        form.append("storage", common.ws.storage);
        form.append(
          "file",
          new File(
            [await getFiletreeTgz(viditing.initialDirectory.tree)],
            `dir-${new Date().getTime()}.tgz`
          )
        );

        try {
          // await dispatch.common.requestAxios({
          //   url: "storage/clean",
          //   method: "POST",
          //   data: { storage: common.ws.storage }
          // });

          const resp = await dispatch.common.requestAxios({
            url: "storage/import/from_file",
            method: "POST",
            data: form,
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (resp.status === 200) {
            console.log(
              "viditing.audioPlayer:",
              store.getState().common.ws,
              viditing.audioPlayer
            );

            dispatch.ide.setWebOutputModal({
              mode: !typesForWeb.find(
                type =>
                  type.value === store.getState().viditing.elements.terminalType
              )
                ? webOutputModalModes.NONE
                : webOutputModalModes.GENERAL,
              latestAddress:
                store.getState().common.ws.web.length ===
                store.getState().common.ws.webOutputOrigin.length
                  ? "/"
                  : store
                      .getState()
                      .common.ws.web.substring(
                        store.getState().common.ws.webOutputOrigin.length
                      )
            });

            //dispatch.viditing.toggleBatchProcessing(true);

            let platform = window.navigator.platform;
            //if (viditing.audioPlayer.onReady) {

            this.setStatus(VIDITING_STATUS.INIT);

            //}
          } else {
            toast.error("파일 등기화에 실패 했습니다.", TOAST_OPTION(2000));
            window.location.reload();
          }
        } catch ({ message, ...rest }) {
          //toast.error(message.toString());
          console.log("ERROR:::::", { message, ...rest });
        }
      } else {
        toast.error("등록된 초기 데이터가 없습니다.", TOAST_OPTION(2000));

        console.log("ERROR:::::등록된 초기 데이터가 없습니다.");
        window.history.back();
        //postProcess();
      }
    },
    async requestReloadTerminal(payload, { common, viditing }) {
      if (viditing.initialDirectory.tree.length) {
        this.setFiletree(viditing.initialDirectory.tree);
        this.setCurrentFile(null);
        this.setTabs([]);

        const form = new FormData();
        form.append("storage", common.ws.storage);
        form.append(
          "file",
          new File(
            [await getFiletreeTgz(viditing.initialDirectory.tree)],
            `dir-${new Date().getTime()}.tgz`
          )
        );

        try {
          // await dispatch.common.requestAxios({
          //   url: "storage/clean",
          //   method: "POST",
          //   data: { storage: common.ws.storage }
          // });

          const resp = await dispatch.common.requestAxios({
            url: "storage/import/from_file",
            method: "POST",
            data: form,
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          // if (resp.status === 200) {
          //   dispatch.viditing.toggleBatchProcessing(true);
          // }
        } catch ({ message, ...rest }) {
          toast.error(message.toString(), TOAST_OPTION(2000));
          console.log("ERROR:::::", { message, ...rest });
        }
      } else {
        toast.error("등록된 초기 데이터가 없습니다.", TOAST_OPTION(2000));
        console.log("ERROR:::::등록된 초기 데이터가 없습니다.");
        //postProcess();
      }
    },
    async requestChangeTerminal(payload, { common, viditing }) {
      console.log(
        "requestChangeTerminal::::::",
        viditing.filetree,
        viditing.currentfile
      );
      if (store.getState().viditing.filetree.length) {
        const form = new FormData();
        form.append("storage", common.ws.storage);
        form.append(
          "file",
          new File(
            [await getFiletreeTgz(store.getState().viditing.filetree, false)],
            `dir-${new Date().getTime()}.tgz`
          )
        );

        try {
          // await dispatch.common.requestAxios({
          //   url: "storage/clean",
          //   method: "POST",
          //   data: { storage: common.ws.storage }
          // });

          const resp = await dispatch.common.requestAxios({
            url: "storage/import/from_file",
            method: "POST",
            data: form,
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (resp.status === 200) {
            await dispatch.viditing.applyFiletreeAlign(
              store.getState().viditing.currentFile.relative_path
            );
            this.setChangeBatchTerminal(false);
            //dispatch.viditing.toggleBatchProcessing(true);
          }
        } catch ({ message, ...rest }) {
          this.setChangeBatchTerminal(false);
          toast.error(message.toString(), TOAST_OPTION(2000));
          console.log("ERROR:::::", { message, ...rest });
        }
      } else {
        this.setChangeBatchTerminal(false);
        toast.error("등록된 초기 데이터가 없습니다.", TOAST_OPTION(2000));
        console.log("ERROR:::::등록된 초기 데이터가 없습니다.");
        //postProcess();
      }
    },
    async requestSeekTerminal(
      { onProgress, startTimeStamp, progressRef, seekTo },
      { common, viditing }
    ) {
      if (store.getState().viditing.filetree.length) {
        const form = new FormData();
        form.append("storage", common.ws.storage);
        form.append(
          "file",
          new File(
            [await getFiletreeTgz(store.getState().viditing.filetree, false)],
            `dir-${new Date().getTime()}.tgz`
          )
        );

        try {
          // await dispatch.common.requestAxios({
          //   url: "storage/clean",
          //   method: "POST",
          //   data: { storage: common.ws.storage }
          // });

          const resp = await dispatch.common.requestAxios({
            url: "storage/import/from_file",
            method: "POST",
            data: form,
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (resp.status === 200) {
            onProgress("release", startTimeStamp);
            console.log("seekterminalLoad!!!!", startTimeStamp);

            //dispatch.viditing.toggleBatchProcessing(true);

            progressRef.current.seekTo(
              parseFloat(startTimeStamp / 1000),
              "seconds"
            );

            dispatch.viditing.setStatus(VIDITING_STATUS.PLAYING);
            store.getState().viditing.editor.ref.focus();

            //소켓응답이 없는 상황시 ;;; 파일이 같은경우 임시 해제
            // setTimeout(() => {
            //   dispatch.viditing.toggleBatchProcessing(false);
            // }, 2000);
          }
        } catch ({ message, ...rest }) {
          toast.error(message.toString(), TOAST_OPTION(2000));
          console.log("ERROR:::::", { message, ...rest });
        }
      } else {
        toast.error("등록된 초기 데이터가 없습니다.", TOAST_OPTION(2000));
        console.log("ERROR:::::등록된 초기 데이터가 없습니다.");
        //postProcess();
      }
    },
    // setupServerSync(serverSync, rootState) {
    //   this.serverSync = new WebWorker(ServerSync);
    //   this.serverSync.addEventListener(
    //     EVENT_MESSAGE,
    //     ({ data: { time } }) => {
    //       this.timeForServer = time;
    //     },
    //     false
    //   );
    //   this.serverSync.postMessage({ action: "init" });
    //
    //   this.setTimes({ serverSync });
    // },

    async selectFileSeek(
      { targetFile, withoutRecords },
      {
        common: {
          ws: { storage }
        },
        viditing: {
          currentFile,
          seekTabs,
          seekFiletree,
          status,
          editor: { ref: editor },
          sendingFile
        }
      }
    ) {
      if (
        !currentFile ||
        targetFile.relative_path !== currentFile.relative_path
      ) {
        const targetTab = seekTabs.find(
          t => t.relative_path === targetFile.relative_path
        );
        let currentSelection = null;
        if (currentFile) {
          currentSelection = editor.getSelection();
        }

        await this.saveFileSeek();
        await this.getFileSeek(targetFile);

        let newTabs = null;

        if (targetTab) {
          newTabs = store.getState().viditing.seekTabs.map(t => {
            if (t.relative_path === targetFile.relative_path) {
              t.lastValue = t.value = targetFile.value;
              t.isActive = true;
            } else {
              delete t.isActive;
            }

            if (
              currentSelection &&
              t.relative_path === currentFile.relative_path
            ) {
              t.selection = currentSelection;
            }

            return t;
          });

          if (targetTab.selection) {
            const {
              positionLineNumber: lineNumber,
              positionColumn: column
            } = targetTab.selection;
            editor.setSelection(targetTab.selection);
            editor.revealPosition({ lineNumber, column });
            //editor.focus();
          }
        } else {
          targetFile.isActive = true;

          // this.addTab(targetFile);
          newTabs = [
            ...store.getState().viditing.seekTabs.map(t => {
              delete t.isActive;

              if (
                currentSelection &&
                t.relative_path === currentFile.relative_path
              ) {
                t.selection = currentSelection;
              }

              return t;
            }),
            targetFile
          ];

          // editor.setPosition({lineNumber: Infinity, column: Infinity});
          const position = { lineNumber: 1, column: 1 };
          editor.setPosition(position);
          editor.revealPosition(position);
          //editor.focus();
        }
        this.setSeekTabs(newTabs);
        this.setCurrentFile(targetFile);
        await this.refreshActivityOnFiletreeSeek(targetFile);
      }
    },

    async selectFile(
      { targetFile, withoutRecords },
      {
        common: {
          ws: { storage }
        },
        viditing: {
          currentFile,
          tabs,
          filetree,
          status,
          editor: { ref: editor }
        }
      }
    ) {
      if (
        !currentFile ||
        targetFile.relative_path !== currentFile.relative_path
      ) {
        const targetTab = tabs.find(
          t => t.relative_path === targetFile.relative_path
        );
        let currentSelection = null;
        if (currentFile) {
          currentSelection = editor.getSelection();
        }

        await this.saveFile();
        await this.getFile(targetFile);

        let newTabs = null;

        if (targetTab) {
          newTabs = tabs.map(t => {
            if (t.relative_path === targetFile.relative_path) {
              t.lastValue = t.value = targetFile.value;
              t.isActive = true;
            } else {
              delete t.isActive;
            }

            if (
              currentSelection &&
              t.relative_path === currentFile.relative_path
            ) {
              t.selection = currentSelection;
            }

            return t;
          });

          if (targetTab.selection) {
            setTimeout(() => {
              const {
                positionLineNumber: lineNumber,
                positionColumn: column
              } = targetTab.selection;
              editor.setSelection(targetTab.selection);
              editor.revealPosition({ lineNumber, column });
              editor.focus();
            }, 100);
          }
        } else {
          targetFile.isActive = true;

          // this.addTab(targetFile);
          newTabs = [
            ...tabs.map(t => {
              delete t.isActive;

              if (
                currentSelection &&
                t.relative_path === currentFile.relative_path
              ) {
                t.selection = currentSelection;
              }

              return t;
            }),
            targetFile
          ];

          setTimeout(() => {
            // editor.setPosition({lineNumber: Infinity, column: Infinity});
            const position = { lineNumber: 1, column: 1 };
            editor.setPosition(position);
            editor.revealPosition(position);
            editor.focus();
          }, 100);
        }

        //if (status === VIDITING_STATUS.SEEK) {
        //this.setTabs(newTabs);
        //} else {
        this.setTabs(newTabs);
        this.setCurrentFile(targetFile);
        this.refreshActivityOnFiletree(targetFile);
        //}
      }
    },

    async getFileSeek(
      targetFile,
      {
        common: {
          ws: { storage, web }
        },
        viditing: {
          seekFiletree,
          currentFile,
          setCurrentFile,
          editor: { ref: editor },
          sendingFile
        }
      }
    ) {
      const type = getFileType(targetFile.name);
      //this.setCurrentFile({ lastValue: currentFile.value });
      let data = null;
      let imageURL = "";

      const walk = arr => {
        arr.forEach(node => {
          if (node.file_type === "directory") {
            walk(node.children);
          } else if (node.relative_path === targetFile.relative_path) {
            data = node;
          }
        });
      };
      walk(store.getState().viditing.seekFiletree);
      try {
        if (data) {
          if (currentFile && currentFile.isImage) {
            URL.revokeObjectURL(currentFile.imageURL);
          }

          if (type.isImage) {
            try {
              imageURL = URL.createObjectURL(data);
            } catch (e) {
              imageURL = web + targetFile.relative_path;
            }
            targetFile.imageURL = imageURL;
            targetFile.isImage = true;
            targetFile.webUrl = web;
            this.setCurrentFile({
              imageURL: imageURL,
              isImage: true
            });
          } else {
            if (data.value) {
              targetFile.buffer = data.buffer;

              targetFile.encoding = data.encoding;

              if (data.encoding !== "binary") {
                targetFile.lastValue = targetFile.value = data.value;
              }
            } else {
              targetFile.encoding = false;

              if (data.encoding !== "binary") {
                targetFile.lastValue = targetFile.value = "";
              }
            }
          }
        }
      } catch (err) {
        console.log("error:::", err);
        toast.error(
          "서버 오류로 파일을 불러오지 못했습니다.",
          TOAST_OPTION(2000)
        );
      }

      if (sendingFile.selection) {
        editor.setSelection(sendingFile.selection);
        //editor.focus();
      }
      this.setSendingFile({ path: null, selection: null });
    },

    async getFile(
      targetFile,
      {
        common: {
          ws: { storage, web }
        },
        viditing: {
          currentFile,
          editor: { ref: editor },
          sendingFile
        }
      }
    ) {
      const type = getFileType(targetFile.name);
      let imageURL = "";

      if (store.getState().viditing.serviceMode === "STUDENT") {
        const config = { responseType: "arraybuffer" };

        if (type.isImage) {
          config.responseType = "blob";
        }

        try {
          const { status, data } = await dispatch.common.requestAxios({
            url: "file",
            method: "POST",
            data: { storage, path: targetFile.relative_path },
            config
          });

          if (status === 200) {
            if (currentFile && currentFile.isImage) {
              URL.revokeObjectURL(currentFile.imageURL);
            }

            if (type.isImage) {
              try {
                imageURL = URL.createObjectURL(data);
              } catch (e) {
                imageURL = web + targetFile.relative_path;
              }
              targetFile.imageURL = imageURL;
              targetFile.isImage = true;
              targetFile.webUrl = web;
              this.setCurrentFile({
                imageURL: imageURL,
                isImage: true
              });
            } else {
              const buffer = new Buffer(data.toString());
              targetFile.buffer = new Uint8Array(buffer);

              const encoding = getEncoding(buffer);
              targetFile.encoding = encoding;

              if (encoding !== "binary") {
                targetFile.lastValue = targetFile.value = data.toString();
              }
            }
          }
        } catch (err) {
          console.log(err);
          toast.error(
            "서버 오류로 파일을 불러오지 못했습니다.",
            TOAST_OPTION(2000)
          );
        }
      } else {
        let data = null;
        const walk = arr => {
          arr.forEach(node => {
            if (node.file_type === "directory") {
              walk(node.children);
            } else if (node.relative_path === targetFile.relative_path) {
              data = node;
            }
          });
        };
        walk(store.getState().viditing.filetree);

        try {
          if (data) {
            if (currentFile && currentFile.isImage) {
              URL.revokeObjectURL(currentFile.imageURL);
            }

            if (type.isImage) {
              try {
                imageURL = URL.createObjectURL(data);
              } catch (e) {
                imageURL = web + targetFile.relative_path;
              }
              targetFile.imageURL = imageURL;
              targetFile.isImage = true;
              targetFile.webUrl = web;
              this.setCurrentFile({
                imageURL: imageURL,
                isImage: true
              });
            } else {
              if (data.value) {
                const buffer = new Buffer(data.value);

                targetFile.buffer = new Uint8Array(buffer);

                const encoding = getEncoding(buffer);
                targetFile.encoding = encoding;

                if (encoding !== "binary") {
                  targetFile.lastValue = targetFile.value = data.value.toString();
                }

                // targetFile.buffer = data.buffer;
                //
                // targetFile.encoding = data.encoding;
                //
                // if (data.encoding !== "binary") {
                //   targetFile.lastValue = targetFile.value = data.value;
                // }
              } else {
                targetFile.encoding = false;

                if (data.encoding !== "binary") {
                  targetFile.lastValue = targetFile.value = "";
                }
              }
            }
          }
        } catch (err) {
          console.log("error:::", err);
          toast.error("오류로 파일을 불러오지 못했습니다.", TOAST_OPTION(2000));
        }
      }
      //this.setCurrentFile({ lastValue: currentFile.value });

      if (sendingFile.selection) {
        editor.setSelection(sendingFile.selection);
        editor.focus();
      }
      this.setSendingFile({ path: null, selection: null });
    },
    async saveFileSeek(
      params,
      {
        common: {
          ws: { storage }
        },
        viditing: {
          seekFiletree,
          currentFile,
          editor: { ref: editor },
          sendingFile
        }
      }
    ) {
      if (
        currentFile &&
        currentFile.lastValue !== currentFile.value &&
        !sendingFile.path
      ) {
        try {
          this.setCurrentFile({ lastValue: currentFile.value });

          const walk = arr => {
            arr.forEach(node => {
              if (node.file_type === "directory") {
                walk(node.children);
              } else if (node.relative_path === currentFile.relative_path) {
                node.lastValue = node.value = currentFile.value;
              }
            });
          };

          const newTree = JSON.parse(
            JSON.stringify(store.getState().viditing.seekFiletree)
          );
          walk(newTree);

          this.setSeekFiletree(newTree);

          // console.log(currentFile, status, data, contentType);
        } catch (err) {
          if (sendingFile.selection) {
            editor.setSelection(sendingFile.selection);
            //editor.focus();
          }
          this.setSendingFile({ path: null, selection: null });
        }
      }
    },
    async saveFile(
      params,
      {
        common: {
          ws: { storage }
        },
        viditing: {
          filetree,
          currentFile,
          editor: { ref: editor },
          sendingFile
        }
      }
    ) {
      if (
        currentFile &&
        currentFile.lastValue !== currentFile.value &&
        !sendingFile.path
      ) {
        // const sendingFileParams = { path: currentFile.relative_path };

        // if (editor.hasTextFocus()) {
        //   // document.activeElement.blur();
        //   //
        //   // const selection = editor.getSelection();
        //   // sendingFileParams.selection = selection;
        //   // console.log(selection, editor.getModel().getValueInRange(selection));
        // }
        // // this.setSendingFile(sendingFileParams);

        try {
          const {
            status,
            data: { mtime }
          } = await this.processSavingFile(currentFile);

          if (status === 200) {
            this.setCurrentFile({ mtime, lastValue: currentFile.value });

            const walk = arr => {
              arr.forEach(node => {
                if (node.file_type === "directory") {
                  walk(node.children);
                } else if (node.relative_path === currentFile.relative_path) {
                  node.lastValue = node.value = currentFile.value;
                }
              });
            };

            const newTree = JSON.parse(
              JSON.stringify(store.getState().viditing.filetree)
            );
            walk(newTree);

            this.setFiletree(newTree);
          }

          // console.log(currentFile, status, data, contentType);
        } catch (err) {
          if (sendingFile.selection) {
            editor.setSelection(sendingFile.selection);
            editor.focus();
          }
          this.setSendingFile({ path: null, selection: null });
        }
      }
    },
    async processSavingFile(
      targetFile,
      {
        common: {
          ws: { storage }
        }
      }
    ) {
      const form = new FormData();
      form.append("storage", storage);
      form.append("path", targetFile.relative_path);
      form.append(
        "file",
        new File(
          [targetFile.value],
          targetFile.relative_path.substring(
            targetFile.relative_path.lastIndexOf("/") + 1
          )
        )
      );

      return dispatch.common.requestAxios({
        url: "file",
        method: "PUT",
        data: form,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      // return axios.put(
      //   `https://fs-apiserver.${process.env.REACT_APP_BACKEND_PROFILE}.codelion.io/file`,
      //   form,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data"
      //     }
      //   }
      // );
    },
    async loadHistoryList(
      { history, stepGid },
      {
        common: {
          apollo: {
            client: { general: client }
          }
        }
      }
    ) {
      try {
        const {
          data: { stepHistoryCalendar }
        } = await client.query({
          query: STEP_HISTORY_CALENDAR,
          context: getApolloContext(),
          fetchPolicy: "network-only",
          variables: {
            first: 100,
            step: stepGid
          }
        });

        this.setHistoryCalendars(stepHistoryCalendar);
      } catch (graphQLErrors) {
        console.log("graphqlErr:", graphQLErrors);
        //this.graphQlErrors({ history, graphQLErrors });
      }
    },

    async loadHistoryInfos(
      { history, stepGid, date },
      {
        common: {
          apollo: {
            client: { general: client }
          }
        }
      }
    ) {
      try {
        const {
          data: {
            stepHistoryInfo: { histories }
          }
        } = await client.query({
          query: STEP_HISTORY_INFO,
          context: getApolloContext(),
          fetchPolicy: "network-only",
          variables: {
            first: 100,
            step: stepGid,
            date
          }
        });

        return histories;
      } catch (graphQLErrors) {
        console.log("graphqlErr:", graphQLErrors);
        //this.graphQlErrors({ history, graphQLErrors });
      }
    },

    async loadHistory(
      { history, historyId, tabTree, fileTree, currentFile },
      {
        common: {
          ws: { storage },
          apollo: {
            client: { general: client }
          }
        }
      }
    ) {
      try {
        this.setChangeLoadData({ tabTree, fileTree, currentFile });

        const {
          data: {
            loadHistory: { ok }
          }
        } = await client.mutate({
          mutation: LOAD_HISTORY,
          context: await getApolloContext(),
          variables: {
            historyId,
            storage
          }
        });

        if (ok) {
          await dispatch.viditing.applyFiletreeAlign(
            store.getState().viditing.currentFile.relative_path
          );
        }
      } catch (graphQLErrors) {
        //console.log("error:", graphQLErrors);
        this.graphQlErrors({ history, graphQLErrors });
      }
    },

    async historyCreate(
      { historyMode, history },
      {
        common: {
          apollo: {
            client: { general: client }
          },

          ws: { storage }
        },
        ide: {
          step: { id: stepGid },
          leftTab
        },
        viditing: {
          audioPlayer: { lastPlayTime },
          filetree,
          currentFile,
          tabs
        }
      }
    ) {
      let hisCurrentFile = currentFile ? currentFile : {};
      let hisFileTree = filetree;
      let hisTabTree = tabs;
      let name = historyMode;
      let playTime = lastPlayTime;
      let step = stepGid;

      if (!storage) {
        toast.warn(
          "강의가 로딩된 후 학습을 시작하실 수 있습니다.",
          TOAST_OPTION(2000)
        );
        return;
      }

      if (
        store.getState().viditing.filetree !==
        store.getState().viditing.changeModeData.filetree
      ) {
        if (
          store.getState().viditing.changeModeData.beforeFiletree !==
          store.getState().viditing.filetree
        ) {
          this.setChangeModeDataBeforeFiletree(historyMode);

          const walk = arr => {
            arr.forEach(node => {
              if (node.file_type === "directory") {
                walk(node.children);
              } else {
                if (node.encoding !== "binary") {
                  node.buffer = {};
                  node.value = "";
                  node.lastValue = "";
                }
              }
            });
          };

          if (hisCurrentFile) {
            hisCurrentFile.buffer = {};
            hisCurrentFile.value = hisCurrentFile.lastValue = "";
          }

          walk(hisFileTree);
          walk(hisTabTree);

          try {
            const {
              data: {
                createHistory: { ok }
              }
            } = await client.mutate({
              mutation: CREATE_HISTORY,
              context: await getApolloContext(),
              variables: {
                currentFile: JSON.stringify(hisCurrentFile),
                fileTree: JSON.stringify(hisFileTree),
                tabTree: JSON.stringify(hisTabTree),
                name,
                playTime,
                step,
                storage
              }
            });

            if (ok) {
              if (leftTab === "change-history") {
                this.setHistoryCurrentDate(moment().format("YYYY-MM-DD"));
              }
            }
          } catch (graphQLErrors) {
            //console.log("error:", graphQLErrors);
            this.graphQlErrors({ history, graphQLErrors });
          }
        }
      }
    },

    async applyFileFromServerSeek(
      paramFile,
      {
        viditing: {
          elements: { terminalType },
          currentFile,
          seekTabs
        }
      }
    ) {
      // console.log({ currentFile, msg });
      if (paramFile.target !== "BIG_ADDRESS_INPUT") {
        let targetFile = JSON.parse(
          JSON.stringify(store.getState().viditing.currentFile)
        );
        if (currentFile && currentFile.relative_path === paramFile.path) {
          await this.saveFileSeek(targetFile);
          this.setCurrentFile(targetFile);
        } else {
          if (!paramFile.path) {
            console.log("paramFile.path::", paramFile);
          }

          targetFile = {
            ...paramFile,
            name: paramFile.path
              ? paramFile.path.substring(paramFile.path.lastIndexOf("/") + 1)
              : "",
            relative_path: paramFile.path ? paramFile.path : ""
          };

          await this.saveFileSeek(targetFile);
        }

        const walk = arr => {
          arr.forEach(node => {
            if (node.file_type === "directory") {
              walk(node.children);
            } else if (paramFile.path === node.relative_path) {
              node.buffer = targetFile.buffer;
              node.encoding = targetFile.encoding;

              if (targetFile.encoding !== "binary") {
                node.lastValue = node.value = targetFile.value;
              }
            }
          });
        };
        const newTree = JSON.parse(
          JSON.stringify(store.getState().viditing.seekFiletree)
        );
        walk(newTree);
        this.setSeekFiletree(newTree);

        this.setSeekTabs(
          store.getState().viditing.seekTabs.map(t => {
            if (t.relative_path === paramFile.path) {
              t.lastValue = t.value = targetFile.value;
            }
            return t;
          })
        );
        console.log("seekTabs::", store.getState().viditing.seekTabs);
      }

      //나중에 SEEK시 마지막에만 리프레시 되도록 수정할
      // switch (terminalType) {
      //   case "web":
      //   case "django":
      //   case "ror":
      //     dispatch.meta.setWebOutputModal({ reload: true });
      //     break;
      //   default:
      //   //Nothing
      // }
    },

    async applyFiletreeAlign(
      path,
      {
        viditing: {
          elements: { terminalType },
          currentFile,
          tabs
        }
      }
    ) {
      let targetFile = JSON.parse(JSON.stringify(currentFile));

      if (currentFile && currentFile.relative_path === path) {
        await this.getFile(targetFile);
      } else {
        targetFile = {
          name: path && path.substring(path.lastIndexOf("/") + 1),
          relative_path: path
        };
        await this.getFile(targetFile);
      }
      this.setCurrentFile(targetFile);

      const walk = arr => {
        arr.forEach(node => {
          if (node.file_type === "directory") {
            walk(node.children);
          } else if (path === node.relative_path) {
            node.buffer = targetFile.buffer;
            node.encoding = targetFile.encoding;

            if (targetFile.encoding !== "binary") {
              node.lastValue = node.value = targetFile.value;
            }
          }
        });
      };
      const newTree = JSON.parse(
        JSON.stringify(store.getState().viditing.filetree)
      );
      walk(newTree);
      this.setFiletree(newTree);
      this.setTabs(
        tabs.map(t => {
          if (t.relative_path === path) {
            t.lastValue = t.value = targetFile.value;
          }
          return t;
        })
      );
    },
    async applyFileFromServer(
      msg,
      {
        viditing: {
          elements: { terminalType },
          currentFile,
          tabs,
          initialDirectory: { webAutoReload }
        }
      }
    ) {
      let targetFile = JSON.parse(JSON.stringify(currentFile));
      if (
        currentFile &&
        currentFile.relative_path === msg.path &&
        (!currentFile.mtime || currentFile.mtime < msg.mtime)
      ) {
        await this.getFile(targetFile);
        // console.log(currentFile);
        this.setCurrentFile(targetFile);
      } else {
        targetFile = {
          name: msg.path.substring(msg.path.lastIndexOf("/") + 1),
          relative_path: msg.path
        };
        await this.getFile(targetFile);
      }

      const walk = arr => {
        arr.forEach(node => {
          if (node.file_type === "directory") {
            walk(node.children);
          } else if (msg.path === node.relative_path) {
            node.buffer = targetFile.buffer;
            node.encoding = targetFile.encoding;

            if (targetFile.encoding !== "binary") {
              node.lastValue = node.value = targetFile.value;
            }
          }
        });
      };
      const newTree = JSON.parse(
        JSON.stringify(store.getState().viditing.filetree)
      );

      walk(newTree);
      this.setFiletree(newTree);
      this.setTabs(
        tabs.map(t => {
          if (t.relative_path === msg.path) {
            t.lastValue = t.value = targetFile.value;
          }
          return t;
        })
      );

      console.log("webAutoReload:", webAutoReload, terminalType);

      if (webAutoReload) {
        switch (terminalType) {
          case "web":
            dispatch.ide.setWebOutputModal({ reload: true });
            break;
          case "django":
            dispatch.ide.setWebOutputModal({ reload: true });
            break;
          case "ror":
            dispatch.ide.setWebOutputModal({ reload: true });
            break;
          default:
          //Nothing
        }
      }
    },
    // addTab(
    //   targetFile,
    //   {
    //     recording: { tabs }
    //   }
    // ) {
    //   this.setTabs([...tabs, targetFile]);
    //   this.setCurrentFile(targetFile);
    //   this.refreshActivityOnFiletree(targetFile);
    // },
    async refreshActivityOnFiletreeSeek(
      targetFile,
      { viditing: { seekFiletree } }
    ) {
      let stack = [];
      const walkActive = (arr, parent) => {
        arr.forEach(node => {
          if (node.file_type === "directory") {
            node.dirStack = node.depth === 0 ? [] : [...parent.dirStack];
            node.dirStack.push(node.relative_path);
            walkActive(node.children, node);
          } else if (targetFile.relative_path === node.relative_path) {
            node.isActive = true;

            if (node.depth > 0) {
              stack = parent.dirStack;
            }
          } else {
            delete node.isActive;
          }
        });
      };

      const newTree = JSON.parse(
        JSON.stringify(store.getState().viditing.seekFiletree)
      );
      walkActive(newTree);

      const walkDirOpen = arr => {
        arr.forEach(node => {
          if (stack.length > node.depth) {
            if (node.file_type === "directory") {
              if (stack[node.depth] === node.relative_path) {
                node.toggled = true;
              }
              walkDirOpen(node.children);
            }
          }
        });
      };
      walkDirOpen(newTree);

      this.setSeekFiletree(newTree);
    },
    refreshActivityOnFiletree(targetFile, { viditing: { filetree } }) {
      let stack = [];
      const walkActive = (arr, parent) => {
        arr.forEach(node => {
          if (node.file_type === "directory") {
            node.dirStack = node.depth === 0 ? [] : [...parent.dirStack];
            node.dirStack.push(node.relative_path);
            walkActive(node.children, node);
          } else if (targetFile.relative_path === node.relative_path) {
            node.isActive = true;

            if (node.depth > 0) {
              stack = parent.dirStack;
            }
          } else {
            delete node.isActive;
          }
        });
      };

      const newTree = JSON.parse(JSON.stringify(filetree));
      walkActive(newTree);

      const walkDirOpen = arr => {
        arr.forEach(node => {
          if (stack.length > node.depth) {
            if (node.file_type === "directory") {
              if (stack[node.depth] === node.relative_path) {
                node.toggled = true;
              }
              walkDirOpen(node.children);
            }
          }
        });
      };
      walkDirOpen(newTree);

      this.setFiletree(newTree);
    },
    async closeTabSeek(
      targetFile,
      {
        viditing: {
          seekFiletree,
          seekTabs,
          editor: { ref: editor }
        }
      }
    ) {
      const idx = store
        .getState()
        .viditing.seekTabs.findIndex(
          t => t.relative_path === targetFile.relative_path
        );
      const activeIdx = store
        .getState()
        .viditing.seekTabs.findIndex(t => !!t.isActive);

      if (idx === activeIdx) {
        await this.saveFileSeek();
      }

      const newActiveIdx =
        idx > activeIdx ? activeIdx : activeIdx > 0 ? activeIdx - 1 : 0;
      let newActiveFile = {};

      //await new Promise(async resolve => {
      const newTabs = [
        ...store.getState().viditing.seekTabs.slice(0, idx),
        ...store.getState().viditing.seekTabs.slice(idx + 1)
      ].map((t, i) => {
        if (i === newActiveIdx) {
          t.isActive = true;
          newActiveFile = t;

          if (t.selection) {
            //setTimeout(() => {
            const {
              positionLineNumber: lineNumber,
              positionColumn: column
            } = t.selection;
            editor.setSelection(t.selection);
            editor.revealPosition({ lineNumber, column });
            //editor.focus();
            //}, 100);
          }

          this.setCurrentFile(t);
        } else {
          delete t.isActive;
        }
        return t;
      });

      this.setSeekTabs(newTabs);
      await this.refreshActivityOnFiletreeSeek(newActiveFile);

      if (!newTabs.length) {
        this.setCurrentFile(null);
      }

      //resolve();
      //});
    },

    async closeTab(
      targetFile,
      {
        viditing: {
          filetree,
          tabs,
          editor: { ref: editor }
        }
      }
    ) {
      const idx = tabs.findIndex(
        t => t.relative_path === targetFile.relative_path
      );
      const activeIdx = tabs.findIndex(t => !!t.isActive);

      if (idx === activeIdx) {
        await this.saveFile();
      }

      const newActiveIdx =
        idx > activeIdx ? activeIdx : activeIdx > 0 ? activeIdx - 1 : 0;
      let newActiveFile = {};
      const newTabs = [...tabs.slice(0, idx), ...tabs.slice(idx + 1)].map(
        (t, i) => {
          if (i === newActiveIdx) {
            t.isActive = true;
            newActiveFile = t;

            if (t.selection) {
              setTimeout(() => {
                const {
                  positionLineNumber: lineNumber,
                  positionColumn: column
                } = t.selection;
                editor.setSelection(t.selection);
                editor.revealPosition({ lineNumber, column });
                editor.focus();
              }, 100);
            }

            this.setCurrentFile(t);
          } else {
            delete t.isActive;
          }
          return t;
        }
      );

      this.setTabs(newTabs);
      this.refreshActivityOnFiletree(newActiveFile);

      if (!newTabs.length) {
        this.setCurrentFile(null);
      }
    },

    async addToFiletreeSeek(
      { file_type, path },
      { viditing: { seekFiletree } }
    ) {
      const arr = path.split("/");
      const stack = arr
        .reduce((a, c, i) => {
          a.push(arr.slice(0, a.length + 1).join("/"));
          return a;
        }, [])
        .slice(1);

      const walk = arr => {
        let isNotFound = true;

        arr.forEach(node => {
          if (
            stack[node.depth] === node.relative_path &&
            (lastDepth < stack.length - 1
              ? node.file_type === "directory"
              : file_type === "directory"
              ? node.file_type === "directory"
              : node.file_type !== "directory")
          ) {
            isNotFound = false;

            if (node.file_type === "directory") {
              lastDepth++;
              walk(node.children);
            }
          }
        });

        if (isNotFound && lastDepth < stack.length) {
          const relative_path = stack[lastDepth];
          const node = {
            relative_path,
            name: relative_path.substring(relative_path.lastIndexOf("/") + 1),
            file_type: lastDepth < stack.length - 1 ? "directory" : file_type,
            depth: lastDepth
          };

          arr.push(node);
          arr.sort(window.filetreeComparer);

          if (node.file_type === "directory") {
            lastDepth++;
            node.children = [];
            walk(node.children);
          }
        }
      };

      const newTree = JSON.parse(
        JSON.stringify(store.getState().viditing.seekFiletree)
      );
      let lastDepth = 0;
      walk(newTree);

      this.setSeekFiletree(newTree);
    },

    addToFiletree({ file_type, path }, { viditing: { filetree } }) {
      const arr = path.split("/");
      const stack = arr
        .reduce((a, c, i) => {
          a.push(arr.slice(0, a.length + 1).join("/"));
          return a;
        }, [])
        .slice(1);

      const walk = arr => {
        let isNotFound = true;

        arr.forEach(node => {
          if (
            stack[node.depth] === node.relative_path &&
            (lastDepth < stack.length - 1
              ? node.file_type === "directory"
              : file_type === "directory"
              ? node.file_type === "directory"
              : node.file_type !== -"directory")
          ) {
            isNotFound = false;

            if (node.file_type === "directory") {
              lastDepth++;
              walk(node.children);
            }
          }
        });

        if (isNotFound && lastDepth < stack.length) {
          const relative_path = stack[lastDepth];
          const node = {
            relative_path,
            name: relative_path.substring(relative_path.lastIndexOf("/") + 1),
            file_type: lastDepth < stack.length - 1 ? "directory" : file_type,
            depth: lastDepth
          };

          arr.push(node);
          arr.sort(window.filetreeComparer);

          if (node.file_type === "directory") {
            lastDepth++;
            node.children = [];
            walk(node.children);
          }
        }
      };

      const newTree = JSON.parse(JSON.stringify(filetree));
      let lastDepth = 0;
      walk(newTree);

      this.setFiletree(newTree);
    },

    async removeFromFiletreeSeek(
      { file_type, path },
      { viditing: { seekFiletree, seekTabs } }
    ) {
      const arr = path.split("/");
      const stack = arr
        .reduce(a => {
          a.push(arr.slice(0, a.length + 1).join("/"));
          return a;
        }, [])
        .slice(1);

      const walk = arr => {
        arr.forEach((node, idx) => {
          if (
            stack[node.depth] === node.relative_path &&
            (lastDepth < stack.length - 1
              ? node.file_type === "directory"
              : file_type === "directory"
              ? node.file_type === "directory"
              : node.file_type !== "directory")
          ) {
            if (lastDepth === stack.length - 1) {
              arr.splice(idx, 1);
            } else if (node.file_type === "directory") {
              lastDepth++;
              walk(node.children);
            }
          }
        });
      };

      const newTree = JSON.parse(
        JSON.stringify(store.getState().viditing.seekFiletree)
      );
      let lastDepth = 0;
      walk(newTree);

      this.setSeekFiletree(newTree);

      const idxActiveTab = store
        .getState()
        .viditing.seekTabs.findIndex(t => t.relative_path === path);
      if (idxActiveTab > -1) {
        await this.closeTabSeek(
          store.getState().viditing.seekTabs[idxActiveTab]
        );
      }
    },

    removeFromFiletree({ file_type, path }, { viditing: { filetree, tabs } }) {
      const arr = path.split("/");
      const stack = arr
        .reduce(a => {
          a.push(arr.slice(0, a.length + 1).join("/"));
          return a;
        }, [])
        .slice(1);

      const walk = arr => {
        arr.forEach((node, idx) => {
          if (
            stack[node.depth] === node.relative_path &&
            (lastDepth < stack.length - 1
              ? node.file_type === "directory"
              : file_type === "directory"
              ? node.file_type === "directory"
              : node.file_type !== "directory")
          ) {
            if (lastDepth === stack.length - 1) {
              arr.splice(idx, 1);
            } else if (node.file_type === "directory") {
              lastDepth++;
              walk(node.children);
            }
          }
        });
      };

      const newTree = JSON.parse(JSON.stringify(filetree));
      let lastDepth = 0;
      walk(newTree);

      this.setFiletree(newTree);

      const idxActiveTab = tabs.findIndex(t => t.relative_path === path);
      if (idxActiveTab > -1) {
        this.closeTab(tabs[idxActiveTab]);
      }
    },

    renameSeek(
      { file_type, src_path, dest_path },
      { viditing: { seekFiletree, seekTabs, currentFile } }
    ) {
      const idxLastSlash = dest_path.lastIndexOf("/") + 1;
      const name = dest_path.substring(idxLastSlash);

      if (
        dest_path.substring(0, idxLastSlash) ===
        src_path.substring(0, src_path.lastIndexOf("/") + 1)
      ) {
        const walk = arr => {
          arr.forEach(node => {
            if (!isChanged) {
              if (node.relative_path === src_path) {
                node.relative_path = dest_path;
                node.name = name;
                isChanged = true;
              } else if (node.file_type === "directory") {
                walk(node.children);
              }
            }
          });
        };

        let isChanged = false;

        const newTree = JSON.parse(
          JSON.stringify(store.getState().viditing.seekFiletree)
        );
        walk(newTree);
        this.setSeekFiletree(newTree);
      } else {
        this.removeFromFiletreeSeek({ file_type, path: src_path });
        this.addToFiletreeSeek({ file_type, path: dest_path });
      }

      this.setSeekTabs(
        store.getState().viditing.seekTabs.map(t => {
          if (t.relative_path === src_path) {
            t.relative_path = dest_path;
            t.name = name;
          }
          return t;
        })
      );

      if (currentFile && currentFile.relative_path === src_path) {
        // console.log("rename", currentFile);
        this.setCurrentFile({ relative_path: dest_path, name });
      }
    },

    rename(
      { file_type, src_path, dest_path },
      { viditing: { filetree, tabs, currentFile } }
    ) {
      const idxLastSlash = dest_path.lastIndexOf("/") + 1;
      const name = dest_path.substring(idxLastSlash);

      if (
        dest_path.substring(0, idxLastSlash) ===
        src_path.substring(0, src_path.lastIndexOf("/") + 1)
      ) {
        const walk = arr => {
          arr.forEach(node => {
            if (!isChanged) {
              if (node.relative_path === src_path) {
                node.relative_path = dest_path;
                node.name = name;
                isChanged = true;
              } else if (node.file_type === "directory") {
                walk(node.children);
              }
            }
          });
        };

        let isChanged = false;

        const newTree = JSON.parse(JSON.stringify(filetree));
        walk(newTree);
        this.setFiletree(newTree);
      } else {
        this.removeFromFiletree({ file_type, path: src_path });
        this.addToFiletree({ file_type, path: dest_path });
      }

      this.setTabs(
        tabs.map(t => {
          if (t.relative_path === src_path) {
            t.relative_path = dest_path;
            t.name = name;
          }
          return t;
        })
      );

      if (currentFile && currentFile.relative_path === src_path) {
        // console.log("rename", currentFile);
        this.setCurrentFile({ relative_path: dest_path, name });
      }
    },
    async updateViditingStepComplete(
      { step, time, finish = false },
      rootState
    ) {
      const {
        common: {
          apollo: {
            client: { general: client }
          }
        }
      } = rootState;

      try {
        const {
          data: {
            updateLiveCodingStepProgress: {
              ok,
              isCompletedStep,
              isCompletedCourse
            }
          }
        } = await client.mutate({
          mutation: UPDATE_VIDITING_STEP_COMPLETE,
          context: await getApolloContext(),
          variables: { step, timestamp: time }
        });

        if (finish) {
          if (ok) {
            const { typename, id, completed } = await dispatch.common.nextStep({
              step
            });

            return {
              isCompletedStep,
              isCompletedCourse,
              typename,
              id,
              completed
            };

            // if (__typename) {
            //   if (!completed) {
            //     dispatch.ide.toggleModalStepIsCompletedText({
            //       textGrade: "STEP"
            //     });
            //     dispatch.ide.toggleModalStepIsCompleted(true);
            //   }
            // } else {
            //   if (isCompletedCourse) {
            //     dispatch.ide.toggleModalStepIsCompletedText({
            //       textGrade: "COURSE"
            //     });
            //     dispatch.ide.toggleModalStepIsCompleted(true);
            //   }
            // }
          } else {
            return {
              isCompletedStep: false,
              isCompletedCourse: false,
              typename: "",
              id: "",
              completed: false
            };
          }
        }
      } catch (e) {
        console.log(e);
        return {
          isCompletedStep: false,
          isCompletedCourse: false,
          typename: "",
          id: "",
          completed: false
        };
      }
    }
  })
};
