export default function Viditing(args) {
  this.addEventListener("message", ({ data: { action, time, timeline } }) => {
    const params = { action };

    switch (action) {
      case "init":
        time = 0;
        this.time = time;

        // this.timeline = timeline;

        this.maxTime = 0;

        timeline.forEach(item => {
          switch (item.type) {
            case "pointermove":
            case "pointerdown":
            case "pointerup":
              this.timeline.pointer.push(item);
              break;
            default:
              if (this.timeline[item.type]) {
                this.timeline[item.type].push(item);
              } else {
                console.debug(`예외 이벤트!`, item);
              }
          }

          if (this.maxTime < item.timestamp) {
            this.maxTime = item.timestamp;
          }
        });
        // while (cur < timeline[timeline.length - 1].timestamp) {
        //   cur += this.interval;
        // }

        // this.paused = false;
        params.initialized = action === "init";
        break;
      case "setOnlyTime":
        this.time = time;
        break;
      case "setTime":
        this.time = time;

        for (const key in this.timeline) {
          this.lastIndex[key] = -1;
        }
        for (const key in this.lastIndex) {
          if (this.timeline[key].length > 0) {
            this.timeline[key].forEach((node, index) => {
              if (node.timestamp <= time) {
                this.lastIndex[key] = index;
              }
            });
          }
        }
        //console.log("after:timeline:", this.lastIndex, this.timeline);
        break;
      case "reset":
        this.time = 0;

        for (const key in this.lastIndex) {
          this.lastIndex[key] = -1;
        }
        this.paused = true;

        break;
      case "hold":
      case "paused":
        this.paused = true;
        break;
      case "release":
        if (!this.delayed) {
          this.delayed = true;
          setTimeout(() => {
            this.paused = false;
          }, 300);
        } else {
          this.paused = false;
        }

        break;
      default:
      // Nothing
    }

    params.time = this.time;
    //console.log("workerMessage:", params, this.paused, this.lastIndex);
    postMessage(params);
  });

  this.delayed = true;
  this.interval = 200;
  this.time = 0;
  this.paused = true;

  this.timeline = {
    pointer: [],
    toggledir: [],
    output: [],
    change: [],
    value: [],
    scroll: [],
    selection: [],
    close: [],
    mode: [],
    dimension: [],
    create: [],
    remove: [],
    move: [],
    src: [],
    PAUSE: [],
    scrollTop: [],
    visibility: [],
    finish_marker: [],
    console: []
  };
  this.lastIndex = {};
  for (const key in this.timeline) {
    this.lastIndex[key] = -1;
  }

  this.timelineKeys = Object.keys(this.timeline);

  setInterval(() => {
    //function postMessgageTop() {
    if (!this.paused) {
      this.time += this.interval;

      this.timelineKeys.forEach(key => {
        let lastIdx = this.lastIndex[key];

        const arr = [];
        while (
          ++lastIdx < this.timeline[key].length &&
          this.time >= parseInt(this.timeline[key][lastIdx].timestamp)
        ) {
          arr.push(this.timeline[key][lastIdx]);
        }
        if (arr.length) {
          this.lastIndex[key] = lastIdx - 1;

          if (key === "finish_marker") {
            this.paused = true;

            postMessage({
              timeByInterval: this.time,
              action: "changeStatusToFinished"
            });
          } else {
            postMessage({ timeByInterval: this.time, key, arr });
          }
        }
      });
    }
  }, this.interval);
  setInterval(() => {
    if (!this.paused) {
      postMessage({ time: this.time });
    }
  }, 200);
}
