import React, { Component } from "react";
import { connect } from "react-redux";
import Split from "react-split-grid";
import { Motion, spring } from "react-motion";
import ReactMarkdown from "react-markdown";
import breaks from "remark-breaks";

import ScrollBar from "../common/ScrollBar";
import { atomOneDark } from "../common/codeBlocks";
import Filetree from "./Filetree";
import TabsAndEditor from "./TabsAndEditor";
// import Loading from "../common/Loading";
import Loading from "../common/LoadingQuiz";
import { ALPACO_CNAME, KBBANK_CNAME } from "../../constants";

import { ReactComponent as IconSimpleHint } from "../../images/img-ide-quiz-subhint.svg";

class GridForFiletree extends Component {
  scrollbar = null;

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      quiz: {
        simpleHint: {
          //isShown: prevQuizSimpleHintIsShown,
          content: prevQuizSimpleHintContent
        }
      }
    } = prevProps;
    const {
      quiz: {
        simpleHint: {
          isShown: quizSimpleHintIsShown,
          content: quizSimpleHintContent
        }
      },
      setQuizSimpleHint
    } = this.props;

    if (
      // prevQuizSimpleHintIsShown === quizSimpleHintIsShown &&
      quizSimpleHintIsShown &&
      prevQuizSimpleHintContent !== quizSimpleHintContent
    ) {
      setQuizSimpleHint({ height: this.scrollbar.getScrollHeight() + 18 });
    }
  }

  render() {
    const {
      gridForFiletree,
      gridForQuizSolutionFiletree,
      editorContainer,
      quiz: { simpleHint, solution },
      setGridForFiletree,
      setGridForQuizSolutionFiletree,
      sectors,
      classType,
      offSetSetting,
      leftTab,
      ws,
      authBasic
    } = this.props;

    return (
      <>
        {classType === "evaluation" && leftTab !== "quiz" && ws.isGrade && (
          <Loading />
        )}
        <div
          className="d-grid h-100"
          ref={editorContainer.ref}
          style={{
            gridTemplateColumns: `1fr ${solution.isShown ? "1fr" : 0}`
          }}
        >
          <Motion
            style={{
              x: spring(gridForFiletree.colSize, {
                stiffness: 500,
                damping: 50
              })
            }}
          >
            {({ x }) => (
              <Split
                minSize={gridForFiletree.minSize}
                gridTemplateColumns={`${x}px ${
                  gridForFiletree.colSize ? 4 : 0
                }px 1fr`}
                onDragStart={(direction, track) => {
                  //setStatus(VIDITING_STATUS.PAUSED);
                }}
                onDragEnd={(direction, track) => {
                  //setStatus(VIDITING_STATUS.PLAYING);
                }}
                onDrag={(direction, track, style) => {
                  const size = Number.parseInt(style.split(" ")[0], 10);
                  setGridForFiletree({
                    colSize: size,
                    memorizedColSize: size
                  });
                  offSetSetting(false);
                }}
                render={({ getGridProps, getGutterProps }) => {
                  return (
                    <div
                      {...getGridProps()}
                      className="d-grid h-100 flex-grow-1"
                    >
                      <Filetree sectors={sectors} />

                      <div
                        {...getGutterProps("column", 1)}
                        className={`gutter gutter-column`}
                      />
                      <TabsAndEditor sectors={sectors} />
                    </div>
                  );
                }}
              />
            )}
          </Motion>

          {solution.isShown && (
            <>
              <Motion
                style={{
                  x: spring(gridForQuizSolutionFiletree.colSize, {
                    stiffness: 500,
                    damping: 50
                  })
                }}
              >
                {({ x }) => (
                  <Split
                    minSize={gridForQuizSolutionFiletree.minSize}
                    gridTemplateColumns={`${x}px ${
                      gridForQuizSolutionFiletree.colSize ? 8 : 0
                    }px 1fr`}
                    onDrag={(direction, track, style) => {
                      const size = Number.parseInt(style.split(" ")[0], 10);
                      setGridForQuizSolutionFiletree({
                        colSize: size,
                        memorizedColSize: size
                      });
                    }}
                    render={({ getGridProps, getGutterProps }) => {
                      return (
                        <div
                          {...(classType === "learning" && getGridProps())}
                          className="quiz-solution-container d-grid h-100 flex-grow-1"
                        >
                          {classType === "learning" && <Filetree forSolution />}
                          {/* <div
                            {...getGutterProps("column", 1)}
                            className={`gutter gutter-column`}
                          /> */}
                          <TabsAndEditor forSolution />
                        </div>
                      );
                    }}
                  />
                )}
              </Motion>

              {solution.isShown && (
                <div className="quiz-solution-label position-absolute">
                  퀴즈 솔루션
                </div>
              )}
            </>
          )}
        </div>

        {simpleHint.isShown &&
          classType === "evaluation" &&
          (!authBasic.organization ||
            authBasic.organization?.cname === KBBANK_CNAME) && (
            <div
              className="simple-hint-container w-100 position-absolute"
              ref={simpleHint.containerRef}
              style={{ height: simpleHint.height }}
            >
              <ScrollBar
                getRef={scrollbar => {
                  this.scrollbar = scrollbar;
                }}
              >
                <div>
                  <ReactMarkdown
                    source={simpleHint.content}
                    renderers={{ code: atomOneDark }}
                    plugins={[breaks]}
                    escapeHtml={false}
                    className="markdown-body"
                    linkTarget={"_blank"}
                  />
                </div>
              </ScrollBar>
              <IconSimpleHint className="position-absolute border-0" />
            </div>
          )}

        {classType === "evaluation" &&
          authBasic.organization &&
          authBasic.organization?.cname !== KBBANK_CNAME && (
            <div
              className="simple-hint-container w-100 position-absolute notice"
              style={{ height: "40px" }}
            >
              <ScrollBar
                getRef={scrollbar => {
                  this.scrollbar = scrollbar;
                }}
              >
                <div>
                  <ReactMarkdown
                    source={
                      "문제를 다 풀고 <span><i></i>저장<i></i></span> 버튼을 눌러 답안을 저장하세요."
                    }
                    renderers={{ code: atomOneDark }}
                    plugins={[breaks]}
                    escapeHtml={false}
                    className="markdown-body"
                    linkTarget={"_blank"}
                  />
                </div>
              </ScrollBar>
              <IconSimpleHint className="position-absolute border-0" />
            </div>
          )}
      </>
    );
  }
}

const mapState = ({
  common: { ws },
  ide: {
    leftTab,
    classType,
    gridForFiletree,
    gridForQuizSolutionFiletree,
    editorContainer,
    quiz
  },
  auth: { authBasic }
}) => ({
  ws,
  leftTab,
  classType,
  gridForFiletree,
  gridForQuizSolutionFiletree,
  editorContainer,
  quiz,
  authBasic
});

const mapDispatch = ({
  ide: {
    setGridForFiletree,
    setGridForQuizSolutionFiletree,
    setQuizSimpleHint
  },
  viditing: { setStatus }
}) => ({
  setGridForFiletree,
  setGridForQuizSolutionFiletree,
  setQuizSimpleHint,

  setStatus
});

export default connect(mapState, mapDispatch)(GridForFiletree);
