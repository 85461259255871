import React, { Component, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import ScrollMemory from "react-router-scroll-memory";
import {
  isMobile,
  isMobileOnly,
  isTablet,
  isChrome,
  isSafari
} from "react-device-detect";

import { Slide, ToastContainer } from "react-toastify";
// import debounce from "lodash/debounce";
// import root from "window-or-global";

import { REACT_APP_AUTH_EXPIRY_INTERVAL } from "../config"

import NoMatch from "./common/NoMatch";
import Loading from "./common/Loading";
import Maintenance from "./common/Maintenance";
import BroswerGuide from "./common/BroswerGuide";

import * as QueryString from "query-string";

import GNB from "./common/GNB";
import Footer from "./common/Footer";

import "react-toastify/dist/ReactToastify.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";

import "../styles/index.scss";

import WebWorker from "../workers/WebWorker";
import CheckingAuth from "../workers/CheckingAuth";

import Auth from "./auth/Container";
import PrivateRoute from "./auth/PrivateRoute";
import SignupSuccess from "./auth/postback/SignupSuccess";
import EmailInfomation from "./auth/postback/EmailInfomation";
import SignupFailure from "./auth/postback/SignupFailure";

const Landing = lazy(() => import("./Landing"));
const Class = lazy(() => import("./class/Container"));
const Course = lazy(() => import("./class/ContainerCourse"));
const Personal = lazy(() => import("./personal/Container"));
const Orders = lazy(() => import("./orders/Container"));
const OrdersComplete = lazy(() => import("./orders/Complete"));

const SignUp = lazy(() => import("./auth/SignUp"));
const Catalog = lazy(() => import("./catalog/Container"));

//const Admin = lazy(() => import("./admin/Container"));

// const EVENT_RESIZE = `resize`;
const EVENT_MESSAGE = `message`;

class MainContainer extends Component {
  // constructor(props) {
  //   super(props);
  //
  //   this.onResizeWindow = debounce(() => {
  //     const { clientHeight: height = 0, clientWidth: width = 0 } =
  //       root.document.body || {};
  //     props.setWindowDimension({ width, height });
  //   }, 100);
  // }

  state = {
    systemMaintenance: {}
  };

  onMessageTimer = async ({ data }) => {
    const { isAuthenticated, signout, refreshAccessToken } = this.props;

    if (isAuthenticated) {
      try {
        await refreshAccessToken();
      } catch (err) {
        signout();
      }
    }
  };

  onSSODataParse = props => {
    //const thisTime = moment().format("HH");
    //const { userkey, company } = props.match.params;
    const history = this.props.history;
    //const weekDayName = moment().format("dddd");

    let referrer = window.location.pathname;

    let userKeyTmp = referrer.substring(
      referrer.indexOf("/b2b/sso/") + 9,
      referrer.indexOf("/kbbank")
    );
    let companyCode = referrer.substring(referrer.lastIndexOf("/") + 1);

    if (!isChrome && !isSafari) {
      history.push({
        pathname: "/browserInfo",
        userkey: userKeyTmp,
        company: companyCode
      });
    } else {
      this.props.b2bSignIn({
        userkey: userKeyTmp,
        company: companyCode,
        history
      });
    }
  };

  onSSOCourseDataParse = props => {
    const history = this.props.history;
    const company = "alpaco";
    const { p_num, id, global_id } = QueryString.parse(props.location.search);

    if (!isChrome && !isSafari) {
      history.push({
        pathname: "/browserInfo",
        userkey: id,
        company
      });
    } else {
      this.props.b2bSignInCourse({
        p_num,
        id,
        global_id,
        company,
        history
      });
    }
  };

  onSSOAuthParse = props => {
    const history = this.props.history;
    const { user, company } = QueryString.parse(props.location.search);

    console.log("this.props;::", props, ":user,company:", user, company);

    if (!isChrome && !isSafari) {
      history.push({
        pathname: "/browserInfo",
        userkey: user,
        company
      });
    } else {
      this.props.b2bSignInAuth({
        userkey: user,
        company,
        history
      });
    }
  };

  async componentDidMount() {
    await this.props.checkAuthentication({ history: this.props.history });
    // window.addEventListener(EVENT_RESIZE, this.onResizeWindow);
    // this.onResizeWindow();

    this.worker = new WebWorker(CheckingAuth);
    this.worker.addEventListener(EVENT_MESSAGE, this.onMessageTimer, false);
    this.worker.postMessage({
      action: "start",
      time:
        Number.parseInt(REACT_APP_AUTH_EXPIRY_INTERVAL, 10) - 30000
    });
    this.props.setMobileChk({
      isMobile,
      isMobileOnly,
      isTablet,
      isChrome,
      isSafari
    });
    try {
      const systemMaintenance = await this.props.getSystemMaintenance();
      this.setState({
        ...this.state,
        systemMaintenance: systemMaintenance.data
      });
    } catch (e) {}
  }

  componentWillUnmount() {
    // window.removeEventListener(EVENT_RESIZE, this.onResizeWindow);

    this.worker.removeEventListener(EVENT_MESSAGE, this.onMessageTimer);
    this.worker.terminate();
  }

  render() {
    const systemMaintenance = this.state.systemMaintenance;

    return (
      <>
        <ScrollMemory />

        {/*<div id="outdated"></div>*/}
        {/*<script src="outdated-browser-rework.js"></script>*/}
        {/*<script src="main.js"></script>*/}

        <Suspense fallback={<Loading />}>
          {systemMaintenance && systemMaintenance.now_maintenance ? (
            <Maintenance systemMaintenance={systemMaintenance} />
          ) : (
            <Switch>
              <Route
                exact
                path="/"
                render={props => (
                  <GeneralWrapper excludeFooter isMobile={isMobileOnly}>
                    <Landing {...props} isMobile={isMobileOnly} />
                  </GeneralWrapper>
                )}
              />

              <Route path="/browserInfo" render={props => <BroswerGuide />} />

              <PrivateRoute
                path="/personal"
                render={props => (
                  <GeneralWrapper>
                    <Personal {...props} />
                  </GeneralWrapper>
                )}
              />
              <PrivateRoute
                path="/orders/payments"
                render={props => (
                  <GeneralWrapper>
                    <Orders {...props} />
                  </GeneralWrapper>
                )}
              />
              <PrivateRoute
                path="/orders/complete"
                render={props => (
                  <GeneralWrapper>
                    <OrdersComplete {...props} />
                  </GeneralWrapper>
                )}
              />
              <Route
                path="/signup"
                render={props => (
                  <GeneralWrapper>
                    <SignUp {...props} />
                  </GeneralWrapper>
                )}
              />
              <Route
                path="/auth"
                render={props => (
                  <GeneralWrapper>
                    <Auth {...props} />
                  </GeneralWrapper>
                )}
              />
              <Route
                path="/catalog"
                render={props => (
                  <GeneralWrapper>
                    <Catalog {...props} />
                  </GeneralWrapper>
                )}
              />
              {/*<Route*/}
              {/*  path="/admin"*/}
              {/*  render={props => (*/}
              {/*    <GeneralWrapper>*/}
              {/*      <Admin {...props} />*/}
              {/*    </GeneralWrapper>*/}
              {/*  )}*/}
              {/*/>*/}

              <Route path="/class/:step" component={Class} />
              <Route path="/course/:step" component={Course} />

              <Route
                path="/postback/signup-success"
                render={props => (
                  <GeneralWrapper>
                    <SignupSuccess {...props} />
                  </GeneralWrapper>
                )}
              />
              <Route
                path="/postback/email-information"
                render={props => (
                  <GeneralWrapper>
                    <EmailInfomation {...props} />
                  </GeneralWrapper>
                )}
              />
              <Route
                path="/postback/signup-failure"
                render={props => (
                  <GeneralWrapper>
                    <SignupFailure {...props} />
                  </GeneralWrapper>
                )}
              />

              <Route
                path="/b2b/sso"
                render={props => this.onSSODataParse(props)}
              />

              <Route
                path="/b2b/course"
                render={props => this.onSSOCourseDataParse(props)}
              />

              <Route
                path="/b2b/auth"
                render={props => this.onSSOAuthParse(props)}
              />

              <Route component={NoMatch} />
            </Switch>
          )}
        </Suspense>

        <ToastContainer
          position="top-center"
          autoClose={2000}
          bodyClassName="text-center"
          transition={Slide}
          closeButton={false}
        />
      </>
    );
  }
}

export const GeneralWrapper = ({
  children,
  excludeFooter,
  isMobile,
  systemMaintenance
}) => (
  <>
    {isMobile ? (
      <section className="d-flex w-100 flex-grow-1">{children}</section>
    ) : (
      <>
        <div>
          <GNB />
        </div>
        {!excludeFooter ? (
          <div>
            <section id="wrapper" className="flex-grow-1 d-flex">
              {children}
            </section>
          </div>
        ) : (
          <section id="wrapper" className="flex-grow-1 d-flex">
            {children}
          </section>
        )}

        {!excludeFooter && (
          <div className="mt-auto">
            <Footer />
          </div>
        )}
      </>
    )}
  </>
);

const mapState = ({ common: { ws }, auth: { isAuthenticated } }) => ({
  ws,
  isAuthenticated
});

const mapDispatch = ({
  auth: {
    signout,
    setAuthentication,
    checkAuthentication,
    refreshAccessToken,
    b2bSignIn,
    b2bSignInAuth,
    b2bSignInCourse
  },
  common: { setWindowDimension, getSystemMaintenance },
  ide: { setMobileChk }
}) => ({
  signout,
  setAuthentication,
  checkAuthentication,
  refreshAccessToken,
  b2bSignIn,
  b2bSignInAuth,
  b2bSignInCourse,
  setWindowDimension,
  getSystemMaintenance,
  setMobileChk
});

export default connect(mapState, mapDispatch)(MainContainer);
