import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TagManager from "react-gtm-module";
import moment from "moment/min/moment-with-locales";
import Moment from "react-moment";

import i18n from "../i18n"; // initialized i18next instance

import ErrorBoundary from "./common/ErrorBoundary";
import Container from "./Container";
import { connect } from "react-redux";
import momentDurationFormatSetup from "moment-duration-format";

TagManager.initialize({
  gtmId: "GTM-K2SMZTD"
});

Moment.globalMoment = moment;

momentDurationFormatSetup(moment);

class _ extends Component {
  constructor(props) {
    super(props);

    i18n.on("languageChanged", lng => {
      // axios.defaults.headers.common['Accept-Language'] = lng;
      props.setCurrentLocale(lng);
    });
  }

  render() {
    return (
      <ErrorBoundary>
        <Router>
          <Switch>
            <Route exact path="/oauth2/authorize/:provider" component={Idp} />
            <Route exact path="/idpcallback" component={Signing} />
            {/* <Route
              path="/info/sitemap"
              component={() => {
                window.location.assign(
                  "https://backend.codelion.io/info/sitemap/"
                );
                return null;
              }}
            /> */}
            <Route component={Container} />
          </Switch>
        </Router>
      </ErrorBoundary>
    );
  }
}

const mapDispatch = ({ common: { setCurrentLocale } }) => ({
  setCurrentLocale
});
export default connect(null, mapDispatch)(_);

class Idp extends Component {
  componentDidMount() {
    // const auth = GetCognitoAuth(this.props.match.params.provider);
    // auth.getSession();
  }

  render() {
    return <Signing provider={this.props.match.params.provider} />;
  }
}

const Signing = ({ provider = "Google" }) => (
  <div style={{ display: "flex", flex: 1, margin: 0, color: "white" }}>
    <div>Signing in with {provider}...</div>
  </div>
);
