import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { Button, Dropdown } from "react-bootstrap";
import { Motion, spring } from "react-motion";

import iconClose from "../../images/btn-windowtapcancle-t-01.svg";
import iconMoreOn from "../../images/btn-idewindowtap-moresee-on.svg";
import iconMoreOff from "../../images/btn-idewindowtap-moresee-off.svg";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button variant="link" onClick={onClick} className="border-0 more">
    {children}
  </Button>
));

class Tabs extends Component {
  state = {
    needScroll: false,
    isOpenMore: false,
    canScrollLeft: false,
    canScrollRight: false,
    scrollLeft: 0
  };

  containerWidth = 0;
  container = React.createRef();
  list = React.createRef();
  scrollbar;
  refs = [];

  processScroll = ev => {
    const scrollLeft = this.scrollbar.getScrollLeft();
    const len = this.list.current.clientWidth - scrollLeft + 40 - 1;
    const { clientWidth: containerWidth } = this.container.current;

    this.setState({
      ...this.state,
      canScrollLeft: scrollLeft > 1,
      canScrollRight: this.state.needScroll && len > containerWidth
    });

    this.onScrollByThrottling(ev);
  };

  onScrollByThrottling = ev => {
    if (ev) {
      //const { scrollLeft } = ev.target;
      // this.props.pushToCargo({
      //   target: SECTORS.TABS.name,
      //   type: "scroll",
      //   scrollLeft
      // });
    }
  };

  onSelectItem = (eventKey, withoutRecords = false) => {
    const { tabs, selectFile } = this.props;
    selectFile({ targetFile: tabs[eventKey], withoutRecords });

    setTimeout(() => {
      const clientWidth = this.scrollbar.getClientWidth();
      const scrollLeft = this.scrollbar.getScrollLeft();
      const diff = this.scrollbar.getScrollWidth() - clientWidth;

      const { clientWidth: width, offsetLeft } = this.refs[eventKey].current;
      let left = scrollLeft + 68;
      // console.log(diff, offsetLeft);

      if (left > offsetLeft) {
        left = offsetLeft - 68;
      } else if (scrollLeft + clientWidth < offsetLeft + width) {
        left = offsetLeft + width - clientWidth + 68;
      }

      if (left !== scrollLeft + 68) {
        this.setState({
          ...this.state,
          scrollLeft: diff < left ? diff : left < 0 ? 0 : left
        });
      }
    }, 100);
  };

  componentDidMount() {
    const { classType } = this.props;

    if (classType === "learning") {
      this.onUpdate = setInterval(() => {
        const { clientWidth: containerWidth } = this.container.current;

        if (this.containerWidth !== containerWidth) {
          const { clientWidth: listWidth } = this.list.current;
          const needScroll = containerWidth - 40 < listWidth;

          if (this.state.needScroll !== needScroll) {
            this.setState({ ...this.state, needScroll });
          }

          this.processScroll();

          this.containerWidth = containerWidth;
        }
      }, 200);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { tabs: prevTabs, currentFile: prevFile } = prevProps;
    const { tabs, currentFile, by, setBy, classType } = this.props;

    if (classType === "learning") {
      if (prevTabs.length !== tabs.length) {
        this.containerWidth = 0;
        this.refs = tabs.map(t => createRef());
      }
      if (
        prevFile &&
        currentFile &&
        prevFile.relative_path !== currentFile.relative_path &&
        by.filetree
      ) {
        this.onSelectItem(
          tabs.findIndex(t => currentFile.relative_path === t.relative_path),
          true
        );
        setBy({ filetree: false });
      }
    }
  }

  componentWillUnmount() {
    const { classType } = this.props;

    if (classType === "learning") {
      clearTimeout(this.onUpdate);
    }
  }

  render() {
    const {
      tabs,
      setBy,
      setScrollElements,
      selectFile,
      closeTab,
      gtmPushDataLayer
    } = this.props;
    const {
      needScroll,
      isOpenMore,
      canScrollLeft,
      canScrollRight
    } = this.state;

    return (
      <div className="d-flex" ref={this.container}>
        <div className="position-relative flex-grow-1" style={{ height: 40 }}>
          <Scrollbars
            ref={scrollbar => {
              this.scrollbar = scrollbar;
              setScrollElements({ tabs: scrollbar });
            }}
            className={"scrollbar-container"}
            renderThumbHorizontal={({ style, ...props }) => (
              <div style={{ ...style, display: "none" }} {...props} />
            )}
            onScroll={this.processScroll}
          >
            <ul
              className="list-group list-group-horizontal list-unstyled d-inline-flex"
              ref={this.list}
            >
              {tabs.map((t, t_i) => {
                return (
                  <li
                    key={t_i}
                    className={`d-flex align-items-center${
                      t.isActive ? " active" : ""
                    }`}
                    ref={this.refs[t_i]}
                    onClick={() => {
                      if (this.props.serviceMode !== "TEACHER") {
                        setBy({ tabs: true });
                        selectFile({ targetFile: t });
                      }
                    }}
                  >
                    <label className="my-2">{t.name}</label>
                    <Button
                      variant="link"
                      className="border-0"
                      onClick={e => {
                        if (this.props.serviceMode !== "TEACHER") {
                          //e.stopPropagation();
                          closeTab(t);
                        }

                        gtmPushDataLayer({
                          event: "ideCodeWindowtapCancelClick"
                        });
                      }}
                    >
                      <img src={iconClose} alt="" className="d-block" />
                    </Button>
                  </li>
                );
              })}
            </ul>
          </Scrollbars>
          <div
            className={`position-absolute shadow left${
              canScrollLeft ? " show" : ""
            }`}
          ></div>
          <div
            className={`position-absolute shadow right${
              canScrollRight ? " show" : ""
            }`}
          ></div>
        </div>
        {needScroll && (
          <Dropdown
            alignRight
            onSelect={eventKey => {
              if (this.props.serviceMode !== "TEACHER") {
                setBy({ tabs: true });
                this.onSelectItem(eventKey);
              }

              gtmPushDataLayer({
                event: "ideCodeWindowtapMoreseeClick"
              });
            }}
            onToggle={open => {
              if (this.props.serviceMode !== "TEACHER") {
                this.setState({ ...this.state, isOpenMore: open });
              }
            }}
          >
            <Dropdown.Toggle as={CustomToggle}>
              <img
                src={isOpenMore ? iconMoreOn : iconMoreOff}
                alt=""
                className="d-block"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {tabs.map((t, t_i) => {
                return (
                  <Dropdown.Item
                    eventKey={t_i}
                    key={t_i}
                    bsPrefix={`dropdown-item${t.isActive ? " active" : ""}`}
                    onClick={e => {
                      e.preventDefault();
                      setBy({ tabs: true });
                      selectFile({ targetFile: t });
                    }}
                  >
                    {t.name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        )}

        <Motion
          style={{
            scrollLeft: spring(this.state.scrollLeft, {
              stiffness: 500,
              damping: 50
            })
          }}
        >
          {currentStyles => {
            return (
              <Scroller
                scrollingElement={this.scrollbar}
                scrollLeft={currentStyles.scrollLeft}
              />
            );
          }}
        </Motion>
      </div>
    );
  }
}

class Scroller extends Component {
  componentDidUpdate(prevProps) {
    const { scrollingElement, scrollLeft } = this.props;
    // console.log(scrollingElement, prevProps.scrollLeft, scrollLeft);
    if (scrollingElement && prevProps.scrollLeft !== scrollLeft) {
      scrollingElement.scrollLeft(scrollLeft);
    }
  }

  render() {
    return null;
  }
}

const mapState = ({
  ide: { classType },
  viditing: { serviceMode, tabs, currentFile, by }
}) => ({
  classType,
  serviceMode,
  tabs,
  currentFile,
  by
});
const mapDispatch = ({
  viditing: {
    setBy,
    setScrollElements,

    selectFile,
    closeTab
  },
  gtm: { gtmPushDataLayer }
}) => ({
  setBy,
  setScrollElements,
  selectFile,
  closeTab,
  gtmPushDataLayer
});

export default connect(mapState, mapDispatch)(Tabs);
