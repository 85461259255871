import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translations_en from "./locales/en/translations.json";
import translations_ko from "./locales/ko/translations.json";
import translations_vi from "./locales/vi/translations.json";
import translations_th from "./locales/th/translations.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: { translation: translations_en },
  ko: { translation: translations_ko },
  vi: { translation: translations_vi },
  th: { translation: translations_th }
};

export { resources };

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "ko",
    debug: false,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
