export default function TerminalAck(args) {
  this.addEventListener("message", ({ data: { action, time } }) => {
    const params = { action };

    switch (action) {
      case "start":
        // this.interval = time;

        clearInterval(this.interval);
        this.interval = setInterval(() => {
          postMessage(params);
        }, time);
        break;
      default:
      // Nothing
    }
  });
}
