import Tar from "tar-js";
import zlib from "react-zlib-js";
import Encoding from "encoding-japanese";

export const DURATION_MAX = 1000 * 20 * 60;
export const DURATION_FORMAT = "mm:ss";
export const EVENT_RESIZE = `resize`;

export const VIDITING_STATUS = {
  INIT: "INIT",
  READY: "READY",
  LOADING: "LOADING",
  PLAYING: "PLAYING",
  PAUSED: "PAUSED",
  SEEK: "SEEK",
  FINISHED: "FINISHED",
  BEFORE_LOADING: "BEFORE_LOADING"
};

export const MODAL_COMPLETED_TEXT = {
  STEP: "스탭이 완료 되었습니다. 다음 스탭으로 이동하시겠습니까?",
  CHAPTER: "챕터가 완료 되었습니다. 다음 챕터로 이동하시겠습니까?",
  COURSE: "코스가 완료되었습니다! 수고하셨습니다!",
  NOT_COMPLETED: "다음 스텝 진행을 위한 수업 재생시간이 부족합니다."
};

export const leftTabs = {
  chapter: { columnInitSize: 296 },
  "q-and-a": { columnInitSize: 370 },
  references: { columnInitSize: 296 },
  "change-history": { columnInitSize: 296 },
  quiz: { columnInitSize: 296 },
  peeking: { columnInitSize: 296 }
};

export const classTypes = {
  "media-only": {
    backTypeName: "VideoStepNode",
    leftTabs: ["chapter", "references", "q-and-a"]
  },
  learning: {
    backTypeName: "LiveCodingStepNode",
    leftTabs: ["chapter", "references", "change-history", "q-and-a"]
  },
  evaluation: {
    backTypeName: "ExecutionQuizStepNode",
    leftTabs: [
      "chapter",
      "quiz",
      // "peeking",
      "q-and-a"
    ]
  }
};

export const getClassTypeByBack = backTypeName => {
  return Object.keys(classTypes).reduce((acc, cur) => {
    if (classTypes[cur].backTypeName === backTypeName) {
      acc = cur;
    }
    return acc;
  }, null);
};
export const isSameSteps = (gid, id) => {
  return gid.indexOf(id) > -1;
};

export const EVENT_MESSAGE = `message`;

export const EVENT_VISIBILITYCHANGE = `visibilitychange`;

export const getFileType = filename => {
  if (filename) {
    const ext = filename.substring(filename.lastIndexOf(".") + 1);
    let result = ext;
    let isImage = false;

    switch (ext) {
      case "css":
      case "html":
      case "json":
      case "jpeg":
      case "png":
      case "svg":
      case "xml":
      case "txt":
      case "yaml":
        break;
      case "htm":
        result = "html";
        break;
      case "js":
        result = "javascript";
        break;
      case "md":
        result = "markdown";
        break;
      case "py":
        result = "python";
        break;
      case "jpg":
        result = "jpeg";
        break;
      case "rb":
        result = "ruby";
        break;
      case "yml":
        result = "yaml";
        break;
      default:
        result = "ini";
    }

    switch (ext) {
      case "jpeg":
      case "jpg":
      case "png":
      case "svg":
      case "ico":
        isImage = true;
        break;
      default:
      // Nothing
    }
    return { type: result, isImage, ext };
  } else {
    return { type: "", isImage: false, ext: "" };
  }
};

export const SECTORS = {
  FILETREE: { name: "FILETREE" },
  TABS: { name: "TABS" },
  EDITOR: { name: "EDITOR" },
  IMAGE: { name: "IMAGE" },
  TERMINAL_TAB: { name: "TERMINAL_TAB" },
  TERMINAL: { name: "TERMINAL" },
  WEB_OUTPUT: { name: "WEB_OUTPUT" },
  PROGRESS: { name: "PROGRESS" }
};

export const getUint8Array = data => {
  if (data) {
    let newBuffer = new Uint8Array(new Buffer(data));
    let result = new TextEncoder("utf-8").encode(newBuffer);
    console.log("decode:", new TextDecoder("utf-8").decode(result));

    return result;
  }
};

export const getFiletreeTgz = (tree, mode = true) => {
  // const ignores = store
  //   .getState()
  //   .meta.ignore1.split("\n")
  //   .map(l => {
  //     const v = l.trim();
  //     return {
  //       value: v,
  //       isRoot: v.indexOf("/") === 0
  //     };
  //   });

  const tape = new Tar();
  const walk = arr => {
    arr.forEach(node => {
      if (node.file_type === "directory") {
        // tape.append(node.relative_path.substring(1));
        walk(node.children);
      } else if (
        // !ignores.some(({ value: i, isRoot }) => {
        //   let indexOf = node.relative_path.indexOf(i);
        //   return isRoot ? indexOf === 0 : indexOf > -1;
        // })
        true
      ) {
        // const type = getFileType(node.name);
        let buffer = "";
        try {
          if (mode) {
            buffer = node.buffer.data || node.buffer;

            if (
              !(buffer instanceof Uint8Array) &&
              !(buffer instanceof Array) &&
              node.encoding !== "ASCII"
            ) {
              buffer = Object.keys(buffer).reduce((acc, idx, i) => {
                acc[idx] = buffer[idx];
                return acc;
              }, []);
            } else {
              //if (!node.value) {
              buffer = Object.keys(buffer).reduce((acc, idx, i) => {
                acc[idx] = buffer[idx];
                return acc;
              }, []);

              let nodeVal = new TextDecoder("utf-8").decode(
                new Uint8Array(buffer)
              );

              node.lastValue = node.value = nodeVal;
              //}
            }
          } else {
            if (node.encoding !== "binary") {
              if (node.value) {
                buffer = new Buffer(node.value);

                node.buffer = new Uint8Array(buffer);

                const encoding = getEncoding(buffer);
                node.encoding = encoding;

                if (encoding !== "binary") {
                  buffer = new TextEncoder("utf-8").encode(node.value);
                } else {
                  if (
                    !(buffer instanceof Uint8Array) &&
                    !(buffer instanceof Array) &&
                    node.encoding !== "ASCII"
                  ) {
                    buffer = Object.keys(buffer).reduce((acc, idx, i) => {
                      acc[idx] = buffer[idx];
                      return acc;
                    }, []);
                  } else {
                    buffer = new TextEncoder("utf-8").encode(node.value);
                  }
                }
              } else {
                buffer = node.buffer.data || node.buffer;

                if (
                  !(buffer instanceof Uint8Array) &&
                  !(buffer instanceof Array) &&
                  node.encoding !== "ASCII"
                ) {
                  buffer = Object.keys(buffer).reduce((acc, idx, i) => {
                    acc[idx] = buffer[idx];
                    return acc;
                  }, []);
                }
              }
            } else {
              buffer = node.buffer.data || node.buffer;

              if (
                !(buffer instanceof Uint8Array) &&
                !(buffer instanceof Array) &&
                node.encoding !== "ASCII"
              ) {
                buffer = Object.keys(buffer).reduce((acc, idx, i) => {
                  acc[idx] = buffer[idx];
                  return acc;
                }, []);
              }
            }
          }
        } catch (err) {
          console.error(node, err);
        }

        tape.append(
          node.relative_path.substring(1),
          // type.isImage
          //   ?
          new Uint8Array(buffer)
          // : new TextEncoder(/*"utf-8"*/).encode(node.value)
        );
      }
    });
  };
  walk(tree);

  return new Promise((resolve, reject) => {
    zlib.gzip(tape.out, (error, result) => {
      if (error) reject(error);

      const blob = new Blob([result], { type: "application/x-gzip" });

      resolve(blob);
    });
  });
};

export const getFiletreeUnTgz = gzURL => {
  return new Promise((resolve, reject) => {
    fetch(gzURL, {
      method: "GET"
    })
      .then(response => response.arrayBuffer())
      .then(blob => {
        const buffer = Buffer.from(blob);

        return resolve(
          new Promise((resolve, reject) => {
            zlib.gunzip(buffer, (error, result) => {
              if (error) {
                console.log("Error", error);
                return reject(error);
              }

              //const { initialFiletree, timeline } = JSON.parse(result);
              //console.log("JSON.parse(result)", JSON.parse(result));
              return resolve(JSON.parse(result));
            });
          })
        );
      })
      .catch(error => {
        console.log("Error", error);
        return reject(error);
      });
  });
};

export const getFiletreeDownload = gzURL => {
  // return await fetch(gzURL, {
  //   method: "GET"
  // })
  //   .then(response => {
  //     response.json();
  //   })
  //   .catch(error => {
  //     console.error("fetch Error:", error);
  //   })
  //   .then(response => {
  //     return response;
  //   });

  return fetch(gzURL)
    .then(response => {
      response.json();
    })
    .then(data => {
      // Work with JSON data here
      return data;
    })
    .catch(err => {
      // Do something for an error here
      console.log("Error Reading data " + err);
    });

  // return fetch(gzURL)
  //   .then(response => response.arrayBuffer())
  //   .then(blob => {
  //     let returnVal = JSON.stringify(
  //       new TextDecoder("utf-8").decode(Buffer.from(blob))
  //     );
  //     return returnVal;
  //   })
  //   .catch(error => {
  //     console.log("Error", error);
  //   });

  // return new Promise((resolve, reject) => {
  //   fetch(gzURL)
  //     .then(response => response.json())
  //     .then(blob => {
  //       console.log("blob:", blob);
  //       return resolve(
  //         blob
  //         // new Promise((resolve, reject) => {
  //         //   return resolve(JSON.parse(blob));
  //         // })
  //       );
  //     })
  //     .catch(e => {});
  // });

  // return new Promise((resolve, reject) => {
  //   fetch(gzURL)
  //     .then(response => response.json())
  //     .then(blob => {
  //       return resolve(blob);
  //     })
  //     .catch(error => {
  //       console.log("Error", error);
  //       return reject(error);
  //     });
  // });
};

export const typesForTerminalOnly = [
  { label: "Python", value: "python" },
  { label: "Ruby", value: "ruby" }
];
export const typesForWeb = [
  { label: "Web (with Web Output)", value: "web" },
  { label: "Django (with Web Output)", value: "django" },
  { label: "Ruby on Rails (with Web Output)", value: "ror" }
];

export const webOutputModalModes = {
  NONE: "NONE",
  GENERAL: "GENERAL",
  MINIMIZED: "MINIMIZED"
};

export const historyModes = {
  RUN: "RUN",
  UNACTIVATED: "UNACTIVATED",
  PLAY: "PLAY",
  LOAD: "LOAD"
};

export const terminalTabs = {
  TEACHER: "강의 터미널",
  STUDENT: "나의 터미널"
};

export const slideConsts = {
  maxWidth: 1280,
  maxPad: 56 * 2,
  ratio: 0.15,
  padX: 74,
  padY: 14
};

// export const REGEXP_PASSWORD = new RegExp(
//   process.env.REACT_APP_AWS_COGNITO_PASSWORD_HTML5_REGEXP
// );

export const REGEXP_PASSWORD = /(?=^.{8,}$)(?=.*\d)(?=.*[\^$+=*.[\]{}()?\-\u0022!@\u0023%&/\\,><':;|_~`])(?=.*[a-z]).*$/;

export const CREDENTIALS_CHANGING = "CREDENTIALS_CHANGING";
export const CREDENTIALS_FLUSH = "CREDENTIALS_FLUSH";
export const KEY_ACCESS_TOKEN = "codelion.accessToken";
export const KEY_REFRESH_TOKEN = "codelion.refreshToken";
export const KEY_AUTH_IS_PERMANENT = "codelion.auth.isPermanent";

export const getAccessToken = () => {
  return (
    sessionStorage.getItem(KEY_ACCESS_TOKEN) ||
    localStorage.getItem(KEY_ACCESS_TOKEN)
  );
};
export const getRefreshToken = () => {
  return (
    sessionStorage.getItem(KEY_REFRESH_TOKEN) ||
    localStorage.getItem(KEY_REFRESH_TOKEN)
  );
};
export const setAccessToken = (accessToken, isPermanent) => {
  const savedIsPermanent = JSON.parse(
    localStorage.getItem(KEY_AUTH_IS_PERMANENT) || false
  );
  window[
    isPermanent || savedIsPermanent ? "localStorage" : "sessionStorage"
  ].setItem(KEY_ACCESS_TOKEN, accessToken);
};
export const setRefreshToken = (refreshToken, isPermanent) => {
  window[isPermanent ? "localStorage" : "sessionStorage"].setItem(
    KEY_REFRESH_TOKEN,
    refreshToken
  );
};
export const setTokens = (isPermanent, accessToken, refreshToken) => {
  removeTokens();
  setAccessToken(accessToken, isPermanent);
  setRefreshToken(refreshToken, isPermanent);

  // localStorage.setItem(KEY_AUTH_IS_PERMANENT, isPermanent);
};

export const removeTokens = () => {
  ["localStorage", "sessionStorage"].forEach(storage => {
    window[storage].removeItem(KEY_ACCESS_TOKEN);
    window[storage].removeItem(KEY_REFRESH_TOKEN);
  });
};

export const setCookieAtOnTime = ({ name, value, expiredays }) => {
  let todayDate = new Date();
  todayDate = new Date(
    parseInt(todayDate.getTime() / 86400000) * 86400000 + 54000000
  );
  if (todayDate > new Date()) {
    expiredays = expiredays - 1;
  }
  todayDate.setDate(todayDate.getDate() + expiredays);
  document.cookie =
    encodeURIComponent(name) +
    "=" +
    encodeURIComponent(value) +
    "; path=/; expires=" +
    todayDate.toGMTString() +
    ";";
};

export const setCookie = ({ name, value, days }) => {
  let date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  let expires = `expires=${date.toGMTString()};`;

  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value
  )}; path=/; ${expires && expires}`;
};

export const getCookie = name => {
  let matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const removeCookie = name => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1999 00:00:10 GMT;`;
};

export const getApolloContext = () => {
  const result = {};
  const accessToken = getAccessToken();

  if (accessToken) {
    result.headers = { authorization: `JWT ${accessToken}` };
  }

  return result;
};

export const getEncoding = buffer => {
  let encoding = Encoding.detect(buffer);

  switch (encoding) {
    case false:
    case "ASCII":
    case "UTF8":
    case "UTF16":
    case "UTF16BE":
    case "UTF16LE":
      break;
    default:
      encoding = "binary";
  }
  return encoding;
};

export const target_BIG_ADDRESS_INPUT = "BIG_ADDRESS_INPUT";

export const stripOldAndIncompleteHangul = str =>
  str
    .split("")
    .filter(c => {
      const cc = c.charCodeAt(0);
      return (cc < 4352 || 4546 < cc) && (cc < 12593 || 12643 < cc);
    })
    .join("");
