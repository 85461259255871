import React, { lazy, useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { ReactComponent as Intellectualproperty } from "../../images/img-intellectualproperty.svg";

const ContactUs = lazy(() => import("../common/modals/ContactUs"));

const Footer = ({ contactUS, setContactUS }) => {
  const [contactUsShow, setContactUsShow] = useState({
    isShow: false
  });

  const controlContactUs = useCallback(
    value => {
      setContactUsShow({
        isShow: !contactUsShow.isShow
      });
    },
    [contactUsShow]
  );

  useEffect(() => {
    if (contactUS) {
      setContactUsShow({
        isShow: contactUS
      });
      setContactUS(false);
    }
  }, [contactUS]);

  // if (contactUS) {
  //   contactFun();
  //   // setContactUsShow({
  //   //   isShow: contactUS
  //   // });
  // }

  const showCopyRight = useCallback((e, type) => {
    if (type) {
      document.getElementById("copyright__wrapper").classList.add("active");
      e.target.classList.add("active");
    } else {
      document.getElementById("copyright__wrapper").classList.remove("active");
      e.target.classList.remove("active");
    }
  }, []);

  return (
    <footer>
      <section className="top">
        <Container className="px-0">
          <Row className="mx-0">
            <div className="col px-0">
              <Row className="row-title mx-0">(주)멋쟁이사자처럼</Row>
              <Row className="row-contents mx-0">
                <ul className="list-unstyled" style={{ marginTop: "9px" }}>
                  <li>
                    <label className="mb-0">대표자</label>
                    <span>이두희</span>
                  </li>
                  <li>
                    <label className="mb-0 align-top">주소지</label>
                    <span>
                      <p>서울특별시 강남구 삼성동 146-14</p>
                      {/* <p className="mb-0">(대치동, 한국섬유산업연합회)</p> */}
                    </span>
                  </li>
                  <li>
                    <label className="mb-0">사업자등록번호</label>
                    <span>264-88-01106</span>
                  </li>
                  <li>
                    <label className="mb-0">통신판매업신고번호</label>
                    <span>2019-서울강남-00774</span>
                  </li>
                </ul>
              </Row>
            </div>
            <div className="col px-0 ">
              <Row className="row-title mx-0">
                <Intellectualproperty style={{ marginTop: "-3px" }} />
              </Row>
              <Row className="row-contents mx-0">
                <ul className="list-unstyled">
                  <li className="mb-3">코드라이언 출원 특허</li>
                  <li>특허 번호 10-2097921</li>
                  <li>특허 번호 10-2097922</li>
                  <li>특허 번호 10-2097923</li>
                </ul>
              </Row>
            </div>
            <div className="col px-0 ">
              <Row className="row-title mx-0">CODE LION</Row>
              <Row className="row-contents mx-0">
                <ul className="list-unstyled codelion-footer-menu">
                  <li
                    onMouseOver={e => {
                      showCopyRight(e, true);
                    }}
                    onMouseLeave={e => {
                      showCopyRight(e, false);
                    }}
                  >
                    만든사람들
                    <div id="copyright__wrapper">
                      <h3>코드라이언 팀</h3>
                      <p>
                        강민경, 구민상, 마한빈, 박인식, 양상모, 오빈, 오재운,
                        유병혁, 이두희, 장정석, 전은선, 최준원, 한창표
                      </p>
                    </div>
                  </li>
                  <li
                    onClick={() => {
                      controlContactUs();
                    }}
                  >
                    문의하기
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://drive.google.com/open?id=1a_xqbJf9-BClNnhRYFA9J8Y9-LtLPN_m"
                    >
                      사용매뉴얼
                    </a>
                  </li>
                </ul>
              </Row>
            </div>
            <div className="col px-0 ">
              <Row className="row-title mx-0">패밀리 사이트</Row>
              <Row className="row-contents mx-0">
                <ul className="list-unstyled familysite-list">
                  <li>
                    <a
                      href="http://class.likelion.net/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LIKE LION WORKS
                    </a>
                  </li>
                </ul>
              </Row>
            </div>
          </Row>
        </Container>
      </section>
      <section className="bottom">
        <Container className="px-0 text-center">
          <Row className="mx-0">
            <Col className="p-0 text-left">
              <ul className="list-unstyled">
                <li className="d-inline-block align-middle">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.notion.so/c4320a9c732143c081c67789b4a095fb"
                  >
                    이용약관
                  </a>
                </li>
                <li className="d-inline-block h-100 align-middle">
                  <div className="vertical-line" />
                </li>
                <li className="d-inline-block align-middle">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.notion.so/4a981d15472741d99b37798a5beae8d5"
                  >
                    개인정보처리방침
                  </a>
                </li>
              </ul>
            </Col>
            <Col className="p-0 h-100 align-middle copyright">
              Copyright © 2020 (주)멋쟁이사자처럼. All rights reserved.
            </Col>
            <Col className="p-0 h-100" />
          </Row>
        </Container>
      </section>
      <ContactUs show={contactUsShow.isShow} onHide={controlContactUs} />
    </footer>
  );
};

const mapState = ({ ide: { contactUS } }) => ({
  contactUS
});

const mapDispatch = ({ ide: { setContactUS } }) => ({
  setContactUS
});
export default connect(mapState, mapDispatch)(Footer);
