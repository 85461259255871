// import ApolloClient from "apollo-boost";
import { ApolloClient as RawApolloClient } from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";
import { ApolloLink, Observable } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import {
  selectURI,
  selectHttpOptionsAndBody,
  fallbackHttpConfig,
  serializeFetchParameter,
  createSignalIfSupported,
  parseAndCheckHttpResponse
} from "apollo-link-http-common";
import { extractFiles } from "extract-files";

import { getApolloContext, VIDITING_STATUS, getAccessToken } from "../common";
import { TOAST_OPTION, KBBANK_CNAME } from "../constants";

import axios from "axios";
import Moment from "react-moment";
import i18n from "../i18n";
import introspectionQueryResultData from "../graphql/fragment/fragmentTypes.json";
import {
  NEXT_STEP,
  TYPENAME,
  ALL_LANGUAGE_TAGS,
  STEP_BY_CLASSTYPE
} from "../graphql/queries";
import sockette from "sockette";
import { toast } from "react-toastify";
import store from "../store";
import {
  REACT_APP_BACKEND_ENDPOINT,
  REACT_APP_BACKEND_OASIS,
  REACT_APP_BACKEND_TARANGIRE,
  REACT_APP_BACKEND_BRANCH,
} from "../config";

Moment.globalLocale = i18n.language;

export default {
  state: {
    ws: {
      isGrade: false,
      isLoading: false,
      socket: {},
      isConnected: false,
      storage: "",
      terminal: "",
      step: 0
    },
    mainMov: { isShow: false, isPlaying: false },
    mixpanelCourse: [],
    currentLocale: i18n.language,
    isGnbOpacity: false,
    windowDimension: { width: 0, height: 0 },
    notifications: [],
    nextStep: {},
    allLanguageTags: [],
    apollo: {
      client: {
        general: new RawApolloClient({
          cache: new InMemoryCache({
            fragmentMatcher: new IntrospectionFragmentMatcher({
              introspectionQueryResultData
            })
          }),
          link: new HttpLink({ uri: REACT_APP_BACKEND_ENDPOINT })
        }),
        upload: new RawApolloClient({
          cache: new InMemoryCache(),
          link: new ApolloLink(operation => {
            const uri = selectURI(
              operation,
              REACT_APP_BACKEND_ENDPOINT
            );
            const context = operation.getContext();
            const contextConfig = {
              http: context.http,
              options: context.fetchOptions,
              credentials: context.credentials,
              headers: context.headers
            };

            const { options, body } = selectHttpOptionsAndBody(
              operation,
              fallbackHttpConfig,
              // linkConfig,
              contextConfig
            );

            const { clone, files } = extractFiles(body);
            const payload = serializeFetchParameter(clone, "Payload");

            if (files.size) {
              // Automatically set by fetch when the body is a FormData instance.
              delete options.headers["content-type"];

              // GraphQL multipart request spec:
              // https://github.com/jaydenseric/graphql-multipart-request-spec

              const form = new FormData();
              const { query, variables } = JSON.parse(payload);
              // console.log(JSON.parse(payload))
              form.append("query", query);
              form.append("variables", JSON.stringify(variables));

              const map = {};
              let i = 0;
              files.forEach(paths => {
                map[++i] = paths;
              });
              form.append("map", JSON.stringify(map));

              i = 0;
              files.forEach((paths, file) => {
                // form.append(++i, file, file.name)
                form.append("file", file, file.name);
              });

              options.body = form;
            } else options.body = payload;

            return new Observable(observer => {
              // Allow aborting fetch, if supported.
              const { controller, signal } = createSignalIfSupported();
              if (controller) options.signal = signal;

              fetch(uri, options)
                .then(response => {
                  // Forward the response on the context.
                  operation.setContext({ response });
                  return response;
                })
                .then(parseAndCheckHttpResponse(operation))
                .then(result => {
                  observer.next(result);
                  observer.complete();
                })
                .catch(error => {
                  if (error.name === "AbortError")
                    // Fetch was aborted.
                    return;

                  if (error.result && error.result.errors && error.result.data)
                    // There is a GraphQL result to forward.
                    observer.next(error.result);

                  observer.error(error);
                });

              // Cleanup function.
              return () => {
                // Abort fetch.
                if (controller) controller.abort();
              };
            });
          })
        })
      }
    }
  },
  reducers: {
    setWs(state, payload) {
      return {
        ...state,
        ws: { ...state.ws, ...payload }
      };
    },
    setMainMov(state, payload) {
      return {
        ...state,
        mainMov: { ...state.mainMov, ...payload }
      };
    },
    setNextStep(state, payload) {
      return {
        ...state,
        nextStep: { ...payload }
      };
    },
    setAllLanguageTags(state, payload) {
      return {
        ...state,
        allLanguageTags: { ...payload }
      };
    },
    setCurrentLocale(state, payload) {
      Moment.globalLocale = payload;
      return { ...state, currentLocale: payload };
    },
    toggleGnbOpacity(state, payload) {
      return {
        ...state,
        isGnbOpacity: payload ? payload.isGnbOpacity : !state.isGnbOpacity
      };
    },
    setWindowDimension(state, payload) {
      return { ...state, windowDimension: payload };
    },
    addNotification(state, payload) {
      return { ...state, notifications: [...state.notifications, payload] };
    },

    setMixpanelCourse(state, payload) {
      return { ...state, mixpanelCourse: payload };
    }
  },
  effects: dispatch => ({
    graphQlErrors(
      { graphQLErrors, history },
      {
        auth: {
          authBasic: { organization }
        }
      }
    ) {
      const { pathname, search, hash } = document.location;
      if (graphQLErrors) {
        graphQLErrors.forEach(async error => {
          try {
            switch (error.code) {
              case 1100001:
                if (organization) {
                  alert(
                    "네트워크 오류가 발생하였습니다. \n\n다시한번 접근 부탁드립니다."
                  );
                } else {
                  toast.error("수업을 등록해주세요.", TOAST_OPTION(2000));
                  window.history.back();
                }
                break;
              case 1400000:
                toast.error(
                  "장시간 사용하지 않아 자동 로그아웃 되었습니다.",
                  TOAST_OPTION(2000)
                );

                if (history) {
                  history.push("/auth/signin", {
                    from: `${pathname}${search}${hash}`
                  });
                } else {
                  window.location.href = "/auth/signin";
                }
                break;
              default:
              //Nothing
            }
          } catch (e) {
            console.log("history-error:common:", e);
          }
        });
        return null;
      } else {
        console.error("graphQLErrors:common:", graphQLErrors);
        if (history) {
          history.push("/auth/signin", {
            from: `${pathname}${search}${hash}`
          });
        } else {
          window.location.href = "/auth/signin";
        }
      }
    },
    async getTypenameByBackNode({ gid, history }, rootState) {
      const { general: client } = rootState.common.apollo.client;
      try {
        const {
          data: {
            node: { __typename }
          }
        } = await client.query({
          query: TYPENAME,
          fetchPolicy: "network-only",
          variables: { gid }
        });
        return __typename;
      } catch ({ graphQLErrors }) {
        console.log("graphQLErrors-TYPENAME:", graphQLErrors);
        this.graphQlErrors({ history, graphQLErrors });
      }
    },
    async getClassByStepType({ classType, step, history }, rootState) {
      const { general: client } = rootState.common.apollo.client;
      const stepByClassTypeQuery = STEP_BY_CLASSTYPE[classType];

      try {
        const { data } = await client.query({
          query: stepByClassTypeQuery,
          context: getApolloContext(),
          fetchPolicy: "network-only",
          variables: { step }
        });

        return data;
      } catch ({ graphQLErrors }) {
        this.graphQlErrors({ history, graphQLErrors });
      }
    },
    async newSocket({ timeout = 10000, maxAttempts = -1 } = {}, rootState) {
      let confirmBool = false;
      const socket = await new sockette(
        `wss://ws.${REACT_APP_BACKEND_OASIS}.codelion.io`,
        //`wss://8k3pb50hdj.execute-api.ap-northeast-2.amazonaws.com/test`,
        {
          timeout: timeout,
          maxAttempts: maxAttempts,
          onopen: async e => {
            const { common, ide } = store.getState();
            if (common.ws.storage) {
              common.ws.socket.json({
                type: "resource",
                context: {
                  action: "reconnect",
                  storage: common.ws.storage,
                  user: localStorage.getItem("codelion.accessToken")
                }
              });
            } else {
              if (ide.classType === "learning") {
                await dispatch.viditing.setStatus(VIDITING_STATUS.LOADING);
                await dispatch.viditing.initTerminal();
                await dispatch.viditing.onMessageForTerminalTimer();
              }
            }
            this.setWs({ isConnected: true });
            console.log(
              "socket-opened!!!!!",
              `${REACT_APP_BACKEND_OASIS}`
            );
          },
          onmessage: ({ data }) => {
            const { common, ide, viditing, auth } = store.getState();
            const { type, message } = JSON.parse(data);
            console.log("소켓 메세지", data);

            if (message.action) {
              switch (type) {
                case "resource":
                  switch (message.action) {
                    case "create":
                      const { result, ...rest } = message;
                      let origin = null;

                      if (message.result) {
                        if (ide.classType === "learning") {
                          origin = new URL(rest.terminal).origin;
                          dispatch.viditing.requestTerminal();
                        }

                        this.setWs({
                          isConnected: result,
                          ...rest,
                          origin,
                          isLoading: false,
                          isGrade: false,
                          webOutputOrigin: rest.web
                            ? new URL(rest.web).origin
                            : null
                        });

                        console.log("socket create!!::", rest);
                      }
                      break;
                    case "disconnect":
                      if (!confirmBool) {
                        this.setWs({ isDisconnected: true });

                        confirmBool = true;
                      }
                      break;
                    case "reconnect":
                      this.setWs({
                        storage: message.storage,
                        isLoading: false,
                        isGrade: false
                      });
                      break;
                    default:
                      if (message.step) {
                        dispatch.common.setWs({ step: message.step });
                      }
                      break;
                  }

                  break;

                case "file_sync":
                  if (message.action === "watcher") {
                    if (viditing.isBatchProcessing || !common.ws.terminal) {
                      dispatch.viditing.switchOffBatchProcessing(false);
                    }
                  } else {
                    if (viditing.isBatchProcessing || !common.ws.terminal) {
                      dispatch.viditing.switchOffBatchProcessing(false);
                    } else if (viditing.status !== VIDITING_STATUS.SEEK) {
                      switch (message.action) {
                        case "create":
                          //if(viditing.serviceMode==="STUDENT"){
                          //dispatch.viditing.applyFileFromServer(message);
                          //}else{
                          dispatch.viditing.addToFiletree(message);
                          //}
                          break;
                        case "modify":
                          dispatch.viditing.applyFileFromServer(message);
                          break;
                        case "remove":
                          dispatch.viditing.removeFromFiletree(message);
                          break;
                        case "rename":
                          dispatch.viditing.rename(message);

                          break;
                        default:
                        //Nothing
                      }
                      dispatch.viditing.switchOffBatchProcessing(false);
                    }
                  }
                  break;
                default:
                  break;
                //Nothing

                case "quiz":
                  switch (message.action) {
                    case "create":
                    case "reconnect":
                      this.setWs({
                        storage: message.storage,
                        isLoading: false,
                        isGrade: false
                      });
                      break;
                    case "grade":
                      this.setWs({ isLoading: false, isGrade: false });
                      if (message.grade === false) {
                        const resultSubQuiz = {
                          ...ide.quiz.subQuizzes[ide.quiz.inProgressSubQuiz]
                        };
                        dispatch.ide.setQuiz({
                          tryCount: message.fail_count,
                          subQuizzes: {
                            ...ide.quiz.subQuizzes,
                            [ide.quiz.inProgressSubQuiz]: {
                              ...resultSubQuiz,
                              failCount: message.fail_count,
                              questionSource: ide.editor.value
                            }
                          }
                        });

                        //b2b 제외
                        if (
                          !auth.authBasic?.organization ||
                          auth.authBasic.organization?.cname === KBBANK_CNAME
                        ) {
                          toast.error(
                            `다시 한번 고민해보세요!`,
                            TOAST_OPTION(2000)
                          );
                        }
                      } else if (message.grade === true) {
                        const resultSubQuiz = {
                          ...ide.quiz.subQuizzes[ide.quiz.inProgressSubQuiz],
                          completed: true,
                          questionSource: ide.editor.value
                        };

                        const getNextSubQuiz = () => {
                          const orderNextSubQuiz =
                            ide.quiz.subQuizzes[ide.quiz.lastOnGoingSubQuiz]
                              .order + 1;
                          const filterNextSubQuiz = Object.keys(
                            ide.quiz.subQuizzes
                          ).filter(v => {
                            if (
                              ide.quiz.subQuizzes[v].order === orderNextSubQuiz
                            ) {
                              return v;
                            }
                          });
                          if (filterNextSubQuiz.length)
                            return filterNextSubQuiz[0];
                          else return "";
                        };
                        const nextSubQuiz = getNextSubQuiz() || "";

                        dispatch.ide.setQuiz({
                          lastOnGoingSubQuiz:
                            nextSubQuiz !== ""
                              ? nextSubQuiz
                              : resultSubQuiz.hashid,
                          isSubQuizComplete: true,
                          subQuizzes: {
                            ...ide.quiz.subQuizzes,
                            [ide.quiz.inProgressSubQuiz]: resultSubQuiz
                          }
                        });

                        const isQuizAllPass = () => {
                          const resultSubQuizzes = {
                            ...ide.quiz.subQuizzes,
                            [ide.quiz.inProgressSubQuiz]: resultSubQuiz
                          };

                          const subQuizzesArr = Object.keys(resultSubQuizzes);
                          const filterCompleteQuiz = subQuizzesArr.filter(v => {
                            return resultSubQuizzes[v].completed === true;
                          });

                          if (
                            filterCompleteQuiz.length === subQuizzesArr.length
                          ) {
                            // 전체 Quiz step 종료시
                            dispatch.ide.toggleModalStepIsCompleted(true);
                          }
                        };

                        //b2b 제외
                        if (
                          !auth.authBasic?.organization ||
                          auth.authBasic.organization?.cname === KBBANK_CNAME
                        ) {
                          isQuizAllPass();
                        }
                      }
                      break;
                    default:
                  }
              }
            } else {
              //소켓 응답 실패
              this.setWs({ isLoading: false, isGrade: false });
            }
          },
          onclose: e => {
            const { common } = store.getState();
            console.log("소켓 Closed!", e);
            this.setWs({ isLoading: true });
            if (common.ws.storage) {
              this.newSocket();
            }
          },
          onerror: e => {
            console.log("소켓 Error:", e);
            this.setWs({
              isConnected: false,
              storage: "",
              isLoading: false,
              isGrade: false
            });
          }
        }
      );
      this.setWs({ socket });
    },
    async requestSocket(data = {}, rootState) {
      const state = store.getState();
      if (
        Object.keys(state.common.ws.socket).length &&
        state.common.ws.isConnected
      ) {
        await state.common.ws.socket.json(data);
      }
    },
    async closeSocket(payload, rootState) {
      const { common } = store.getState();
      if (Object.keys(common.ws.socket).length > 0) {
        // common.ws.socket.json({
        //   type: "resource",
        //   context: {
        //     action: "destroy",
        //     storage: common.ws.storage,
        //     user: localStorage.getItem("codelion.accessToken")
        //   }
        // });

        this.setWs({
          socket: {},
          isConnected: false,
          storage: "",
          isLoading: false,
          isGrade: false
        });
        await common.ws.socket.close();
      }
    },
    async requestFetch({ url = "", method = "PUT", data } = {}, rootState) {
      // Default options are marked with *
      return await fetch(
        `https://fs-apiserver.${REACT_APP_BACKEND_OASIS}.codelion.io/${url}`,
        {
          method: method, // *GET, POST, PUT, DELETE, etc.
          body: data // body data type must match "Content-Type" header
        }
      )
        .then(response => response.json()) // parses JSON response into native JavaScript objects
        .catch(error => {
          console.error("fetch Error:", error);
          toast.error(
            `CODE LION 서버 접속에 문제가 있습니다.\n다시 시도해주세요.`,
            TOAST_OPTION(2000)
          );
        })
        .then(response => {
          console.log("fetch Success:", method);
          return response;
        });
    },

    async requestAxios(
      { url = "", method = "PUT", data, config = {} } = {},
      rootState
    ) {
      return await axios({
        url: `https://fs-apiserver.${REACT_APP_BACKEND_OASIS}.codelion.io/${url}`,
        method: method,
        data: data,
        config
      })
        .catch(error => {
          console.error("axios Error:", error);
          // toast.error(`CODELION 접속에 문제가 있습니다.\n다시 시도해주세요.`, {
          //   closeOnClick: true
          // });
        })
        .then(response => {
          console.log("file PUT Success:", method);
          return response;
        });
    },
    async requestTarangire(
      { url = "", method = "GET", data, config = {} } = {},
      rootState
    ) {
      return await axios({
        url: `${REACT_APP_BACKEND_TARANGIRE}${url}`,
        headers: {
          authorization: `Bearer ${getAccessToken()}`
        },
        method: method,
        data: data,
        config
      })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          throw error.response;
        });
    },
    async nextStep({ step }, rootState) {
      const {
        common: {
          apollo: {
            client: { general: client }
          }
        }
      } = rootState;

      try {
        const {
          data: { nextStep }
        } = await client.query({
          query: NEXT_STEP,
          context: getApolloContext(),
          fetchPolicy: "network-only", //fetchPolicy of ‘network-only’또는‘cache-first’
          variables: { step: step }
        });

        let typename, id, completed;
        if (nextStep) {
          typename = nextStep.__typename;
          id = nextStep.id;
          completed = nextStep.completed;
        } else {
          typename = null;
          id = null;
          completed = null;
        }
        this.setNextStep({ typename, id, completed });
        return { typename, id, completed };

        // if (completed) {
        //   dispatch.ide.toggleModalStepIsCompletedText({
        //     textGrade: "STEP"
        //   });
        //   if (isCompletedChapter) {
        //     dispatch.ide.toggleModalStepIsCompletedText({
        //       textGrade: "CHAPTER"
        //     });
        //     if (isCompletedCourse) {
        //       dispatch.ide.toggleModalStepIsCompletedText({
        //         textGrade: "COURSE"
        //       });
        //       dispatch.ide.toggleModalStepIsCompleted(true);
        //     }
        //   }
        // } else {
        //   dispatch.ide.toggleModalStepIsCompletedText({ textGrade: "STEP" });
        //   dispatch.ide.toggleModalStepIsCompleted(true);
        // }
        //
      } catch (e) {
      }
    },
    async getAllLanguageTags({} = {}, rootState) {
      const {
        common: {
          apollo: {
            client: { general: client }
          }
        }
      } = rootState;

      try {
        const {
          data: { allLanguageTags }
        } = await client.query({
          query: ALL_LANGUAGE_TAGS,
          context: getApolloContext(),
          fetchPolicy: "network-only" //fetchPolicy of ‘network-only’또는‘cache-first’
        });
        this.setAllLanguageTags(allLanguageTags);
      } catch (e) {
      }
    },
    async getSystemMaintenance({} = {}, rootState) {
      return await axios({
        url: `https://${REACT_APP_BACKEND_BRANCH}.codelion.io/maintenance-state`,
        method: "GET"
      });
    }
  })
};
