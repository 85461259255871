import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, FormControl } from "react-bootstrap";
import { Rnd } from "react-rnd";
import rafSchd from "raf-schd";

import {
  EVENT_MESSAGE,
  webOutputModalModes,
  VIDITING_STATUS
} from "../../common";

import { ReactComponent as IconRefresh } from "../../images/btn-browserrefresh-off.svg";

class WebOutput extends Component {
  state = {
    url: null,
    randomKey: Math.floor(Math.random() * 10000000)
  };

  constructor(props) {
    super(props);
    this.webOutputID = props.webOutputModal.webOutputID;
  }

  componentDidMount() {
    // this.setState({ ...this.state, url: this.props.ws.web });
    // this.props.setWebOutputModal({ reload: true });

    const { setWebOutputModal } = this.props;
    setWebOutputModal({
      latestAddress: "/"
    });

    // this.setState({ ...this.state, url: this.props.ws.web });
    // this.props.setWebOutputModal({ reload: true });
    setTimeout(() => {
      this.iframe = this.props.iframeTarget;
      window.addEventListener(EVENT_MESSAGE, this.onMessage);
    });
  }

  onMessage = ({ origin, data }) => {
    const {
      ws: { webOutputOrigin }
    } = this.props;

    if (webOutputOrigin === origin && typeof data !== "string") {
      switch (data.type) {
        case "batch":
          // console.log(data);
          data.list.forEach(this.processMessage);
          break;
        default:
          this.processMessage(data);
      }
    }
  };

  processMessage = data => {
    const {
      updateScroll,
      updateStatus,
      webOutputModal: { latestAddress },
      webConsole,
      ws: { webOutputOrigin },
      status,
      setWebOutputModal,
      setWebConsole
    } = this.props;
    const { type, href, ...rest } = data;

    switch (data.type) {
      case "ready":
        const addr = rest.url.substring(webOutputOrigin.length);

        if (addr !== latestAddress) {
          setWebOutputModal({ latestAddress: addr });
          //setTimeout(this.recordRefreshing);
        }

        if (updateStatus) {
          updateStatus("LOADED", addr);
        }
        // console.log("message from weboutput", { ...rest });

        if (status !== VIDITING_STATUS.FINISHED) {
          setWebConsole({ list: [] });
        }

        break;
      case "scroll":
        const address = href.substring(webOutputOrigin.length);

        if (updateScroll) {
          updateScroll({ ...rest, address });
        }
        break;
      case "error":
      case "console":
        const level = data.level || data.type;
        const args = data.args || [data.eventOrMessage];

        // console.log(data, level, args);

        if (webConsole.timer !== null) {
          clearTimeout(webConsole.timer);
        }
        if (status !== VIDITING_STATUS.FINISHED) {
          this.consoleQueue.push({
            level,
            args,
            ...(data.type === "error" ? { exceptional: true } : {})
          });
          this.processConsole();
        }

        break;
      default:
      // console.log(data);
    }
  };

  consoleQueue = [];
  processConsole = rafSchd(() => {
    const { webConsole, setWebConsole } = this.props;

    setWebConsole({
      //shown: true,
      list: [...webConsole.list, ...this.consoleQueue]
      // timer: window.setTimeout(() => {
      //   setWebConsole({ shown: false });
      // }, 3000)
    });
    this.consoleQueue = [];
  });

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      webOutputModal: {
        reload: prevReload,
        mode: prevMode,
        latestAddress: prevLatestAddress,
        initializeUrl: prevInitializeUrl
      }
      //webConsole: { shown: prevWebConsoleShown }
    } = prevProps;

    const {
      webOutputModal: { reload, latestAddress, initializeUrl, mode },
      //webConsole: { shown: webConsoleShown },
      setWebOutputModal,
      initialDirectory
    } = this.props;

    const { webAutoReload } = initialDirectory;

    if (prevReload !== reload && !!reload) {
      this.processReloading(latestAddress, prevLatestAddress, prevMode);
    }

    if (prevInitializeUrl !== initializeUrl && !!initializeUrl) {
      setWebOutputModal({ initializeUrl: false });
      this.setState({ ...this.state, url: null });
    }
  }

  componentWillUnmount() {
    window.removeEventListener(EVENT_MESSAGE, this.onMessage);
  }

  processReloading = (addr, prevAddr, prevMode) => {
    const {
      updateStatus,
      webOutputModal: { mode },
      ws: { webOutputOrigin },
      setWebOutputModal,
      serviceMode,
      initialDirectory
    } = this.props;

    let url = null;
    let isOrigin = false;

    setWebOutputModal({ reload: false });

    try {
      url = new URL(addr).href;
    } catch (err) {
      url = `${webOutputOrigin}${addr.indexOf("/") === 0 ? "" : "/"}${addr}`;
      isOrigin = true;
    }

    if (url !== this.state.url || isOrigin) {
      this.setState({
        ...this.state,
        randomKey: Math.floor(Math.random() * 10000000)
      });

      if (updateStatus) {
        updateStatus("REQUEST", addr);
      }

      if (
        this.state.url !== null &&
        isOrigin &&
        url === `${webOutputOrigin}${prevAddr}`
      ) {
        try {
          if (
            serviceMode === "STUDENT" &&
            mode === webOutputModalModes.GENERAL &&
            prevMode !== webOutputModalModes.MINIMIZED
          ) {
            if (this.iframe.contentWindow.postMessage) {
              console.log("reload1!!!");
              this.iframe.contentWindow.postMessage({ action: "reload" }, "*");
            } else {
              this.iframe.src = "";
              this.iframe.src = url;
            }
          } else if (serviceMode === "TEACHER") {
            if (this.iframe.contentWindow.postMessage) {
              console.log("reload2!!!");
              this.iframe.contentWindow.postMessage({ action: "reload" }, "*");
            } else {
              this.iframe.src = "";
              this.iframe.src = url;
            }
          }
        } catch (e) {
          this.iframe.src = "";
          this.iframe.src = url;
        }
      } else {
        this.setState({ ...this.state, url: null }, () => {
          setTimeout(() => {
            this.setState({ ...this.state, url });
          }, 100);
        });
      }
    }
  };

  onResize = detail => {
    const { updateDimension } = this.props;

    if (updateDimension) {
      updateDimension(detail);
    } else {
      // pushToCargo({
      //   target: SECTORS.WEB_OUTPUT.name,
      //   type: "dimension",
      //   detail
      // });
    }
  };

  render() {
    const {
      updateDimension,
      webOutputModal: {
        latestAddress,
        isShownBigAddressInput,
        addressInput,
        ...webOutputModal
      },
      setWebOutputModal,
      gtmPushDataLayer
    } = this.props;

    const { url } = this.state;

    return (
      <>
        <Rnd
          ref={c => {
            this.props.sectors.WEB_OUTPUT.ref = c;
          }}
          className={`rnd${
            webOutputModal.mode === webOutputModalModes.NONE ||
            webOutputModal.mode === webOutputModalModes.MINIMIZED
              ? " d-none"
              : ""
          }`}
          style={{ backgroundColor: "#fff", left: "7px", top: "11px" }}
          size={{
            width: webOutputModal.width,
            height:
              webOutputModal.height.toString().lastIndexOf("px") > -1
                ? `${parseInt(
                    webOutputModal.height.substring(
                      0,
                      webOutputModal.height.lastIndexOf("px")
                    )
                  ) + 24}px`
                : webOutputModal.height + 24
          }}
          //position={{ x: webOutputModal.left, y: webOutputModal.top }}
          bounds=".viditing-boundary"
          dragHandleClassName="handle"
          minWidth={200}
          minHeight={200}
          resizeHandleClasses={{
            topRight: "resize-tr",
            right: "resize-r",
            bottomRight: "resize-br",
            bottom: "resize-b",
            bottomLeft: "resize-bl"
          }}
          onDragStop={(e, d) => {
            // this.setState({ x: d.x, y: d.y });
            setWebOutputModal({
              left: d.x,
              top: d.y
            });
          }}
          onDrag={(e, d) => {
            const detail = {
              left: d.x,
              top: d.y
            };

            if (updateDimension) {
              updateDimension(detail);
            } else {
              // pushToCargo({
              //   target: SECTORS.WEB_OUTPUT.name,
              //   type: "dimension",
              //   detail
              // });
            }
          }}
          onResize={(e, direction, ref, delta, position) => {
            const detail = {
              width: ref.style.width,
              height: ref.style.height,
              left: position.x,
              top: position.y
            };
            setWebOutputModal(detail);
            this.onResize(detail);
          }}
        >
          <div
            className="h-100 d-flex flex-column position-relative"
            onPointerMove={e => {
              const { onPointerMove } = this.props;
              if (onPointerMove) {
                onPointerMove(e);
              }
            }}
            onPointerDown={e => {
              const { onPointerDown } = this.props;

              if (onPointerDown) {
                onPointerDown(e);
              }
            }}
            onPointerUp={e => {
              const { onPointerUp } = this.props;
              if (onPointerUp) {
                onPointerUp(e);
              }
            }}
          >
            <div className="handle position-relative">
              <Button
                variant="link"
                className="position-absolute border-0"
                onClick={() => {
                  setWebOutputModal({
                    mode: webOutputModalModes.MINIMIZED
                  });

                  gtmPushDataLayer({
                    event: "ideFunctionMinibrowserMinimizationClick"
                  });
                }}
              >
                <hr className="my-0" />
              </Button>
              <span className="position-absolute w-100 text-center">
                Mini-browser
              </span>
              <form className="address-container d-flex mt-4 pr-2">
                <Button
                  variant="link"
                  className="mr-2"
                  onClick={() => {
                    console.log("reload click!!");
                    setWebOutputModal({ reload: true });

                    gtmPushDataLayer({
                      event: "ideFunctionMinibrowserRefreshClick"
                    });
                  }}
                >
                  <IconRefresh className="d-block" />
                </Button>
                <FormControl
                  className="px-2 py-0 text-truncate flex-grow-1"
                  value={latestAddress}
                  onChange={() => {}}
                  onFocus={() => {
                    const target = {
                      isShownBigAddressInput: true
                    };

                    if (latestAddress !== addressInput) {
                      target.addressInput = latestAddress;
                    }
                    setWebOutputModal(target);

                    setTimeout(() => {
                      webOutputModal.addressInputRef.current.focus();
                    }, 200);
                    gtmPushDataLayer({
                      event: "ideFunctionMinibrowserURLWindowClick"
                    });
                  }}
                />
              </form>
            </div>
            <div className="flex-grow-1">
              <iframe
                id={this.webOutputID}
                src={url}
                width="100%"
                height="100%"
                className="iframe-style"
                allowFullScreen
                frameBorder={0}
                marginWidth={0}
                marginHeight={0}
                data-hj-allow-iframe=""
              />
            </div>
            <div className="position-absolute handle"></div>
          </div>
        </Rnd>
        {isShownBigAddressInput && (
          <form
            className={`address-fixed-container position-fixed d-flex align-items-center justify-content-center`}
            onSubmit={e => {
              e.preventDefault();

              this.setState({ ...this.state, url: null }, () => {
                setWebOutputModal({
                  reload: true,
                  latestAddress: addressInput,
                  isShownBigAddressInput: false
                });
              });
            }}
            onBlur={() => {
              setWebOutputModal({ isShownBigAddressInput: false });
            }}
          >
            <div>
              <FormControl
                ref={webOutputModal.addressInputRef}
                className="text-truncate"
                value={addressInput}
                onChange={e => {
                  const value = e.target.value;

                  setWebOutputModal({ addressInput: value });
                }}
              />
            </div>
          </form>
        )}
      </>
    );
  }
}

const mapState = ({
  ide: { webOutputModal, webConsole },
  viditing: {
    elements: { terminalType },
    status,
    serviceMode,
    initialDirectory
  },
  common: { ws }
}) => ({
  webOutputModal,
  webConsole,
  terminalType,
  ws,
  status,
  serviceMode,
  initialDirectory
});
const mapDispatch = ({
  ide: { setWebOutputModal, setWebConsole },
  gtm: { gtmPushDataLayer }
}) => ({
  setWebOutputModal,
  setWebConsole,
  gtmPushDataLayer
});

export default connect(mapState, mapDispatch)(WebOutput);
