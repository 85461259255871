import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import Split from "react-split-grid";
import { debounce, throttle } from "lodash";
import Loading from "../common/LoadingClass";
import LoadingSeeking from "../common/Loading";
import * as Terminal from "xterm";
import { toast } from "react-toastify";

import { faMousePointer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  EVENT_RESIZE,
  EVENT_MESSAGE,
  SECTORS,
  VIDITING_STATUS,
  typesForWeb,
  slideConsts,
  webOutputModalModes,
  historyModes,
  EVENT_VISIBILITYCHANGE,
  target_BIG_ADDRESS_INPUT
} from "../../common";

import WebWorker from "../../workers/WebWorker";
import Viditing from "../../workers/Viditing";
import TerminalAck from "../../workers/TerminalAck";

import GridForFiletree from "./GridForFiletree";
import GridForConsoles from "./GridForConsoles";
import Progress from "./Progress";
import { ReactComponent as IconSlideClose } from "../../images/btn-clickimgcancel.svg";
import WebOutput from "./WebOutput";
import root from "window-or-global";
import { TOAST_OPTION } from "../../constants";
import { REACT_APP_MODE } from '../../config'
Terminal.loadAddon("fit");

class GridForNs extends Component {
  sectors = {};
  xterm = {};
  save = debounce(() => {
    this.props.saveFile();
  }, 400);
  processSave = debounce(async (state, relative_path) => {
    await this.props.processSavingFile({
      ...state,
      relative_path
    });
  }, 40);
  returnNode = null;
  viditingPoint = [];

  webOutput = null;
  webOutputMeta = { scroll: { scrollX: 0, scrollY: 0 }, queueForLoaded: [] };

  state = {
    status: VIDITING_STATUS.LOADING,
    time: 0,
    duration: 0,
    mouse: { x: 160, y: 100, isDown: false, numberForUpAnimation: 0 },
    teacherPause: false,
    firstWebOutputMode: webOutputModalModes.MINIMIZED,
    slideViewtime: 0,
    isSlideLock: false,
    loading: true,
    playbackRate: 1
  };

  constructor(props) {
    super(props);

    if (props.classType === "learning") {
      this.webOutputID = props.webOutputModal.webOutputID;

      props.newSocket();

      this.terminalWorker = new WebWorker(TerminalAck);
      this.terminalWorker.addEventListener(
        EVENT_MESSAGE,
        props.onMessageForTerminalTimer,
        false
      );

      this.player = new WebWorker(Viditing);
      this.player.addEventListener(EVENT_MESSAGE, this.onMessage, false);
    }

    Object.keys(SECTORS).forEach(target => {
      this.sectors[target] = { name: target, ref: createRef() };
    });
  }

  load = async startTimeStamp => {
    console.log("load:", startTimeStamp);

    const {
      setWebOutputModal,
      setWebConsole,
      webOutputModal,
      webConsole,
      setCurrentFile,
      setFiletree,
      setTabs,
      selectFile,
      timeline,
      editor,
      scrollElements,
      tabs,
      status,
      audioPlayer,
      selectFileSeek,
      saveFileSeek,
      applyFileFromServerSeek,
      elements,
      initialDirectory,
      serviceMode
    } = this.props;

    const { webAutoReload } = initialDirectory;

    const initTasks = {
      selectFile: [],
      setValue: [],
      setSelection: [],
      scrollTop: [],
      scrollLeft: [],
      setScrollPosition: [],
      writeTerm: [],
      setVisibilityWebOutput: [],
      setDimensionWebOutput: [],
      setSrcWebOutput: [],
      scrollWebOutput: [],
      scrollTerm: [],
      setVisibilityWebConsole: [],
      printToWebConsole: [],
      scrollWebConsole: []
    };

    const walkSelecting = (arr, file) => {
      arr.forEach(node => {
        if (node.file_type === "directory") {
          walkSelecting(node.children, file);
        } else if (
          node.relative_path &&
          node.relative_path === file.relative_path
        ) {
          initTasks.selectFile.push(node);
        }
      });
    };

    const treeWalk = (arr, file) => {
      arr.forEach(node => {
        if (node.file_type === "directory") {
          treeWalk(node.children, file);
        } else if (node.relative_path === file.path) {
          node.buffer = file.buffer;
          node.encoding = file.encoding;

          if (file.encoding !== "binary") {
            node.lastValue = node.value = file.value;
          }
        }
      });
    };

    let lastTimeline = [];

    if (typesForWeb.find(type => type.value === elements.terminalType)) {
      setWebOutputModal({
        mode: this.state.firstWebOutputMode,
        initializeUrl: true
      });
    }

    this.xterm.term.reset();

    this.player.postMessage({
      action: "setTime",
      time: startTimeStamp
    });

    this.props.setAudioPlayer({
      lastSeekTime: parseFloat(startTimeStamp),
      lastPlayTime: startTimeStamp,
      onEnd: false
    });

    this.props.setScriptTime({
      lastPlayTime: startTimeStamp
    });

    if (status === VIDITING_STATUS.SEEK) {
      let tmpValue = [];
      let tmpPushData = [];
      setWebConsole({ list: [] });

      let tmpTimeline = {
        pointerup: [],
        pointerdown: [],
        pointermove: [],
        toggledir: [],
        //output: [],
        //change: [],
        value: [],
        scroll: [],
        //selection: [],
        //close: [],
        mode: [],
        dimension: [],
        create: [],
        remove: [],
        //move: [],
        src: [],
        scrollTop: [],
        visibility: [],
        console: [],
        origin: []
      };

      let timelineKeys = Object.keys(tmpTimeline);

      timeline.reverse();

      timeline.map((item, index) => {
        if (item.timestamp <= startTimeStamp) {
          if (timelineKeys.includes(item.type)) {
            if (item.type === "value") {
              if (item.path && !tmpValue.includes(item.target + item.path)) {
                lastTimeline.push(item);
                tmpValue.push(item.target + item.path);
              }
              // } else if (!tmpPushData.includes(item.target + item.type)) {
              //   lastTimeline.push(item);
              //   tmpPushData.push(item.target + item.type);
              // }
            }

            // if (
            //   item.type === "mode" &&
            //   item.target === "WEB_OUTPUT" &&
            //   !tmpPushData.includes(item.target + item.type)
            // ) {
            //   if (item.value && item.timestamp >= startTimeStamp - 20000) {
            //     lastTimeline.push(item);
            //     tmpPushData.push(item.target + item.type);
            //   }
            // }

            if (
              item.type !== "value" &&
              item.target !== "WEB_OUTPUT" &&
              !tmpPushData.includes(item.target + item.type)
            ) {
              lastTimeline.push(item);
              tmpPushData.push(item.target + item.type);
            }
          } else {
            lastTimeline.push(item);
          }
        }
      });

      timeline.reverse();
      lastTimeline.reverse();

      for (const node of lastTimeline) {
        const key = node.type;
        switch (key) {
          case "output":
            this.xterm.term.write(node.payload);
            this.xterm.term.focus();
            //this.onResizeWindow();
            break;
          case "toggledir":
            if (audioPlayer.lastPlayTime < startTimeStamp) {
              if (
                node.timestamp < startTimeStamp &&
                node.timestamp >= audioPlayer.lastPlayTime
              ) {
                this.walkSeek(this.props.seekFiletree, key, {
                  relative_path: node.path,
                  toggled: node.expanded
                });

                if (this.returnNode) {
                  await this.props.refreshActivityOnFiletreeSeek(
                    this.returnNode
                  );
                }
              }
            } else {
              this.walkSeek(this.props.seekFiletree, key, {
                relative_path: node.path,
                toggled: node.expanded
              });

              if (this.returnNode) {
                await this.props.refreshActivityOnFiletreeSeek(this.returnNode);
              }
            }

            break;
          case "change":
            // if (audioPlayer.lastPlayTime < startTimeStamp) {
            //   if (
            //     node.timestamp < startTimeStamp &&
            //     node.timestamp >= audioPlayer.lastPlayTime
            //   ) {
            //     if (node.timestamp <= 0) {
            //       setCurrentFile(null);
            //     }
            //     await selectFileSeek({
            //       targetFile: node.file,
            //       withoutRecords: true
            //     });
            //   }
            // } else {
            //if (node.timestamp <= 0) {
            setCurrentFile(null);
            //}
            await selectFileSeek({
              ...node,
              targetFile: node.file,
              withoutRecords: true
            });
            //}

            break;
          case "value":
            const { currentFile } = this.props;

            if (audioPlayer.lastPlayTime < startTimeStamp) {
              if (
                node.timestamp < startTimeStamp &&
                node.timestamp >= audioPlayer.lastPlayTime
              ) {
                if (currentFile && currentFile.relative_path === node.path) {
                  setCurrentFile({ value: node.value });
                  //editor.ref.focus();
                  await saveFileSeek();
                } else {
                  await applyFileFromServerSeek({
                    ...node,
                    relative_path: node.path
                  });
                }
              }
            } else {
              if (currentFile && currentFile.relative_path === node.path) {
                setCurrentFile({ value: node.value });
                //editor.ref.focus();
                await saveFileSeek();
              } else {
                await applyFileFromServerSeek({
                  ...node,
                  relative_path: node.path
                });
              }
            }

            break;
          case "scroll":
            switch (node.target) {
              case this.sectors.FILETREE.name:
                scrollElements.filetree.scrollTop(node.scrollTop);
                break;
              case this.sectors.TABS.name:
                scrollElements.tabs.scrollLeft(node.scrollLeft);
                break;
              case this.sectors.EDITOR.name:
                editor.ref.setScrollPosition(node.detail);
                break;
              case this.sectors.WEB_OUTPUT.name:
                const { status, queueForLoaded } = this.webOutputMeta;
                const command = {
                  action: "scroll",
                  scrollX: node.scrollX,
                  scrollY: node.scrollY
                };

                let hrefState = null;
                let urlState = null;
                try {
                  if (node.href) {
                    if (node.href.indexOf("codelion.io") > -1) {
                      hrefState = node.href.substring(
                        node.href.indexOf("codelion.io") + 11
                      );
                    }
                  }

                  if (status.url) {
                    if (status.url.indexOf("codelion.io") > -1) {
                      urlState = status.url.substring(
                        status.url.indexOf("codelion.io") + 11
                      );
                    }
                  }

                  if (node.href === undefined || hrefState === urlState) {
                    this.postToWebOutput(command);
                    queueForLoaded.splice(0, queueForLoaded.length);
                  }
                } catch (e) {}

                break;
              default:
              //Nothing
            }

            break;
          case "selection":
            if (node.target === target_BIG_ADDRESS_INPUT) {
              try {
                webOutputModal.addressInputRef.current.focus();
                webOutputModal.addressInputRef.current.setSelectionRange(
                  node.start,
                  node.end
                );
              } catch (err) {}
            } else {
              editor.ref.setSelection(node.selection);
            }

            break;
          case "close":
            if (audioPlayer.lastPlayTime < startTimeStamp) {
              if (
                node.timestamp < startTimeStamp &&
                node.timestamp >= audioPlayer.lastPlayTime
              ) {
                this.walkSeek(this.props.seekFiletree, key, node.file);

                if (this.returnNode) {
                  await this.props.closeTabSeek(this.returnNode);
                }
              }
            } else {
              this.walkSeek(this.props.seekFiletree, key, node.file);

              if (this.returnNode) {
                await this.props.closeTabSeek(this.returnNode);
              }
            }

            break;
          case "mode":
            this.setWebOutputModalDebounceSet({ mode: node.value });
            this.setState({
              ...this.state,
              firstWebOutputMode: node.value
            });

            break;
          case "dimension":
            this.updateWebOutputDimension(node.sector, node.detail);

            break;
          case "create":
            if (audioPlayer.lastPlayTime < startTimeStamp) {
              if (
                node.timestamp < startTimeStamp &&
                node.timestamp >= audioPlayer.lastPlayTime
              ) {
                await this.props.addToFiletreeSeek({
                  file_type: node.file_type,
                  path: node.path
                });
              }
            } else {
              await this.props.addToFiletreeSeek({
                file_type: node.file_type,
                path: node.path
              });
            }
            break;
          case "remove":
            if (audioPlayer.lastPlayTime < startTimeStamp) {
              if (
                node.timestamp < startTimeStamp &&
                node.timestamp >= audioPlayer.lastPlayTime
              ) {
                await this.props.removeFromFiletreeSeek({
                  file_type: node.file_type,
                  path: node.path
                });
              }
            } else {
              await this.props.removeFromFiletreeSeek({
                file_type: node.file_type,
                path: node.path
              });
            }

            break;
          case "move":
            if (audioPlayer.lastPlayTime < startTimeStamp) {
              if (
                node.timestamp < startTimeStamp &&
                node.timestamp >= audioPlayer.lastPlayTime
              ) {
                await this.props.renameSeek({
                  file_type: node.file_type,
                  src_path: node.prev_path,
                  dest_path: node.path
                });
              }
            } else {
              await this.props.renameSeek({
                file_type: node.file_type,
                src_path: node.prev_path,
                dest_path: node.path
              });
            }

            break;
          case "origin":
            if (startTimeStamp === 0) {
              this.setWebOutputModalDebounceAddress(
                {
                  latestAddress: "/",
                  reload: true
                },
                100
              );
            }

            break;
          case "src":
            if (!!node.hasNotReloaded) {
              this.setWebOutputModalDebounceSet({ initializeUrl: true });
            }
            if (
              webOutputModal.mode !== webOutputModalModes.NONE &&
              webAutoReload
            ) {
              setTimeout(() => {
                this.setWebOutputModalDebounceAddress(
                  {
                    latestAddress: node.address,
                    reload: true
                  },
                  100
                );
              });
            } else {
              this.setWebOutputModalDebounceAddress({
                latestAddress: node.address
              });
            }

            break;
          case "scrollTop":
            this.xterm.term.viewport.viewportElement.scrollTo({
              top: node.payload
            });

            break;
          case "visibility":
            const target = {
              isShownBigAddressInput: node.visibility
            };

            if (
              !!node.visibility &&
              webOutputModal.latestAddress !== webOutputModal.addressInput
            ) {
              target.addressInput = webOutputModal.latestAddress;
            }
            this.setWebOutputModalDebounceAddress(target);

            break;

          case "console":
            const console = { list: [...webConsole.list] };

            switch (node.level) {
              case "visibility":
                initTasks.setVisibilityWebConsole.push(node.value);
                break;
              case "scroll":
                initTasks.scrollWebConsole.push({ x: node.x, y: node.y });
                break;
              case "clear":
                console.list = [];
              default:
                initTasks.printToWebConsole.push(node);
                break;
            }
            setTimeout(() => {
              setWebConsole(console);
            });

            break;
          default:
          //console.log("{ time, key, arr, action }");
          // Nothing
        }
      }

      lastTimeline = null;

      /*End*/

      setTimeout(async () => {
        tmpValue = null;
        tmpPushData = null;
        lastTimeline = null;

        console.log("this.props.seekTabs:", this.props.seekTabs);

        setFiletree(this.props.seekFiletree);
        setTabs(this.props.seekTabs);

        await this.props.requestSeekTerminal({
          onProgress: this.onProgress,
          startTimeStamp,
          progressRef: this.sectors.PROGRESS.ref
        });

        this.props.setSeekFiletree([]);
        this.props.setSeekTabs([]);

        if (
          typesForWeb.find(
            type => type.value === this.props.elements.terminalType
          ) &&
          webAutoReload
        ) {
          this.setWebOutputModalDebounceAddress({ reload: true });
        }
      }, 100);

      /*seekloadStart*/
      //await this.onProgress("release", startTimeStamp);
      /*seekloadEnd*/
    } else if (status !== "LOADING") {
      //마지막 재생 시점부터 시작!!
      if (
        !this.props.step.completed &&
        this.props.step.progressTimestamp >= 3000 &&
        false
      ) {
        this.props.setAudioPlayer({
          lastSeekTime: parseFloat(this.props.step.progressTimestamp)
        });

        this.props.setStatus(VIDITING_STATUS.BEFORE_LOADING);
        setTimeout(() => this.props.setStatus(VIDITING_STATUS.SEEK));
      } else {
        lastTimeline = timeline;

        this.props.setFiletree(this.props.initialDirectory.tree);
        this.props.setCurrentFile(null);
        this.props.setTabs([]);
        setWebOutputModal({ initializeUrl: true });

        try {
          for (const node of lastTimeline) {
            if (node.timestamp <= 0 && status !== VIDITING_STATUS.SEEK) {
              switch (node.target) {
                case "FILE":
                  walkSelecting(this.props.initialDirectory.tree, node.file);
                  break;
                case SECTORS.WEB_OUTPUT.name:
                  switch (node.type) {
                    case "mode":
                      initTasks.setVisibilityWebOutput.push(node.value);
                      break;
                    case "dimension":
                      initTasks.setDimensionWebOutput.push(node);
                      break;
                    case "src":
                      initTasks.setSrcWebOutput.push(node.address);
                      break;
                    case "scroll":
                      initTasks.scrollWebOutput.push(node);
                      break;
                    case "console":
                      switch (node.level) {
                        case "visibility":
                          initTasks.setVisibilityWebConsole.push(node.value);
                          break;
                        case "scroll":
                          initTasks.scrollWebConsole.push({
                            x: node.x,
                            y: node.y
                          });
                          break;
                        default:
                          initTasks.printToWebConsole.push(node);
                          break;
                      }
                      break;

                    default:
                    //Nothing
                  }
                  break;
                case SECTORS.EDITOR.name:
                  switch (node.type) {
                    case "selection":
                      initTasks.setSelection.push(node.selection);
                      break;
                    case "scroll":
                      initTasks.setScrollPosition.push(node.detail);
                      break;
                    default:
                    //Nothing
                  }
                  break;
                case SECTORS.FILETREE.name:
                  switch (node.type) {
                    case "scroll":
                      initTasks.scrollTop.push(node.scrollTop);
                      break;
                    default:
                    //Nothing
                  }
                  break;
                case SECTORS.TABS.name:
                  switch (node.type) {
                    case "scroll":
                      initTasks.scrollLeft.push(node.scrollLeft);
                      break;
                    default:
                    //Nothing
                  }
                  break;
                case SECTORS.TERMINAL.name:
                  switch (node.type) {
                    case "output":
                      initTasks.writeTerm.push(node.payload);
                      break;
                    case "scrollTop":
                      initTasks.scrollTerm.push(node.payload);
                      break;
                    default:
                    //Nothing
                  }
                  break;

                default:
                //Nothing
              }
            }

            //초기 비디팅포인트 설정
            if (node.target === "COMMAND" && node.type === "PAUSE") {
              this.viditingPoint.push(node);
            }
          }
          //console.log("viditingPoint:", this.viditingPoint);

          const consoleList = [];

          for (const key in initTasks) {
            switch (key) {
              case "selectFile":
                const len = initTasks[key].length;
                for (let i = 0; i < len; i++) {
                  if (i >= len - 1) {
                    //setBy({ filetree: true, tabs: true });
                  }
                  selectFile({
                    targetFile: initTasks[key][i],
                    withoutRecords: true
                  });
                }
                break;
              case "setValue":
                //const { currentFile } = this.props;

                for (const state of initTasks[key]) {
                  treeWalk(this.props.initialDirectory.tree, state);
                  setTabs(
                    tabs.map(t => {
                      if (t.relative_path === state.path) {
                        t.lastValue = t.value = state.value;
                      }
                      return t;
                    })
                  );
                }
                break;
              case "setSelection":
                for (const selection of initTasks[key]) {
                  await new Promise(resolve => {
                    setTimeout(() => {
                      editor.ref.setSelection(selection);
                      resolve();
                    }, 200);
                  });
                }
                break;
              case "scrollTop":
                for (const scrollTop of initTasks[key]) {
                  await new Promise(resolve => {
                    setTimeout(() => {
                      scrollElements.filetree.scrollTop(scrollTop);
                      resolve();
                    });
                  });
                }
                break;
              case "scrollLeft":
                for (const scrollLeft of initTasks[key]) {
                  await new Promise(resolve => {
                    setTimeout(() => {
                      scrollElements.tabs.scrollLeft(scrollLeft);
                      resolve();
                    });
                  });
                }
                break;
              case "setScrollPosition":
                for (const scrollPosition of initTasks[key]) {
                  await new Promise(resolve => {
                    setTimeout(() => {
                      // console.log(scrollPosition);
                      editor.ref.setScrollPosition(scrollPosition);
                      resolve();
                    }, 200);
                  });
                }
                break;
              case "writeTerm":
                for (const output of initTasks[key]) {
                  if (output) {
                    await new Promise(resolve => {
                      setTimeout(() => {
                        this.xterm.term.write(output);
                        this.onResizeWindow();
                        this.xterm.term.focus();
                        resolve();
                      });
                    });
                  }
                }
                break;
              case "setVisibilityWebOutput":
                for (const output of initTasks[key]) {
                  this.setWebOutputModalDebounceSet({
                    mode: output
                  });
                  this.setState({
                    ...this.state,
                    firstWebOutputMode: output
                  });
                }
                break;
              case "setDimensionWebOutput":
                for (const output of initTasks[key]) {
                  //setWebOutputModal(output);

                  this.updateWebOutputDimension(output.sector, output.detail);
                }
                break;
              case "setSrcWebOutput":
                for (const output of initTasks[key]) {
                  this.setWebOutputModalDebounceAddress({
                    latestAddress: output,
                    addressInput: output
                  });
                }
                break;
              case "scrollWebOutput":
                for (const scroll of initTasks[key]) {
                  setTimeout(() => {
                    // this.postToWebOutput({ action: scroll.type, ...scroll });
                    this.webOutputMeta.queueForLoaded.push({
                      action: scroll.type,
                      ...scroll
                    });
                  }, 0);
                }
                break;
              case "setVisibilityWebConsole":
                for (const shown of initTasks[key]) {
                  console.log(shown);
                  setWebConsole({ shown });
                }
                break;
              case "printToWebConsole":
                for (const item of initTasks[key]) {
                  consoleList.push(item);
                }
                break;
              case "scrollWebConsole":
                if (consoleList.length) {
                  setWebConsole({ list: [...consoleList] });
                }
                for (const scroll of initTasks[key]) {
                  setTimeout(() => {
                    setWebConsole({ scrollForPlayback: { ...scroll } });
                  });
                }
                break;
              case "scrollTerm":
                for (const top of initTasks[key]) {
                  setTimeout(() => {
                    this.xterm.term.viewport.viewportElement.scrollTo({ top });
                  });
                }
                break;
              default:
              //Nothing
            }
          }

          if (webAutoReload) {
            this.setWebOutputModalDebounceSet({ reload: true });
          }

          lastTimeline = null;

          console.log(
            "load-end:::!!",
            this.props.audioPlayer.duration,
            this.props.scriptTime.duration
          );

          const durationGap = Math.abs(
            parseInt(this.props.audioPlayer.duration) -
              parseInt(this.props.scriptTime.duration)
          );
          if (durationGap > 1500) {
            console.log("durationGap:", durationGap);
            if (REACT_APP_MODE === "production") {
              if (
                parseInt(this.props.audioPlayer.duration) <
                parseInt(this.props.scriptTime.duration)
              ) {
                this.props.setScriptTime({ durationGap: durationGap });
              }
              this.onIntervalStart();
              // toast.error(
              //   "오디오와 데이터(json) duration 차이가 발생하여 확인이 필요합니다!!\n오디오길이: " +
              //     parseInt(this.props.audioPlayer.duration) +
              //     "\nJSON길이: " +
              //     parseInt(this.props.scriptTime.duration),
              //   {
              //     autoClose: 500
              //   }
              // );
            } else {
              // window.alert(
              //   "오디오와 데이터(json) duration 차이가 발생하여 확인이 필요합니다!!"
              // );
              if (
                parseInt(this.props.audioPlayer.duration) >
                parseInt(this.props.scriptTime.duration)
              ) {
                this.props.setScriptTime({ durationGap: durationGap });
              }
              this.onIntervalStart();
              // toast.error(
              //   "오디오와 데이터(json) duration 차이가 발생하여 확인이 필요합니다!!\n오디오길이: " +
              //     parseInt(this.props.audioPlayer.duration) +
              //     "\nJSON길이: " +
              //     parseInt(this.props.scriptTime.duration)
              // );
            }
          } else {
            this.onIntervalStart();
          }
        } catch (message) {
          console.log(message);
          toast.error("message:::;" + message, TOAST_OPTION(2000));
        }
      }
    }

    //let tmpTimeline = Object.assign({}, timeline);
  };

  onIntervalStart = () => {
    let playInterval = setInterval(() => {
      if (
        this.props.audioPlayer.onReady &&
        this.props.status === VIDITING_STATUS.READY
      ) {
        clearInterval(playInterval);
        this.onPlayThrottle(VIDITING_STATUS.PLAYING);
        this.props.setStatus(VIDITING_STATUS.PLAYING);
      }
    }, 200);
  };

  onIntervalSeekStart = () => {
    let playInterval = setInterval(async () => {
      if (!this.props.changeBatchTerminal) {
        clearInterval(playInterval);
        if (
          this.props.audioPlayer.lastPlayTime <
          this.props.audioPlayer.lastSeekTime
        ) {
          this.props.setSeekFiletree(this.props.filetree);
          this.props.setSeekTabs(this.props.tabs);
        } else {
          this.props.setSeekFiletree(this.props.initialDirectory.tree);
          this.props.setSeekTabs([]);
        }
        await this.load(this.props.audioPlayer.lastSeekTime);
      }
    }, 200);
  };

  onProgress = async (action, progressTime = 0) => {
    //this.load(progressTime);
    switch (action) {
      case "release":
        this.player.postMessage({
          action: "release",
          time: progressTime
        });
        break;
      case "reset":
        this.player.postMessage({
          action: "reset"
        });
        break;
      case "setTime":
        break;
      default:
        break;
    }
  };

  serverProgressUpdate = ({ step, timestamp, finish = false }) => {
    // if (this.props.classType === "learning") {
    //   try {
    //     this.props.updateViditingStepComplete({
    //       step,
    //       time: timestamp,
    //       finish
    //     });
    //   } catch (e) {}
    // }
  };

  onMessage = async ({ data: { timeByInterval, time, key, arr, action } }) => {
    const newStates = [{}];

    if (key) {
      const {
        setWebOutputModal,
        webOutputModal,
        setWebConsole,
        webConsole,
        editor: { ref: editor },
        scrollElements,
        selectFile,
        addToFiletree,
        removeFromFiletree,
        rename
      } = this.props;

      switch (key) {
        case "pointer":
          for (const state of arr) {
            const { offsetLeft, offsetTop } = this.sectors[state.target];

            if (offsetLeft !== undefined) {
              let newState = newStates[newStates.length - 1];
              if (newState.mouse) {
                newState = {};
                newStates.push(newState);
              }
              newState.mouse = {
                ...this.state.mouse,
                x: offsetLeft + state.offsetX,
                y: offsetTop + state.offsetY,
                ...(state.type === "pointerdown" || state.type === "pointerup"
                  ? { isDown: state.type === "pointerdown" }
                  : {})
              };
            }
          }
          break;
        case "output":
          for (const state of arr) {
            this.xterm.term.write(state.payload);
            this.xterm.term.focus();
          }
          break;
        case "toggledir":
          for (const state of arr) {
            this.walk(this.props.filetree, key, {
              relative_path: state.path,
              toggled: state.expanded
            });
          }
          break;
        case "change":
          for (const state of arr) {
            selectFile({
              targetFile: state.file,
              withoutRecords: true
            });
          }
          break;
        case "value":
          for (const state of arr) {
            if (state.target === target_BIG_ADDRESS_INPUT) {
              this.setWebOutputModalDebounceAddress({
                addressInput: state.value
              });
            } else {
              const { currentFile } = this.props;

              if (currentFile && currentFile.relative_path === state.path) {
                this.props.setCurrentFile({ value: state.value });
                editor.focus();
                this.save();
              } else {
                await this.processSave(state, state.path);
              }
            }
          }
          break;
        case "scroll":
          for (const state of arr) {
            switch (state.target) {
              case this.sectors.FILETREE.name:
                scrollElements.filetree.scrollTop(state.scrollTop);
                break;
              case this.sectors.TABS.name:
                scrollElements.tabs.scrollLeft(state.scrollLeft);
                break;
              case this.sectors.EDITOR.name:
                editor.setScrollPosition(state.detail);
                break;
              case this.sectors.WEB_OUTPUT.name:
                const { status, queueForLoaded } = this.webOutputMeta;
                const command = {
                  action: "scroll",
                  scrollX: state.scrollX,
                  scrollY: state.scrollY
                };

                let hrefState = null;
                let urlState = null;

                if (state.href) {
                  if (state.href.indexOf("codelion.io") > -1) {
                    hrefState = state.href.substring(
                      state.href.indexOf("codelion.io") + 11
                    );
                  }
                }

                if (status.url) {
                  if (status.url.indexOf("codelion.io") > -1) {
                    urlState = status.url.substring(
                      status.url.indexOf("codelion.io") + 11
                    );
                  }
                }

                if (state.href === undefined || hrefState === urlState) {
                  this.postToWebOutput(command);
                  queueForLoaded.splice(0, queueForLoaded.length);
                }

                break;

              default:
              //Nothing
            }
          }
          break;
        case "selection":
          for (const state of arr) {
            if (state.target === target_BIG_ADDRESS_INPUT) {
              try {
                webOutputModal.addressInputRef.current.focus();
                webOutputModal.addressInputRef.current.setSelectionRange(
                  state.start,
                  state.end
                );
              } catch (err) {}
            } else {
              editor.setSelection(state.selection);
            }
          }
          break;
        case "close":
          for (const state of arr) {
            this.walk(this.props.filetree, key, state.file);
          }
          break;
        case "mode":
          for (const state of arr) {
            this.props.setWebOutputModal({ mode: state.value });
          }
          break;
        case "dimension":
          for (const state of arr) {
            //setWebOutputModal(state.detail);

            this.updateWebOutputDimension(state.sector, state.detail);
          }
          break;
        case "create":
          for (const state of arr) {
            addToFiletree({
              file_type: state.file_type,
              path: state.path
            });
          }
          break;
        case "remove":
          for (const state of arr) {
            removeFromFiletree({
              file_type: state.file_type,
              path: state.path
            });
          }
          break;
        case "move":
          for (const state of arr) {
            rename({
              file_type: state.file_type,
              src_path: state.prev_path,
              dest_path: state.path
            });
          }
          break;
        case "src":
          for (const state of arr) {
            //setWebOutputModal({ latestAddress: state.address, reload: true });
            if (!!state.hasNotReloaded) {
              this.setWebOutputModalDebounceSet({ initializeUrl: true });
              //setWebOutputModal({ initializeUrl: true });
            }
            if (webOutputModal.mode !== webOutputModalModes.NONE) {
              setTimeout(() => {
                this.setWebOutputModalDebounceAddress({
                  latestAddress: state.address,
                  reload: true
                });
              }, 100);
            } else {
              this.setWebOutputModalDebounceAddress({
                latestAddress: state.address
              });
            }
          }
          break;
        case "PAUSE":
          //if (this.props.serviceMode === "TEACHER") {
          try {
            this.setState({ ...this.state, teacherPause: true }, async () => {
              await this.props.changeServiceMode({ mode: "STUDENT" });
            });
          } catch (e) {
            console.log("pause:error:", e);
          }
          //}
          break;
        case "scrollTop":
          for (const state of arr) {
            this.xterm.term.viewport.viewportElement.scrollTo({
              top: state.payload
            });
          }
          break;
        case "visibility":
          for (const state of arr) {
            const target = {
              isShownBigAddressInput: state.visibility
            };

            if (
              !!state.visibility &&
              webOutputModal.latestAddress !== webOutputModal.addressInput
            ) {
              target.addressInput = webOutputModal.latestAddress;
            }
            setWebOutputModal(target);
          }
          break;
        case "console":
          const console = { list: [...webConsole.list] };

          for (const state of arr) {
            switch (state.level) {
              case "visibility":
                console.shown = state.value;
                break;
              case "scroll":
                console.scrollForPlayback = { x: state.x, y: state.y };
                break;
              case "clear":
                console.list = [];
                // setWebConsole({ list: [] });
                break;
              default:
                console.list.push(state);
                break;
            }
          }

          // if (console.list.length) {
          setTimeout(() => {
            setWebConsole(console);
          });
          // }
          break;
        default:
          console.log({ time, key, arr, action });
        // Nothing
      }
    }
    if (time) {
      newStates[0].time = time;

      if (this.props.serviceMode === "STUDENT") {
        this.player.postMessage({
          action: "paused"
        });
      } else {
        if (this.props.status === VIDITING_STATUS.PLAYING) {
          this.props.setScriptTime({
            lastPlayTime: parseFloat(time)
          });

          this.onUpdateStepSlide(time);

          this.onUpdateStepPlayTime(time);
        }
      }
    }
    if (action) {
      switch (action) {
        case "changeStatusToFinished":
          console.log("finished...:");
          newStates[0].status = VIDITING_STATUS.FINISHED;
          setTimeout(() => {
            this.props.setStatus(VIDITING_STATUS.FINISHED);
          });

          break;
        default:
        //Nothing
      }
    }

    if (Object.keys(newStates[0]).length) {
      for (const state of newStates) {
        await new Promise(resolve => {
          this.setState({ ...this.state, ...state }, resolve);
        });
      }
    }
  };

  async componentDidMount() {
    const {
      editorContainer,
      setWebOutputModal,
      setTerm,
      classType,
      elements,
      gridForFiletree,
      audioPlayer,
      setGridForFiletree,
      setAudioPlayer
    } = this.props;

    const { clientWidth, clientHeight } = editorContainer.ref.current;

    if (classType === "learning") {
      setAudioPlayer({
        playbackRate: audioPlayer.playbackRateMem
      });

      if (typesForWeb.find(type => type.value === elements.terminalType)) {
        setWebOutputModal({
          mode: webOutputModalModes.MINIMIZED,
          left: clientWidth - 400,
          top: clientHeight - 300,
          ref: document.querySelector(".class-container .rnd")
        });
      }

      setTimeout(() => {
        this.setupSlideDimension(1280, 720);
      }, 1000);

      this.offSetSetting(true);

      Terminal.loadAddon("fit");

      this.xterm.term = new Terminal({
        //rendererType: "dom"
        scrollback: 4096,
        fontFamily: `"DejaVu Sans Mono", "Everson Mono", FreeMono, Menlo, Terminal, monospace, "Apple Symbols"`,
        fontSize: 13,
        lineHeight: 1.15, //1.38,
        border: 0
      });

      this.xterm.term.open(this.sectors.TERMINAL.ref.current);
      setTerm(this.xterm.term);

      this.webOutput = document.getElementById(this.webOutputID);

      window.addEventListener(EVENT_RESIZE, this.onResizeWindow);
      this.onResizeWindow();

      window.addEventListener(
        EVENT_VISIBILITYCHANGE,
        this.handleVisibilityChange
      );
    }

    if (classType === "evaluation") {
      setGridForFiletree({
        colSize: 0
      });
    } else {
      setGridForFiletree({
        colSize:
          gridForFiletree.memorizedColSize > 0
            ? gridForFiletree.memorizedColSize
            : gridForFiletree.minSize
      });
    }
  }

  handleVisibilityChange = () => {
    if (document.hidden && this.props.serviceMode === "STUDENT") {
      this.props.historyCreate({
        historyMode: "UNACTIVATED",
        history: this.props.history
      });
    }
  };

  setWebOutputModalDebounceAddress = debounce((...rest) => {
    this.props.setWebOutputModal({ ...rest[0] });
  }, 50);

  setWebOutputModalDebounceSet = debounce((...rest) => {
    this.props.setWebOutputModal({ ...rest[0] });
  }, 20);

  componentWillUnmount() {
    const {
      closeSocket,
      onMessageForTerminalTimer,
      classType,
      setStatus,
      setResetBaseData,
      setSlide,
      setWebOutputModal,
      setWs
    } = this.props;

    if (classType === "learning") {
      setStatus(VIDITING_STATUS.PAUSED);

      this.serverProgressUpdate({
        step: this.props.step.id,
        timestamp: this.props.audioPlayer.lastPlayTime
      });

      if (this.props.serviceMode === "STUDENT") {
        this.props.historyCreate({
          historyMode: "UNACTIVATED",
          history: this.props.history
        });
      }
      setWebOutputModal({
        mode: webOutputModalModes.NONE,
        initializeUrl: true
      });
      setWs({ step: 0 });
      setResetBaseData();
      closeSocket();

      this.player.removeEventListener(EVENT_MESSAGE, this.onMessage);
      this.player.terminate();

      this.terminalWorker.removeEventListener(
        EVENT_MESSAGE,
        onMessageForTerminalTimer
      );
      this.terminalWorker.terminate();

      window.removeEventListener(
        EVENT_VISIBILITYCHANGE,
        this.handleVisibilityChange
      );

      window.removeEventListener(EVENT_RESIZE, this.onResizeWindow);

      setSlide({
        width: 300,
        height: 300,
        widthForNormal: 0,
        heightForNormal: 0,
        isVisible: false,
        isMinimized: true,
        widthForMini: 0,
        heightForMini: 0,
        currentSlideUrl: null
      });
    }

    if (classType === "evaluation") {
      setWs({ step: 0 });
      setResetBaseData();
      closeSocket();
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      webOutputModal: prevWebOutputModal,
      status: prevStatus
    } = prevProps;

    const {
      mouse: { isDown: prevIsDown }
    } = prevState;

    const {
      mouse: { isDown, numberForUpAnimation }
    } = this.state;

    const {
      ws,
      elements,
      webOutputModal,
      setWebOutputModal,
      classType,
      status,
      setStatus,
      timeline
    } = this.props;

    if (classType === "learning") {
      if (ws.storage && status === VIDITING_STATUS.INIT) {
        setStatus(VIDITING_STATUS.READY);

        if (timeline.length > 0 && prevStatus !== status) {
          //setTimeout(() => {
          this.player.postMessage({
            action: "init",
            timeline: timeline.slice(timeline.findIndex(el => el.timestamp > 0))
          });
          //}, 100);

          this.setState({
            ...this.state,
            duration: this.props.timeline[this.props.timeline.length - 1]
              .timestamp
          });

          this.props.setScriptTime({
            duration: this.props.timeline[this.props.timeline.length - 1]
              .timestamp
          });
          //setTimeout(async () => {
          await this.load(0);
          //}, 200);
        }

        console.log(
          "elements.terminalType:",
          elements.terminalType,
          !typesForWeb.find(type => type.value === elements.terminalType)
        );

        setWebOutputModal({
          mode: !typesForWeb.find(type => type.value === elements.terminalType)
            ? webOutputModalModes.NONE
            : this.state.firstWebOutputMode
        });
      } else if (status === "READY") {
        //if (this.props.audioPlayer.onReady) {

        // setStatus(VIDITING_STATUS.PLAYING);
        // await this.onPlayThrottle(VIDITING_STATUS.PLAYING);
        this.props.toggleBatchProcessing(false);
        //}
      } else {
        if (prevStatus !== status) {
          //console.log("this.props.ws:", this.props.ws);

          if (this.props.ws.storage) {
            if (
              prevStatus === VIDITING_STATUS.FINISHED &&
              status !== VIDITING_STATUS.SEEK &&
              status !== VIDITING_STATUS.PAUSED
            ) {
              this.props.setAudioPlayer({
                lastSeekTime: parseFloat(0),
                lastPlayTime: 0,
                onEnd: false
              });
              this.props.setScriptTime({
                lastPlayTime: parseFloat(0)
              });

              this.props.setStatus(VIDITING_STATUS.BEFORE_LOADING);
              setTimeout(() => this.props.setStatus(VIDITING_STATUS.SEEK));

              this.props.timeWorker.postMessage({ action: "set", time: 0 });

              this.props.timeWorker.postMessage({
                action: "release"
              });

              await this.props.finishReset();

              // this.player.postMessage({
              //   action: "reset"
              // });
              // await this.props.requestReloadTerminal();
              // await this.load(0);
              // this.sectors.PROGRESS.ref.current.seekTo(
              //   parseFloat(0),
              //   "seconds"
              // );
              // setStatus(VIDITING_STATUS.READY);
            } else if (!status) {
              //setStatus(VIDITING_STATUS.PLAYING);
            } else {
              await this.onPlayThrottle(status);
            }
          } else {
            // toast.error("서버 연결이 종료되어 페이지를 다시 로드합니다.", {
            //   autoClose: 1000
            // });
            //window.location.reload();
            return;
          }
          // }else{
          //   if (prevStatus === status && status===VIDITING_STATUS.PLAYING && this.props.audioPlayer.onEnd) {
          //     this.props.setAudioPlayer({onEnd :false});
          //     await this.onPlayThrottle(status)
          //   }
        }
      }
      if (!isDown && prevIsDown !== isDown) {
        this.setState({
          ...this.state,
          mouse: {
            ...this.state.mouse,
            numberForUpAnimation: numberForUpAnimation === 1 ? 2 : 1
          }
        });
      }

      if (
        prevWebOutputModal.mode !== webOutputModal.mode &&
        !!typesForWeb.find(t => t.value === elements.terminalType)
      ) {
        if (webOutputModal.mode === webOutputModalModes.GENERAL) {
          this.setWebOutputModalDebounceSet({
            top: webOutputModal.top,
            left: webOutputModal.left
          });
        }
      }
    }
  }

  onUpdateStepPlayTime = throttle(timestamp => {
    if (
      Math.abs(
        this.props.scriptTime.lastPlayTime - this.props.audioPlayer.lastPlayTime
      ) > 300 &&
      this.props.status === VIDITING_STATUS.PLAYING
    ) {
      // this.sectors.PROGRESS.ref.current.seekTo(
      //   parseFloat(this.props.scriptTime.lastPlayTime / 1000),
      //   "seconds"
      // );
      // this.props.setAudioPlayer({
      //   lastPlayTime: this.props.scriptTime.lastPlayTime
      // });

      this.player.postMessage({
        action: "setOnlyTime",
        time: this.props.audioPlayer.lastPlayTime
      });
      this.props.setScriptTime({
        lastPlayTime: this.props.audioPlayer.lastPlayTime
      });
    }
    this.serverProgressUpdate({
      step: this.props.step.id,
      timestamp: this.props.audioPlayer.lastPlayTime
    });
  }, 2000);

  onUpdateStepSlide = slideTime => {
    const {
      step: {
        slides: { edges: slideData }
      },
      setSlide,
      slide
    } = this.props;

    let slideImgUrl = null;
    slideData.some(({ node }) => {
      if (node.startTimestamp <= slideTime && node.endTimestamp >= slideTime) {
        try {
          slideImgUrl = node.img.url;
        } catch (e) {}
        return true;
      }
    });

    if (slideImgUrl) {
      if (!this.state.isSlideLock) {
        if (slideImgUrl !== slide.currentSlideUrl) {
          this.setState({
            ...this.state,
            slideViewtime: 0
          });
          setTimeout(() => {
            setSlide({
              currentSlideUrl: slideImgUrl,
              isVisible: true,
              isMinimized: false
            });
          }, 100);

          //slide open / visible
        } else {
          if (this.state.slideViewtime === 0) {
            setSlide({ isVisible: true, isMinimized: false });
            this.setState({
              ...this.state,
              slideViewtime: 1
            });
          }
        }
      } else {
        setTimeout(() => {
          setSlide({
            currentSlideUrl: slideImgUrl,
            isVisible: true,
            isMinimized: true
          });
        }, 100);
      }
    } else {
      //slide min/ hidden
      setSlide({ currentSlideUrl: "", isVisible: false, isMinimized: true });
    }
  };

  onPlayThrottle = throttle(async status => {
    switch (status) {
      case VIDITING_STATUS.PLAYING:
        await this.props.changeServiceMode({
          mode: "TEACHER",
          historyMode: historyModes.PLAY,
          history: this.props.history
        });
        this.setState({ ...this.state, teacherPause: false });
        if (this.props.audioPlayer.lastPlayTime > 1) {
          if (
            typesForWeb.find(
              type => type.value === this.props.elements.terminalType
            )
          ) {
            this.props.setWebOutputModal({
              mode: this.state.firstWebOutputMode
            });
          }
        }

        setTimeout(() => {
          this.player.postMessage({
            action: "release"
          });
          this.props.timeWorker.postMessage({
            action: "release"
          });
        });

        break;
      case VIDITING_STATUS.PAUSED:
        this.player.postMessage({
          action: "paused"
        });
        this.props.timeWorker.postMessage({
          action: "hold"
        });
        break;
      case VIDITING_STATUS.FINISHED:
        this.player.postMessage({
          action: "reset"
        });
        this.props.timeWorker.postMessage({
          action: "stop"
        });

        this.props.setAudioPlayer({
          lastSeekTime: parseFloat(0),
          lastPlayTime: 0,
          onEnd: false
        });
        this.props.setScriptTime({
          lastPlayTime: parseFloat(0)
        });

        break;
      case VIDITING_STATUS.SEEK:
        await this.props.changeServiceMode({
          mode: "TEACHER",
          historyMode: historyModes.PLAY,
          history: this.props.history
        });

        //await this.props.onProgress("reset", 0);

        this.onIntervalSeekStart();

        break;
      default:
        break;
    }
  }, 100);

  initPlaying = debounce((timeline, setStatus) => {
    if (timeline.length > 0) {
      this.player.postMessage({
        action: "init",
        timeline: timeline.slice(timeline.findIndex(el => el.timestamp > 0))
      });

      setTimeout(async () => await this.load(0), 200);
    }
  }, 200);

  offSetSetting = debounce(didMount => {
    const { gridForFiletree } = this.props;
    let leftOffsetGap = gridForFiletree.colSize - 160;
    if (leftOffsetGap < 0) {
      leftOffsetGap = 0;
    }
    //const { girdForFiletree } = this.props;
    Object.keys(this.sectors).forEach(key => {
      const sector = this.sectors[key];
      let el = sector.ref.current;

      if (el && el.offsetParent) {
        let { offsetTop, offsetLeft } = el;

        while (!el.offsetParent.classList.contains("viditing-boundary")) {
          el = el.offsetParent;

          offsetTop += el.offsetTop;
          offsetLeft += el.offsetLeft;
        }
        if (key === "FILETREE") {
          sector.offsetTop = offsetTop - 3;
          sector.offsetLeft = offsetLeft;
        } else {
          sector.offsetTop = offsetTop;
          sector.offsetLeft = offsetLeft;
        }
      }

      if (key === this.sectors.WEB_OUTPUT.name) {
        sector.offsetTop = 0;
        sector.offsetLeft = leftOffsetGap;
      }
      if (didMount === false) {
        this.xterm.term.fit();
      }
    });
  }, 100);

  updateWebOutputDimension = (sector, { top, left, ...detail }) => {
    setTimeout(() => {
      if (typeof this.sectors.WEB_OUTPUT.ref.updatePosition === "function") {
        this.sectors.WEB_OUTPUT.ref.updatePosition({
          y: top + 3,
          x: left
        });
      }
      this.props.setWebOutputModal({
        ...detail,
        top: top + 3,
        left: left
      });
    });
  };

  postToWebOutput = debounce(message => {
    const {
      ws: { webOutputOrigin },
      webOutputModal: { latestAddress }
    } = this.props;

    let url = `${webOutputOrigin}${latestAddress}`;

    try {
      setTimeout(() => {
        try {
          if (this.webOutput.contentWindow.postMessage) {
            if (
              this.webOutput.contentWindow.postMessage(message, "*") !==
              undefined
            ) {
              this.webOutput.contentWindow.postMessage(message, "*");
            } else {
              //this.webOutput.src = "";
              this.webOutput.src = url;
            }
          }
        } catch (e) {
          //this.webOutput.src = "";
          this.webOutput.src = url;
          console.log("postMessage:error:", message);
        }
      }, 200);
    } catch (e) {
      this.webOutput.src = "";
      this.webOutput.src = url;
      console.log("postMessage:error:", message);
    }
  }, 100);

  walk = (arr, type, file) => {
    if (Array.isArray(arr)) {
      arr.forEach(node => {
        if (node.file_type === "directory") {
          // tape.append(node.relative_path.substring(1));
          this.walk(node.children, type, file);

          if (
            file.relative_path === node.relative_path &&
            type === "toggledir"
          ) {
            node.toggled = file.toggled;
            this.props.refreshActivityOnFiletree(node);
          }
        } else {
          if (file.relative_path === node.relative_path && type === "close") {
            this.props.closeTab(node);
          }
        }
      });
    }
  };

  walkSeek = (arr, type, file) => {
    if (Array.isArray(arr)) {
      arr.forEach(node => {
        if (node.file_type === "directory") {
          // tape.append(node.relative_path.substring(1));
          this.walkSeek(node.children, type, file);

          if (
            file.relative_path === node.relative_path &&
            type === "toggledir"
          ) {
            node.toggled = file.toggled;
            this.returnNode = node; //this.props.refreshActivityOnFiletreeSeek(node);
          }
        } else {
          if (file.relative_path === node.relative_path && type === "close") {
            this.returnNode = node; //this.props.closeTabSeek(node);
          }
        }
      });
    }
  };

  onResizeWindow = debounce(() => {
    try {
      this.props.onResizeGridRow();
      this.xterm.term.fit();
    } catch (err) {
      console.log("err:", err);
    }
  }, 100);

  setupSlideDimension = (width, height) => {
    const { setSlide, refreshSlideDimension } = this.props;
    let widthForMini = width;
    let heightForMini = height;

    if (width > height) {
      widthForMini = slideConsts.maxWidth;
      heightForMini = (height * slideConsts.maxWidth) / width;
    } else {
      heightForMini = slideConsts.maxWidth;
      widthForMini = (width * slideConsts.maxWidth) / height;
    }

    setSlide({ width, height, widthForMini, heightForMini });
    refreshSlideDimension();
  };

  slideLeftMargin = () => {
    const { clientWidth: width = 0 } = root.document.body || {};

    return width - 350 - this.props.gridForLeftTab.colSize;
  };

  render() {
    const {
      gridForNS,
      classType,
      slide,
      setGridForNS,
      status,
      gtmPushDataLayer
    } = this.props;

    const { mouse } = this.state;
    return (
      <>
        {classType === "learning" &&
          (status === VIDITING_STATUS.LOADING ||
            status === VIDITING_STATUS.INIT) && <Loading />}
        {classType === "learning" &&
          (status === VIDITING_STATUS.BEFORE_LOADING ||
            status === VIDITING_STATUS.SEEK) && <LoadingSeeking />}
        <Split
          minSize={gridForNS.minSize}
          gridTemplateRows={
            classType !== "evaluation"
              ? gridForNS.gridTemplateRows
              : gridForNS.gridTemplateQuizRows
          }
          onDrag={(direction, track, style) => {
            console.log("gridForNS:", style);
            setGridForNS({ gridTemplateRows: style });
            //onResizeGridRow();
            this.onResizeWindow();
            this.offSetSetting(false);
          }}
          render={({ getGridProps, getGutterProps }) => {
            return (
              <div
                {...getGridProps()}
                className="d-grid h-100 drag-boundary overflow-hidden"
              >
                <div
                  className="d-flex flex-column position-relative"
                  style={
                    classType === "evaluation" ? { paddingBottom: "48px" } : {}
                  }
                >
                  <GridForFiletree
                    sectors={this.sectors}
                    offSetSetting={this.offSetSetting}
                  />
                </div>
                <div
                  {...getGutterProps("row", 1)}
                  className={`gutter gutter-row`}
                />
                <div className="d-grid">
                  <GridForConsoles
                    sectors={this.sectors}
                    onResizeGridRow={this.props.onResizeGridRow}
                  />

                  <Progress
                    sectors={this.sectors}
                    onProgress={this.onPlayThrottle}
                    seekLoad={this.load}
                    viditingPlayer={this.player}
                    teacherPause={this.state.teacherPause}
                    viditingPoint={this.viditingPoint}
                    handleProgressAudioEnded={
                      this.props.handleProgressAudioEnded
                    }
                  />
                </div>
                {classType === "learning" && (
                  <div
                    className={`mouse d-inline-block position-absolute on-anim-${mouse.numberForUpAnimation}`}
                    style={{
                      top: mouse.y ? mouse.y : 0,
                      left: mouse.x ? mouse.x : 0,
                      transition: "all 0.2s"
                    }}
                  >
                    <FontAwesomeIcon icon={faMousePointer} />
                  </div>
                )}

                {classType === "learning" && (
                  <WebOutput
                    sectors={this.sectors}
                    updateStatus={(status2, address) => {
                      const { queueForLoaded } = this.webOutputMeta;
                      this.webOutputMeta.status = { current: status2, address };

                      if (status2 === "LOADED" && queueForLoaded.length) {
                        const command = queueForLoaded[0];
                        if (
                          command.action === "scroll" &&
                          (command.address === undefined ||
                            command.address === status2.address)
                        ) {
                          this.postToWebOutput({ ...command });
                          queueForLoaded.splice(0, queueForLoaded.length);
                        }
                      }
                    }}
                    iframeTarget={this.webOutput}
                  />
                )}
              </div>
            );
          }}
        />

        {classType === "learning" &&
          status !== VIDITING_STATUS.LOADING &&
          status !== VIDITING_STATUS.INIT &&
          status !== VIDITING_STATUS.BEFORE_LOADING &&
          status !== VIDITING_STATUS.SEEK && (
            <div
              className={`slide-container ${
                slide.isMinimized ? " minimized" : ""
              } ${slide.isVisible ? "d-block" : "d-none"}`}
              onClick={e => {
                e.stopPropagation();
                if (e.target === e.currentTarget) {
                  if (this.props.status === VIDITING_STATUS.PLAYING) {
                    this.props.setStatus(VIDITING_STATUS.PAUSED);
                  } else {
                    this.props.setStatus(VIDITING_STATUS.PLAYING);
                  }
                } else {
                  this.props.setSlide({ isMinimized: true });
                }

                // if (e.target === e.currentTarget) {
                //   if (serviceMode === "TEACHER") {
                //     changeServiceMode({ mode: "STUDENT" });
                //   }
                //   this.props.setSlide({ isMinimized: true });
                // }
              }}
            >
              <div
                className={`slide position-absolute`}
                style={{
                  zIndex: 21,
                  width: slide.widthForNormal,
                  height: slide.heightForNormal,
                  ...(slide.isMinimized
                    ? {
                        width: slide.widthForMini,
                        height: slide.heightForMini,
                        top: "auto",
                        bottom: 50,
                        left: `${this.slideLeftMargin()}px`,
                        marginBottom:
                          slide.heightForMini * slideConsts.ratio * 0.5 +
                          slideConsts.padY,
                        marginLeft:
                          slide.widthForMini * slideConsts.ratio * 0.5 +
                          slideConsts.padX,
                        transform: `translate(-50%, 50%) scale(${slideConsts.ratio})`
                      }
                    : {})
                }}
                onClick={e => {
                  e.stopPropagation();
                  if (slide.isMinimized) {
                    this.setState({ ...this.state, isSlideLock: false }, () => {
                      this.props.setSlide({ isMinimized: false });
                    });
                  }
                }}
              >
                <img
                  src={slide.currentSlideUrl}
                  style={{ width: "100%", height: "100%" }}
                  alt={""}
                />
                {!slide.isMinimized && (
                  <Button
                    variant="link"
                    className="close position-absolute border-0"
                    style={{ color: "#00ffdd" }}
                    onClick={e => {
                      e.stopPropagation();
                      if (!slide.isMinimized) {
                        this.setState(
                          { ...this.state, isSlideLock: true },
                          () => {
                            this.props.setSlide({ isMinimized: true });
                          }
                        );
                      }
                      //this.setupSlideDimension(slide.height, slide.width);

                      gtmPushDataLayer({
                        event: "ideSlideCancelClick"
                      });
                    }}
                  >
                    <IconSlideClose style={{ color: "#00ffdd" }} />
                  </Button>
                )}
              </div>
            </div>
          )}
      </>
    );
  }
}

const mapState = ({
  common: { ws },
  ide: {
    leftTab,
    gridForFiletree,
    gridForLeftTab,
    gridForNS,
    classType,
    editorContainer,
    webOutputModal,
    slide,
    step,
    webConsole
  },
  viditing: {
    status,
    currentFile,
    filetree,
    elements,
    isBatchProcessing,
    timeline,
    initialDirectory,
    editor,
    scrollElements,
    tabs,
    audioPlayer,
    seekFiletree,
    seekTabs,
    serviceMode,
    scriptTime
  }
}) => ({
  leftTab,
  gridForFiletree,
  gridForLeftTab,
  gridForNS,
  classType,
  editorContainer,
  webOutputModal,
  slide,
  ws,
  step,
  webConsole,

  // from viditing
  status,
  currentFile,
  filetree,
  elements,
  isBatchProcessing,
  timeline,
  initialDirectory,
  editor,
  scrollElements,
  tabs,
  audioPlayer,
  seekFiletree,
  seekTabs,
  serviceMode,
  scriptTime
});

const mapDispatch = ({
  common: {
    getTypenameByBackNode,
    newSocket,
    requestSocket,
    closeSocket,
    setWs
  },

  ide: {
    setGridForNS,
    setGridForFiletree,
    setWebOutputModal,
    setSlide,
    refreshSlideDimension,
    toggleModalStepIsCompleted,
    setLoadingModal,
    setWebConsole
  },
  viditing: {
    setStatus,
    setTerm,
    setFiletree,
    setCurrentFile,
    processSavingFile,
    setTabs,
    setBy,
    selectFile,
    selectFileSeek,
    terminalActWorker,
    setTerminalWorker,
    closeTerminalWorker,
    closeTab,
    onMessageForTerminalTimer,
    initTerminal,
    toggleBatchProcessing,
    refreshActivityOnFiletree,
    refreshActivityOnFiletreeSeek,
    saveFile,
    saveFileSeek,
    setSeekFiletree,
    setSeekTabs,
    applyFileFromServerSeek,
    addToFiletreeSeek,
    removeFromFiletreeSeek,
    renameSeek,
    addToFiletree,
    removeFromFiletree,
    rename,
    closeTabSeek,
    requestReloadTerminal,
    requestSeekTerminal,
    setAudioPlayer,
    updateViditingStepComplete,
    changeServiceMode,
    historyCreate,
    setResetBaseData,
    setScriptTime
  },
  gtm: { gtmPushDataLayer }
}) => ({
  // from common
  getTypenameByBackNode,
  newSocket,
  requestSocket,
  closeSocket,
  setWs,

  setGridForNS,
  setGridForFiletree,
  setWebOutputModal,
  setSlide,
  refreshSlideDimension,
  toggleModalStepIsCompleted,
  setLoadingModal,
  setWebConsole,

  // from viditing
  setStatus,
  setTerm,
  setFiletree,
  setCurrentFile,
  processSavingFile,
  setTabs,
  setBy,
  selectFile,
  selectFileSeek,
  terminalActWorker,
  setTerminalWorker,
  closeTerminalWorker,
  closeTab,
  onMessageForTerminalTimer,
  initTerminal,
  toggleBatchProcessing,
  refreshActivityOnFiletree,
  refreshActivityOnFiletreeSeek,
  saveFile,
  saveFileSeek,
  setSeekFiletree,
  setSeekTabs,
  applyFileFromServerSeek,
  addToFiletreeSeek,
  removeFromFiletreeSeek,
  renameSeek,
  addToFiletree,
  removeFromFiletree,
  rename,
  closeTabSeek,
  requestReloadTerminal,
  requestSeekTerminal,
  setAudioPlayer,
  updateViditingStepComplete,
  changeServiceMode,
  historyCreate,
  setResetBaseData,
  setScriptTime,
  gtmPushDataLayer
});

export default connect(mapState, mapDispatch)(GridForNs);
