import React, { Component } from "react";
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://0d694f385fda424891a5529fc32ee541@sentry.io/1326532",
  environment: process.env.NODE_ENV
});
// should have been called before using it here
// ideally before even rendering your react app

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <button
          onClick={() =>
            Sentry.showReportDialog({ eventId: this.state.eventId })
          }
        >
          Report feedback
        </button>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}
