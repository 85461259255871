import store from "../store";

export default {
  state: {
    name: "",
    elements: { terminalType: "quiz" },
    status: null,
    initialDirectory: { path: null, blob: null, tree: [] },
    isBatchProcessing: false,

    currentFile: null,

    editor: {},
    loading: { file: false },
    hint: { failCount: 0, softHint: null, hardHint: null, solution: null }
  },

  reducers: {
    setElements(state, payload = { terminalType: "quiz" }) {
      return { ...state, elements: payload };
    },
    setStatus(state, payload) {
      return {
        ...state,
        status: payload
      };
    },
    setInitialDirectory(state, payload) {
      return {
        ...state,
        initialDirectory: payload
      };
    },
    setEditor(state, payload) {
      return {
        ...state,
        editor: { ...state.editor, ...payload }
      };
    },
    setCurrentFile(state, payload) {
      return {
        ...state,
        currentFile: { ...state.currentFile, ...payload }
      };
    },
    setHint(state, payload) {
      return {
        ...state,
        hint: { ...state.hint, ...payload }
      };
    }
  },
  effects: dispatch => ({
    navigateSubQuiz(payload, rootState) {
      const state = store.getState();
      const quizState = state.ide.quiz;
      // console.log("navigateSubQuiz State::", state.ide.quiz);
      // console.log("navigateSubQuiz payload::", payload);
      // newSocket(); //connect new socket
      // setWs({ isLoading: true });

      if (Object.keys(quizState.subQuizzes)) {
        //서브퀴즈 목록에서 선택 or 서브퀴즈 완료후 뱃지 클릭시
        dispatch.ide.setQuiz({
          inProgressSubQuiz: payload,
          lastOnGoingSubQuiz: !quizState.subQuizzes[payload].completed
            ? payload
            : quizState.lastOnGoingSubQuiz,
          isSubQuizComplete: false,
          softHint: quizState.subQuizzes[payload].softHint,
          tryCount: quizState.subQuizzes[payload].failCount,
          hardHintShown: false,
          solution: {
            isShown: false
          }
        });
        dispatch.ide.setEditor({
          value: quizState.subQuizzes[payload].questionSource
        });

        if (quizState.subQuizzes[payload].failCount >= 2) {
          dispatch.ide.toggleQuizSimpleHint({
            content: quizState.subQuizzes[payload].softHint,
            isShown: true
          });
        } else {
          dispatch.ide.toggleQuizSimpleHint({
            isShown: false
          });
        }
      }
    }
  })
};
