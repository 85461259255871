import React, { Component, lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import Loading from "../common/Loading";
import NoMatch from "../common/NoMatch";
import ResetPassword from "./ResetPassword";

const SignIn = lazy(() => import("./SignIn"));

class Container extends Component {
  render() {
    const { match } = this.props;
    return (
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route
            path={`${match.url}/signin`}
            render={props => <SignIn {...props} />}
          />
          <Route
            path={`${match.url}/reset-password`}
            component={ResetPassword}
          />

          <Route component={NoMatch} />
        </Switch>
      </Suspense>
    );
  }
}

export default Container;
