export default function TimerForStep(args) {
  // let onmessage = e => {
  //   // eslint-disable-line no-unused-vars
  //   // Write your code here...
  //   console.log("Web Worker called by PARENT THREAD...", e.data);
  //   postMessage("Response");
  // };
  this.addEventListener("message", ({ data: { action, time } }) => {
    const params = { action };

    switch (action) {
      case "set":
        this.time = time;
        params.initialized = true;
        break;
      case "stop":
        this.paused = true;
        this.time = 0;
        break;
      case "hold":
      case "release":
        this.paused = action === "hold";
        break;
      default:
      // Nothing
    }

    if (action !== "stop" && action !== "hold") {
      params.time = this.time;
      postMessage(params);
    }
  });

  this.time = 0;
  this.paused = true;
  // setInterval(() => {
  //   if (!this.paused) {
  //     this.time += 10;
  //   }
  // }, 10);
  setInterval(() => {
    if (!this.paused) {
      this.time = this.time + 1000;
      postMessage({ time: this.time });
    }
  }, 1000);
}

// self.addEventListener("message", event => {
//   console.log("Web Worker called by PARENT THREAD...", event.data);
// });
