import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

import { ReactComponent as Logo } from "../../images/img-logo-wh-small.svg";
import { ReactComponent as IconError } from "../../images/img-errorcode.svg";

const NoMatch = () => (
  <Container className="d-flex h-100 flex-column justify-content-center">
    <div className="no-match-container">
      <Link className="d-block" to="/">
        <Logo />
      </Link>

      <h1 className="position-relative">
        <IconError className="position-absolute" />
        404 Not Found
      </h1>
      <p>Oops! Looks like this page doesn’t exist.</p>
    </div>
  </Container>
);
export default NoMatch;
