import React, { Component } from "react";
import { Button } from "react-bootstrap";

import { leftTabs, classTypes } from "../../common";
import { connect } from "react-redux";

import { isMobile, isTablet } from "react-device-detect";

class LeftTabs extends Component {
  componentDidMount() {
    this.props.gtmPushDataLayer({
      event: "ViewStep",
      userHashid: this.props.authBasic.hashid,
      userNickName: this.props.authBasic.nickname,
      stepId: this.props.step.id
    });

    const { leftTab, classType, setupLeftTab } = this.props;
    if (leftTab === null && classType && classType === "evaluation") {
      setTimeout(() => {
        setupLeftTab("quiz");
      }, 500);
    }
  }

  render() {
    const {
      leftTab,
      classType,
      setupLeftTab,
      controlContactUs,
      setSlide,
      gtmPushDataLayer
    } = this.props;

    return (
      <>
        <div className="left-tabs d-flex flex-column">
          <ul className="list-unstyled mb-0">
            {Object.keys(leftTabs)
              .filter(tab => classTypes[classType].leftTabs.includes(tab))
              .map((key, i) => {
                let hoverTitle = "";
                let hotjarEventName = "";
                switch (key) {
                  case "chapter":
                    hoverTitle = "강의목차";
                    hotjarEventName = "ideTapbarCourseIndexClick";
                    break;
                  case "references":
                    hoverTitle = "자료실";
                    hotjarEventName = "ideTapbarReferenceClick";
                    break;
                  case "change-history":
                    hoverTitle = "버전 히스토리";
                    hotjarEventName = "ideTapbarVersionHistoryClick";
                    break;
                  case "q-and-a":
                    hoverTitle = "질문답변";
                    hotjarEventName = "ideTapbarQnABoardClick";
                    break;
                  case "quiz":
                    hoverTitle = "퀴즈";
                    break;
                  default:
                    break;
                }

                return isTablet && key === "q-and-a" ? (
                  ""
                ) : (
                  <li key={i}>
                    <Button
                      variant="link"
                      className={`border-0 ${key}${
                        leftTab === key ? " on" : ""
                      }`}
                      onClick={e => {
                        setupLeftTab(key);
                        if (classType === "learning") {
                          setSlide({ isMinimized: true });
                        }
                        if (!isMobile && this.props.editor.ref) {
                          try {
                            setTimeout(() => {
                              this.props.editor.ref.layout();
                            }, 150);
                          } catch (e) {}
                        }

                        hotjarEventName &&
                          gtmPushDataLayer({
                            event: hotjarEventName
                          });
                      }}
                      title={hoverTitle}
                    />
                  </li>
                );
              })}
          </ul>
          <div className="flex-grow-1"></div>
          <div className="mt-auto">
            <ul className="list-unstyled mb-0">
              <li key={9} className="contactUs-btn">
                <Button
                  variant="link"
                  className={`border-0 contactUs`}
                  onClick={() => {
                    controlContactUs();
                    gtmPushDataLayer({
                      event: "ideTapbarInquiryClick"
                    });
                  }}
                  title={`문의하기`}
                />
              </li>
              <li key={10}>
                <Button
                  variant="link"
                  className={`border-0 manual ide-manual-btn`}
                  href={`https://drive.google.com/file/d/1a_xqbJf9-BClNnhRYFA9J8Y9-LtLPN_m/view`}
                  target="_blank"
                  as="a"
                  title={`매뉴얼 보기`}
                  onClick={() => {
                    gtmPushDataLayer({
                      event: "ideTapbarCodelionManualClick"
                    });
                  }}
                />
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

const mapState = ({
  auth: { authBasic },
  ide: { leftTab, editor, classType, mobileChk, step }
}) => ({
  authBasic,
  leftTab,
  editor,
  classType,
  mobileChk,
  step
});

const mapDispatch = ({
  gtm: { gtmPushDataLayer },
  ide: { setupLeftTab, setSlide }
}) => ({
  gtmPushDataLayer,
  setupLeftTab,
  setSlide
});

export default connect(mapState, mapDispatch)(LeftTabs);
