import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Button, Container, Form } from "react-bootstrap";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { SEND_PASSWORD_RESET_EMAIL } from "../../graphql/mutations";
import { TOAST_OPTION } from "../../constants";

class ResetPassword extends Component {
  state = {
    focus: { username: false }
  };

  toggleFocus = ({ type, target: { name } }) => {
    this.setState({
      ...this.state,
      focus: { ...this.state.focus, [name]: type === "focus" }
    });
  };

  render() {
    const { i18n, client, history, gtmPushDataLayer } = this.props; //eslint-disable-line

    return (
      <Container className="auth-container reset-password d-flex flex-column align-items-center">
        <h2>비밀번호 찾기</h2>
        <Formik
          initialValues={{
            username: ""
          }}
          validate={({ username }) => {
            let errors = {};

            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(username)) {
              errors.username = "이메일 형식을 확인해주세요.";
            }
            return errors;
          }}
          onSubmit={async ({ username }, { setSubmitting }) => {
            gtmPushDataLayer({
              event: "findPWTemporaryPWSendClick"
            });

            try {
              const {
                data: {
                  sendPasswordResetEmail: { ok }
                }
              } = await client.mutate({
                mutation: SEND_PASSWORD_RESET_EMAIL,
                variables: {
                  email: username,
                  // locale: i18n.language.split("-")[0].toUpperCase()
                  locale: "KO"
                }
              });

              if (ok) {
                toast.success(
                  "임시 비밀번호가 메일로 발송되었습니다. 메일함을 확인해주세요!",
                  TOAST_OPTION(2000)
                );
                history.replace("/auth/signin");
              }
            } catch ({ graphQLErrors }) {
              graphQLErrors.forEach(err => {
                toast.error(err.message, TOAST_OPTION(2000));
              });

              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting
            /* and other goodies */
          }) => {
            const isInvalid = {
              username: errors.username && touched.username
            };
            return (
              <Form
                noValidate
                onSubmit={handleSubmit}
                className="w-100 d-flex flex-column"
              >
                <Form.Group bsPrefix="form-group mb-0">
                  <div
                    className={`guide position-relative${
                      isInvalid.username ? " is-invalid" : ""
                    }`}
                  >
                    {isInvalid.username ? errors.username : null}
                  </div>
                  <Form.Control
                    type="email"
                    name="username"
                    bsPrefix="form-control mb-5"
                    autoComplete="username"
                    onChange={handleChange}
                    onFocus={this.toggleFocus}
                    onBlur={e => {
                      this.toggleFocus(e);
                      handleBlur(e);
                    }}
                    value={values.username}
                    placeholder="이메일 주소를 입력해주세요"
                    isInvalid={isInvalid.username}
                  />
                </Form.Group>

                <Button
                  type="submit"
                  className="lion"
                  disabled={!isValid || isSubmitting}
                >
                  임시 비밀번호 보내기
                </Button>

                <Link
                  to="/signup"
                  className="btn underline align-self-center"
                  onClick={() => {
                    gtmPushDataLayer({ event: "findPWGoSignUpClick" });
                  }}
                >
                  아직 코드라이언 회원이 아니신가요?{" "}
                  <strong>회원가입하기</strong>
                </Link>
              </Form>
            );
          }}
        </Formik>
      </Container>
    );
  }
}

const mapState = ({
  common: {
    apollo: {
      client: { general: client }
    }
  }
}) => ({
  client
});

const mapDispatch = ({ gtm: { gtmPushDataLayer } }) => ({
  gtmPushDataLayer
});

export default withTranslation()(
  withRouter(connect(mapState, mapDispatch)(ResetPassword))
);
