// import * as React from "react";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

import { getApolloContext, getAccessToken } from "../../common";

import PersonalDropdown from "./PersonalDropdown";
import { CATALOG_GROUP } from "../../graphql/queries";
import imgLogo from "../../images/img-logo-horizontal-wh-01.svg";

const GNB = ({
  location: { pathname },
  isAuthenticated,
  client,
  mixpanelCourse,
  setMixpanelCourse,
  gtmPushDataLayer
}) => {
  const getCurrentToken = getAccessToken();
  // const [courseObj, setCourseObj] = useState();
  // const { loading } = useQuery(CATALOG_GROUP(isAuthenticated), {
  //   context: getApolloContext(),
  //   fetchPolicy: "network-only",
  //   client: client,
  //   skip: mixpanelCourse.length,
  //   onCompleted: res => {
  //     if (!loading && res && res.allCourses && res.allCourses.edges.length) {
  //       setMixpanelCourse(res.allCourses.edges);
  //     }
  //   },
  //   onError: err => {
  //     console.error(err);
  //   }
  // });

  // useEffect(() => {
  //   try {
  //     if (pathname && mixpanelCourse.length) {
  //       const getExactCourse = mixpanelCourse.find(({ node }) => {
  //         return pathname.indexOf(node.id) !== -1;
  //       });
  //       if (getExactCourse) {
  //         setCourseObj({
  //           courseName: getExactCourse.node.name,
  //           courseId: getExactCourse.node.id
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, [mixpanelCourse, pathname]);

  return (
    <Navbar className="gnb-container justify-content-between p-0 fixed-top">
      <Container className="px-0 h-100">
        <Navbar.Brand as={Link} to="/">
          <img
            src={imgLogo}
            alt=""
            className="d-block"
            onClick={() => {
              gtmPushDataLayer({
                event: "headerLogoClick"
              });
            }}
          />
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav.Link
            as={Link}
            to="/catalog"
            onClick={() => {
              gtmPushDataLayer({
                event: "headerCatalogClick"
              });
            }}
          >
            카탈로그
          </Nav.Link>
          {/*<Nav.Link href="#">Pricing</Nav.Link>*/}
          {/*<Nav.Link href="#">Community</Nav.Link>*/}
        </Navbar.Collapse>
        <Nav className="m-0 align-items-center">
          {isAuthenticated ? (
            <>
              <PersonalDropdown />
              {/* <div className="vertical-line" />
              <LocaleDropdown /> */}
            </>
          ) : (
            <>
              {pathname !== "/auth/signin" && !getCurrentToken && (
                <Link
                  to={{
                    pathname: "/auth/signin",
                    state: { from: pathname }
                  }}
                  onClick={() => {
                    gtmPushDataLayer({
                      event: "headerLoginClick"
                    });
                  }}
                >
                  로그인
                </Link>
              )}
              {pathname !== "/signup" && !getCurrentToken && (
                <Button
                  as={Link}
                  to="/signup"
                  variant="link"
                  className="signup ml-4"
                  onClick={() => {
                    gtmPushDataLayer({
                      event: "headerSignupClick"
                    });
                  }}
                >
                  회원가입
                </Button>
              )}
            </>
          )}
        </Nav>
      </Container>
    </Navbar>
  );
};

const mapState = ({
  auth: { isAuthenticated },
  common: {
    mixpanelCourse,
    apollo: {
      client: { general: client }
    }
  }
}) => ({ isAuthenticated, mixpanelCourse, client });

const mapDispatch = ({
  common: { setMixpanelCourse },
  gtm: { gtmPushDataLayer }
}) => ({
  setMixpanelCourse,
  gtmPushDataLayer
});

export default withRouter(connect(mapState, mapDispatch)(GNB));
