import React from "react";
import ReactLoading from "react-loading";

const Loading = ({ isNotFixed }) => {
  let dimension = "position-fixed vw-100 vh-100";

  if (isNotFixed) {
    dimension = "position-absolute w-100 h-100 bg-dark";
  }

  return (
    <div
      style={{ top: 0, left: 0, zIndex: 99, opacity: 0.6 }}
      className={`loading-wrapper d-flex justify-content-center align-items-center ${dimension}`}
    >
      <ReactLoading type="cylon" color="#fff" />
    </div>
  );
};

export default Loading;
