import React, { Component } from "react";
import { ReactComponent as Logo } from "../../images/img-logo-horizontal-wh-01.svg";
import imgLoadingscene01 from "../../images/img-loadingscene-01.png";
import imgLoadingscene02 from "../../images/img-loadingscene-02.png";
import imgLoadingscene03 from "../../images/img-loadingscene-03.png";
import { connect } from "react-redux";
import { ALPACO_CNAME } from "../../constants";

class Loading extends Component {
  // //const Loading = ({ isNotFixed, status, ws, randomScene = 1 }) => {
  // componentDidUpdate() {
  //   const { loadingStep } = this.props;
  //
  //   console.log("loadingClass==ws::", loadingStep);
  // }

  render() {
    const {
      isNotFixed,
      randomScene,
      authBasic: { organization }
    } = this.props;

    let organizationThis = null;
    if (organization) {
      organizationThis = organization.cname;
    }

    let dimension = "position-fixed vw-100 vh-100 bg-transparent";
    let dimension_bg = "position-fixed vw-100 vh-100 bg-dark";

    if (isNotFixed) {
      dimension = "position-absolute w-100 h-100 bg-transparent";
    }
    let tmpTime = 0;
    //let timeInterval = null;
    // const timeReturn = reset => {
    //   if (reset) {
    //     clearInterval(timeInterval);
    //   }
    //   timeInterval = setInterval(() => {
    //     if (tmpTime >= 20) {
    //       tmpTime = 1;
    //       clearInterval(timeInterval);
    //     }
    //     tmpTime++;
    //   }, 200);
    // };
    //
    const loadText = () => {
      let returnStr = "";
      switch (this.props.loadingStep) {
        case 1:
          returnStr = "터미널 연결중···";
          break;
        case 2:
          returnStr = "서비스 생성중···";
          break;
        case 3:
          returnStr = "저장소 생성중···";
          break;
        case 4:
          returnStr = "저장소 등록중···";
          break;
        case 5:
          returnStr = "강의 데이터 동기화 중···";
          break;
        case 6:
          returnStr = "연결완료···";
          break;
        default:
          returnStr = "데이터 정보 로드중···";
      }
      return returnStr;
    };

    return (
      <>
        <div
          style={{
            top: 0,
            left: 0,
            zIndex: 98,
            opacity: 0.8,
            backgroundColor: "#1e1e1e"
          }}
          className={`d-flex justify-content-center align-items-center ${dimension_bg}`}
        ></div>
        <div
          style={{
            top: 0,
            left: 0,
            zIndex: 99,
            backgroundColor: "#1e1e1e"
          }}
          className={`loading-wrapper d-flex justify-content-center align-items-center ${dimension}`}
        >
          {organizationThis === ALPACO_CNAME ? (
            <div
              className="ideloadingscene-bg justify-content-center align-items-center"
              style={{ width: "604px", height: "150px" }}
            >
              <div style={{ marginTop: "40px" }}>
                <Logo />
              </div>
              <div className="loading-text justify-content-center align-items-center">
                <div className="d-flex w-100 progress-bardefault">
                  <div
                    className="progress-baractive"
                    style={{
                      width: `${this.props.loadingStep * 20 + tmpTime}%`,
                      transition: "right 0.5s"
                    }}
                  ></div>
                </div>
                {loadText()}
                {this.props.loadingStep <= 5 &&
                  `${this.props.loadingStep * 20 + tmpTime}%`}
              </div>
            </div>
          ) : (
            <div className="ideloadingscene-bg align-items-center">
              {randomScene === 1 && (
                <>
                  <div className="img_loadingscene01">
                    <img src={imgLoadingscene01} alt="loading-scene-01" />
                  </div>
                  <div className="loading-text justify-content-center align-items-center">
                    <div className="d-flex w-100 progress-bardefault">
                      <div
                        className="progress-baractive"
                        style={{
                          width: `${this.props.loadingStep * 20 + tmpTime}%`,
                          transition: "right 0.5s"
                        }}
                      ></div>
                    </div>
                    {loadText()}
                    {this.props.loadingStep <= 5 &&
                      `${this.props.loadingStep * 20 + tmpTime}%`}
                  </div>
                </>
              )}
              {randomScene === 2 && (
                <>
                  <div className="img_loadingscene01">
                    <img src={imgLoadingscene02} alt="loading-scene-01" />
                  </div>

                  <div className="loading-text justify-content-center align-items-center">
                    <div className="d-flex w-100 progress-bardefault">
                      <div
                        className="progress-baractive"
                        style={{
                          width: `${this.props.loadingStep * 20 + tmpTime}%`,
                          transition: "right 0.5s"
                        }}
                      ></div>
                    </div>
                    {loadText()}
                    {this.props.loadingStep <= 5 &&
                      `${this.props.loadingStep * 20 + tmpTime}%`}
                  </div>
                </>
              )}
              {randomScene === 3 && (
                <>
                  <div className="img_loadingscene01">
                    <img src={imgLoadingscene03} alt="loading-scene-01" />
                  </div>
                  <div className="loading-text justify-content-center align-items-center">
                    <div className="d-flex w-100 progress-bardefault">
                      <div
                        className="progress-baractive"
                        style={{
                          width: `${this.props.loadingStep * 20 + tmpTime}%`,
                          transition: "right 0.5s"
                        }}
                      ></div>
                    </div>
                    {loadText()}
                    {this.props.loadingStep <= 5 &&
                      `${this.props.loadingStep * 20 + tmpTime}%`}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}
//};

const mapState = ({
  common: {
    ws: { step: loadingStep }
  },
  viditing: { status, randomScene },
  auth: { authBasic }
}) => ({
  loadingStep,
  status,
  randomScene,
  authBasic
});

export default connect(mapState, null)(Loading);
