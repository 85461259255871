import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import Tabs from "./Tabs";
import Editor from "./Editor";

import iconFolderOn from "../../images/btn-idefolder-t-01-on.svg";
import iconFolderOff from "../../images/btn-idefolder-t-01-off.svg";
import { ReactComponent as IconQuizPass } from "../../images/img-ide-quizpass.svg";
import { ReactComponent as IconQuizNextStep } from "../../images/btn-ide-nextstep.svg";
import { ReactComponent as IdeClap } from "../../images/img-ide-clap.svg";
import { ReactComponent as IdeCongratulaion } from "../../images/img-ide-congratulaion.svg";
import { ALPACO_CNAME, KBBANK_CNAME } from "../../constants";

import { terminalTabs } from "../../common";

class TabsAndEditor extends Component {
  isQuizStepFinish() {
    const { quiz } = this.props;
    const subQuizzesArr = Object.keys(quiz.subQuizzes);
    const filterCompleteQuiz = subQuizzesArr.filter(v => {
      return quiz.subQuizzes[v].completed === true;
    });

    if (filterCompleteQuiz.length && subQuizzesArr.length) {
      return filterCompleteQuiz.length === subQuizzesArr.length; // 전체 Quiz step 종료시
    } else {
      return false;
    }
  }

  getNextSubQuiz = () => {
    const { quiz, navigateSubQuiz, leftTab, setupLeftTab } = this.props;
    navigateSubQuiz(quiz.lastOnGoingSubQuiz);
    if (leftTab !== "quiz") setupLeftTab("quiz");
  };

  b2bQuizResult() {
    const { authBasic, quiz } = this.props;
    const subQuizzesArr = Object.keys(quiz.subQuizzes);
    const filterCompleteQuiz = subQuizzesArr.filter(v => {
      return quiz.subQuizzes[v].completed === true;
    });

    if (authBasic.organization?.cname !== ALPACO_CNAME) {
      return (
        <p className={"quiz-pass-title b2b"}>
          {filterCompleteQuiz.length} / {subQuizzesArr.length} 문항
        </p>
      );
    }
    return <p className={"quiz-pass-title b2b"}>총 {quiz.totalScore}점</p>;
  }

  // shouldComponentUpdate(nextProps, nextState, nextContext) {
  //   if (this.props.classType === "learning") {
  //     return nextProps !== this.props;
  //   } else {
  //     return nextProps !== this.props;
  //   }
  // }

  render() {
    const {
      forSolution,
      quiz,
      classType,
      terminals: { currentTab },
      sectors,
      currentFile,
      gtmPushDataLayer,
      authBasic,
      setQuiz,
      toggleModalStepIsCompleted
    } = this.props;

    const {
      [forSolution
        ? "gridForQuizSolutionFiletree"
        : "gridForFiletree"]: gridForFiletree,
      [forSolution
        ? "setGridForQuizSolutionFiletree"
        : "setGridForFiletree"]: setGridForFiletree
    } = this.props;

    const isCollapsed = gridForFiletree.colSize === 0;

    const subQuizzesArr = Object.keys(quiz.subQuizzes);
    const filterCompleteQuiz = subQuizzesArr.filter(v => {
      return quiz.subQuizzes[v].completed === true;
    });

    return (
      <div className="d-flex flex-column overflow-hidden">
        {!forSolution && (
          <div className="tabs-container d-flex" ref={sectors.TABS.ref}>
            <Button
              variant="link"
              className={
                classType !== "evaluation" ? "border-0" : "border-0 d-none"
              }
              onClick={() => {
                const { memorizedColSize, minSize } = gridForFiletree;
                const colSize = isCollapsed
                  ? memorizedColSize < minSize
                    ? minSize
                    : memorizedColSize
                  : 0;
                setGridForFiletree({ colSize });
                //console.log("mobileChk:", this.props.mobileChk);
                const { isMobile } = this.props.mobileChk;
                if (!isMobile) {
                  setTimeout(() => {
                    this.props.editor.ref.layout();
                  }, 150);
                }

                gtmPushDataLayer({
                  event: "ideFiletreeOpenFiletreeClick"
                });
              }}
            >
              <img
                src={isCollapsed ? iconFolderOff : iconFolderOn}
                alt=""
                className="d-block"
              />
            </Button>
            <div className="flex-grow-1">
              {classType !== "evaluation" && <Tabs />}
            </div>
          </div>
        )}

        <div
          className="flex-grow-1 d-flex"
          ref={!forSolution && sectors.EDITOR.ref}
          onClick={() => {
            if (
              authBasic.organization?.cname !== KBBANK_CNAME &&
              quiz.isQuizPassShow
            ) {
              setQuiz({
                isQuizPassShow: false
              });
              // } else if (
              //   authBasic.organization?.cname !== ALPACO_CNAME &&
              //   quiz.isQuizPassShow
              // ) {
              //   toggleModalStepIsCompleted(true);
            } else {
              if (
                !this.isQuizStepFinish() &&
                quiz.isSubQuizComplete &&
                classType === "evaluation" &&
                (!authBasic.organization ||
                  authBasic.organization?.cname === KBBANK_CNAME)
              ) {
                this.getNextSubQuiz();
              }
            }

            gtmPushDataLayer({
              event: "ideQuizPassClick"
            });
          }}
        >
          {/* b2c 퀴즈패스 */}
          {(!authBasic.organization ||
            authBasic.organization?.cname === KBBANK_CNAME) &&
            !this.isQuizStepFinish() &&
            quiz.isSubQuizComplete &&
            classType === "evaluation" && (
              <div className={"quiz-pass-wrapper"}>
                <p className={"quiz-pass-title"}>참 잘했어요!</p>
                <IconQuizPass className={"quiz-pass-icon"} />
                <p className={"quiz-pass-desc"}>Next Step</p>
                <IconQuizNextStep className={"quiz-nextstep-icon"} />
              </div>
            )}

          {/* b2b 퀴즈패스 */}
          {authBasic.organization &&
            authBasic.organization?.cname !== KBBANK_CNAME &&
            classType === "evaluation" &&
            quiz.isQuizPassShow && (
              <div className={"quiz-pass-wrapper"}>
                {this.b2bQuizResult()}
                <IdeCongratulaion className={"quiz-pass-icon"} />
                <IdeClap className={"quiz-pass-clap"} />
                {authBasic.organization?.cname !== ALPACO_CNAME && (
                  <p className={"quiz-pass-desc"}>Next Step</p>
                )}
                <IconQuizNextStep className={"quiz-nextstep-icon"} />
              </div>
            )}

          {currentFile && currentFile.isImage && (
            <div
              className="image-container flex-grow-1 d-flex align-items-center justify-content-center overflow-hidden"
              ref={sectors.IMAGE.ref}
            >
              <img src={currentFile.imageURL} alt="" />
            </div>
          )}

          <Editor
            show={
              classType === "learning"
                ? currentFile && !currentFile.isImage
                : true
            }
            sectors={this.sectors}
            readOnly={
              classType === "learning"
                ? currentTab === terminalTabs.TEACHER
                  ? true
                  : false
                : false
            }
            forSolution={forSolution}
            answer={
              forSolution && quiz.subQuizzes[quiz.inProgressSubQuiz].answer
            }
          />
        </div>
      </div>
    );
  }
}

const mapState = ({
  ide: {
    leftTab,
    gridForFiletree,
    gridForQuizSolutionFiletree,
    editorContainer,
    quiz,
    step,
    classType,
    terminals,
    editor,
    mobileChk
  },
  viditing: { currentFile, initialDirectory },
  auth: { authBasic }
}) => ({
  leftTab,
  gridForFiletree,
  gridForQuizSolutionFiletree,
  editorContainer,
  quiz,
  step,
  classType,
  terminals,
  editor,
  mobileChk,

  currentFile,
  initialDirectory,
  authBasic
});

const mapDispatch = ({
  ide: {
    setGridForFiletree,
    setGridForQuizSolutionFiletree,
    setupLeftTab,
    setQuiz,
    toggleModalStepIsCompleted
  },
  quiz: { navigateSubQuiz },
  gtm: { gtmPushDataLayer }
}) => ({
  setQuiz,
  setGridForFiletree,
  setGridForQuizSolutionFiletree,
  navigateSubQuiz,
  setupLeftTab,
  gtmPushDataLayer,
  toggleModalStepIsCompleted
});

export default connect(mapState, mapDispatch)(TabsAndEditor);
