export default {
  state: {
    paymentsTemp: {}
  },
  reducers: {
    setPaymentsTemp(state, payload) {
      console.log(payload);
      return {
        ...state,
        paymentsTemp: payload
      };
    }
  },
  effects: dispatch => ({})
};
