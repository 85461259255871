import React, { Component } from "react";

import { ReactComponent as Logo } from "../../images/img-logo-horizontal-wh-01.svg";
import { ReactComponent as SystemNotice } from "../../images/img-systemnotice.svg";
import Moment from "react-moment";

class Maintenance extends Component {
  render() {
    return (
      <div className="d-flex flex-column w-100 h-100 maintenance-container">
        <div className="p-5"></div>
        <div className="p-5"></div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="p-5"></div>

          <div className="p-5 justify-content-center">
            <div className="p-4"></div>
            <div>
              <Logo />
            </div>
            <div className="p-4"></div>
            <div className="p-1"></div>
            <div className="d-flex align-items-start justify-content-center">
              <SystemNotice />
              <span className="p-2"></span>
              <div className="d-flex flex-column w-100 h-100 maintenance-container">
                <div className="maintenance-text1">서비스 점검중 입니다.</div>
                <div className="p-3"></div>
                <div className="maintenance-text2">
                  보다 안정적인 서비스 제공을 위해 코드라이언 서비스를
                  점검중입니다.
                </div>{" "}
                <div className="maintenance-text2">
                  점검시간 동안 서비스 이용이 제한되는 점 양해 부탁드립니다.
                </div>{" "}
                <div className="maintenance-text2">
                  업데이트 후 더욱 멋진 서비스로 찾아뵙겠습니다 :)
                </div>
                <div className="p-4"></div>
                <div className="d-flex align-items-center maintenance-bottom">
                  <div className="p-2"></div>
                  <div className="d-flex flex-column">
                    <div className="align-items-center maintenance-bottom-text1">
                      점검일시
                    </div>
                    <div className="align-items-center maintenance-bottom-text2">
                      <Moment format="YYYY/MM/DD hh:mm:ss">
                        {this.props.systemMaintenance.start}
                      </Moment>
                      {`(약 ${this.props.systemMaintenance.duration}분 소요)`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-5"></div>
          <div className="p-5"></div>
        </div>
      </div>
    );
  }
}

export default Maintenance;
