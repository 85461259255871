import React, { Component } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { RESEND_VERIFY_EMAIL } from "../../../graphql/mutations";
import { toast } from "react-toastify";
import qs from "query-string";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { TOAST_OPTION } from "../../../constants";

class EmailInfomation extends Component {
  state = { username: null };

  componentDidMount() {
    const { search } = document.location;
    this.setState({ ...this.state, username: qs.parse(search).email });
  }

  onEmailReSend = async () => {
    try {
      const {
        data: {
          resendVerifyEmail: { ok }
        }
      } = await this.props.client.mutate({
        mutation: RESEND_VERIFY_EMAIL,
        variables: {
          email: this.state.username,
          redirectUrl: document.location.origin,
          locale: this.props.i18n.language.split("-")[0].toUpperCase()
        }
      });
      if (ok) {
        toast.success(
          `새로운 인증 메일이 발송되었습니다. 메일함을 확인해주세요.`,
          TOAST_OPTION(2000)
        );
        //history.replace("/auth/signin");
      }
    } catch ({ graphQLErrors, message }) {
      toast.error(
        `새로운 인증 메일이 발송되지 못했습니다.

(${message})`,
        TOAST_OPTION(2000)
      );
    }
  };

  render() {
    const { gtmPushDataLayer } = this.props;

    return (
      <Container className="auth-container signup-failure d-flex flex-column align-items-center">
        <h2>회원가입 인증 요청</h2>
        <p className="d-inline-block mb-5">
          <span>이메일 인증 후 코드라이언을 시작해주세요!</span>
          <br />
          <br />
          <span className=" mb-5" style={{ color: "#ffffff" }}>
            앗! 인증메일이 사라지셨나요?
            <br />
            <span
              onClick={() => {
                this.onEmailReSend();
                gtmPushDataLayer({
                  event: "signUpGetLinkAgainClick"
                });
              }}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                color: "#00ffdd"
              }}
            >
              인증 링크 재발급 받기
            </span>
          </span>
        </p>
      </Container>
    );
  }
}

const mapState = ({
  common: {
    apollo: {
      client: { general: client }
    }
  }
}) => ({ client });

const mapDispatch = ({ gtm: { gtmPushDataLayer } }) => ({
  gtmPushDataLayer
});

export default withTranslation()(
  withRouter(connect(mapState, mapDispatch)(EmailInfomation))
);
